import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
    case ACTION.SET_PLANS:
      return action.productPlans;

    default:
      return state;
  }
}
