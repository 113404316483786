/* eslint-disable */

import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { red, yellow, grey } from '@material-ui/core/colors';

import { useTheme } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Person from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';

import DroppableWPCardList from './DroppableWPCardList';
import DroppableTaskCardList from './DroppableTaskCardList';
import { AddButton } from '../styled_components/AddButton';
import { PinButton } from '../styled_components/PinButton';

import {
  handleMenu,
  dispatchUpdateViewState,
  setUserColleguePref,
  updateViewState,
  saveCurrentProject,
} from '../../data/actions/userInterface';
import ActionMenu from '../styled_components/ActionMenu';
import { PriorityIcon } from '../Icons/PriorityIcon';

import * as STATUS from '../../constants/status';
import * as NAV from '../../constants/navigation';
import * as TASK from '../../constants/tasks';
import * as utils from '../../utils/generalUtilities';
import * as constants from '../../constants/app';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  column: {
    flex: 1,
    borderLeft: ({ sprintColor }) => `solid 3.5px ${sprintColor}`,
    borderRight: ({ sprintColor }) => `solid 3.5px ${sprintColor}`,
    borderTop: ({ sprintColor }) => `solid 10px ${sprintColor}`,
    minWidth: '250px',
    maxWidth: '400px',
    marginLeft: '7px',
    marginRight: '8px',
    overflow: 'auto',
    width: '100%',
    cursor: 'pointer',
    minHeight: '70vh',
    background: theme.palette.workCard.wbsColumn,
    // maxHeight: ({ isExpanded }) => (isExpanded ? '80vh' : '60px'),
  },
  columnTop: {
    position: 'sticky',
    height: '8px',
    // background: 'red',
    background: `${theme.palette.workCard.started}`,
  },
  columnHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'sticky',
    alignItems: 'flex-start',
    top: 0,
    padding: '15px',
    background: theme.palette.workCard.wbsColumn,
    zIndex: 1,
  },

  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginBottom: '-10px',
    // backgroundColor: 'green'
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    // backgroundColor: 'green'
  },
  lowerRightContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addButtonContainer: {
    // marginTop:
    marginLeft: 5,
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: 10,
    marginBottom: -5,
  },
  addWPButton: {
    height: 14,
    alignSelf: 'center',
    // backgroundColor: 'green'
    marginTop: -5,
    marginBottom: -5,
  },
  addButton: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    margin: '0 5px',
    '&:hover': {
      // background: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    '& svg': {
      fontSize: 22, // sets size of IconButton and scales the font inside
    },
    boxShadow: 1,
  },
  sprintTitle: {
    maxHeight: 50,
    overflow: 'hidden',
  },
  priorityIcon: {
    // marginTop: 4,
    // backgroundColor: 'green'
  },
  rightTitleComponents: {
    flex: 0,
    display: 'flex',
    marginTop: -2,
    marginLeft: 8,
    // position: 'absolute', // to parent as parent position is relative/sticky
    // paddingLeft: 10,
    // top: 12,
    // right: 5,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  colTitle: {
    fontSize: '1.2rem',
    fontWeight: '600',
    width: '100%',
    color: '#444',
  },
  subTitleRow: {
    display: 'flex',
    marginTop: -2,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginLeft: 4,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  tooltip: {
    fontSize: 20,
    // marginTop: 5,
    marginLeft: 4,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  numTasks: {
    fontSize: '.78rem',
  },
  dateString: {
    fontSize: '.8rem',
    alignSelf: 'flex-start',
  },
  endDate: {
    marginLeft: 15,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  endDate2: {
    marginTop: -10,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  pinButton: {
    marginTop: 4,
    marginLeft: 5,
  },
}));

let scrollTimer;

// Draggable container for deliverable header & WP card list
const SprintColumn = ({
  sprint,
  sprintId,
  index,
  isExpanded,
  viewStack,
  viewState,
  handleMenu,
  dispatchUpdateViewState,

  userPrefs,
  onboarding,
  updateViewState,
  saveCurrentProject,
  workflows,
}) => {
  const draggableId = sprintId;
  let { numWeeksInSprint } = viewState;
  const title = sprintId;
  const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(sprintId, numWeeksInSprint);
  const subTitle = sprintStartDate ? `${sprintStartDate.split(',')[0]} - ${sprintEndDate}` : '-';
  const theme = useTheme();

  const sprintColor =
    sprintId === constants.NO_SPRINT
      ? '#8885'
      : sprintId < utils.getRelativeSprintId(numWeeksInSprint)
      ? '#f885'
      : theme.palette.workCard.wbsColumnBorderStarted;
  const classes = useStyles({ isExpanded, sprintColor });

  // <Draggable draggableId={draggableId} index={index}>
  // {(provided, snapshot) => (
  return (
    <Grid
      className={classes.column}
      onScroll={e => {
        if (!viewState.scrollTop.item) viewState.scrollTop.item = {};
        if (e.currentTarget.scrollTop !== viewState.scrollTop.item[sprintId]) {
          if (scrollTimer) {
            clearTimeout(scrollTimer);
          }
          const scrollTop = e.currentTarget.scrollTop;
          scrollTimer = setTimeout(e => {
            // debounce scoll values
            viewState.scrollTop.item[sprintId] = scrollTop;
            scrollTimer = undefined;
          }, 50);
        }
      }}
    >
      <div className={classes.columnHeader}>
        <div className={classes.titleContents}>
          <div className={classes.titleContainer}>
            <div className={classes.sprintTitle}>
              <Tooltip title={`Work Period ${title}`}>
                <Typography className={classes.colTitle}>{title}</Typography>
              </Tooltip>
            </div>
          </div>
          <div className={classes.sprintTitle}>
            <Typography className={classes.subTitleRow}>{subTitle}</Typography>
          </div>
        </div>
      </div>
      <DroppableWPCardList
        listId={sprintId}
        wpCards={sprint}
        viewStack={viewStack}
        parentContext={NAV.SPRINTVIEW}
      />
    </Grid>
  );
};
// )}
// </Draggable>
export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    dispatchUpdateViewState,
    setUserColleguePref,
    updateViewState,
    saveCurrentProject,
  },
)(SprintColumn);
