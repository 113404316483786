import React from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import DraggableWorkflowCard from './DraggableWorkflowCard';
import * as utils from '../../utils/generalUtilities.js';

// Droppable container for Dragable WP cards
export const DroppableWorkflowCardList = ({
  listId = 'LIST',
  workflows, // all
  saveWorkflows,
  templateSetId,
  isPrivateList,
}) => {
  let count = 0;
  const curWorkflowSet = workflows[templateSetId];
  if (!curWorkflowSet) return null;

  const onDragEnd = result => {
    // console.log(`drag ended - do something`);
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      destination.index === 0 ||
      source.index === 0 ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      return;
    }
    let targetLevel = curWorkflowSet[destination.index].library.level;
    if (curWorkflowSet[destination.index + 1]) targetLevel = curWorkflowSet[destination.index].library.level;

    const movedWFs = [curWorkflowSet[source.index]];
    const sourceLevel = curWorkflowSet[source.index].library.level;
    curWorkflowSet.splice(source.index, 1);
    let curIndex = source.index;
    let done = false;
    while (curWorkflowSet[curIndex] && !done) {
      // handle move of children
      if (curWorkflowSet[curIndex].library.level > sourceLevel) {
        movedWFs.push(curWorkflowSet[curIndex]);
        curWorkflowSet.splice(curIndex, 1);
      } else {
        done = true;
      }
    }
    curIndex = destination.index;
    let startLevel;
    movedWFs.forEach(wf => {
      if (!startLevel) {
        startLevel = wf.library.level;
        wf.library.level = targetLevel + wf.library.level - startLevel;
      } else {
        wf.library.level = targetLevel + wf.library.level - startLevel;
      }

      curWorkflowSet.splice(curIndex, 0, wf);
      curIndex++;
    });
    utils.setSmartTemplateIndent(workflows);
    saveWorkflows(workflows); // will push to cloud and update store aftewards
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={listId} type={undefined}>
        {(dropProvided, dropSnapshot) => (
          <div
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps} //
            style={{ paddingBottom: '60px' }}
          >
            {curWorkflowSet.map((project, index) => {
              // console.log(`proj card list - ${project.name} - ${project.template} ${isWorkflow} ${count}`)
              if (count > 200) return null;
              else {
                count++;
                return (
                  <DraggableWorkflowCard
                    key={`wcl-${project.uuid}`}
                    workflow={project}
                    index={index}
                    thin={true}
                    templateSetId={templateSetId}
                    isPrivateList={isPrivateList}
                  />
                );
              }
            })}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
