import * as ACTION from '../../actions/actionTypes';

export function getUsersAssignments(firebase, userId) {
  return new Promise((resolve, reject) => {
    return firebase.db
      .collection('assignments')
      .doc(userId)
      .get()
      .then(docRef => {
        if (docRef.exists) {
          const docData = docRef.data();
          if (!docData) resolve([]);
          resolve(docData.assignmentOrder);
        }
        resolve([]);
      })
      .catch(function(err) {
        reject(`Error getting user's ${userId} assignments: ${err}`);
      });
  });
}

export function saveAssignments(assignmentOrder) {
  return (dispatch, getState) => {
    let { firebase, user } = getState();

    firebase.db
      .collection('assignments')
      .doc(user.uid)
      .set({ assignmentOrder: assignmentOrder }) // save remotely for remote access
      .then(result => {
        dispatch({
          type: ACTION.SET_ASSIGNMENT_ORDER,
          assignmentOrder: assignmentOrder, // update order locally to viewstate reducer
        });
      })
      .catch(error => {
        console.warn(`======= Error updating personal assignments order ${error}`);
      });
  };
}
