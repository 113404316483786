import React from 'react';
import { makeStyles } from '@material-ui/core';

const logoStyles = makeStyles(() => ({
  logo: {
    width: '40px',
    paddingTop: '10px',
    marginLeft: 15,
  },
  white: {
    fill: '#020202',
  },
  primary: {
    fill: '#50e2c2',
  },
}));

export const Logo = () => {
  const classes = logoStyles();
  return (
    <svg
      className={classes.logo}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485.71 411.65"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="96.99"
          y1="339.76"
          x2="208.32"
          y2="339.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#50e2c2" />
          <stop offset="1" stopColor="#c6f6ea" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className={classes.white}
            d="M0 0h110.08c57.45 0 98.83 41.78 98.83 98.43s-41.38 98-98.83 98H56.25v84.76H0zm110.08 140.21c26.92 0 42.58-17.68 42.58-41.78 0-24.51-15.66-42.19-42.58-42.19H56.25v84zM364.1 18.5l121.61 262.72h-61.87l-20.49-44.59H257L355.9 18.5m21.34 161.89L329.43 77.14l-46.65 103.25z"
          />
          <path
            className={classes.primary}
            d="M176.91 308.5L311.02 17.84h53.05l4.83 11.27-160.49 350.74h-.09"
          />
          <path
            d="M208.32 379.85l-9.81 18.95a23.79 23.79 0 01-42.25 0l-9.8-18.95h-.18L97 267.86h62z"
            fill="url(#linear-gradient)"
            opacity=".8"
          />
          <path
            className={classes.primary}
            d="M161.08 308.5l31.41 71.35M176 308.5L310.09 17.84h53.05L368 29.11 207.48 379.85h-.09l-9.81 18.95a23.79 23.79 0 01-42.25 0l-9.8-18.95h-.18"
          />
        </g>
      </g>
    </svg>
  );
};
