import * as ACTION from '../actions/actionTypes';
import * as NAV from '../../constants/navigation';
import * as constants from '../../constants/app';

const INITIAL_STATE = {
  curProjectUUID: null,
  curWindow: NAV.DASHBOARD,
  prevWindow: null,
  lastWindow: {}, // holds last window per projectId
  showGroupDialog: false,
  currentProjectType: NAV.TYPE_PROJECT,
  currentProjectIndex: 0,
  currentWorkflowIndex: 0,
  currentWorkflowSetId: constants.PUBLIC_GROUP_ID,
  currentDeliverable: 0,
  currentWorkPackage: 0,
  currentTask: 0,
  showWorkPackages: true,
  alert: false,
  alertYesButton: 'yes',
  form: false,
  formType: '',
  help: false,
  textLabel: '',
  title: '',
  text: '',
  copyMode: 'COPY',
  copyType: null,
  copyContent: null,
  updatedDoc: null, // external doc changes held while user asked what to do
  overviewTabIndex: 0,
  detailsTabIndex: 0,
  showingAnalytics: false,
  notesExpanded: true,
  usibilityReport: {
    // state memory for Needs Attention report on dashboard
    overdue: true,
    today: true,
    soon: true,
    missing: false,
    justMe: true,
  },
  projectScheduleReport: {
    // state memory for Schedule screen
    overdue: false,
    today: false,
    soon: false,
    missing: false,
    justMe: false, // deprecated, replaced by team-based selectors, projectTeams
    priority: false,
    statusInProgress: false,
    statusDone: false,
    statusNotStarted: false,
    projectTeams: {},
    projectTimeFilters: {},
  },
  scrollTop: {},
  projectScheduleScrollInfo: { scrollTop: 0, scrollHeight: 100 },
  wbsStack: [{ screen: NAV.BOARD, item: { d: -1, w: -1, t: -1 }, level: 'p' }], // new nav item, makes currentDel/WP/Task above redundant .. todo remove those in future
  libStack: [
    {
      screen: NAV.LIBRARY_BOARD,
      item: { d: -1, w: -1, t: -1 },
      level: 'p',
    },
  ],
  // current focused item is held withing stack state... allows jumping around
  wbsCardsUpdateTS: new Date().getTime(),
  libCardsUpdateTS: new Date().getTime(),
  showNotNeededTasks: true,
  sortTasksByStatus: true,
  infoDialogData: null,
  showUnpinned: true,
  showDetailUnpinned: true,
  numWeeksInSprint: 2, // loaded with project
  splitWP: null,
  mergeWPs: null,
  assignmentOrder: {
    dashboard: [], // x projects
    project: {}, // per project
  },
};

export default function viewState(state = INITIAL_STATE, action = {}) {
  // console.log(`ACTION: ${action.type}`)
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.viewState) {
        // delete action.payload.viewState.curWindow;
        // delete action.payload.viewState.currentProjectType;
        delete action.payload.viewState.prevWindow;
        delete action.payload.viewState.showGroupDialog;
        delete action.payload.viewState.form;
        delete action.payload.viewState.alert;
        delete action.payload.viewState.wbsStack;
        delete action.payload.viewState.libStack;
        delete action.payload.viewState.infoDialogData;
        // delete action.payload.viewState.SPLIT_WP;
        // delete action.payload.viewState.SPLIT_WPS;
        // delete action.payload.viewState.splitWS;
        action.payload.viewState.splitWP = null;
        action.payload.viewState = {
          ...INITIAL_STATE,
          ...action.payload.viewState,
          usibilityReport: { ...INITIAL_STATE.usibilityReport, ...action.payload.viewState.usibilityReport },
          projectScheduleReport: {
            ...INITIAL_STATE.projectScheduleReport,
            ...action.payload.viewState.projectScheduleReport,
          },
          // usibilityReport: {...INITIAL_STATE.usibilityReport},
          // projectScheduleReport: {...INITIAL_STATE.projectScheduleReport},
        };
        // Object.assign(action.payload.viewState, result);
        return action.payload.viewState;
      }
      return state;

    case ACTION.SET_USER_INFO:
      return {
        ...state,
        ...action.viewState,
        // curWindow: state.curWindow,
        showGroupDialog: false,
        detailsTabIndex: state.detailsTabIndex,
        alert: INITIAL_STATE.alert,
        alertYesButton: INITIAL_STATE.alertYesButton,
        form: INITIAL_STATE.form,
        formType: INITIAL_STATE.formType,
        help: INITIAL_STATE.help,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
      };
    case ACTION.UPDATE_WORKFLOWS:
    case ACTION.UPDATE_PROJECTS:
      return {
        ...state,
        ...action.newViewState,
        detailsTabIndex: state.detailsTabIndex,
        alert: INITIAL_STATE.alert,
        alertYesButton: INITIAL_STATE.alertYesButton,
        form: INITIAL_STATE.form,
        formType: INITIAL_STATE.formType,
        help: INITIAL_STATE.help,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
      };

    case ACTION.SET_GROUP:
      return {
        ...state,
        curWindow: state.curWindow,
        showGroupDialog: false,
        ...action.viewState,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
      };

    // case ACTION.SET_SPRINT_PERIOD:
    //   return {
    //     ...state,
    //     numWeeksInSprint: action.numWeeksInSprint,
    //     wbsCardsUpdateTS: new Date().getTime(),
    //   };

    case ACTION.SET_ASSIGNMENT_ORDER:
      return {
        ...state,
        assignmentOrder: action.assignmentOrder,
      };

    case ACTION.MERGE_WPS:
      return {
        ...state,
        splitWP: null,
      };

    case ACTION.SPLIT_WP:
      const newState = {
        ...state,
        splitWP: action.splitWP,
      };
      if (action.updateTS) {
        // after split complete
        newState.wbsCardsUpdateTS = new Date().getTime();
        newState.libCardsUpdateTS = new Date().getTime();
      }
      // console.log(action.splitWP)
      return newState;

    case ACTION.SET_VIEW_STATE:
    case ACTION.PROCESS_COMMAND:
      return {
        ...state,
        curWindow: state.curWindow,
        ...action.viewState,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
      };

    case ACTION.TOGGLE_NOT_NEEDED_TASK_VISIBILITY:
      return {
        ...state,
        showNotNeededTasks: !state.showNotNeededTasks,
      };

    case ACTION.TOGGLE_PIN_VISIBILITY:
      return {
        ...state,
        showUnpinned: !state.showUnpinned,
      };

    case ACTION.TOGGLE_DETAIL_PIN_VISIBILITY:
      return {
        ...state,
        showDetailUnpinned: !state.showDetailUnpinned,
      };

    case ACTION.RESET_PROJECT_SCHEDULE_BUTTONS:
      return {
        ...state,
        projectScheduleReport: {
          overdue: false,
          today: false,
          soon: false,
          missing: false,
          justMe: false,
          priority: false,
          statusInProgress: false,
          statusDone: false,
          statusNotStarted: false,
          projectTeams: state.projectScheduleReport.projectTeams,
          projectTimeFilters: state.projectScheduleReport.projectTimeFilters,
        },
        projectScheduleScrollInfo: 0,
      };

    case ACTION.TOGGLE_SORT_TASKS:
      return {
        ...state,
        sortTasksByStatus: !state.sortTasksByStatus,
      };

    case ACTION.UPDATE_PROJECT_CARDS:
      return {
        ...state,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
      };

    case ACTION.RESET_UI_PARMS:
      return {
        ...INITIAL_STATE,
        curWindow: state.curWindow,
        lastWindow: state.lastWindow,
        detailsTabIndex: state.detailsTabIndex,
        currentProjectType: state.currentProjectType,
        currentProjectIndex: state.currentProjectIndex,
        currentWorkflowIndex: state.currentWorkflowIndex,
        currentWorkflowSetId: state.currentWorkflowSetId,
        currentDeliverable: state.currentDeliverable,
        currentWorkPackage: state.currentWorkPackage,
        currentTask: state.currentTask,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
        wbsStack: state.wbsStack,
        libStack: state.libStack,
      };

    // case ACTION.CLEAR_USER_INFO:
    //   return {
    //     ...INITIAL_STATE,
    //     lastWindow: state.lastWindow,
    //   };

    case ACTION.SET_PS_SCROLL_TOP:
      return {
        ...state,
        projectScheduleScrollInfo: action.scrollInfo,
      };
    default:
      return state;
  }
}
