import * as ACTION from '../actions/actionTypes';
// import * as utils from '../../utils/generalUtilities';
import { getInitialUserPrefs } from '../../data/actions/app';

export default function UserPrefsReducer(state = getInitialUserPrefs(), action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
    case ACTION.SET_PREFERENCES:
      return action.userPrefs;

    default:
      return state;
  }
}
