
const MS_TEST_CLIENT_ID = '2976f4dc-c8be-4e2c-a33e-a38b9ade1a55'; //currently steve's test app
const MS_CLIENT_ID = '9489c8c5-b415-4eca-8045-af7476f20689'; //currently steve's test app

const redirectURI = window.location.origin;

export const config = {
  // clientId: origin === 'https://app.project-assistant.com' ? MS_CLIENT_ID : MS_CLIENT_ID,  // for testing prod backend app
  clientId: origin === 'https://app.project-assistant.com' ? MS_CLIENT_ID : MS_TEST_CLIENT_ID,
  redirectUri: redirectURI,
  scopes: [
    'User.Read',
    'Files.Read.All',
    // 'calendars.readwrite'
  ]
};


