/* eslint-disable */

import { Tooltip } from '@material-ui/core';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableProjectCard from './DraggableProjectCard';

// Droppable container for Dragable WP cards
export const DroppableProjectCardList = ({
  listId = 'LIST',
  projects, // actual cards now
  isWorkflow = false,
  showLimitReachedDialog,
}) => {
  let count = 0;
  // console.log(`New proj card list -++++++++++++++`)

  return (
    <Droppable droppableId={listId} type={undefined}>
      {(dropProvided, dropSnapshot) => (
        <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
          {projects.map((project, index) => {
            // console.log(`proj card list - ${project.name} - ${project.template} ${isWorkflow} ${count}`)
            if (count > 200 || project.deletedDate) return null;
            else {
              count++;
              return (
                <DraggableProjectCard
                  key={`pcl-${project.uuid}-${index}`}
                  project={project}
                  index={index}
                  isWorkflow={isWorkflow}
                  thin={true}
                  showLimitReachedDialog={showLimitReachedDialog}
                />
              );
            }
          })}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
