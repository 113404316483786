import React, { Fragment } from 'react';
// import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Chip, Card, CardContent, Tooltip, Badge } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AttachFile from '@material-ui/icons/AttachFileOutlined';
import Reorder from '@material-ui/icons/ReorderOutlined';
import * as NAV from '../../constants/navigation';
import * as TASK from '../../constants/tasks';
import * as constants from '../../constants/app';
import { InfoButton } from '../styled_components/InfoButton';
const useStyles = makeStyles(theme => ({
  wrapperContainer: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    overflowY: ({ scrollY }) => (scrollY ? 'scroll' : 'hidden'),
    marginLeft: 10,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ alignRight }) => alignRight && 'space-between',
    alignItems: ({ alignBaseline }) => (alignBaseline ? 'baseline' : 'center'),
    marginBottom: ({ headerMarginBottom }) => headerMarginBottom,
    marginTop: ({ headerMarginTop }) => headerMarginTop ?? 0,
    width: ({ headerWidth }) => `${headerWidth - 100}px`,
    // maxHeight: '80px',
  },
  splitWPtitle: ({ alignRight, indentTitle }) => ({
    minWidth: alignRight ? '35vw' : '20px',
    fontSize: '1.25rem',
    fontWeight: 700,
    // color: 'blue',
    color: '#00afa9',
    marginRight: '20px',
    marginLeft: indentTitle ? `${indentTitle}px` : undefined,
  }),
  title: ({ alignRight, indentTitle }) => ({
    minWidth: alignRight ? '35vw' : '20px',
    fontSize: '1.25rem',
    fontWeight: 550,
    color: '#555',
    marginRight: '20px',
    marginLeft: indentTitle ? `${indentTitle}px` : undefined,
  }),
  cardTitle: {
    fontSize: '0.8rem',
    fontWeight: 550,
    color: '#888',
    marginLeft: 8,
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxHeight: ({ maxTitleCardHeight }) => (maxTitleCardHeight ? maxTitleCardHeight : undefined),
  },
  attachIcon: {
    alignSelf: 'flex-start',
    height: '30px',
    width: '30px',
    border: '2px solid #aaa',
    borderRadius: '5px',
    marginBottom: '4px',
    marginLeft: '20px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6',
  },
  card: {
    boxShadow: '1px 1px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1.2px ${theme.palette.workCard.notStarted}`,
    cursor: 'pointer',
    zIndex: 5,
    // maxHeight: 40,
    minWidth: 400,
    marginRight: 15,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'flex-start',
    alignItems: 'flex-start',
    padding: 0,
    paddingLeft: 10,
    paddingTop: 5,
  },
  cardLeftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart',
  },
  started: {
    background: theme.palette.workCard.started,
  },
  completed: {
    background: theme.palette.workCard.completed,
  },
  chip: {
    marginRight: '15px',
    border: ({ viewCard }) =>
      viewCard
        ? `solid 1px ${viewCard.cardType === TASK.TASK_CARD ? '#C250E2A0' : '#E2C250C0'}`
        : 'solid 1px',
    background: ({ viewCard }) =>
      viewCard ? (viewCard.cardType === TASK.TASK_CARD ? '#C250E225' : '#E2C25030') : 'none',
  },
  backButton: {
    marginRight: 20,
    // marginTop: 14,
    color: '#FFF',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.menu,
    },
    '& svg': {
      fontSize: 35, // sets size of IconButton and scales the font inside
    },
  },
  editButton: {
    alignSelf: 'flex-start',
    color: '#999',
    width: '40px',
    height: '40px',
    marginTop: -8,
  },
  editButtonIcon: {
    fontWeight: 800,
  },
}));

export const ContentWrapper = ({
  children,
  viewStack,
  title,
  superTitle,
  titleChildren,
  alignRight = false,
  isSmall = false,
  scrollY = false,
  alignBaseline = false,
  refId = null,
  backToWindow = null,
  detailEditStr = null,
  viewState,
  dispatchUpdateViewState,
  handleMenu = null,
  viewCard = null,
  indentTitle = false,
  headerMarginTop = 0,
  headerMarginBottom = 30,
  toggleAttachmentsOpen = null,
  attachmentState,
  badge,
  backCallback,
  headerWidth = window.innerWidth,
  maxTitleCardHeight,
  splitWPTitle = false,
  theme,
  setInfoDialog,
  saveCurrentProject,
}) => {
  const classes = useStyles({
    alignRight,
    isSmall,
    scrollY,
    alignBaseline,
    viewCard,
    indentTitle,
    detailEditStr,
    headerMarginTop,
    headerMarginBottom,
    headerWidth,
    maxTitleCardHeight,
  });

  const completed = !viewCard
    ? false
    : viewCard.cardType === TASK.DELIVERABLE_CARD
    ? viewCard.deliverable.actualEndDate !== ''
    : viewCard.cardType === TASK.WORKPACKAGE_CARD
    ? viewCard.workpackage.actualEndDate !== ''
    : viewCard.task.actualEndDate !== '';

  const started = !viewCard
    ? false
    : viewCard.cardType === TASK.DELIVERABLE_CARD
    ? viewCard.deliverable.actualStartDate !== ''
    : viewCard.cardType === TASK.WORKPACKAGE_CARD
    ? viewCard.workpackage.actualStartDate !== ''
    : viewCard.task.actualStartDate !== '';

  const cardTitle = !viewCard
    ? ''
    : viewCard.cardType === TASK.DELIVERABLE_CARD
    ? 'Deliverable'
    : viewCard.cardType === TASK.WORKPACKAGE_CARD
    ? `Task Group in ${viewCard?.parentCard?.deliverable?.name}`
    : `Task in ${viewCard?.parentCard?.workpackage?.name}`;

  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.headerContainer}>
        {backToWindow && (
          <IconButton
            // color="inherit"
            aria-label="back"
            onClick={() => {
              if (viewStack) {
                const newStack = viewStack.slice(); // copy existing stack
                if (newStack.length > 1) {
                  newStack.pop(); // pop last item
                }
                Object.assign(viewState, {
                  detailsTabIndex: 1,
                  curWindow: backToWindow,
                });
                if (backToWindow === NAV.BOARD || backToWindow === NAV.SPRINTVIEW) {
                  viewState.wbsStack = newStack;
                } else {
                  viewState.libStack = newStack;
                }
              } else {
                Object.assign(viewState, {
                  curWindow: backToWindow,
                  prevWindow: null,
                });
              }
              if (backCallback) {
                backCallback();
              }
              dispatchUpdateViewState(viewState);
            }}
            edge="start"
            size="small"
            className={classes.backButton}
            style={{ marginLeft: '8px' }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {detailEditStr ? (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={classes.cardRow}>
              <div className={classes.cardContainer}>
                <Typography className={classes.cardTitle}>{cardTitle}</Typography>
                <Card
                  key={`cwcard`}
                  id={`cwcard`}
                  className={`
                    ${classes.card} 
                    ${completed ? classes.completed : ''} 
                    ${started ? classes.started : ''}
                  `}
                  variant={'elevation'}
                >
                  <CardContent className={classes.cardContent}>
                    <div className={classes.cardLeftContent}>
                      {refId && <Chip label={refId} className={classes.chip} />}
                      <Typography className={classes.title} variant="h5">
                        {title}
                      </Typography>
                    </div>
                    {detailEditStr && (
                      <IconButton
                        aria-label="edit name"
                        onClick={() => {
                          handleMenu(detailEditStr);
                        }}
                        edge="start"
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </CardContent>
                </Card>
              </div>
              {viewCard.cardType === TASK.WORKPACKAGE_CARD && (
                <InfoButton
                  handleClick={() => {
                    setInfoDialog({
                      // sets InfoDialog reducer values
                      title: `Task Group: ${viewCard.workpackage.name}`,
                      content: {
                        description: viewCard.workpackage.description,
                        tooltip: viewCard.workpackage.tooltip ?? constants.DEFAULT_TOOLTIP,
                      },
                      onSave: result => {
                        viewCard.workpackage.description = result.description;
                        viewCard.workpackage.tooltip = result.tooltip;
                        saveCurrentProject();
                      },
                    });
                  }}
                  size="small"
                  color={theme.palette.primary.infoButton}
                  bgdColor="#FFF0"
                  hoverColor={theme.palette.primary.infoButton + '10'}
                  borderColor={theme.palette.primary.infoButton}
                  tooltip={viewCard.workpackage.tooltip ?? constants.DEFAULT_TOOLTIP}
                  containerClassName={classes.infoButton}
                  type={'i'}
                />
              )}
              {toggleAttachmentsOpen && attachmentState && (
                <Tooltip
                  title={
                    cardTitle === 'Task'
                      ? 'show task'
                      : cardTitle === 'Task Group'
                      ? 'show tasks'
                      : 'show workpackages'
                  }
                >
                  <Reorder
                    className={classes.attachIcon}
                    style={{ alignSelf: 'flex-start', marginRight: '20px', marginTop: '20px' }}
                    onClick={e => {
                      toggleAttachmentsOpen();
                    }}
                  />
                </Tooltip>
              )}
              {toggleAttachmentsOpen && !attachmentState && (
                <Tooltip title={!badge || badge === 0 ? 'add attachments' : 'view attachments'}>
                  <Badge
                    badgeContent={badge}
                    color="secondary"
                    style={{ alignSelf: 'flex-start', marginRight: '20px', marginTop: '20px' }}
                  >
                    <AttachFile
                      className={classes.attachIcon}
                      onClick={e => {
                        toggleAttachmentsOpen();
                      }}
                    />
                  </Badge>
                </Tooltip>
              )}
            </div>
            {titleChildren}
          </div>
        ) : (
          <Fragment>
            {splitWPTitle && (
              <Typography className={classes.splitWPtitle} variant="h5">
                Split Task Group:
              </Typography>
            )}
            {refId && <Chip label={refId} className={classes.chip} />}
            {superTitle ? (
              <div className={classes.cardContainer}>
                <Typography className={classes.cardTitle} style={{ marginLeft: 1 }}>
                  {superTitle}
                </Typography>
                <Typography className={classes.title} variant="h5">
                  {title}
                </Typography>
              </div>
            ) : (
              <Typography className={classes.title} variant="h5">
                {title}
              </Typography>
            )}
            {detailEditStr && (
              <IconButton
                aria-label="edit name"
                onClick={() => {
                  handleMenu(detailEditStr);
                }}
                edge="start"
                className={classes.editButton}
              >
                <EditIcon />
              </IconButton>
            )}
            {titleChildren}
          </Fragment>
        )}
      </div>
      {children}
    </div>
  );
};
// export default connect(
//   state => {
//     return {};
//   },
//   {
//     saveCurrentProject,
//     setInfoDialog,
//   },
// )(ContentWrapper);
