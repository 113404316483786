

import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import { Agenda, Login } from '@microsoft/mgt-react';
import InsertDriveFile from '@material-ui/icons/InsertDriveFileOutlined';
import Folder from '@material-ui/icons/Folder';
import SaveAlt from '@material-ui/icons/SaveAltOutlined';
import HighlightOff from '@material-ui/icons/HighlightOffOutlined';
import RemoveRedEye from '@material-ui/icons/RemoveRedEyeOutlined';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import AddIcon from '@material-ui/icons/Add';
import Cached from '@material-ui/icons/Cached';
import FlatList from 'flatlist-react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getOneDriveFileList, 
  msAzureSignIn,
  msAzureSignOut
} from '../../data/actions/msAzureAPI';
import msAzure from '../../data/reducers/msAzureAPI';

const odStyles = makeStyles(theme => ({
  loginButton: {
    color: 'black',
    border: 'grey',
    width: '60px',
    backgroundColor: '#eee',
  },
  itemRow: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid lightGrey',
    // backgroundColor: '#02f0f060'
  },
  refreshIcon: {
    marginRight: '30px',
    height: '30px',
    width: '30px',
    border: '1px solid #aaa',
    borderRadius: '5px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6'

  },
  fileIcon: {
    marginRight: '25px',
    color: 'grey',
  },
  rightSideIcons: {
    width: '60px'
  },
  rightSideIcon: {
    marginLeft: '20px'
  },
  fileName: {
    flex: 1
  },
  flatList: {
    marginBottom: '15px'
  }
}))
let signingIn = false;
let oldDriveFolder ;
function OneDriveFileManager({
  msAzureAPI,
  getOneDriveFileList,
  attachCallback,
  msAzureSignIn,
  msAzureSignOut,
}) {
  const classes = odStyles();
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if(msAzureAPI.signedIn) getOneDriveFileList('');  // set
  }, [msAzureAPI.signedIn]);

  useEffect(() => {
    if(oldDriveFolder !== msAzureAPI.currentOneDriveFolder) setSpinner(false);  
  }, [msAzureAPI]);

  const renderItemRow = (item, attachCallback) => {
    return (
      <div>
        {item.folder ? (
            <div className={classes.itemRow} onClick={(e) => {
              if(!spinner) {
                setTimeout(() => {setSpinner(true)}, 0);
                let newFolderRoot = msAzureAPI.currentOneDriveFolder;
                if(newFolderRoot !== '') newFolderRoot = `${newFolderRoot}/`
                getOneDriveFileList(`${newFolderRoot}${item.name}`)
              }
            }}>
              <Folder className={classes.fileIcon}/>
              <div className={classes.fileName}>{item.name}</div>
            </div>
          ) : (
            <div className={classes.itemRow}>
              <InsertDriveFile className={classes.fileIcon}/>
              <div 
                className={classes.fileName}  
                onClick={(e) => { 
                  window.open(item.webUrl) 
                }}
              >
                {item.name}
              </div>
              <div className={classes.rightSideIcons}>
                <AddIcon 
                  className={classes.rightSideIcon} 
                  onClick={() => {
                    if(attachCallback) {
                      attachCallback(item)
                    }
                  }}
                />
              </div>
            </div>
          )
        }
      </div>
    )
  }

  if(!msAzureAPI.initialized) return null; // msAzureAPI not finished initializing

  return (
    <div>
      {msAzureAPI.signedIn && (
        <div>
          {msAzureAPI.oneDriveFileList && (
            <Fragment>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space_between', alignItems: 'center', marginLeft: '40px'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <div style={{fontWeight: '700' }}>
                    File Listt
                  </div>
                  {spinner && <CircularProgress style={{ color: '#666', marginLeft: '20px' }} size={20} />}
                </div>
                <Cached 
                  className={classes.refreshIcon}
                  disabled={spinner}
                  onClick={(e) => {
                    if(!spinner) {
                      setTimeout(() => {
                        oldDriveFolder = null;
                        setSpinner(true)
                      }, 0);
                      getOneDriveFileList(msAzureAPI.currentOneDriveFolder)
                    }
                  }}
                />
                <Button
                  // size={size}
                  style={{width: '120px'}}
                  onClick={(e) => {msAzureSignOut()}}
                  variant="contained"
                  className={classes.loginButton}
                  disabled={spinner}
                >
                  Sign Out
                </Button>
              </div>
              <div style={{marginTop: '4px', marginLeft: '40px', border: '1px solid grey', backgroundColor: spinner ? '#f8f8f8' : 'white'}}>
                {msAzureAPI.currentOneDriveFolder !== '' && (
                  <div className={classes.itemRow} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#eee'}} onClick={(e) => {
                    if(!spinner) {
                      setTimeout(() => {setSpinner(true)}, 0);
                      let pathBits = msAzureAPI.currentOneDriveFolder.split('/');
                      pathBits.pop();
                      const newFolderRoot = pathBits.join('/');
                      getOneDriveFileList(`${newFolderRoot}`)
                    }
                  }}>
                    <KeyboardReturn className={classes.fileIcon}/>
                    <div className={classes.fileName}>{msAzureAPI.currentOneDriveFolder}</div>
                  </div>
                )}
                <FlatList
                  className={classes.flatList}
                  list={msAzureAPI.oneDriveFileList}
                  renderItem={(item) => renderItemRow(item, attachCallback)}
                  // renderOnScroll
                />           
              </div>

            </Fragment>
          )}
        </div>
      )}
      {!msAzureAPI.signedIn && (
        <div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '40px', marginLeft: '40px'}}>
            <div style={{marginRight: '30px', fontWeight: '700'}}>You are not signed into OneDrive</div>
            <Button
              // size={size}
              style={{width: '120px'}}
              onClick={(e) => {msAzureSignIn()}}
              variant="contained"
              className={classes.loginButton}
              disabled={spinner}
            >
              Sign in
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(
  state => {
    return {
      msAzureAPI: state.msAzureAPI,
    };
  },
  {
    getOneDriveFileList,
    msAzureSignIn,
    msAzureSignOut
  },
)(OneDriveFileManager);
