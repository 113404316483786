import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = null;
// const INITIAL_STATE = {
//   id: 12345678,
//   name: 'My Group',
//   admin: ['smason.van@gmail.com'],
//   members: [
//     'stephen@project-guidance.com',
//     'demo1@project-assistant.com',
//     'smason.test51@yahoo.com',
//     'stephen@project-assistant.com',
//   ],
// };

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
    case ACTION.SET_USER_GROUPS:
      return action.adminGroup;

    case ACTION.SET_GROUP:
      return action.group;

    default:
      return state;
  }
}
