import moment from 'moment';
import * as USER from '../../../constants/user';

function unpackFeatures(serializedFeatures) {
  // decode free and basic feature list
  const features = [];
  let featureItems = serializedFeatures.split(';');
  if (featureItems.length === 0) {
    throw new Error(`Empty feature list encountered. Populate Stripe plan metadata.`);
  }
  featureItems.forEach(feature => {
    const bits = feature.trim().split(':');
    if (bits.length !== 2) {
      throw new Error(`Badly formed feature list items encountered. Edit the Stripe metadata.`);
    }
    features.push({
      name: bits[0].trim(),
      value: bits[1].trim(),
    });
  });
  return features;
}

export function fetchPlans(firebase, userUID) {
  return new Promise((resolve, reject) => {
    const plans = {};
    const startTS = new Date().getTime();
    firebase.db
      .collection('plans')
      .where('active', '==', true)
      .get()
      .then(plansSnapshot => {
        const responseTS = new Date().getTime();
        console.log(
          `in fetchPlans - got ${plansSnapshot.size} active plans in ${(responseTS - startTS) / 1000} sec`,
        );
        const plansArray = [];
        plansSnapshot.forEach(planDoc => {
          const planData = planDoc.data();
          if (!planData.metadata) throw new Error(`in fetchPlans, encountered plan with no meta data`);
          const featureList = unpackFeatures(planData.metadata['features']);
          console.log(`plan ${planData.name}/ ${planDoc.id} => ${JSON.stringify(planData)}`);
          plans[planDoc.id] = {
            id: planDoc.id,
            order: planData.metadata.order,
            name: planData.name,
            prices: [],
            features: featureList,
            visibleInApp: planData.metadata.visibleInApp,
            metadata: planData.metadata,
            planDocRef: planDoc.ref,
          };
          plansArray.push(plans[planDoc.id]);
        });
        plansArray.sort((a, b) => a.order - b.order);
        if (
          plansArray.length < 2 ||
          plansArray[0].order !== '0' ||
          plansArray[0].metadata.freePlan !== 'true' ||
          plansArray[1].order !== '1'
        ) {
          throw new Error(`Missing or badly-formed Stripe plans found in dbase`);
        }
        // at this point the plans are sorted by their metadata.order value which is set in Stripe 0 = free plan, 1, 2... are subscribable plans in order of presentation, left to right
        return plansArray;
      })
      .then(sortedPlans => {
        const priceFetchPromises = [];
        const startTS = {};
        sortedPlans.forEach(plan => {
          console.log(`processing ${plan.name}`);
          if (plan.visibleInApp === 'true') {
            // if the plan is visible as a selectable upgrade option in the app, go get it's price info
            startTS[plan.id] = new Date().getTime();
            priceFetchPromises.push(
              plan.planDocRef
                .collection('prices')
                .get()
                .then(priceSnapshot => {
                  const responseTS = new Date().getTime();
                  console.log(
                    `in get price for ${plan.name} / ${plan.id} - response in ${(responseTS -
                      startTS[plan.id]) /
                      1000} sec`,
                  );

                  const prices = {};
                  const priceArray = [];
                  priceSnapshot.docs.forEach(priceDoc => {
                    const priceData = priceDoc.data();
                    const monthsPer = priceData.recurring.interval === 'year' ? 12 : 1;
                    prices[priceDoc.id] = {
                      id: priceDoc.id,
                      amount: priceData.unit_amount / 100,
                      interval: priceData.recurring.interval,
                      monthlyEq: priceData.unit_amount / 100 / monthsPer,
                    };
                    priceArray.push(prices[priceDoc.id]);
                    console.log(`price ${priceDoc.id} => ${prices[priceDoc.id]}`);
                  });
                  // sort prices in order of monthly charge
                  priceArray.sort((a, b) => a.monthlyEq - b.monthlyEq);
                  plan.prices = priceArray;
                }),
            );
          }
          delete plan.planDocRef; // remove unneeded doc ref
        });
        return sortedPlans;
      })
      .then(sortedPlans => {
        // from https://console.firebase.google.com/u/0/project/project-assistant-beta/extensions/instances/firestore-stripe-payments?tab=details
        // When configuring the Stripe FB extension you can choose to ‘Sync’ new users to Stripe.
        // If set to ‘Sync’, the extension listens to new users signing up and then automatically
        // creates a Stripe customer object and a customer record in your Cloud Firestore. If set to
        // ‘Do not sync’ (default), the extension will create the customer object “on the fly” with
        // the first checkout session creation.
        // As a result, the user will only exist in the customers|subscription collections if they have (or have had) a subscription
        console.log(`in sortedPlans...`);
        firebase.db
          .collection('customers')
          .doc(userUID)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(subSnapshot => {
            // In this implementation we only expect one active or trialing subscription to exist.
            if (subSnapshot.docs.length > 1) {
              throw new Error(`User has ${subSnapshot.docs.length} active subscriptions`);
            }
            let curUserPlan;
            const doc = subSnapshot.docs[0];
            if (!doc) {
              // if no active subscription - just set current user plan as free plan
              curUserPlan = {
                name: USER.FREE_PLAN_NAME,
                features: sortedPlans[0].features,
              };
            } else {
              // parse current subscription to define user plan
              const userSubscriptionData = doc.data();
              console.log(`user subscription ${doc.id} => ${JSON.stringify(userSubscriptionData)}`);

              // if insider subscription, manually created in Firestore
              if (userSubscriptionData.manual) {
                const manExpiryDate = userSubscriptionData.expiryTS
                  ? moment.unix(userSubscriptionData.expiryTS)
                  : moment('2100/01/01'); // forever
                const subPlan = plans[userSubscriptionData.planId];
                if (!subPlan) throw new Error('manual subscription to non-existant plan');

                curUserPlan = {
                  refPlanIndex: subPlan.order,
                  planId: 'manual',
                  name: userSubscriptionData.planName,
                  features: subPlan.features,
                  expiry: manExpiryDate,
                  expired: false,
                  autorenew: false,
                };
              } else {
                const subscribedProductId = userSubscriptionData.items[0].plan.product;
                const subPlan = plans[subscribedProductId];
                if (!subPlan) throw new Error('User subscribed to non-existant plan');
                const expiryDate = moment.unix(userSubscriptionData.current_period_end.seconds);
                const subExpired = expiryDate.isBefore(moment());
                // const subExpired = true  // for testing

                curUserPlan = {
                  refPlanIndex: subExpired ? 0 : subPlan.order,
                  name: subExpired ? USER.FREE_PLAN_NAME : subPlan.name,
                  features: subExpired ? sortedPlans[0].features : subPlan.features,
                  expiry: expiryDate,
                  expired: subExpired,
                  expiredPlan: subExpired ? subPlan.name : '',
                  autorenew: !userSubscriptionData.cancel_at_period_end,
                };
              }
            }
            resolve({
              userPlan: curUserPlan,
              allProductPlansSorted: sortedPlans, // drop the free plans from the subscribable plans
            });
          });
      })
      .catch(err => {
        console.error(`fetchPlans: ${err}`);
        reject(`fetchPlans: ${err}`);
      });
  });
}
