import * as ACTION from '../actions/actionTypes';
import moment from 'moment';

const INITIAL_STATE = {
  data: {},
  startTS: null,
  lastSessionUpdateTS: new Date().getTime(),
  saveToBackend: false,
  // userActionWhileSessionExpired: false,
};

export default function s(state = INITIAL_STATE, action) {
  const now = new Date().getTime();
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.session) {
        return action.payload.session;
      }
      return state;

    case ACTION.SETUP_SESSION_INFO:
      const newData = action.sessionData;
      if (state.data.geo) {
        Object.assign(newData, { geo: state.data.geo }); // keep old geo if it exists, will be overwritten with SET_SESSION_GEO if network available
      }
      // console.log(`++++++++++++++++++++++ STARTING NEW SESSION 2 +++++++++++++++++`)
      return {
        data: newData,
        lastSessionUpdateTS: now,
//        startTS: null,
        startTS: newData?.user?.uid === state?.data?.uid && state.startTS ? state.startTS : moment().local().format(), // needs to be in local time to capture time of day use
        saveToBackend: true,
        // userActionWhileSessionExpired: false,
      }; // new session, reset session

    case ACTION.SET_SESSION_GEO:
      const newState = Object.assign({}, state);
      newState.data.geo = action.geoData;
      return newState;

    case ACTION.SET_USER_INFO: // if user info set after session started, patch session data values
      if (state.data.user && !state.data.user.email) {
        return {
          ...state,
          data: {
            ...state.data,
            user: {
              uid: action.userToken.uid,
              email: action.userToken.email,
              role: action.role,
            },
            uid: `S::${
              action.userToken.email
            }::${new Date().getTime()}`,
          }
        };
      }
      return state;

    case ACTION.SESSION_UPDATE:
      return {
        ...state,
        data: action.data,
        saveToBackend: true,
        // userActionWhileSessionExpired: false,
      };

    case ACTION.SESSION_SAVED:
      return {
        ...state,
        saveToBackend: false,
      };

    case ACTION.ANALYTICS_UPDATE: // don't set TS for these actions
      return state;

    case ACTION.SESSION_EXPIRED:
      console.log(
        `=================== session expired ================ ${now}`,
      );
      return {
        ...INITIAL_STATE,
        data: state.data,
        saveToBackend: true,
      };

    // capture major user interaction (navigation and editing)
    case ACTION.SET_VIEW_STATE:  // user navigation
    case ACTION.PROCESS_COMMAND:  // some action on an item
    case ACTION.SET_GROUP:
    case ACTION.SHOW_GROUP_DIALOG:
    case ACTION.SET_USER_GROUPS:
    case ACTION.NEW_ACCOUNT_REQUESTED:
    case ACTION.SET_PREFERENCES:
    case ACTION.RESET_UI_PARMS:
    case ACTION.SET_TEMPLATES:
    case ACTION.ADD_DELIVERABLE:
    case ACTION.ADD_ATTACHMENT:
    case ACTION.EDIT_ATTACHMENT:
    case ACTION.DELETE_ATTACHMENT: 
      if(!state.startTS) {   // if the session hasn't started, start it by setting startTS
        console.log(`++++++++++++++++++++++ STARTING NEW SESSION  +++++++++++++++++`)
        return {
          ...state,
          startTS: moment().local().format(), // needs to be in local time to capture time of day use
          lastSessionUpdateTS: now,
          saveToBackend: true,
        }
      } else {
        return {
          ...state,
          lastSessionUpdateTS: now,
          saveToBackend: true,
        }      
      }

    default:  
      return state
  }
}
