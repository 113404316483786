import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_INFO_DIALOG:
      return action.infoDialogData;

    default:
      return state;
  }
}
