/* eslint-disable */

import * as Yup from 'yup';
import * as schema from '../data/projectSchema.js';
import { v4 as genUUID } from 'uuid';
import * as ACTION from '../data/actions/actionTypes.js';
import * as STATUS from '../constants/status';
import * as TASK from '../constants/tasks.js';
import moment from 'moment';
import * as NAV from '../constants/navigation';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as constants from '../constants/app';

/**
 * Description
 * @function formatDate
 * @param {date}  date
 * @returns {string} YYY-MM-DD
 **/
function formatDate(date) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
}

export const getNowFormatted = () => {
  return formatDate(new Date());
};

export function getSprintHumanDates(sprintId, numWeeksInSprint) {
  if (!sprintId || sprintId === constants.NO_SPRINT) return [null, null, null, null];
  const weekId = sprintId2Date(sprintId, numWeeksInSprint);
  const firstDayOfSprintMoment = moment(weekId); // first monday of sprint
  const startOfSprintMoment = moment(firstDayOfSprintMoment);
  const endOfSprintMoment = moment(firstDayOfSprintMoment).add(numWeeksInSprint * 7 - 1, 'days');
  return [
    startOfSprintMoment.format('MMM D, YYYY'),
    endOfSprintMoment.format('MMM D, YYYY'),
    startOfSprintMoment,
    endOfSprintMoment,
  ];
}
const getRelativeMoment = (numWeeksInSprint, offset = 0) => {
  const curSprintId = date2SprintId(moment().format('YYYY-MM-DD'), numWeeksInSprint);
  const firstDayOfSprintMoment = sprintId2Date(curSprintId, numWeeksInSprint);
  return firstDayOfSprintMoment.add(offset * numWeeksInSprint * 7, 'days');
};

const getRelativeSprintId = (numWeeksInSprint, offset = 0) => {
  return date2SprintId(getRelativeMoment(numWeeksInSprint, offset), numWeeksInSprint);
};

export const getSprintOffset = (sprintId, refSprintId, numWeeksInSprint) => {
  if (!sprintId || sprintId === constants.NO_SPRINT) return 0;

  if (!refSprintId) refSprintId = getRelativeSprintId(numWeeksInSprint);

  const targetSprintMoment = moment(sprintId2Date(sprintId, numWeeksInSprint));
  const refSprintMoment = moment(sprintId2Date(refSprintId, numWeeksInSprint));
  const dayDiff = targetSprintMoment.diff(refSprintMoment, 'days');
  const sprintDiff = dayDiff / (numWeeksInSprint * 7);
  return Math.floor(sprintDiff);
};

const sprintId2Date = (sprintId, numWeeksInSprint) => {
  // sprintId is in the format of 2023-10  where 10 is the 10th sprint in that year (with spring length set by numWeeksInSprint)
  const bits = sprintId.split('-');
  let year = parseInt(bits[0]);
  // if (sprintId === '2024-14') {
  //   let a = 1;
  // }
  let weekNum = parseInt(bits[1] - 1) * numWeeksInSprint + 1;
  if (weekNum > 52) {
    weekNum = 1;
    year = year + 1;
  }
  const weekIdSprint = `${year}-${weekNum}`;
  const weekMoment = moment(weekIdSprint, 'GGGG-WW');
  return weekMoment;
  // const startOfWeek = weekMoment.startOf('isoWeek');  // does this do anything?? as sprints are started on beginning of week
  // const lastWorkDayOfSprintMoment = startOfWeek.subtract(3, 'days');
  // // const lastWorkDayOfSprintMoment = startOfWeek.add(11, 'days');
  // const lastWorkDayOfSprint = lastWorkDayOfSprintMoment.format('YYYY-MM-DD');

  // return lastWorkDayOfSprint;
};

const date2SprintId = (date, numWeeksInSprint) => {
  if (!date || date === '') return constants.NO_SPRINT;

  const dateMoment = moment(date);
  let year = dateMoment.year();
  // find start date of first workperiod in that year
  let startOfYear = moment(`${year}-01`, 'GGGG-WW');
  let dateDiff = dateMoment.diff(startOfYear, 'days');
  if (dateDiff < 0) {
    // end of prev year
    year = year.subtract(1, 'year');
    startOfYear = moment(`${year}-01`, 'GGGG-WW');
    dateDiff = dateMoment.diff(startOfYear, 'days');
  }

  let sprintStartWeekNum = Math.floor(dateDiff / (7 * numWeeksInSprint)) + 1;
  let weekStr = `${sprintStartWeekNum}`.padStart(2, '0');
  let yearStr = `${year}`.padStart(4, '0');
  let sprintId = `${yearStr}-${weekStr}`;
  // if (sprintId === '2024-14') {
  //   let a = 1;
  // }
  let weekNum = (sprintStartWeekNum - 1) * numWeeksInSprint + 1;

  if (weekNum > 52) {
    sprintStartWeekNum = 1;
    year = year + 1;
    weekStr = `${sprintStartWeekNum}`.padStart(2, '0');
    yearStr = `${year}`.padStart(4, '0');
  }

  // sprintId is in the format of 2023-10  where 10 is the 10th sprint in that year (with spring length set by numWeeksInSprint)
  return `${yearStr}-${weekStr}`;
};

const sprintWPCardsOrderSort = cardArray => {
  cardArray.sort((a, b) => {
    if (!a.workpackage.sprintOrder) a.workpackage.sprintOrder = 0;
    if (!b.workpackage.sprintOrder) b.workpackage.sprintOrder = 0;
    if (a.workpackage.sprintOrder > b.workpackage.sprintOrder) return 1;
    if (a.workpackage.sprintOrder < b.workpackage.sprintOrder) return -1;
    return 0;
  });
  // reset sprintorders after sort
  cardArray.forEach((item, index) => {
    item.workpackage.sprintOrder = index;
  });
};

const getSprintData = (wbsCards, numWeeksInSprint) => {
  const sprints = {
    [constants.NO_SPRINT]: [],
    activeSprints: {},
  };
  const curSprintId = getRelativeSprintId(numWeeksInSprint, 0);
  sprints['activeSprints'][curSprintId] = []; // force in current sprint into list

  let latestSprintId = curSprintId;

  wbsCards.delCards.forEach(dCard => {
    dCard.wpCards.forEach(wpCard => {
      if (wpCard.workpackage.expectedEndDate && wpCard.workpackage.expectedEndDate !== '') {
        const sprintId = date2SprintId(wpCard.workpackage.expectedEndDate, numWeeksInSprint);
        if (
          wpCard.status === STATUS.IN_PROGRESS ||
          (wpCard.status === STATUS.NOT_STARTED && (wpCard.pinned || wpCard.childPinned)) ||
          (wpCard.status === STATUS.DONE && sprintId >= curSprintId && sprintId !== constants.NO_SPRINT)
        ) {
          if (!sprints['activeSprints'][sprintId]) {
            sprints['activeSprints'][sprintId] = [];
            if (sprintId > latestSprintId) {
              latestSprintId = sprintId;
            }
          }
          sprints['activeSprints'][sprintId].push(wpCard);
        }
      } else {
        if (
          wpCard.status === STATUS.IN_PROGRESS ||
          (wpCard.status === STATUS.NOT_STARTED && (wpCard.pinned || wpCard.childPinned))
        ) {
          sprints[constants.NO_SPRINT].push(wpCard);
        }
      }
    });
  });
  const latestSprintIdOffset = getSprintOffset(latestSprintId, curSprintId, numWeeksInSprint);
  for (let i = 1; i <= latestSprintIdOffset + 1; i++) {
    const relSprintId = getRelativeSprintId(numWeeksInSprint, i);
    if (!sprints['activeSprints'][relSprintId]) {
      sprints['activeSprints'][relSprintId] = []; // force in as many future sprint into list as match data + 1
    }
  }

  // sort sprints by stored sprint order
  sprintWPCardsOrderSort(sprints[constants.NO_SPRINT]);
  Object.keys(sprints['activeSprints']).forEach(sprintId => {
    sprintWPCardsOrderSort(sprints['activeSprints'][sprintId]);
  });
  return sprints;
};

const setWPSprint = (project, wpCard, sprintId, sprintOrder, numWeeksInSprint) => {
  const [sprintStartDate, sprintEndDate] = getSprintHumanDates(sprintId, numWeeksInSprint);

  // const lastDayOfSprint = sprintId2Date(sprintId, numWeeksInSprint);
  // const firstDayOfSprint = moment(lastDayOfSprint)
  //   .subtract(numWeeksInSprint * 7 + 3, 'days')
  //   .format('YYYY-MM-DD');

  if (sprintId === constants.NO_SPRINT) {
    let allTasksNotStarted = true;
    wpCard.workpackage.tasks.forEach(task => {
      if (task.status !== STATUS.NOT_NEEDED && task.status !== STATUS.NOT_STARTED) {
        allTasksNotStarted = false;
      }
    });
    if (!allTasksNotStarted) return false;

    wpCard.workpackage.expectedEndDate = '';
  } else {
    wpCard.workpackage.expectedEndDate = moment(sprintStartDate).format('YYYY-MM-DD');
    // wpCard.workpackage.expectedEndDate = sprintStartDate;
  }
  wpCard.workpackage.sprintOrder = sprintOrder;
  refreshStats(project);
  return true;
};

const isValidEmail = email => {
  const matchResult = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  return !!matchResult;
};

const checkForNewMember = (email, project, community) => {
  if (isValidEmail(email)) {
    const existingEmail = project.sharedWith.find(memberEmail => memberEmail.trim() === email);
    if (!existingEmail) {
      project.sharedWith.push(email);
    }
    // following no longer needed as community is now updated after each project save (members add/removed/assigned etc)
    // const existingCommunityMember = community.find(memberEmail => memberEmail.trim() === email);
    // if (!existingCommunityMember) {
    //   const newCommunity = community.slice();
    //   newCommunity.push(email);
    //   updateCommunity(newCommunity);
    // }
  }
};

export const isInfoEmpty = content => {
  if (typeof content === 'string') {
    return content === '';
  } else {
    if (content instanceof Object && content?.blocks) {
      return content?.blocks?.length === 1 && content?.blocks[0].text === '';
    }
  }
  return false;
};

export const setSmartTemplateIndent = templates => {
  let prevTemplateLevel = -1;

  Object.keys(templates).forEach(setId => {
    templates[setId].forEach((template, index) => {
      if (template.library.level > 1) {
        template.canOutdent = true;
      } else {
        delete template.canOutdent;
      }
      if (template.library.level <= prevTemplateLevel) {
        template.canIndent = true;
      } else {
        delete template.canIndent;
      }
      prevTemplateLevel = template.library.level;
      template.library.order = index;
    });
  });
};

export const getCurrentProjectInfo = props => {
  let curProjectSet;
  let curProjectIndex;
  if (props.viewState.currentProjectType === NAV.TYPE_PROJECT) {
    curProjectSet = props.projects;
    curProjectIndex = props.viewState.currentProjectIndex;
    if (curProjectIndex > curProjectSet.length) {
      curProjectIndex = 0;
    }
  } else {
    curProjectSet = props.workflows[props.viewState.currentWorkflowSetId];
    if (!curProjectSet) curProjectSet = 0;
    curProjectIndex = props.viewState.currentWorkflowIndex;
    if (!curProjectSet || !curProjectIndex || curProjectIndex > curProjectSet.length) {
      curProjectIndex = 0;
    }
  }
  return [curProjectSet, curProjectIndex];
};
export const getCurrentProjectOrWorkflow = props => {
  const [curProjectSet, curProjectIndex] = getCurrentProjectInfo(props);
  return curProjectSet[curProjectIndex];
};
export const getCurrentProject = props => {
  // if (!props.viewState.currentProjectIndex || !props.projects[props.viewState.currentProjectIndex]) {
  //   return props.projects[0];
  // }
  return props.projects ? props.projects[props.viewState.currentProjectIndex] : null;
};
export const getCurrentWorkflow = props => {
  // if (!props.viewState.currentProjectIndex || !props.workflows[props.viewState.currentProjectIndex]) {
  //   return props.workflows[0];
  // }
  return props.workflows[props.viewState.currentProjectIndex];
};

export const findVisibleWorkflowByIndex = (workflows, index) => {
  // assumes wfs presented in flatlist
  let resultWF = null;
  let count = -1;
  Object.keys(workflows).forEach(setId => {
    workflows[setId].forEach(wf => {
      if (wf.library.visible) count++;
      if (count === index) resultWF = wf;
    });
  });
  return resultWF;
};
export const getVisibleWorkflows = (workflows, index) => {
  const result = {}; // constructs multiple workflows into flatlist
  Object.keys(workflows).forEach(setId => {
    workflows[setId].forEach(wf => {
      if (wf.library.visible) result[wf.uuid] = { setId: setId, template: wf };
    });
  });
  return result;
};
export const resetProjectScroll = project => {
  const result = [];
  project.deliverables.forEach(del => {
    delete del.scrollTop;
    delete del.detailScrollTop;
    del.workPackages.forEach(wp => {
      delete wp.scrollTop;
      delete wp.detailScrollTop;
    });
  });
  return result;
};

export const subcomponetsHaveNoDateSet = (component, startEnd, type) => {
  let result = true;
  switch (type) {
    case 'W':
      component.tasks.forEach(task => {
        if (
          (startEnd === 'start' && task.expectedStartDate && task.expectedStartDate !== '') ||
          (startEnd === 'end' &&
            task.expectedEndDate &&
            task.expectedEndDate !== '' &&
            isTaskNeededAndNotDone(task.status))
        ) {
          result = false;
        }
      });
      break;
    default:
    case 'D':
      component.workPackages.forEach(wp => {
        if (
          (startEnd === 'start' && wp.expectedStartDate && wp.expectedStartDate !== '') ||
          (startEnd === 'end' && wp.expectedEndDate && wp.expectedEndDate !== '')
        ) {
          result = false;
        }
        wp.tasks.forEach(task => {
          if (
            (startEnd === 'start' && task.expectedStartDate && task.expectedStartDate !== '') ||
            (startEnd === 'end' &&
              task.expectedEndDate &&
              task.expectedEndDate !== '' &&
              isTaskNeededAndNotDone(task.status))
          ) {
            result = false;
          }
        });
      });
      break;
    case 'P':
      component.deliverables.forEach(del => {
        if (
          (startEnd === 'start' && del.expectedStartDate && del.expectedStartDate !== '') ||
          (startEnd === 'end' && del.expectedEndDate && del.expectedEndDate !== '')
        ) {
          result = false;
        }
        del.workPackages.forEach(wp => {
          if (
            (startEnd === 'start' && wp.expectedStartDate && wp.expectedStartDate !== '') ||
            (startEnd === 'end' && wp.expectedEndDate && wp.expectedEndDate !== '')
          ) {
            result = false;
          }
          wp.tasks.forEach(task => {
            if (
              (startEnd === 'start' && task.expectedStartDate && task.expectedStartDate !== '') ||
              (startEnd === 'end' && task.expectedEndDate && task.expectedEndDate !== '')
            ) {
              result = false;
            }
          });
        });
      });
      break;
  }
  return result;
};

export const findParentWorkflowByUUID = (workflows, uuid) => {
  let resultWF = null;
  Object.keys(workflows).forEach(setId => {
    const parents = {};
    workflows[setId].forEach(wf => {
      parents[wf.library.level] = wf;
      if (wf.uuid === uuid) resultWF = parents[wf.library.level - 1];
    });
  });
  return resultWF;
};

export const findWorkflowByUUID = (workflows, uuid) => {
  let resultWF = null;
  Object.keys(workflows).forEach(setId => {
    workflows[setId].forEach(wf => {
      if (wf.name === 'First Smart Workflow' && wf.workflowSetId !== 1) {
        let a = 1;
      }
      if (!resultWF && setId === constants.PUBLIC_GROUP_ID.toString() && wf.library.default) {
        resultWF = wf;
      }
      if (wf.uuid === uuid) resultWF = wf;
    });
  });
  return resultWF;
};
export const getLibraryComponents = (workflows, uuid, component) => {
  let result = [];
  const parents = {};
  let maxLevel = -1;
  let collect = true;

  let curProjectTemplate;
  let defaultTemplate;
  Object.keys(workflows).forEach(setId => {
    workflows[setId].forEach(template => {
      // if (template.name === 'First Smart Workflow' && template.workflowSetId !== 1) {
      //   let a = 1;
      // }
      if (template) {
        if (template.uuid === uuid) curProjectTemplate = template;
        if (setId === constants.PUBLIC_GROUP_ID.toString()) {
          if (template.library.default) {
            defaultTemplate = template;
          }
        }
      }
    });
  });
  if (!curProjectTemplate) curProjectTemplate = defaultTemplate;
  // collect all parent templates into the result
  const sortedWorkflow = workflows[curProjectTemplate.workflowSetId].sort((p1, p2) =>
    p1.library.order < p2.library.order ? -1 : p1.library.order === p2.library.order ? 0 : 1,
  );
  sortedWorkflow.forEach(wf => {
    if (wf) {
      if (collect) {
        parents[wf.library.level] = wf;
        maxLevel = wf.library.level;
      }
      if (wf.uuid === curProjectTemplate.uuid) collect = false;
    }
  });
  for (let i = 0; i <= maxLevel; i++) {
    let wf = parents[i];
    if (wf) {
      let itemCopy;
      switch (component) {
        case 'DELIVERABLE':
          wf.library.components.deliverables.forEach(item => {
            itemCopy = { ...item };
            itemCopy.level = i;
            result.push(itemCopy);
          });
          // result = result.concat(wf.library.components.deliverables);
          break;
        case 'WORKPACKAGE':
          wf.library.components.workPackages.forEach(item => {
            itemCopy = { ...item };
            itemCopy.level = i;
            result.push(itemCopy);
          });
          // result = result.concat(wf.library.components.workPackages);
          break;
        case 'TASK':
          wf.library.components.tasks.forEach(item => {
            itemCopy = { ...item };
            itemCopy.level = i;
            result.push(itemCopy);
          });
          // result = result.concat(wf.library.components.tasks);
          break;

        default:
      }
    }
  }
  return result;
};
export const createLibCards = curWorkflow => {
  const components = curWorkflow.library.components;

  const dCards = [];

  let curDelCard = {
    cardType: TASK.LIB_TASK_CONTAINER_CARD,
    uuid: NAV.LIBRARY_TASK_COL_UUID,
    parentCard: null,
    deliverable: { name: 'Tasks', library: true },
    index: 0,
    numSiblings: 2 + components.deliverables.length,
    wpCards: [],
  };
  dCards.push(curDelCard);
  components.tasks.forEach((task, ti) => {
    curDelCard.wpCards.push({
      cardType: TASK.TASK_CARD,
      uuid: task.uuid,
      parentCard: { index: 0, parentCard: { index: 0 } },
      task: task,
      index: ti,
      numSiblings: components.tasks.length,
    });
  });

  curDelCard = {
    cardType: TASK.LIB_WP_CONTAINER_CARD,
    uuid: NAV.LIBRARY_WP_COL_UUID,
    parentCard: null,
    deliverable: { name: 'Task Groups', library: true },
    index: 1,
    numSiblings: 2 + components.deliverables.length,
    wpCards: [],
  };
  dCards.push(curDelCard);
  components.workPackages.forEach((wp, wi) => {
    let curWPCard = {
      cardType: TASK.WORKPACKAGE_CARD,
      uuid: wp.uuid,
      parentCard: { index: 1 },
      workpackage: wp,
      index: wi,
      numSiblings: components.workPackages.length,
      taskCards: [],
    };
    curDelCard.wpCards.push(curWPCard);
    wp.tasks.forEach((task, ti) => {
      curWPCard.taskCards.push({
        cardType: TASK.TASK_CARD,
        uuid: task.uuid,
        parentCard: curWPCard,
        task: task,
        index: ti,
        numSiblings: wp.tasks.length,
      });
    });
  });

  components.deliverables.forEach((del, di) => {
    let curDelCard = {
      cardType: TASK.DELIVERABLE_CARD,
      uuid: del.uuid,
      parentCard: null,
      deliverable: del,
      index: di + 2,
      numSiblings: components.deliverables.length + 2,
      wpCards: [],
    };
    dCards.push(curDelCard);
    del.workPackages.forEach((wp, wi) => {
      let curWPCard = {
        cardType: TASK.WORKPACKAGE_CARD,
        uuid: wp.uuid,
        parentCard: curDelCard,
        workpackage: wp,
        index: wi,
        numSiblings: del.workPackages.length,
        taskCards: [],
      };
      curDelCard.wpCards.push(curWPCard);
      wp.tasks.forEach((task, ti) => {
        curWPCard.taskCards.push({
          cardType: TASK.TASK_CARD,
          uuid: task.uuid,
          parentCard: curWPCard,
          task: task,
          index: ti,
          numSiblings: wp.tasks.length,
        });
      });
    });
  });
  return dCards; // ordered set of cards (in heirarchy)
};
export const createWBSCards = project => {
  const dCards = [];

  let allDelDone = true;
  let allDelNotStarted = true;
  let delChildPinned = false;
  project.deliverables.forEach((del, di) => {
    let curDelCard = {
      cardType: TASK.DELIVERABLE_CARD,
      uuid: del.uuid,
      parentCard: null,
      deliverable: del,
      index: di,
      numSiblings: project.deliverables.length,
      wpCards: [],
      pinned: del.pinned,
    };
    dCards.push(curDelCard);
    if (del.actualStartDate !== '') allDelNotStarted = false;
    if (del.actualEndDate === '') allDelDone = false;
    if (del.pinned) delChildPinned = true;

    let allWPCardsDone = true;
    let allWPCardsNotStarted = true;
    let WPChildPinned = false;
    del.workPackages.forEach((wp, wi) => {
      let curWPCard = {
        cardType: TASK.WORKPACKAGE_CARD,
        uuid: wp.uuid,
        parentCard: curDelCard,
        workpackage: wp,
        index: wi,
        numSiblings: del.workPackages.length,
        taskCards: [],
        pinned: wp.pinned,
      };
      curDelCard.wpCards.push(curWPCard);
      if (wp.actualStartDate !== '') allWPCardsNotStarted = false;
      if (wp.actualEndDate === '') allWPCardsDone = false;
      if (wp.pinned) WPChildPinned = true;

      let allTaskCardsDone = true;
      let allTaskCardsNotStarted = true;
      let taskChildPinned = false;
      wp.tasks.forEach((task, ti) => {
        let curTaskCard = {
          cardType: TASK.TASK_CARD,
          uuid: task.uuid,
          parentCard: curWPCard,
          task: task,
          index: ti,
          numSiblings: wp.tasks.length,
          pinned: task.pinned,
          status:
            task.status === STATUS.NOT_STARTED
              ? STATUS.NOT_STARTED
              : task.status === STATUS.DONE || task.status === STATUS.NOT_NEEDED
              ? STATUS.DONE
              : STATUS.IN_PROGRESS,
        };
        curWPCard.taskCards.push(curTaskCard);
        if (task.actualStartDate !== '') allTaskCardsNotStarted = false;
        if (task.actualEndDate === '' && task.status !== STATUS.NOT_NEEDED) allTaskCardsDone = false;
        if (task.pinned) taskChildPinned = true;
      });
      curWPCard.allTaskCardsNotStarted = project.library ? false : allTaskCardsNotStarted;
      curWPCard.allTaskCardsDone = project.library ? false : allTaskCardsDone;
      curWPCard.childPinned = taskChildPinned;
      // WPChildPinned = WPChildPinned || taskChildPinned;

      curWPCard.status =
        allTaskCardsNotStarted && !allTaskCardsDone // this is probably redundant with curWPCard.workpackage.status
          ? STATUS.NOT_STARTED
          : allTaskCardsDone
          ? STATUS.DONE
          : STATUS.IN_PROGRESS;

      wp.status = curWPCard.status;
    });
    curDelCard.allWPCardsNotStarted = project.library ? false : allWPCardsNotStarted;
    curDelCard.allWPCardsDone = project.library ? false : allWPCardsDone;
    curDelCard.childPinned = WPChildPinned;
    curDelCard.status = allWPCardsNotStarted // this is probably redundant with curDelCard.deliverable.status
      ? STATUS.NOT_STARTED
      : allWPCardsDone
      ? STATUS.DONE
      : STATUS.IN_PROGRESS;
    del.status = curDelCard.status;
  });
  let projectCards = {
    allDelNotStarted: project.library ? false : allDelNotStarted,
    allDelDone: project.library ? false : allDelDone,
    childPinned: delChildPinned,
    delCards: dCards,
  };
  return projectCards; // ordered set of cards (in heirarchy)
};

const generateNewUID = () => {
  return genUUID();
};
const projectInfoValidationSchema = Yup.object({
  name: Yup.string('Enter a name').required('Name is required'),
  sponsor: Yup.string("Enter project sponsor's name."),
  projectManager: Yup.string("Enter project Manager's name."),
  projectType: Yup.string('Enter project type.'),
  creator: Yup.string('Project creator.'),
  problemOpportunity: Yup.string('Describe the problem or opportunity this project addresses.'),
  note: Yup.string('Notes on this project.'),
  expectedStartDate: Yup.date(),
  // expectedEndDate: Yup.date(),
  actualStartDate: Yup.date().default(() => new Date()),
  actualEndDate: Yup.date().default(() => new Date()),
  refPrefix: Yup.string('Enter a (one word) Project ID')
    .max(5, 'max 5 char ID')
    .matches(/^[0-9a-zA-Z]*$/, 'letters and digits only in ID')
    .required('Project ID is required'),
});
const stepNoteValidationSchema = Yup.object({
  note: Yup.string('Enter step notes.'),
});

const WAY_IN_THE_PAST = '1900-01-01';

/**
 * Check to see if a task is complete. It is complete when it has been marked Done.
 * @param {string} status
 */
const isTaskNeeded = status => {
  if (status !== STATUS.NOT_NEEDED) return true;
  return false;
};
const isTaskNeededAndNotDone = status => {
  if (status !== STATUS.NOT_NEEDED && status !== STATUS.DONE) return true;
  return false;
};
const isTaskDone = status => {
  if (status === STATUS.DONE) return true;
  // if (answer && answer.includes('YES')) return true; // 'YES' maps to 'Done' in the latest logic - in future change strings to constants
  return false;
};
const addTaskToTotal = status => {
  if (status !== STATUS.NOT_NEEDED) return true;
  // if (answer && answer.includes('LATER')) return false; // 'LATER' maps to 'Skip' in the latest logic - in future change strings to constants
  return false;
};
const hasActiveStatus = status => {
  if (status !== STATUS.NOT_STARTED && status !== STATUS.NOT_NEEDED) return true;
  // if (
  //   answer &&
  //   (answer.includes('YES') ||
  //     answer.includes('NO') ||
  //     answer.includes('LATER'))
  // )
  //   return true; // 'LATER' maps to 'Skip' in the latest logic - in future change strings to constants
  return false;
};

const isInProgressStatus = status => {
  if (status !== STATUS.NOT_STARTED && status !== STATUS.NOT_NEEDED && status !== STATUS.DONE) {
    return true;
  }
  return false;
};
const getStatusCategory = item => {
  if (item.status) {
    if (
      item.status !== STATUS.NOT_STARTED &&
      item.status !== STATUS.NOT_NEEDED &&
      item.status !== STATUS.DONE
    ) {
      return STATUS.IN_PROGRESS;
    }
    return item.status;
  } else {
    return item.actualEndDate !== ''
      ? STATUS.DONE
      : item.actualStartDate !== ''
      ? STATUS.IN_PROGRESS
      : STATUS.NOT_STARTED;
  }
};

const DUE_SOON_DAYS_CUTOFF = 4;
const getDateStats = date => {
  if (!date) {
    return { daysLeft: 0, isLate: false, dueSoon: false };
  } else {
    const now = moment().startOf('day');
    const dueDate = moment(date).startOf('day');
    const daysLeft = dueDate.diff(now, 'days');

    return {
      daysLeft: daysLeft,
      isLate: daysLeft < 0,
      dueSoon: daysLeft >= 0 && daysLeft <= DUE_SOON_DAYS_CUTOFF,
    };
  }
};

const getDateStatsInfo = (isLate, dueSoon, daysLeft, due = true) => {
  const lateIconColor = dueSoon ? yellow[800] : isLate ? red[500] : '#666';
  const tooltipTitle =
    daysLeft > 0
      ? daysLeft <= DUE_SOON_DAYS_CUTOFF
        ? `${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''} ${due ? 'to go' : 'until start'}`
        : due
        ? ''
        : `${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''} until start`
      : daysLeft === 0
      ? ` ${due ? 'due' : 'starts'} today`
      : `${Math.abs(daysLeft)} day${daysLeft < -1 ? 's' : ''} ${due ? 'overdue' : 'past start date'} `;

  return { lateIconColor, tooltipTitle };
};

const getDateValues = (item, statusCat) => {
  let dateText;
  let dateStats;
  let dateStatInfo;
  let dateLateIconColor;
  let dateTooltipTitle;

  // get work period information
  const reduxStore = window.store.getState();
  const sprintId = date2SprintId(item.expectedEndDate, reduxStore.viewState.numWeeksInSprint);
  const [humanSprintStartDate, humanSprintEndDate, startMoment, endMoment] = getSprintHumanDates(
    sprintId,
    reduxStore.viewState.numWeeksInSprint,
  );
  const eDate = endMoment ? endMoment.format('YYYY-MM-DD') : '';
  const sDate = startMoment ? startMoment.format('YYYY-MM-DD') : '';
  const itemScheduled = !!endMoment;

  switch (statusCat) {
    case STATUS.NOT_STARTED:
      if (itemScheduled) {
        dateStats = getDateStats(sDate);
        dateStatInfo = getDateStatsInfo(dateStats.isLate, dateStats.dueSoon, dateStats.daysLeft, false);
        dateText = `${startMoment.format('MM/DD/YYYY')}`;
        dateTooltipTitle = 'work period start date';
        dateLateIconColor = red[500];
        // dateText = 'xx/xx/xx';
        // sDate !== '' ? `${startMoment.format('MM/DD/YYYY')}` : 'xx/xx/xx';
        // dateTooltipTitle = sDate !== '' ? dateStatInfo.tooltipTitle : 'past start date';
        // dateLateIconColor = dateText === 'xx/xx/xx' ? grey[500] : dateStatInfo.lateIconColor;
      }
      break;
    case STATUS.IN_PROGRESS:
      if (itemScheduled) {
        dateStats = getDateStats(eDate);
        dateStatInfo = getDateStatsInfo(dateStats.isLate, dateStats.dueSoon, dateStats.daysLeft, true);
        dateText = `${endMoment.format('MM/DD/YYYY')}`;
        dateTooltipTitle = itemScheduled ? dateStatInfo.tooltipTitle : 'not scheduled yet';
        dateLateIconColor = dateStatInfo.lateIconColor;
        // dateText =
        //   itemScheduled ? `${endMoment.format('MM/DD/YYYY')}` : 'xx/xx/xx';
        // dateTooltipTitle = eDate !== '' ? dateStatInfo.tooltipTitle : 'not scheduled yet';
        // dateLateIconColor = dateText === 'xx/xx/xx' ? grey[500] : dateStatInfo.lateIconColor;
      }
      break;
    default:
    case STATUS.DONE:
      dateStats = getDateStats(item.actualEndDate);
      dateStatInfo = getDateStatsInfo(dateStats.isLate, dateStats.dueSoon, dateStats.daysLeft, true);
      dateText =
        item.actualEndDate !== '' ? `${moment(item.actualEndDate).format('MM/DD/YYYY')}` : 'xx/xx/xx';
      dateTooltipTitle = '';
      dateLateIconColor = dateText === 'xx/xx/xx' ? grey[500] : '#444';
      break;
  }
  return { dateText, dateTooltipTitle, dateLateIconColor };
};
const getEarliestDate = (list, initial) => {
  const earliest = list.reduce((acc, { actualStartDate }) => {
    if (!actualStartDate) return acc;
    return actualStartDate > acc ? acc : actualStartDate;
  }, initial);
  return earliest;
};

/**
 * - 
 * - A WP is completed when all tasks have a YES or NO answers
 * - A LATER answer is incomplete and doesn't contribute to the overall percentage or completion of a WP
 * - The percentage is calculated based on tasks that have been answered YES or NO

 */

const setStartDate = (item, newDate) => {
  item.actualStartDate = newDate ? newDate : formatDate(new Date());
  if (!item.expectedStartDate) item.expectedStartDate = item.actualStartDate;
};

const setEndDate = (item, newDate) => {
  item.actualEndDate = newDate ? newDate : formatDate(new Date());
  if (!item.expectedEndDate) item.expectedEndDate = item.actualEndDate;
};
export const clearAllSelections = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};
export const randColor = () => {
  const maxPerColor = 12; // lower value forces more darker, mixed colors
  const limit = 27 - 2 * (15 - maxPerColor);
  const red = Math.min(Math.floor(Math.random() * limit), maxPerColor);
  const green = Math.min(Math.floor(Math.random() * (limit - red)), maxPerColor);
  const blue = Math.min(limit - red - green, maxPerColor);
  const redHexStr = red ? (red * 16).toString(16) : '00';
  const greenHexStr = green ? (green * 16).toString(16) : '00';
  const blueHexStr = blue ? (blue * 16).toString(16) : '00';
  // console.log(`rgb ${red} ${green} ${blue}`)
  return `#${redHexStr}${greenHexStr}${blueHexStr}C0`;
};

const getWBSLink = (type, pIndex, dIndex, wIndex, tIndex) => {
  switch (type) {
    default:
    case 'p':
      return {
        projIndex: pIndex,
        stack: [
          {
            screen: NAV.BOARD,
            item: { d: -1, w: -1, t: -1 },
            level: 'p',
          },
        ],
      };

    case 'd':
      return {
        projIndex: pIndex,
        stack: [
          {
            screen: NAV.BOARD,
            item: { d: -1, w: -1, t: -1 },
            level: 'p',
          },
          {
            screen: NAV.BOARD_DETAIL,
            item: { d: dIndex, w: -1, t: -1 },
            level: 'd',
          },
        ],
      };
    case 'w':
      return {
        projIndex: pIndex,
        stack: [
          {
            screen: NAV.BOARD,
            item: { d: -1, w: -1, t: -1 },
            level: 'p',
          },
          {
            screen: NAV.BOARD_DETAIL,
            item: { d: dIndex, w: wIndex, t: -1 },
            level: 'w',
          },
        ],
      };
    case 't':
      return {
        projIndex: pIndex,
        stack: [
          {
            screen: NAV.BOARD,
            item: { d: -1, w: -1, t: -1 },
            level: 'p',
          },
          {
            screen: NAV.BOARD_DETAIL,
            item: { d: dIndex, w: wIndex, t: -1 },
            level: 'w',
          },
          {
            screen: NAV.BOARD_DETAIL,
            item: { d: dIndex, w: wIndex, t: tIndex },
            level: 't',
          },
        ],
      };
  }
};

const getAlertInfo = (obj, numWeeksInSprint = null) => {
  const { expectedEndDate, actualEndDate, expectedStartDate, actualStartDate } = obj;

  // hack to format old data
  const cleanedExpectedEndDate = expectedEndDate ? moment(expectedEndDate).format('YYYY-MM-DD') : '';

  let alertType = null;
  let text = null;
  const now = moment().startOf('day');
  let dueDate;
  let daysLeft;

  // get work period information
  const reduxStore = window.store.getState();
  const localNumWeeksInSprint = numWeeksInSprint ? numWeeksInSprint : reduxStore.viewState.numWeeksInSprint;
  const sprintId = date2SprintId(cleanedExpectedEndDate, localNumWeeksInSprint);
  const [humanSprintStartDate, humanSprintEndDate, startMoment, endMoment] = getSprintHumanDates(
    sprintId,
    localNumWeeksInSprint,
  );

  if (!!endMoment && actualStartDate === '') {
    // have expected start date, but haven't started, mark as overdue
    dueDate = endMoment.startOf('day');
    daysLeft = dueDate.diff(now, 'days');
    if (daysLeft < 0) {
      alertType = 'overdue';
      text = `${Math.abs(daysLeft)} day${daysLeft < -1 ? 's' : ''} overdue`;
      return { alertType, text };
    }
    if (daysLeft >= 0 && daysLeft <= DUE_SOON_DAYS_CUTOFF) {
      if (daysLeft === 0) {
        alertType = 'today';
        text = `due today`;
      } else {
        alertType = 'soon';
        // text = `due in ${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''}`;
        text = `${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''} to go`;
      }
      return { alertType, text };
    }
  }
  if (!!endMoment && actualEndDate === '') {
    dueDate = endMoment.startOf('day');
    daysLeft = dueDate.diff(now, 'days');
    if (daysLeft < 0) {
      alertType = 'overdue';
      text = `${Math.abs(daysLeft)} day${daysLeft < -1 ? 's' : ''} overdue`;
    }
    if (daysLeft >= 0 && daysLeft <= DUE_SOON_DAYS_CUTOFF) {
      if (daysLeft === 0) {
        alertType = 'today';
        text = `due today`;
      } else {
        alertType = 'soon';
        text = `${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''} to go`;
      }
    }
  } else {
    const status =
      actualEndDate !== '' ? STATUS.DONE : actualStartDate !== '' ? STATUS.IN_PROGRESS : STATUS.NOT_STARTED;
    if (status === STATUS.IN_PROGRESS) {
      alertType = 'missing';
      text = 'Not scheduled';
    }
  }
  return { alertType, text };
};

export const getProjectAlerts = projects => {
  const projData = [];
  let curProj;
  let curDel;
  let curWP;
  let alert;
  const nullAlert = { text: null, alertType: null };
  projects.forEach((proj, pIndex) => {
    alert = proj.actualEndDate ? nullAlert : getAlertInfo(proj, proj.numWeeksInSprint);
    let projectHasAlerts = false;
    // let projectHasAlerts = !!alert.alertType;
    curProj = {
      name: proj.name,
      dueText: alert.text,
      alert: alert.alertType,
      assignedTo: proj.assignedTo,
      uuid: proj.uuid,
      deliverables: [],
      link: {
        projIndex: pIndex,
        projUUID: proj.uuid,
        stack: [
          {
            screen: NAV.SPRINTVIEW,
            // screen: NAV.BOARD,
            item: { d: -1, w: -1, t: -1 },
            level: 'p',
          },
        ],
      },
    };
    let numVisibleDelsInProj = 0;
    proj.deliverables.forEach((del, dIndex) => {
      alert = del.actualEndDate ? nullAlert : getAlertInfo(del, proj.numWeeksInSprint);
      let delHasAlerts = !!alert.alertType;
      const status = getStatusCategory(del);
      curDel = {
        name: del.name,
        dueText: alert.text,
        alert: alert.alertType,
        assignedTo: del.assignedTo,
        uuid: del.uuid,
        workPackages: [],
        priority: del.isPriority,
        status: status,
        statusCategory: status,
        link: {
          projIndex: pIndex,
          projUUID: proj.uuid,
          stack: [
            {
              screen: NAV.SPRINTVIEW,
              item: { d: -1, w: -1, t: -1 },
              level: 'p',
            },
          ],
        },
      };
      let numVisibleWPsInDel = 0;
      del.workPackages.forEach((wp, wpIndex) => {
        alert = wp.actualEndDate ? nullAlert : getAlertInfo(wp, proj.numWeeksInSprint);
        let wpHasAlerts = !!alert.alertType;
        const status = getStatusCategory(wp);
        curWP = {
          name: wp.name,
          dueText: alert.text,
          alert: alert.alertType,
          assignedTo: wp.assignedTo,
          uuid: wp.uuid,
          tasks: [],
          priority: wp.isPriority,
          status: status,
          statusCategory: status,
          link: {
            projIndex: pIndex,
            projUUID: proj.uuid,
            stack: [
              {
                screen: NAV.SPRINTVIEW,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ],
          },
        };
        if (wpHasAlerts) {
          numVisibleWPsInDel++;
          delHasAlerts = true;
          projectHasAlerts = true;
          curDel.workPackages.push(curWP);
        }
      });
      if (numVisibleWPsInDel > 0 && delHasAlerts) {
        numVisibleDelsInProj++;
        projectHasAlerts = true;
        curProj.deliverables.push(curDel);
      }
    });
    if (numVisibleDelsInProj > 0 && projectHasAlerts) {
      projData.push(curProj);
    }
  });
  return projData;
};

const dispatchGetAssignments = () => {
  return (dispatch, getState) => {
    const { projects, user } = getState();
    console.log(`+++++++++++++++++++ running getAssignments from dispatchGetAssignments +++++++++++++++`);

    const newAssignments = getAssignments(projects, { [user.email]: true });
    dispatch({
      type: ACTION.SET_ASSIGNMENT_ORDER,
      assignments: newAssignments,
    });
  };
};

export const getDoneTasksBySprint = project => {
  const doneTasks = {};
  // const unorderedTaskIDsFromScan = [];
  // const assignmentInfoLookup = {};
  const reduxStore = window.store.getState();

  const localNumWeeksInSprint = project.numWeeksInSprint
    ? project.numWeeksInSprint
    : reduxStore.viewState.numWeeksInSprint;
  project.deliverables.forEach((del, dIndex) => {
    const curSprintId = date2SprintId(moment().format('YYYY-MM-DD'), localNumWeeksInSprint);
    del.workPackages.forEach((wp, wpIndex) => {
      wp.tasks.forEach((task, tIndex) => {
        if (task.status === STATUS.DONE && task.actualEndDate) {
          const cleanedActualEndDate = moment(task.actualEndDate).format('YYYY-MM-DD');
          const taskSprintId = date2SprintId(cleanedActualEndDate, localNumWeeksInSprint);
          if (!doneTasks[taskSprintId]) doneTasks[taskSprintId] = [];
          doneTasks[taskSprintId].push({
            uuid: task.uuid,
            task: task,
            path: `${del.name} / ${wp.name}`,
            deliverableId: del.uuid,
            workpackageId: wp.uuid,
            delIndex: dIndex,
            workpackageIndex: wpIndex,
            taskIndex: tIndex,
          });
        }
      });
    });
  });

  const result = [];
  Object.keys(doneTasks).forEach(sprintId => {
    const taskArray = doneTasks[sprintId];
    taskArray.sort((a, b) => {
      if (a.task.actualEndDate < b.task.actualEndDate) return 1;
      if (a.task.actualEndDate > b.task.actualEndDate) return -1;
      return 0;
    });
    const [humanSprintStartDate, humanSprintEndDate, startMoment, endMoment] = getSprintHumanDates(
      sprintId,
      localNumWeeksInSprint,
    );
    const truncatedStartDate = humanSprintStartDate.substring(0, humanSprintStartDate.length - 6);
    result.push({
      sprintId,
      humanDateStr: `${truncatedStartDate} to ${humanSprintEndDate}`,
      taskArray,
    });
  });

  result.sort((a, b) => {
    if (a.sprintId < b.sprintId) return 1;
    if (a.sprintId > b.sprintId) return -1;
    return 0;
  });

  return result;
};
const NOT_ASSIGNED = 'not assigned';

export const getAssignments = (projects, userEmails, oldAssignedTaskIds = null) => {
  const unorderedTaskIDsFromScan = [];
  const assignmentInfoLookup = {};
  const reduxStore = window.store.getState();

  if (!oldAssignedTaskIds) {
    oldAssignedTaskIds = reduxStore.assignments.assignedTaskIds;
  }
  if (!oldAssignedTaskIds) {
    oldAssignedTaskIds = [];
  }
  const allProjects = reduxStore.projects;
  if (!allProjects) {
    return { assignmentInfo: newAssignmentInfo, assignedTaskIds: newAssignedTaskIds };
  }
  projects.forEach(proj => {
    // console.log(`in proj ${proj.name}`);
    const pIndex = allProjects.findIndex(p => {
      return p.uuid === proj.uuid;
    });
    proj.deliverables.forEach((del, dIndex) => {
      const localNumWeeksInSprint = proj.numWeeksInSprint
        ? proj.numWeeksInSprint
        : reduxStore.viewState.numWeeksInSprint;
      const curSprintId = date2SprintId(moment().format('YYYY-MM-DD'), localNumWeeksInSprint);
      // console.log(`in del: ${del.name}`);
      del.workPackages.forEach((wp, wpIndex) => {
        // console.log(`in wp: ${wp.name}`);
        if (wp.expectedEndDate && wp.expectedEndDate !== '') {
          const cleanedExpectedEndDate = moment(wp.expectedEndDate).format('YYYY-MM-DD');
          const wpSprintId = date2SprintId(cleanedExpectedEndDate, localNumWeeksInSprint);
          // console.log(`   has exp end date: ${wpSprintId}`);
          if (wpSprintId <= curSprintId) {
            wp.tasks.forEach((task, tIndex) => {
              // console.log(`in task: ${task.name}`);
              // console.log(`   has email: ${task.assignedTo}`);
              if (
                (!userEmails || // if no userEmails, get them all that are needed and not done
                  ((task.assignedTo === '' || task.assignedTo === undefined) && userEmails[NOT_ASSIGNED]) ||
                  userEmails[task.assignedTo]) && //
                task.status !== STATUS.DONE &&
                task.status !== STATUS.NOT_NEEDED
              ) {
                // console.log(`------------- in here -------`);
                unorderedTaskIDsFromScan.push(task.uuid);
                assignmentInfoLookup[task.uuid] = {
                  projectObj: proj,
                  project: proj.name,
                  projectId: proj.uuid,
                  projectIndex: pIndex,
                  deliverable: del.name,
                  deliverableId: del.uuid,
                  delIndex: dIndex,
                  workpackage: wp.name,
                  workpackageId: wp.uuid,
                  workpackageIndex: wpIndex,
                  task: task.name,
                  taskObj: task,
                  taskId: task.uuid,
                  taskIndex: tIndex,
                  overdue: wpSprintId !== curSprintId,
                };
              }
            });
          }
        }
      });
    });
  });

  // at this point we have all assignment info in 'assignmentInfoLookup' and
  // all related taskIDs in 'unorderedTaskIDsFromScan'

  // now need to reorder based on saved order memory (oldAssignedTaskIds)

  const assignmentInfoInOrder = [];
  const processedTaskIds = {};
  oldAssignedTaskIds.forEach(taskId => {
    if (!processedTaskIds[taskId]) {
      if (assignmentInfoLookup[taskId]) {
        assignmentInfoInOrder.push(assignmentInfoLookup[taskId]);
      } else {
        // task no longer in list of assignments for user
        // assignmentInfoInOrder.push({ taskId: taskId, taskMissing: true }); // item unassigned from user or moved to another future spring by someone else
      }
      processedTaskIds[taskId] = true;
    }
  });
  const newAssignmentInfo = [];
  unorderedTaskIDsFromScan.forEach(taskId => {
    if (!processedTaskIds[taskId]) {
      // if not already handled, add (new items) to top
      newAssignmentInfo.push(assignmentInfoLookup[taskId]);
      processedTaskIds[taskId] = true;
    }
  });
  assignmentInfoInOrder.forEach(assignmentInfo => {
    newAssignmentInfo.push(assignmentInfo); // add preknown/ordered items to end
  });
  // extract taskIDs into new order memory
  const newAssignedTaskIds = newAssignmentInfo.reduce((acc, info) => {
    if (!info.taskMissing) acc.push(info.taskId);
    return acc;
  }, []);
  return { assignmentInfo: newAssignmentInfo, assignedTaskIds: newAssignedTaskIds };
};

const calcPercentComplete = (workPackage, resetSprint = {}) => {
  let numDoneTasks = 0;
  let numNonSkippedTasks = 0;
  let earliestStartDate = null;
  let latestEndDate = null;
  let earliestEstStartDate = null;
  let latestEstEndDate = null;
  let numTasks = 0;
  let numInProgressItems = 0;
  let resetThisWPSprint = false;
  workPackage.tasks.forEach(task => {
    if (hasActiveStatus(task.status)) {
      if (task.actualStartDate === '') {
        setStartDate(task, null);
      }
    } else {
      task.percentageComplete = 0;
      task.actualStartDate = '';
    }
    if (
      isTaskNeeded(task.status) &&
      task.actualStartDate !== '' &&
      (!earliestStartDate || moment(task.actualStartDate).isBefore(moment(earliestStartDate)))
    ) {
      earliestStartDate = task.actualStartDate;
    }
    if (
      isTaskNeeded(task.status) &&
      task.expectedStartDate &&
      task.expectedStartDate !== '' &&
      (!earliestEstStartDate || moment(task.expectedStartDate).isBefore(moment(earliestEstStartDate)))
    ) {
      earliestEstStartDate = task.expectedStartDate;
    }

    if (addTaskToTotal(task.status)) {
      numNonSkippedTasks++;
    }
    if (isInProgressStatus(task.status)) {
      task.numInProgressItems = 1;
      numInProgressItems++;
    } else {
      task.numInProgressItems = 0;
    }
    if (isTaskDone(task.status)) {
      numDoneTasks++;
      if (task.actualEndDate === '') {
        setEndDate(task, null);
      }
      task.isPriority = false;
      task.percentageComplete = 100;
    } else {
      task.percentageComplete = 0;
      task.actualEndDate = '';
    }
    if (
      isTaskNeeded(task.status) &&
      task.actualEndDate !== '' &&
      (!latestEndDate || moment(task.actualEndDate).isAfter(moment(latestEndDate)))
    ) {
      latestEndDate = task.actualEndDate;
    }
    if (
      isTaskNeededAndNotDone(task.status) &&
      task.actualEndDate === '' &&
      task.expectedEndDate !== '' &&
      (!latestEstEndDate || moment(task.expectedEndDate).isAfter(moment(latestEstEndDate)))
    ) {
      latestEstEndDate = task.expectedEndDate;
    }
    resetThisWPSprint = resetThisWPSprint || (resetSprint?.end && resetSprint?.taskID === task.uuid);
  });

  workPackage.expStartSetByTasks = false;
  workPackage.actualStartDate = '';
  // if (earliestEstStartDate) {
  //   workPackage.expectedStartDate = earliestEstStartDate;
  //   workPackage.expStartSetByTasks = true;
  // } else {
  //   if (workPackage.expStartSetByTasks) {
  //     workPackage.expectedStartDate = '';
  //     workPackage.expStartSetByTasks = false;
  //   }
  // }
  if (earliestStartDate) {
    setStartDate(workPackage, earliestStartDate);
  }

  if (numNonSkippedTasks === 0) {
    workPackage.percentageComplete = 0;
    workPackage.numDoneTasks = 0;
    workPackage.numNonSkippedTasks = 0;
  } else {
    workPackage.percentageComplete = (numDoneTasks / numNonSkippedTasks) * 100;
    workPackage.numDoneTasks = numDoneTasks;
    workPackage.numNonSkippedTasks = numNonSkippedTasks;
    workPackage.numInProgressItems = numInProgressItems;
  }

  workPackage.expEndSetByTasks = false;

  workPackage.actualEndDate = '';
  // if (latestEstEndDate) {
  //   workPackage.expectedEndDate = latestEstEndDate;
  //   workPackage.expEndSetByTasks = true;
  // } else {
  //   if (workPackage.expEndSetByTasks) {
  //     workPackage.expectedEndDate = '';
  //     workPackage.expEndSetByTasks = false;
  //   }
  // }
  if (numDoneTasks === numNonSkippedTasks) {
    workPackage.isPriority = false;
    setEndDate(workPackage, latestEndDate);
    workPackage.expEndSetByTasks = true;
  } else {
    if (numDoneTasks + numInProgressItems > 0) {
      if (workPackage.expectedEndDate === '') {
        const reduxStore = window.store.getState();
        let itemSprintId = date2SprintId(
          moment().format('YYYY-MM-DD'),
          reduxStore.viewState.numWeeksInSprint,
        );
        const expectedEndDateMoment = sprintId2Date(itemSprintId, reduxStore.viewState.numWeeksInSprint);
        workPackage.expectedEndDate = expectedEndDateMoment ? expectedEndDateMoment.format('YYYY-MM-DD') : '';
        workPackage.expEndSetByTasks = true;
      }
    } else {
      // all not started
      if (resetThisWPSprint && workPackage.expectedEndDate !== '') {
        workPackage.expectedEndDate = ''; // unschedule all tasks
        workPackage.expEndSetByTasks = true;
      }
    }
  }
};

function refreshStats(project, resetSprint = {}) {
  let earliestProjStartDate = null;
  let latestProjEndDate = null;
  let earliestProjEstStartDate = null;
  let latestProjEstEndDate = null;
  project.numDoneTasks = 0;
  project.numNonSkippedTasks = 0;
  project.deliverables.forEach(deliverable => {
    let earliestDelStartDate = null;
    let latestDelEndDate = null;
    let earliestDelEstStartDate = null;
    let latestDelEstEndDate = null;
    deliverable.numDoneTasks = 0;
    deliverable.numNonSkippedTasks = 0;
    deliverable.numInProgressItems = 0;
    deliverable.workPackages.forEach(workPackage => {
      calcPercentComplete(workPackage, resetSprint); // also set task start/end dates if not set

      deliverable.numDoneTasks += workPackage.numDoneTasks;
      deliverable.numNonSkippedTasks += workPackage.numNonSkippedTasks;
      deliverable.numInProgressItems += workPackage.numInProgressItems;

      if (
        workPackage.actualStartDate !== '' &&
        (!earliestDelStartDate || moment(workPackage.actualStartDate).isBefore(moment(earliestDelStartDate)))
      ) {
        earliestDelStartDate = workPackage.actualStartDate;
      }
      if (
        workPackage.actualEndDate !== '' &&
        (!latestDelEndDate || moment(workPackage.actualEndDate).isAfter(moment(latestDelEndDate)))
      ) {
        latestDelEndDate = workPackage.actualEndDate;
      }
      if (
        workPackage.expectedStartDate &&
        workPackage.expectedStartDate !== '' &&
        workPackage.status !== STATUS.DONE &&
        (!earliestDelEstStartDate ||
          moment(workPackage.expectedStartDate).isBefore(moment(earliestDelEstStartDate)))
      ) {
        earliestDelEstStartDate = workPackage.expectedStartDate;
      }
      if (
        workPackage.actualEndDate === '' &&
        workPackage.expectedEndDate !== '' &&
        workPackage.status !== STATUS.DONE &&
        (!latestDelEstEndDate || moment(workPackage.expectedEndDate).isAfter(moment(latestDelEstEndDate)))
      ) {
        latestDelEstEndDate = workPackage.expectedEndDate;
      }
    });
    if (deliverable.numNonSkippedTasks === 0) {
      deliverable.percentageComplete = 0;
    } else {
      deliverable.percentageComplete = (deliverable.numDoneTasks / deliverable.numNonSkippedTasks) * 100;
    }

    if (earliestDelEstStartDate) {
      deliverable.expectedStartDate = earliestDelEstStartDate;
      deliverable.expStartSetByWPs = true;
    } else {
      if (deliverable.expStartSetByWPs) {
        deliverable.expectedStartDate = '';
        deliverable.expStartSetByWPs = false;
      }
    }

    deliverable.actualStartDate = '';
    if (earliestDelStartDate) {
      setStartDate(deliverable, earliestDelStartDate);
    }

    if (latestDelEstEndDate) {
      deliverable.expectedEndDate = latestDelEstEndDate;
      deliverable.expEndSetByWPs = true;
    } else {
      deliverable.expectedEndDate = '';
      if (deliverable.expEndSetByWPs) {
        deliverable.expectedEndDate = '';
        deliverable.expEndSetByWPs = false;
      }
    }

    deliverable.actualEndDate = '';
    if (deliverable.numDoneTasks === deliverable.numNonSkippedTasks) {
      deliverable.isPriority = false;
      setEndDate(deliverable, latestDelEndDate);
    }

    project.numDoneTasks += deliverable.numDoneTasks;
    project.numNonSkippedTasks += deliverable.numNonSkippedTasks;
    if (
      deliverable.actualStartDate !== '' &&
      (!earliestProjStartDate || moment(deliverable.actualStartDate).isBefore(moment(earliestProjStartDate)))
    ) {
      earliestProjStartDate = deliverable.actualStartDate;
    }
    if (
      deliverable.actualEndDate !== '' &&
      (!latestProjEndDate || moment(deliverable.actualEndDate).isAfter(moment(latestProjEndDate)))
    ) {
      latestProjEndDate = deliverable.actualEndDate;
    }
    if (
      deliverable.expectedStartDate &&
      deliverable.expectedStartDate !== '' &&
      (!earliestProjEstStartDate ||
        moment(deliverable.expectedStartDate).isBefore(moment(earliestProjEstStartDate)))
    ) {
      earliestProjEstStartDate = deliverable.expectedStartDate;
    }
    if (
      deliverable.expectedEndDate &&
      deliverable.expectedEndDate !== '' &&
      (!latestProjEstEndDate || moment(deliverable.expectedEndDate).isAfter(moment(latestProjEstEndDate)))
    ) {
      latestProjEstEndDate = deliverable.expectedEndDate;
    }
  });
  if (project.numNonSkippedTasks === 0) {
    project.percentageComplete = 0;
  } else {
    project.percentageComplete = (project.numDoneTasks / project.numNonSkippedTasks) * 100;
  }

  if (earliestProjEstStartDate) {
    project.expectedStartDate = earliestProjEstStartDate;
    project.expStartSetByDels = true;
  } else {
    if (project.expStartSetByDels) {
      project.expectedStartDate = '';
      project.expStartSetByDels = false;
    }
  }
  project.actualStartDate = '';
  if (earliestProjStartDate) {
    setStartDate(project, earliestProjStartDate);
  }

  if (latestProjEstEndDate) {
    project.expectedEndDate = latestProjEstEndDate;
    project.expEndSetByDels = true;
  } else {
    if (project.expEndSetByDels) {
      project.expectedEndDate = '';
      project.expEndSetByDels = false;
    }
  }
  project.actualEndDate = '';
  if (project.numDoneTasks === project.numNonSkippedTasks) {
    setEndDate(project, latestProjEndDate);
  }
}

function removeAssigned(userEmail, project) {
  project.deliverables.forEach(deliverable => {
    if (deliverable.assignedTo === userEmail) {
      deliverable.assignedTo = '';
    }
    deliverable.workPackages.forEach(workPackage => {
      if (workPackage.assignedTo === userEmail) {
        workPackage.assignedTo = '';
      }
      workPackage.tasks.forEach(task => {
        if (task.assignedTo === userEmail) {
          task.assignedTo = '';
        }
      });
    });
  });
}

/**
 * Description
 * @function parseCommand
 * @param {string} commandString object.index.verb.location  project.0.delete workPackage.1.add.above
 * @returns {object} {actionObject, actionIndex, actionVerb, actionLocation}
 **/
function parseCommand(commandString) {
  let action = {
    target: '',
    index: 0,
    secondIndex: 0,
    verb: '',
    location: '',
  };
  const commands = commandString.split('.');
  action.target = commands[0].toUpperCase();
  action.index = parseInt(commands[1], 10);
  switch (action.target) {
    case 'WORKFLOW':
      action.index = commands[1];
    case 'WORK PACKAGE':
      action.secondIndex = parseInt(commands[2], 10);
      if (isNaN(action.secondIndex)) action.secondIndex = 0;
      action.verb = commands[3].toUpperCase();
      action.location = commands.length === 5 ? commands[4].toUpperCase() : '';
      break;
    default:
      action.verb = commands[2].toUpperCase();
      action.location = commands.length === 4 ? commands[3].toUpperCase() : '';
  }
  console.log(`parseCommand(${commandString}) = ${JSON.stringify(action, null, 2)}`);
  return action;
}
/**
 * Description
 * @function percentageTasksYes
 * @param {array} tasks
 * @returns {string}'50%' percentage of tasks answered yes.
 **/
function percentageTasksYes(tasks) {
  if (tasks.length === 0) {
    return `0%`;
  }
  const numberDone = tasks
    .map(task => (!task.skip && task.status.toUpperCase() === 'YES' ? 1 : 0))
    .reduce((acc, val) => acc + val);

  const numberTasks = tasks.length;

  if (numberTasks > 0) {
    const percentageResult = (numberDone / numberTasks) * 100;
    return percentageResult;
  } else {
    return '?/?';
  }
}

function sortTaskCards(taskCards) {
  taskCards.forEach(card => {
    card.task.order = getStatusOrder(card.task.status);
  });
  taskCards.sort((a, b) => {
    if (a.task.order < b.task.order) {
      return -1;
    }
    if (a.task.order > b.task.order) {
      return 1;
    }
    // have same status/order so sort by dates
    if (a.task.status === STATUS.NOT_STARTED) {
      if (a.task.expectedStartDate === '' && b.task.expectedStartDate === '') {
        return 0;
      }
      if (a.task.expectedStartDate !== '' && b.task.expectedStartDate === '') {
        return -1;
      }
      if (a.task.expectedStartDate === '' && b.task.expectedStartDate !== '') {
        return 1;
      }
      if (a.task.expectedStartDate < b.task.expectedStartDate) {
        return -1;
      }
      if (a.task.expectedStartDate > b.task.expectedStartDate) {
        return 1;
      }
      return 0;
    } else {
      if (isInProgressStatus(a.task.status)) {
        if (a.task.expectedEndDate === '' && b.task.expectedEndDate === '') {
          return 0;
        }
        if (a.task.expectedEndDate !== '' && b.task.expectedEndDate === '') {
          return -1;
        }
        if (a.task.expectedEndDate === '' && b.task.expectedEndDate !== '') {
          return 1;
        }
        if (a.task.expectedEndDate < b.task.expectedEndDate) {
          return -1;
        }
        if (a.task.expectedEndDate > b.task.expectedEndDate) {
          return 1;
        }
        return 0;
      } else {
        if (isTaskDone(a.task.status)) {
          if (a.task.actualEndDate === '' && b.task.actualEndDate === '') {
            return 0;
          }
          if (a.task.actualEndDate !== '' && b.task.actualEndDate === '') {
            return -1;
          }
          if (a.task.actualEndDate === '' && b.task.actualEndDate !== '') return 1;
          if (a.task.actualEndDate < b.task.actualEndDate) {
            return 1;
          }
          if (a.task.actualEndDate > b.task.actualEndDate) {
            return -1;
          }
          return 0;
        } else {
          return 0;
        }
      }
    }
  });
  return sortedCards;
}

function percentageDeliverableTasksDone(deliverable) {
  let numberDone = 0;
  let numberTasks = 0;
  if (deliverable.workPackages.length === 0) {
    return 0;
  }
  deliverable.workPackages.forEach(workpackage => {
    if (workpackage.tasks.length > 0) {
      numberDone += workpackage.tasks.reduce((acc, task) => {
        return isTaskDone(task.status) ? acc + 1 : acc;
      }, 0);

      numberTasks += workpackage.tasks.reduce((acc, task) => {
        return isTaskNeeded(task.status) ? acc + 1 : acc;
      }, 0);
    }
  });

  if (numberTasks > 0) {
    const percentageResult = (numberDone / numberTasks) * 100;
    return percentageResult;
  } else {
    return null;
  }
}

function percentageWorkpackageTasksDone(workpackage) {
  let numberDone = 0;
  let numberTasks = 0;
  if (workpackage.tasks.length === 0) {
    return 0;
  }

  numberDone += workpackage.tasks.reduce((acc, task) => {
    return isTaskDone(task.status) ? acc + 1 : acc;
  }, 0);

  numberTasks += workpackage.tasks.reduce((acc, task) => {
    return isTaskNeeded(task.status) ? acc + 1 : acc;
  }, 0);

  // numberTasks += workPackage.tasks.length;

  if (numberTasks > 0) {
    const percentageResult = (numberDone / numberTasks) * 100;
    return percentageResult;
  } else {
    return null;
  }
}

function percentageProjectTasksYes(project) {
  let numberDone = 0;
  let numberTasks = 0;

  if (!project) return '?/?';
  project.deliverables.forEach(deliverable => {
    deliverable.workPackages.forEach(workPackage => {
      if (workPackage.tasks.length > 0) {
        numberDone += workPackage.tasks
          .map(task => (isTaskDone(task.status) ? 1 : 0))
          .reduce((acc, val) => acc + val);

        numberTasks += workPackage.tasks
          .map(task => (isTaskNeeded(task.status) ? 1 : 0))
          .reduce((acc, val) => acc + val);
      }
    });
  });

  if (numberTasks > 0) {
    let percentageResult = (numberDone / numberTasks) * 100;
    return percentageResult;
  } else {
    return '?/?';
  }
}
function numNotNeededTasks(workpackage) {
  if (workpackage.tasks.length === 0) return 0;
  return workpackage.tasks.map(task => (isTaskNeeded(task.status) ? 0 : 1)).reduce((acc, val) => acc + val);
}

function genProjectID(nameBase) {
  // remove all none acceptable prefix characters from name
  nameBase = nameBase.replace(/[^0-9a-zA-Z ]/g, '');
  const words = nameBase.split(' ');
  let prefix = '';
  if (words.length === 1) {
    prefix = words[0].substring(0, 5).toUpperCase();
  } else {
    words.forEach(word => {
      if (prefix.length < 5) {
        prefix += word.substring(0, 1).toUpperCase();
      }
    });
  }
  return prefix;
}
export function generateComponentFromTemplate(
  type,
  name,
  creator,
  parentTemplateUUID,
  template,
  destIsTemplate,
) {
  // copy full object across
  const newComponent = JSON.parse(JSON.stringify(template));

  // reset components
  let today = new Date();
  newComponent.expectedStartDate = formatDate(today);
  newComponent.expectedEndDate = '';
  newComponent.actualStartDate = '';
  newComponent.actualEndDate = '';

  newComponent.advice = templateUUID ? null : project.advice;
  newComponent.templateRefUUID = destIsTemplate
    ? 'self'
    : { templateUUID: parentTemplateUUID, componentUUID: template.uuid };

  switch (type) {
    case 'project':
      newComponent.name = name;
      newComponent.creator = creator;
      newComponent.lastModifiedBy = creator;
      newComponent.sponsor = '';
      newComponent.projectManager = '';
      newComponent.sharedWith = [];
      newComponent.refLastNum = 0;
      newComponent.refLastTaskNum = 0;
      newComponent.percentageComplete = 0;
      newComponent.refPrefix = genProjectID(name);
      newComponent.refLastWPNum = 0;
      newComponent.refLastTaskNum = 0;
      newComponent.workflowSetId = null; // used in workflows to hold workflowSetId
      newComponent.library = null; // used in workflows to hold library props
      newComponent.firebaseCollection = 'projects'; // or 'workflows' if a workflow with library components
      break;
    case 'workflow':
      newComponent.name = name;
      newComponent.creator = creator;
      newComponent.lastModifiedBy = creator;
      newComponent.sponsor = '';
      newComponent.projectManager = '';
      break;
    case 'deliverable':
      break;
    case 'workpackage':
      break;
    case 'task':
      break;
    default:
      throw new Error('invalid component type in generateComponentFromTemplate');
  }
}
export function resetProjectState(
  name,
  creator,
  project,
  projectIsATemplate,
  copiedFromTemplate,
  copyInProgress,
  resetNewParms = true,
) {
  project.name = name;
  project.creator = creator;
  project.lastModifiedBy = creator;
  project.template = projectIsATemplate; // old deprecated
  let today = new Date();
  if (resetNewParms) {
    project.expectedStartDate = projectIsATemplate ? '' : formatDate(today);
    project.expectedEndDate = '';
    project.sponsor = '';
    project.projectManager = '';
    project.sharedWith = [];
  }
  project.actualStartDate = '';
  project.actualEndDate = '';
  project.refLastNum = 0;
  project.refLastTaskNum = 0;
  project.percentageComplete = 0;
  project.refPrefix = genProjectID(name);
  project.refLastWPNum = 0;
  project.refLastTaskNum = 0;
  if (!project.tooltip) project.tooltip = constants.DEFAULT_TOOLTIP;

  if (projectIsATemplate) {
    if (project.templateRefUUID && project.templateRefUUID !== 'self' && window.templateUUIDLookup) {
      // look up template using UUID and copy over advice
      const refTemplateComponent = window.templateUUIDLookup[project.templateRefUUID].component;
      if (refTemplateComponent) {
        project.advice = refTemplateComponent.advice;
      } else {
        project.advice = '';
      }
    } else {
      project.advice = '';
    }
    project.templateRefUUID = 'self';
    project.workflowUUID = project.uuid; // parent workflow
    project.firebaseCollection = 'workflows';
  } else {
    project.workflowSetId = null; // only used in workflows to hold workflowSetId
    project.library = null; // only used in workflows to hold library props
    if (copiedFromTemplate) {
      project.templateRefUUID = project.uuid; // project.uuid regenerated below
      project.workflowUUID = project.uuid; // parent workflow - not really used, redundant now
    }
    project.advice = null;
    project.firebaseCollection = 'projects';
  }
  project.uuid = genUUID();
  if (copyInProgress) {
    project.attachmentLinks = null;
  }
  project.deliverables.forEach(deliverable => {
    resetDeliverable(deliverable, project, projectIsATemplate, copiedFromTemplate, copyInProgress);
  });
}

function resetDeliverable(deliverable, curProject, projectIsATemplate, copiedFromTemplate, copyInProgress) {
  if (projectIsATemplate) {
    if (
      deliverable.templateRefUUID &&
      deliverable.templateRefUUID !== 'self' &&
      window.templateUUIDLookup &&
      window.templateUUIDLookup[deliverable.templateRefUUID]
    ) {
      // look up template using UUID and copy over advice
      const refTemplateComponent = window.templateUUIDLookup[deliverable.templateRefUUID].component;
      if (refTemplateComponent) {
        deliverable.advice = refTemplateComponent.advice;
      } else {
        deliverable.advice = '';
      }
    } else {
      deliverable.advice = '';
    }
    deliverable.templateRefUUID = 'self';
  } else {
    if (copiedFromTemplate) deliverable.templateRefUUID = deliverable.uuid;
    deliverable.advice = null;
  }
  if (!deliverable.tooltip) deliverable.tooltip = constants.DEFAULT_TOOLTIP;

  deliverable.pinned = false;
  deliverable.uuid = genUUID();
  deliverable.assignedTo = '';
  deliverable.percentageComplete = 0;
  deliverable.expectedStartDate = '';
  deliverable.actualStartDate = '';
  deliverable.expectedEndDate = '';
  deliverable.actualEndDate = '';
  deliverable.isPriority = false;
  if (copyInProgress) {
    deliverable.attachmentLinks = null;
  }
  deliverable.workPackages.forEach(workPackage => {
    resetWorkPackage(workPackage, curProject, projectIsATemplate, copiedFromTemplate, copyInProgress);
  });
}
function resetWorkPackage(workPackage, curProject, projectIsATemplate, copiedFromTemplate, copyInProgress) {
  if (projectIsATemplate) {
    if (
      workPackage.templateRefUUID &&
      workPackage.templateRefUUID !== 'self' &&
      window.templateUUIDLookup &&
      window.templateUUIDLookup[workPackage.templateRefUUID]
    ) {
      // look up template using UUID and copy over advice
      const refTemplateComponent = window.templateUUIDLookup[workPackage.templateRefUUID].component;
      if (refTemplateComponent) {
        workPackage.advice = refTemplateComponent.advice;
      } else {
        workPackage.advice = '';
      }
    } else {
      workPackage.advice = '';
    }
    workPackage.templateRefUUID = 'self';
  } else {
    if (copiedFromTemplate) workPackage.templateRefUUID = workPackage.uuid;
    workPackage.advice = null;
  }
  if (!workPackage.tooltip) workPackage.tooltip = constants.DEFAULT_TOOLTIP;

  workPackage.pinned = false;
  workPackage.uuid = genUUID();
  workPackage.refId = genWPRefId(curProject);
  workPackage.assignedTo = '';
  workPackage.percentageComplete = 0;
  workPackage.expectedStartDate = '';
  workPackage.actualStartDate = '';
  workPackage.expectedEndDate = '';
  workPackage.actualEndDate = '';
  workPackage.isPriority = false;
  if (copyInProgress) {
    workPackage.attachmentLinks = null;
  }
  workPackage.tasks.forEach(task => {
    resetTask(task, curProject, projectIsATemplate, copiedFromTemplate, copyInProgress);
  });
}
function resetTask(task, curProject, projectIsATemplate, copiedFromTemplate, copyInProgress) {
  if (projectIsATemplate) {
    if (
      task.templateRefUUID &&
      task.templateRefUUID !== 'self' &&
      window.templateUUIDLookup &&
      window.templateUUIDLookup[task.templateRefUUID]
    ) {
      // look up template using UUID and copy over advice
      const refTemplateComponent = window.templateUUIDLookup[task.templateRefUUID].component;
      if (refTemplateComponent) {
        task.advice = refTemplateComponent.advice;
      } else {
        task.advice = '';
      }
    } else {
      task.advice = '';
    }
    task.templateRefUUID = 'self';
  } else {
    if (copiedFromTemplate) task.templateRefUUID = task.uuid;
    task.advice = null;
  }
  task.pinned = false;
  task.uuid = genUUID();
  task.refId = genTaskRefId(curProject);
  task.status = STATUS.NOT_STARTED;
  task.assignedTo = '';
  task.percentageComplete = 0;
  task.expectedStartDate = '';
  task.actualStartDate = '';
  task.expectedEndDate = '';
  task.actualEndDate = '';
  task.isPriority = false;
  if (copyInProgress) {
    task.attachmentLinks = null;
  }
}
function findObjectWithId(list, id) {
  return list.find(item => item.uuid === id);
}
function findObjectIndexWithId(list, id) {
  return list.findIndex(item => item.uuid === id);
}
function getDefaultTemplate(workflows) {
  return workflows[constants.PUBLIC_GROUP_ID].find(workflow => workflow.library.default === true);
}

function getStarterProjectTemplate(workflows) {
  return workflows[constants.PUBLIC_GROUP_ID].find(
    workflow => workflow.starterProject && workflow.starterProject === true,
  );
}

function getAdviceComponent(workflows, templateRef, component) {
  if (!templateRef) return null;
  if (templateRef === 'self') return component;

  let result = null;
  Object.keys(workflows).forEach(setId => {
    // this is a hack... would be nice if we could simply look up components by uuid
    workflows[setId].forEach(template => {
      if (!result) {
        if (template.uuid === templateRef) {
          result = template;
        } else {
          template.deliverables.forEach(deliverable => {
            if (!result) {
              if (deliverable.uuid === templateRef) {
                result = deliverable;
              } else {
                deliverable.workPackages.forEach(workPackage => {
                  if (!result) {
                    if (workPackage.uuid === templateRef) {
                      result = workPackage;
                    } else {
                      workPackage.tasks.forEach(task => {
                        if (task.uuid === templateRef) {
                          result = task;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          template.library.components.deliverables.forEach(deliverable => {
            if (!result) {
              if (deliverable.uuid === templateRef) {
                result = deliverable;
              } else {
                deliverable.workPackages.forEach(workPackage => {
                  if (!result) {
                    if (workPackage.uuid === templateRef) {
                      result = workPackage;
                    } else {
                      workPackage.tasks.forEach(task => {
                        if (task.uuid === templateRef) {
                          result = task;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          template.library.components.workPackages.forEach(workPackage => {
            if (!result) {
              if (workPackage.uuid === templateRef) {
                result = workPackage;
              } else {
                workPackage.tasks.forEach(task => {
                  if (task.uuid === templateRef) {
                    result = task;
                  }
                });
              }
            }
          });
          template.library.components.tasks.forEach(task => {
            if (task.uuid === templateRef) {
              result = task;
            }
          });
        }
      }
    });
  });
  return result;
}

function genTemplateComponentLookupTable(workflows) {
  const lookupTable = {};

  Object.keys(workflows).forEach(setId => {
    const sortedWorkflow = workflows[setId].sort((p1, p2) =>
      p1.library.order < p2.library.order ? -1 : p1.library.order === p2.library.order ? 0 : 1,
    );
    const lastParentUUIDAtLevel = {};
    sortedWorkflow.forEach(template => {
      lastParentUUIDAtLevel[template.library.level] = template.uuid;
      lookupTable[template.uuid] = {
        type: 'p',
        component: template,
        parentUUID: template.library.level === 0 ? null : lastParentUUIDAtLevel[template.library.level - 1],
      };
      template.deliverables.forEach(deliverable => {
        lookupTable[deliverable.uuid] = { type: 'd', component: deliverable };
        deliverable.workPackages.forEach(workPackage => {
          lookupTable[workPackage.uuid] = { type: 'w', component: workPackage };
          workPackage.tasks.forEach(task => {
            lookupTable[task.uuid] = { type: 't', component: task };
          });
        });
      });
      template.library.components.deliverables.forEach(deliverable => {
        lookupTable[deliverable.uuid] = { type: 'd', component: deliverable };
        deliverable.workPackages.forEach(workPackage => {
          lookupTable[workPackage.uuid] = { type: 'w', component: workPackage };
          workPackage.tasks.forEach(task => {
            lookupTable[task.uuid] = { type: 't', component: task };
          });
        });
      });
      template.library.components.workPackages.forEach(workPackage => {
        lookupTable[workPackage.uuid] = { type: 'w', component: workPackage };
        workPackage.tasks.forEach(task => {
          lookupTable[task.uuid] = { type: 't', component: task };
        });
      });
      template.library.components.tasks.forEach(task => {
        lookupTable[task.uuid] = { type: 't', component: task };
      });
    });
  });
  return lookupTable;
}
function createNewProject(name = 'New Project', creator, template) {
  let newProject = JSON.parse(JSON.stringify(template ? template : schema.projectSchema));
  resetProjectState(name, creator, newProject, false, true, false);
  return newProject;
}
function createNewTemplateFromTemplate(name = 'New Workflow', creator, template, setId) {
  let newTemplate = JSON.parse(JSON.stringify(template ? template : schema.projectSchema));
  resetProjectState(name, creator, newTemplate, true, true, false);
  newTemplate.workflowSetId = setId;
  newTemplate.library.level = 1;
  newTemplate.library.default = false;
  newTemplate.library.order = 1000;
  newTemplate.starterProject = false;
  return newTemplate;
}
// currently only called from createCoreSmartTemplates()
function createNewTemplate(name = 'New Workflow', creator, libraryProps) {
  let newTemplate = JSON.parse(JSON.stringify(schema.projectSchema));
  resetProjectState(name, creator, newTemplate, true, false, false);
  newTemplate.workflowSetId = libraryProps.workflowSetId;
  newTemplate.library = libraryProps.library;
  newTemplate.starterProject = false;
  return newTemplate;
}

function clearAttachments(project) {
  project.attachmentLinks = null;
  project.deliverables.forEach(deliverable => {
    deliverable.attachmentLinks = null;
    deliverable.workPackages.forEach(workPackage => {
      workPackage.attachmentLinks = null;
      workPackage.tasks.forEach(task => {
        workPackage.attachmentLinks = null;
      });
    });
  });
}

/**
 * Description
 * @function copyProject
 * @param {object}  name of project
 * @param {string}  creator of project
 * @returns {project}
 **/

function copyProject(
  creator = '',
  oldProject = null, // could be a template or project
  createTemplate = false, // is result to be a template ?  otherwise a project
) {
  let newProject = JSON.parse(JSON.stringify(oldProject));
  resetProjectState(
    createTemplate ? oldProject.name : oldProject.name.concat(' (Copy)'),
    creator,
    newProject,
    createTemplate,
    false,
    true,
  );
  newProject.uuid = genUUID();
  if (createTemplate) {
    newProject.library = oldProject.library;
    newProject.templateName = newProject.name;
    newProject.accessRules = { emailEquals: [], tagEquals: [] };
  } else {
    // clearAttachments(newProject);
  }
  let testProjectStg = JSON.stringify(newProject);

  return newProject;
}

function createNewDeliverable(
  name = 'New Deliverable',
  curProject,
  template = schema.deliverableSchema,
  projectIsATemplate,
  copiedFromTemplate,
) {
  let newDeliverable = JSON.parse(JSON.stringify(template));
  newDeliverable.name = name;
  resetDeliverable(newDeliverable, curProject, projectIsATemplate, copiedFromTemplate, false);
  return newDeliverable;
}

function copyDeliverable(
  name = 'Deliverable copy',
  deliverable,
  curProject,
  mode,
  projectIsATemplate,
  copiedFromTemplate,
) {
  let newDeliverable = JSON.parse(JSON.stringify(deliverable));
  newDeliverable.name = name;
  if (mode === 'COPY')
    resetDeliverable(newDeliverable, curProject, projectIsATemplate, copiedFromTemplate, true);
  return newDeliverable;
}
/**
 * Description
 * @function createNewWorkPackage
 * @param {string}  name of step
 * @param {string}  stepType
 * @returns {step}
 **/

function createNewWorkPackage(
  name = 'New Task Group',
  curProject,
  template = schema.workPackageSchema,
  projectIsATemplate,
  copiedFromTemplate,
) {
  let newWorkPackage = JSON.parse(JSON.stringify(template));
  newWorkPackage.name = name;
  resetWorkPackage(newWorkPackage, curProject, projectIsATemplate, copiedFromTemplate, false);
  return newWorkPackage;
}

function copyWorkPackage(
  name = 'Task Group copy',
  workPackage,
  curProject,
  mode,
  projectIsATemplate,
  copiedFromTemplate,
) {
  let newWorkPackage = JSON.parse(JSON.stringify(workPackage));
  newWorkPackage.name = name;
  if (mode === 'COPY')
    resetWorkPackage(newWorkPackage, curProject, projectIsATemplate, copiedFromTemplate, true);
  else newWorkPackage.refId = genWPRefId(curProject);

  return newWorkPackage;
}
/**
 * Description
 * @function createNewTask
 * @param {string}  name of task
 * @returns {task}
 **/

function createNewTask(
  name = 'New Task',
  curProject,
  template = schema.taskSchema,
  projectIsATemplate,
  copiedFromTemplate,
) {
  let newTask = JSON.parse(JSON.stringify(template));
  newTask.name = name;
  resetTask(newTask, curProject, projectIsATemplate, copiedFromTemplate, false);
  return newTask;
}
function copyTask(name = 'Task copy', task, curProject, mode, projectIsATemplate, copiedFromTemplate) {
  let newTask = JSON.parse(JSON.stringify(task));
  newTask.name = name;
  if (mode === 'COPY') resetTask(newTask, curProject, projectIsATemplate, copiedFromTemplate, true);
  else newTask.refId = genTaskRefId(curProject);

  return newTask;
}

function getStatusColor(status) {
  switch (status) {
    case STATUS.NOT_NEEDED:
      return '#ddd';
    default:
    case STATUS.NOT_STARTED:
      return '#b8dbff';
    case STATUS.IN_PROGRESS:
      return '#fcf8d2';
    case STATUS.BLOCKED:
      return '#df6d66';
    case STATUS.IN_REVIEW:
      return '#efde7a';
    case STATUS.DONE:
      return '#829867';
  }
}

function getStatusOrder(status) {
  switch (status) {
    case STATUS.NOT_NEEDED:
      return 6;
    default:
    case STATUS.NOT_STARTED:
      return 4;
    case STATUS.IN_PROGRESS:
      return 2;
    case STATUS.BLOCKED:
      return 1;
    case STATUS.IN_REVIEW:
      return 3;
    case STATUS.DONE:
      return 5;
  }
}

function genWPRefId(project, idNum = null) {
  if (project) {
    if (!idNum) {
      project.refLastWPNum++;
      idNum = project.refLastWPNum;
    }
    return `${project.refPrefix}-${idNum}`;
  }
  return null;
}

function genTaskRefId(project, idNum = null) {
  if (project) {
    if (!idNum) {
      project.refLastTaskNum++;
      idNum = project.refLastTaskNum;
    }
    return `${project.refPrefix}-T${idNum}`;
  }
  return null;
}

function handleChangeProjectId(project, oldPrefix) {
  project.deliverables.forEach(deliverable => {
    deliverable.workPackages.forEach(wp => {
      const sepInd = wp.refId.indexOf('-'); // old format will have a dash
      if (sepInd >= 0) {
        let oldSuffix = wp.refId.substring(sepInd + 1);
        wp.refId = genWPRefId(project, oldSuffix);
        wp.tasks.forEach(task => {
          let oldSuffix = task.refId.substring(sepInd + 2);
          task.refId = genTaskRefId(project, oldSuffix);
        });
      } else {
        // odd format encountered, reset ids
        wp.refId = genWPRefId(project);
        wp.tasks.forEach(task => {
          task.refId = genTaskRefId(project);
        });
      }
    });
  });
}

function validProjectIDTest(string) {
  const regex = RegExp('^[0-9a-zA-Z]*$');
  if (string.length <= 5 && string !== '' && regex.test(string)) return true;
  return false;
}

/**
 * Description
 * @function toTitleCase
 * @param {string}  phrase
 * @returns {string} phrase with each first letter capitalized.
 **/
const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export {
  getDefaultTemplate,
  getStarterProjectTemplate,
  percentageTasksYes,
  percentageDeliverableTasksDone,
  percentageWorkpackageTasksDone,
  percentageProjectTasksYes,
  parseCommand,
  projectInfoValidationSchema,
  stepNoteValidationSchema,
  dispatchGetAssignments,
  formatDate,
  createNewProject,
  copyProject,
  isInProgressStatus,
  isTaskDone,
  createNewTemplate,
  createNewTemplateFromTemplate,
  createNewDeliverable,
  copyDeliverable,
  createNewWorkPackage,
  copyWorkPackage,
  createNewTask,
  copyTask,
  toTitleCase,
  refreshStats,
  generateNewUID,
  genWPRefId,
  genTaskRefId,
  handleChangeProjectId,
  validProjectIDTest,
  getStatusColor,
  getStatusOrder,
  numNotNeededTasks,
  genProjectID,
  findObjectWithId,
  findObjectIndexWithId,
  getDateStatsInfo,
  getDateStats,
  getStatusCategory,
  getAlertInfo,
  getWBSLink,
  getDateValues,
  getAdviceComponent,
  genTemplateComponentLookupTable,
  removeAssigned,
  isValidEmail,
  checkForNewMember,
  sprintId2Date,
  date2SprintId,
  getRelativeSprintId,
  getSprintData,
  setWPSprint,
  sprintWPCardsOrderSort,
};
