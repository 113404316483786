import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {
  uid: null,
  email: null,
  role: null,
  plan: null,
  groups: [],
  community: [],
};

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
      return {
        ...state,
        // token: action.userToken,
        uid: action.userToken.uid,
        email: action.userToken.email,
        role: action.role,
        plan: action.userPlan,
        groups: action.userGroups,
        // community: action.community,
      };

    case ACTION.TOUCH_USER_EMAIL:
      return {
        ...state,
        email: action.email,
      };

    case ACTION.UPDATE_PROJECTS:
      return {
        ...state,
        community: action.community,
      };

    case ACTION.SET_PLANS:
      return {
        ...state,
        plan: action.userPlan,
      };

    case ACTION.SET_GROUP:
    case ACTION.SET_USER_GROUPS:
      return {
        ...state,
        groups: action.userGroups,
      };

    case ACTION.CLEAR_USER_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
}
