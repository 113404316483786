// status saved with tasks  and status lists
export const NOT_NEEDED = 'Not Needed';
export const NOT_STARTED = 'Not Started';
export const IN_PROGRESS = 'In Progress';
export const BLOCKED = 'Blocked';
export const IN_REVIEW = 'In Review';
export const DONE = 'Done';

// what is drawn on screen
export const statusString = {
  [NOT_NEEDED]: 'Not Needed',
  [NOT_STARTED]: 'Not Started',
  [IN_PROGRESS]: 'In Progress',
  [BLOCKED]: 'On Hold',
  [IN_REVIEW]: 'In Review',
  [DONE]: 'Done',
};
// string lookup
export const statusFromString = {
  'Not Needed': NOT_NEEDED,
  'Not Started': NOT_STARTED,
  'In Progress': IN_PROGRESS,
  'On Hold': BLOCKED,
  'In Review': IN_REVIEW,
  Done: DONE,
};
