/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';
import moment from 'moment';
import FlatList from 'flatlist-react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import { ContentWrapper } from '../styled_components/ContentWrapper';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  IconButton,
  Dialog,
  DialogTitle,
  MenuItem,
  DialogContent,
  DialogActions,
  Button,
  InputAdornment,
  Select,
  LinearProgress,
  Box,
  Typography,
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { v4 as genUUID } from 'uuid';
import ActionMenu from '../styled_components/ActionMenu';
import {
  Add,
  InsertDriveFile,
  Folder,
  HighlightOff,
  KeyboardReturn,
  Edit,
  Delete,
  SaveAlt,
  CloudUploadOutlined,
  WebAsset,
} from '@material-ui/icons';
import Dropzone, { useDropzone } from 'react-dropzone';
import { InputField } from '../styled_components/InputField';
import { useActions } from '../../data/hooks/useActions';
import { addAttachment, deleteAttachment } from '../../data/actions/userInterface';
import { DeliverableIcon, WorkpackageIcon, ProjectIcon } from '../../components/Icons/CustomIcons';
import * as utils from '../../utils/generalUtilities.js';
import OneDriveFileManager from '../styled_components/OneDriveFileManager';

const PARENT_TYPES = {
  project: 'project',
  deliverable: 'deliverable',
  workpackage: 'workpackage',
};

const useStyles = makeStyles(theme => ({
  attachmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'scroll',
    // backgroundColor: 'yellow',
  },
  button: {
    marginRight: '10px',
  },
  addButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.light,
    margin: '0 5px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
    boxShadow: 1,
  },
  dropzone: {
    flex: 1,
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    borderStyle: 'dashed',
    borderRadius: 5,
    minHeight: '30vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fileIcon: {
    paddingRight: '5px',
    width: '24px',
  },
  formInput: {
    marginRight: '5px',
  },
  flexSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  largeInput: {
    flex: 1,
    display: 'flex',
  },
  inputIcon: {
    marginRight: '5px',
  },
  box: {
    margin: '15px 0',
  },
  boxLabel: {
    minWidth: '15%',
    textAlign: 'center',
  },
  itemRow: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid grey',
    borderRadius: '5px',
    marginRight: '15px',
    marginBottom: '10px',
    // backgroundColor: '#02f0f060'
  },
  fileIcon: {
    marginRight: '25px',
    color: 'grey',
  },
  rightSideIcons: {
    width: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
  rightSideIcon: {
    marginLeft: '20px',
  },
  fileName: {
    flex: 1,
    textDecoration: 'underline',
    textDecorationColor: '#0000EE',
    cursor: 'pointer',
  },
  submit: {
    marginTop: '20px',
    margin: 'auto',
    alignSelf: 'flex-end',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  form: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: '700px',
    marginTop: '4px',
    marginBottom: '4px',
  },
}));

const LinearProgressWithLabel = props => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.box}>
      <Box width="100%" mr={1}>
        <LinearProgress color="secondary" variant="determinate" {...props} />
      </Box>
      <Box className={classes.boxLabel}>
        <Typography variant="body2">{`${Math.round(props.value)}% Uploaded`}</Typography>
      </Box>
    </Box>
  );
};

const getIcon = type => {
  switch (type) {
    case PARENT_TYPES.project:
      return <ProjectIcon />;
    case PARENT_TYPES.deliverable:
      return <DeliverableIcon />;
    default:
      return <WorkpackageIcon />;
  }
};

const WEB_LINK = 'web-link';

export const AttachmentsTable = ({ component }) => {
  const [showModal, toggleModal] = useState(false);
  // const [currentProject, setProject] = useState(null);
  const [uploading, setUploading] = useState(0);
  const [editUpload, setEditUpload] = useState(null);
  const store = useStore();
  const storeState = store.getState();
  const { user, firebase } = storeState;
  const handleAdd = useActions(addAttachment);
  const handleDelete = useActions(deleteAttachment);

  const handleFileUpload = upload => {
    const { file } = upload;

    if (!file || !upload) return;

    const uploadTask = firebase.storage
      .ref()
      // .child('attachments/' + currentProject.uuid + '/' + file.name)
      .child('attachments/' + component.uuid + '/' + file.name)
      .put(file);
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploading(progress);
      },
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(`You aren't authorized to upload this file`);
            break;

          case 'storage/canceled':
            console.log(`User cancelled the upload`);
            // User canceled the upload
            break;

          default:
          case 'storage/unknown':
            console.log(`Unknown error`);
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(function(downloadURL) {
            // Handle successful uploads on complete
            const newFile = {
              // to match MS Graph API data format
              uuid: upload.uuid,
              name: upload.name,
              type: upload.file.type,
              lastModifiedDateTime: upload.file.lastModifiedDate ?? new Date().getTime(),
              webURL: downloadURL,
              downloadURL: downloadURL,
              attachedBy: user.email,
              attachedDate: moment().format(),
              attachedTo: component.uuid,
              dataSource: 'file upload',
            };

            // let newFile = upload;
            // newFile.url = downloadURL;
            // newFile.uploadTime = moment().format();
            // delete newFile.file;
            // newFile. = component.uuid
            handleAdd(newFile, component);
          })
          .then(() => {
            toggleModal(false);
            setUploading(0);
          });
      },
    );
  };

  const addFromCloud = ({ source, fileInfo, component }) => {
    if (source.trim() === 'MS OneDrive') {
      const newFile = {
        // to match MS Graph API data format
        uuid: fileInfo.id,
        name: fileInfo.name,
        type: fileInfo.file.mimeType,
        lastModifiedDateTime: fileInfo.lastModifiedDateTime ?? new Date().getTime(),
        webURL: fileInfo.webUrl,
        downloadURL: fileInfo['@microsoft.graph.downloadUrl'],
        attachedBy: user.email,
        attachedDate: moment().format(),
        attachedTo: component.uuid,
        dataSource: source.trim(),
      };
      handleAdd(newFile, component);
      toggleModal(false);
    }
  };

  const handleClose = upload => {
    handleFileUpload(upload);
    toggleModal(false);
  };
  const handleWebClose = newFile => {
    handleAdd(newFile, component);
    toggleModal(false);
  };
  let files = [];
  if (component) {
    files = component.attachmentLinks ?? [];
  }
  return (
    <>
      {showModal && (
        <AttachmentModal
          isOpen={showModal}
          handleClose={handleClose}
          handleWebClose={handleWebClose}
          // project={currentProject}
          component={component}
          user={user}
          progress={uploading}
          // editUpload={editUpload}
          addFromCloud={addFromCloud}
        />
      )}
      <FileList files={files} toggleModal={toggleModal} component={component} handleDelete={handleDelete} />
    </>
  );
};

const FileList = ({ files, toggleModal, component, handleDelete }) => {
  const [selected, setSelected] = useState(null);
  const classes = useStyles();
  const handleRowClick = id => {
    setSelected(id);
  };
  const renderItemRow = item => {
    return (
      <div style={{ overflow: 'hidden', overflowY: 'scroll' }}>
        <div className={classes.itemRow}>
          {item.type === WEB_LINK ? (
            <WebAsset className={classes.fileIcon} />
          ) : (
            <InsertDriveFile className={classes.fileIcon} />
          )}
          <div
            className={classes.fileName}
            onClick={e => {
              window.open(item.webURL);
              e.stopPropagation();
            }}
          >
            {item.name}
          </div>
          <div className={classes.rightSideIcons}>
            {item.type !== WEB_LINK && (
              <SaveAlt
                className={classes.rightSideIcon}
                onClick={e => {
                  window.open(item.downloadURL);
                  e.stopPropagation();
                }}
              />
            )}
            <HighlightOff
              className={classes.rightSideIcon}
              onClick={e => {
                if (window.confirm(`Press OK to remove the link to "${item.name}" from this project`)) {
                  handleDelete(item, component);
                }
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border: '3px solid #bbb',
        borderRadius: '5px',
        flex: 1,
        marginLeft: '10px',
        padding: '20px 0px 0px 10px',
      }}
    >
      <ContentWrapper
        title="Attachments"
        headerWidth={400}
        titleChildren={
          <IconButton
            aria-label="add-attachment"
            aria-controls="add-attachment"
            aria-haspopup="true"
            onClick={() => toggleModal(true)}
            size="small"
            className={classes.addButton}
          >
            <Add fontSize="small" />
          </IconButton>
        }
      >
        <div className={classes.attachmentContainer}>
          {!!files && files.length > 0 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  marginTop: '4px',
                  marginLeft: '20px',
                  rightMargin: '10px',
                  height: 'calc(100vh - 265px)',
                }}
              >
                <FlatList
                  className={classes.flatList}
                  list={files}
                  renderItem={renderItemRow}
                  // renderOnScroll
                />
              </div>
            </TableContainer>
          ) : (
            <i>You don't have any files attached yet. Press the + sign to attach a file.</i>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

const FileRow = ({ file, selected, handleClick }) => {
  const classes = useStyles();
  const isSelected = file.uuid === selected;
  const FILE_ACTIONS = [
    {
      name: 'Edit',
      icon: <Edit />,
      action: {
        uuid: file.uuid,
        type: 'Edit',
      },
    },
    {
      name: 'Delete',
      icon: <Delete />,
      action: {
        uuid: file.uuid,
        type: 'Delete',
      },
    },
  ];
  return (
    <TableRow onClick={() => handleClick(file.uuid)}>
      <TableCell>{file.owner}</TableCell>
      <TableCell>
        <a href={file.url}>{file.name}</a>
      </TableCell>
      <TableCell>
        <span className={classes.flexSpan}>
          {getIcon(file.type)} {file.parentName}
        </span>
      </TableCell>
      <TableCell align="right">{moment(file.uploadTime).format('MM/DD/YYYY')}</TableCell>
    </TableRow>
  );
};

const StyledTab = withStyles(theme => ({
  root: {
    width: 240,
    fontSize: '0.9rem',
    fontColor: theme.palette.primary.main,
    fontWeight: 500,
  },
}))(props => <Tab {...props} />);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const TabBar = ({ value, handleChange }) => {
  const classes = useStyles();
  return (
    <Tabs
      style={{ alignSelf: 'flex-end', marginLeft: 30 }}
      value={value}
      orientation="horizontal"
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
      // variant="fullWidth"
      aria-label="notes tabs"
      scrollButtons="auto"
      className={classes.root}
    >
      <StyledTab label="Upload File" {...a11yProps(0)} icon={<SaveAlt />} />
      <StyledTab label="Add Web Page" {...a11yProps(1)} icon={<WebAsset />} />
      <StyledTab label="OneDrive" {...a11yProps(2)} icon={<CloudUploadOutlined />} />
      {/* <StyledTab label="Google Docs" {...a11yProps(2)} icon={<CloudUploadOutlined />} /> */}
    </Tabs>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const AttachmentModal = ({
  isOpen,
  handleClose,
  handleWebClose,
  // project,
  component,
  user,
  addFromCloud,
  progress = 32,
  // editUpload = null,
}) => {
  const classes = useStyles();
  const [uploadSource, setUploadSource] = useState('upload');
  const [curTabIndex, setCurTabIndex] = useState(0);
  const [errors, setErrors] = useState();
  const [webURL, setWebURL] = useState('');
  const [webLinkName, setWebLinkName] = useState('');
  // const [parentOptions, setParentOptions] = useState([]);

  // useEffect(() => {
  //   if (component) {
  //   }
  // }, []);

  const handleReject = rejectedFiles => {
    const rejectedFile = rejectedFiles[0];
    setErrors(rejectedFile.errors[0].message);
  };

  const handleDrop = acceptedFiles => {
    let upload = {};
    upload.file = acceptedFiles[0]; // Only accept one file at a time
    if (!upload.file) return;
    if (!upload.owner) upload.owner = user.email;
    if (!upload.parent) upload.parent = component.uuid;
    if (!upload.parentName) upload.parentName = component.name;
    if (!upload.uuid) upload.uuid = genUUID();
    if (!upload.name) upload.name = upload.file.name;
    // if (!upload.type) upload.type = PARENT_TYPES.project;
    handleClose(upload);
  };

  const { onDrop, onDropRejected } = useDropzone({
    onDrop,
    onDropRejected,
  });

  const handleChange = (_event, newValue) => {
    if (newValue !== curTabIndex) {
      setCurTabIndex(newValue);
    }
  };

  const handleSubmit = event => {
    if (webLinkName !== '' && webURL !== '') {
      const newFile = {
        // to match MS Graph API data format
        uuid: genUUID(),
        name: webLinkName,
        type: WEB_LINK,
        lastModifiedDateTime: new Date().getTime(),
        webURL: webURL,
        downloadURL: webURL,
        attachedBy: user.email,
        attachedDate: moment().format(),
        attachedTo: component.uuid,
        dataSource: WEB_LINK,
      };
      handleWebClose(newFile);
    }
    event.stopPropagation();
  };
  const handleWebNameChange = event => {
    setWebLinkName(event.target.value);
    event.stopPropagation();
  };
  const handleWebURLChange = event => {
    setWebURL(event.target.value);
    event.stopPropagation();
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="add-attachment" fullWidth maxWidth="md">
      <DialogTitle id="add-attachment">Attach a Document</DialogTitle>
      <DialogContent style={{ height: '70vh' }}>
        <TabBar value={curTabIndex} handleChange={handleChange} />
        <TabPanel className={classes.tabPanel} value={curTabIndex} index={0}>
          <Dropzone onDrop={handleDrop} onDropRejected={handleReject} minSize={0} maxSize={5242880}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {!isDragActive && 'Drop a file to upload or click here to select a new file.'}
                {isDragActive && !errors && 'Ready to drop.'}
                {errors && ` ${errors}`}
              </div>
            )}
          </Dropzone>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={curTabIndex} index={1}>
          <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="name">Name for Link</InputLabel>
                <Input
                  onChange={handleWebNameChange}
                  id="name"
                  name="name"
                  autoComplete="name"
                  // autoFocus
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="url">Site URL</InputLabel>
                <Input
                  onChange={handleWebURLChange}
                  name="url"
                  type="url"
                  id="url"
                  // autoComplete="current-password"
                />
              </FormControl>
              <Button
                type="submit"
                // fullWidth
                alignSelf="center"
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={webURL === '' || webLinkName === ''}
              >
                {'Attach Web Link'}
              </Button>
            </form>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={curTabIndex} index={2}>
          <OneDriveFileManager
            attachCallback={fileInfo => {
              addFromCloud({ source: 'MS OneDrive', fileInfo, component });
            }}
          />
        </TabPanel>
        {/* <TabPanel className={classes.tabPanel} value={curTabIndex} index={2}>
          <OneDriveFileManager 
            attachCallback={(fileInfo) => {
              addFromCloud({source: "Google Drive", fileInfo, component}) 
            }}
          />
        </TabPanel> */}
      </DialogContent>
      <DialogActions>
        <Button disabled={false} variant="contained" onClick={handleClose}>
          Close
        </Button>
        {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose(upload)}
        >
          Attach File
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
