import * as ACTION from '../actions/actionTypes';

// Firebase components
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

const INITIAL_STATE = {
  initialized: false,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  db: null,
  auth: null,
  isLive: false, // true if firebase sdk has reported auth user state via onAuthStateChanged(),
  functions: null,
  stripeExt: null,
};

export default function firebaseReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (!firebase.apps.length) {
        // avoids error during dev hotloading
        firebase.initializeApp({
          apiKey: state.apiKey,
          authDomain: state.authDomain,
          databaseURL: state.databaseURL,
          projectId: state.projectId,
          storageBucket: state.storageBucket,
          messagingSenderId: state.messagingSenderId,
          appId: state.appId,
          measurementId: state.measurementId,
        });

        // following line taken from https://stackoverflow.com/questions/65594133/react-native-to-firestore-firestore-8-2-1-connection-webchannel-transport-er
        // to combate unresolved error: Firestore (8.2.1): Connection WebChannel transport errored
        firebase.firestore().settings({ experimentalAutoDetectLongPolling: true }); //add this..
      }

      console.log(`====== firebase rehydration: ${process.env.REACT_APP_AUTH_DOMAIN}`);
      return {
        ...state,
        initialized: true,
        core: firebase, // temp hook for old code, will remove once port to redux complete
        db: firebase.firestore(),
        auth: firebase.auth(),
        storage: firebase.storage(),
        analytics: null,
        functions: firebase.functions(),
      };

    case ACTION.SET_USER_INFO:
    case ACTION.CLEAR_USER_INFO:
      return {
        ...state,
        isLive: true,
      };

    default:
      return state;
  }
}
