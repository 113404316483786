import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {
  assignmentInfo: null,
  assignedTaskIds: [], // holds last order of assigned task ids
};

export default function assignments(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}
