import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

import { InfoButton } from './styled_components/InfoButton';

import { updateViewState, setInfoDialog } from '../data/actions/userInterface';

import * as utils from '../utils/generalUtilities.js';
import * as constants from '../constants/app';

const useStyles = makeStyles(theme => ({
  templateDropdown: {
    width: 360,
    minWidth: 360,
  },
  nameField: {
    width: '500px',
    marginBottom: '15px',
    // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green",
    //   color: "green"
    // },
    // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "red",
    //   color: "green"
    // },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#BBB',
      // color: "green"
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginLeft: 10,
  },
}));

function FormDialog({
  open,
  title,
  text,
  textLabel,
  workflows,
  curProject,
  formType,
  answerYes,
  answerNo,
  placeholder = '',
  viewState,
  updateViewState,
  setInfoDialog,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [values, setValues] = React.useState(() => {
    const newValues = {
      newText: text,
      template: undefined,
      disabled: true,
      templateName: undefined,
    };
    return newValues;
  });
  // useEffect(() => {
  //   return {
  //     newText: text,
  //     template: undefined,
  //     disabled: true,
  //     templateName: undefined,
  //   }
  // }, [text]);

  if (!open) return null;

  let templateList = [];
  const templateNames = [];
  const templateNameLookup = {};
  const ftSplit = formType.split('-');
  if (ftSplit[0] && ftSplit[1] && ftSplit[1] === 'ADD') {
    // only done from projects, not templates -> Save as template
    templateList = utils.getLibraryComponents(
      workflows,
      curProject.templateRefUUID === 'self' ? curProject.uuid : curProject.templateRefUUID,
      ftSplit[0],
    );

    if (templateList.length > 0) {
      let found = false;
      templateList.forEach((t, i) => {
        templateNames.push(`${t.name}`);
        // templateNames.push(`${i + 1}-${t.name}`);
        templateNameLookup[t.name] = t;
        if (values.template && t.uuid === values.template.uuid) found = true;
      });
      if (!found) {
        // text = templateNames.length > 0 ? (templateList[0].level > 0 ? templateNames[0] : text) : text;
        // setValues({
        //   newText: text,
        //   template: templateList.length > 0 ? templateList[0] : null,
        //   disabled: true,
        //   templateName: templateNames.length > 0 ? templateNames[0] : undefined,
        // });
        // return null;
      }
    }
  }

  const handleYes = () => {
    answerYes(values);
    setValues({
      ...values,
      newText: '',
      template: templateList.length > 0 ? templateList[0] : undefined,
      disabled: true,
      templateName: templateNames.length > 0 ? templateNames[0] : undefined,
    });
  };

  const handleNo = () => {
    answerNo();
    setValues({
      ...values,
      newText: '',
      template: undefined,
      disabled: true,
      templateName: undefined,
    });
  };

  const handleChange = event => {
    let newTemp = values.template;
    if (event.target.name === 'templateName') {
      const newTempIndex = templateNames.indexOf(event.target.value);
      newTemp = templateList[newTempIndex];
    }
    setValues({
      ...values,
      newText: newTemp === values.template ? event.target.value : newTemp.level > 0 ? event.target.value : '',
      [event.target.name]: event.target.value,
      disabled: false,
      template: newTemp ? newTemp : values.template,
    });
  };
  if (!text) text = '';
  const numberLines = text.split(/\r\n|\r|\n/).length;
  const componentTypeName =
    ftSplit[0] === 'DELIVERABLE' ? 'deliverable' : ftSplit[0] === 'WORKPACKAGE' ? 'task group' : 'task';

  return (
    <div>
      <Dialog open={open} onClose={handleNo} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            label={utils.toTitleCase(textLabel)}
            name={'newText'}
            margin="dense"
            id="name"
            type="text"
            multiline={numberLines > 0}
            // defaultValue={values.newText}
            value={values.newText}
            placeholder={placeholder}
            fullWidth
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                handleYes();
              }
            }}
            InputLabelProps={{
              style: { color: '#AAA' },
            }}
            onClick={handleChange}
            onChange={handleChange}
            className={classes.nameField}
          />
          {templateList.length > 0 &&
            (ftSplit[0] === 'DELIVERABLE' || ftSplit[0] === 'WORKPACKAGE' || ftSplit[0] === 'TASK') && (
              <TextField
                required
                select
                variant="outlined"
                label="Workflow"
                name={'templateName'}
                value={values.templateName}
                InputLabelProps={{
                  style: { color: '#AAA' },
                }}
                onChange={e => {
                  handleChange(e);
                }}
                className={classes.templateDropdown}
              >
                {templateNames.map((tempName, index) => (
                  <MenuItem key={index} value={tempName}>
                    <div className={classes.menuItem}>
                      {tempName}
                      {templateList[index].level > 0 && !utils.isInfoEmpty(templateList[index].description) && (
                        <InfoButton
                          handleClick={() => {
                            setInfoDialog({
                              // sets InfoDialog reducer values
                              title: `Template: ${tempName}`,
                              content: {
                                description: templateList[index].description,
                                tooltip: templateList[index].tooltip ?? constants.DEFAULT_TOOLTIP,
                              },
                            });
                          }}
                          size="small"
                          color={theme.palette.primary.infoButton}
                          bgdColor="#FFF0"
                          hoverColor={theme.palette.primary.infoButton + '10'}
                          borderColor={theme.palette.primary.infoButton}
                          tooltip={`view description`}
                          // tooltip={`view advice for this ${componentTypeName}`}
                          containerClassName={classes.infoButton}
                          type={'i'}
                        />
                      )}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleYes}
            color="secondary"
            variant="contained"
            autoFocus
            disabled={values.disabled}
          >
            Save
          </Button>
          <Button variant="contained" onClick={handleNo} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  state => {
    return {};
  },
  {
    updateViewState,
    setInfoDialog,
  },
)(FormDialog);
