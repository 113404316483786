import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import * as STATUS from '../../constants/status';
import { Add } from '@material-ui/icons';

const buttonStyles = makeStyles(theme => ({
  button: {
    color: ({ color }) => `${color}`,
    // theme.palette.getContrastText(bgdColor),
    backgroundColor: ({ bgdColor }) => `${bgdColor}`,
    '&:hover': {
      backgroundColor: ({ hoverColor }) => `${hoverColor}`,
    },
    border: ({ border, borderColor }) => border ? border : `solid 1.5px ${borderColor ?? 'white'}`,
    borderRadius: ({ borderRadius, width }) => borderRadius ? borderRadius : `${Math.floor(width / 2)}px`,
    maxWidth: ({ width }) => `${width}px`,
    minWidth: ({ width }) => `${width}px`,
    maxHeight: ({ width }) => `${width}px`,
    minHeight: ({ width }) => `${width}px`,
    boxShadow: '0px 1px 2px -2px rgba(138, 148, 159, 0.7)',
    // textTransform: 'none', // prevents text from being drawn as uppercase (default)
    // fontStyle: 'italic',
  },
}));

export const AddButton = ({
  handleClick,
  color,
  bgdColor,
  hoverColor,
  borderColor,
  border,
  borderRadius,
  fontSize, 
  fontWeight,
  size, // 'small', 'medium' , 'big'
  tooltip,
  containerClassName,
}) => {
  const width = size === 'small' ? 21 : size === 'medium' ? 31 : 81;
  const buttonClasses = buttonStyles({ color, bgdColor, hoverColor, borderColor, size, width, border, borderRadius });

  return (
    <div className={containerClassName}>
      <Tooltip title={tooltip}>
        <Button size={size} onClick={handleClick} variant="contained" className={buttonClasses.button}>
          <Add style={{fontSize, fontWeight: fontWeight ?? 700}}/>
        </Button>
      </Tooltip>
    </div>
  );
};
