import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveCurrentProject, rollUpDates } from '../../data/actions/userInterface';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import NameIcon from '@material-ui/icons/SupervisorAccount';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { InputField } from '../../components/styled_components/InputField';
import NamePicker from '../NamePicker';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Menu,
  Button,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { PriorityIcon } from '../Icons/PriorityIcon';
import * as TASK from '../../constants/tasks';
import * as constants from '../../constants/app';
import Typography from '@material-ui/core/Typography';
import * as STATUS from '../../constants/status';
import { updateCommunity } from '../../data/actions/app';
import * as utils from '../../utils/generalUtilities.js';

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: '10px',
  },
  formSectionContainer: {
    flexDirection: 'column',
    margin: '20px 0',
    display: 'flex',
  },
  formSection: {
    flexDirection: 'column',
    margin: '0px 0',
    display: 'flex',
  },
  input: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
  },
  priorityInput: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
    marginTop: '15px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemTitle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.assign} !important`,
  },
  specialMenuItem: {
    display: 'flex',
    alignItems: 'center',
    fontColor: 'yellow',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '5px',
  },
  dateMessage: {
    alignSelf: 'flex-start',
    fontSize: '1.1rem',
    fontWeight: 700,
    color: '#444',
    marginLeft: '10px',
  },
  dateMessage2: {
    alignSelf: 'flex-start',
    fontSize: '1.0rem',
    // fontWeight: 600,
    color: '#444',
    marginLeft: '20px',
  },
  dateMessage3: {
    alignSelf: 'flex-start',
    fontSize: '.9rem',
    color: '#444',
    marginLeft: '90px',
    marginTop: '-8px',
  },
  newTeamMemberLabel: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    color: '#666',
  },
  newTeamMemberText: {
    color: theme.palette.secondary.dark,
  },
  addButton: {
    width: '115px',
    backgroundColor: theme.palette.secondary.faint,
    marginLeft: '10px',
  },
  cancelButton: {
    width: '115px',
    backgroundColor: '#EEE',
  },
}));

const assignStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
  },
}));

const ItemDetails = props => {
  const {
    values: { expectedStartDate, expectedEndDate, assignedTo, isPriority },
    item,
    cardType,
    project,
    community,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    saveCurrentProject,
    rollUpDates,
    editStartDate,
    editEndDate,
    viewState,
    sprintData,
    // updateCommunity,
  } = props;
  const classes = useStyles();
  const NEW_PERSON = 'Add new team member';
  const UNASSIGNED = 'Clear assignment';
  const ASSIGNEDTO = 'assignedTo';
  const [anchorEl, setAnchorEl] = useState(null);

  const [assignee, setAssignee] = useState(
    assignedTo !== '' && utils.isValidEmail(assignedTo)
      ? { email: assignedTo, useMenu: true, isValidEmail: true }
      : { email: '', useMenu: true, isValidEmail: false },
  );
  useEffect(() => {
    setAssignee({
      // update local state vars on value update
      email: assignedTo,
      useMenu: true,
      isValidEmail: assignedTo === '' ? false : true,
    });
  }, [assignedTo]);

  const handleSave = (value, name) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    setFieldValue(name, trimmedValue);
    item[name] = trimmedValue;
    if (name === ASSIGNEDTO && trimmedValue !== '' && trimmedValue !== project.creator.trim()) {
      utils.checkForNewMember(trimmedValue, project, community); // add member to project.sharedWith
    }
    saveCurrentProject();
  };
  const handleUpdate = (value, name) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    setFieldValue(name, trimmedValue);
    item[name] = trimmedValue;
    setFieldTouched(name, true, false);
    if (
      name === 'actualStartDate' ||
      name === 'actualEndDate' ||
      name === 'expectedStartDate' ||
      name === 'expectedEndDate'
    ) {
      rollUpDates(true);
    }
    if (name === ASSIGNEDTO && trimmedValue !== '' && trimmedValue !== project.creator.trim()) {
      utils.checkForNewMember(trimmedValue, project, community); // add member to project.sharedWith
    }
    saveCurrentProject();
  };

  const sprintItems = [];
  const sprintMenuItems = [];
  const sprintInPast = {};
  const existingSprintKeys = Object.keys(sprintData);
  existingSprintKeys.sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
  let curSprintId = utils.getRelativeSprintId(viewState.numWeeksInSprint);
  for (let i = -2; i < 0; i++) {
    let prevSprintId = utils.getRelativeSprintId(viewState.numWeeksInSprint, i);
    const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(
      prevSprintId,
      viewState.numWeeksInSprint,
    );

    sprintItems.push({
      sprintId: prevSprintId,
      startDate: sprintStartDate,
      endDate: sprintEndDate,
      menuStr: `${prevSprintId}:   ${sprintStartDate}-${sprintEndDate}`,
    });
    sprintMenuItems.push(`${prevSprintId}: ${sprintStartDate}`);
    sprintInPast[`${prevSprintId}: ${sprintStartDate}`] = true;
  }
  existingSprintKeys.forEach((sprintId, sindex) => {
    if (sprintId >= curSprintId) {
      const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(
        sprintId,
        viewState.numWeeksInSprint,
      );
      sprintItems.push({
        sprintId: sprintId,
        startDate: sprintStartDate,
        endDate: sprintEndDate,
        menuStr: `${sprintId}:    ${sprintStartDate}-${sprintEndDate}`,
      });
      sprintMenuItems.push(`${sprintId}: ${sprintStartDate}`);
      sprintInPast[`${sprintId}: ${sprintStartDate}`] = false;
    }
  });
  const itemSprintId = utils.date2SprintId(item.expectedEndDate, viewState.numWeeksInSprint);
  const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(
    itemSprintId,
    viewState.numWeeksInSprint,
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formSectionContainer}>
        <div className={classes.formSection}>
          <NamePicker
            field={assignee}
            fieldLabel={'Assigned To'}
            newEmailLabel={`New team member email`}
            project={project}
            community={community}
            handleSave={handleSave}
            handleUpdate={handleUpdate}
            newPersonText={NEW_PERSON}
            removeText={UNASSIGNED}
            onBlur={inputValue => {
              setAssignee({
                email: inputValue,
                useMenu: false,
                isValidEmail: utils.isValidEmail(inputValue),
              });
            }}
            cancelButtonClick={() => {
              setAssignee({ email: '', useMenu: true, isValidEmail: false });
            }}
            addButtonClick={() => {
              if (assignee.isValidEmail) {
                setAssignee({
                  email: assignee.email,
                  useMenu: true,
                  isValidEmail: utils.isValidEmail(assignee.email),
                });
                handleSave(assignee.email, ASSIGNEDTO);
              }
            }}
            onMenuChange={e => {
              if (e.target.value === UNASSIGNED) {
                setAssignee({ email: '', useMenu: true, isValidEmail: false });
                handleUpdate('', ASSIGNEDTO);
              } else {
                if (e.target.value === NEW_PERSON) {
                  setAssignee({ email: '', useMenu: false, isValidEmail: false });
                  handleUpdate('', ASSIGNEDTO);
                } else {
                  if (e.target.value !== assignee.email) {
                    setAssignee({
                      email: e.target.value,
                      useMenu: e.target.value !== NEW_PERSON,
                      isValidEmail: utils.isValidEmail(e.target.value),
                    });
                    handleUpdate(e.target.value, ASSIGNEDTO);
                  }
                }
              }
            }}
            InputProps={{
              // see https://mui.com/material-ui/api/input/ for options
              startAdornment: (
                <InputAdornment position="start">
                  <NameIcon />
                </InputAdornment>
              ),
              autocomplete: 'off',
            }}
          />
          <FormControl variant="outlined" className={classes.priorityInput}>
            <InputLabel id="workpackage-priority-label">Priority</InputLabel>
            <Select
              id="priority-select"
              name="isPriority"
              labelId="workpackage-priority-label"
              classes={{ root: classes.menuItem }}
              disabled={item.actualEndDate !== ''}
              value={item.actualEndDate === '' ? isPriority || false : false}
              onChange={e => handleUpdate(e.target.value, 'isPriority')}
              variant="outlined"
              label="Priority"
            >
              <MenuItem className={classes.menuItem} value={false}>
                Not a Priority
              </MenuItem>
              <MenuItem className={classes.menuItem} value={true}>
                <PriorityIcon wrapperClass={classes.icon} />
                Priority
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {cardType === TASK.WORKPACKAGE_CARD && (
        <div className={classes.formSectionContainer}>
          <div className={classes.formSection}>
            <Fragment>
              <Typography variant={'h6'} className={classes.dateMessage}>
                {itemSprintId === constants.NO_SPRINT ? 'Work Not Scheduled' : `Work Scheduled for`}
              </Typography>
              {/* {itemSprintId !== constants.NO_SPRINT && ( */}
              <Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography variant={'h6'} className={classes.dateMessage2}>
                    {`Work Period:`}
                  </Typography>
                  <Tooltip
                    title={`click to ${itemSprintId === constants.NO_SPRINT ? 'set' : 'change'} Work Period`}
                  >
                    <TextField
                      id="outlined-step-sprint"
                      name="workPeriod"
                      variant="outlined"
                      disabled={true}
                      value={itemSprintId}
                      className={classes.input}
                      onClick={e => setAnchorEl(e.currentTarget)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                  <Menu
                    id="sprint-menu"
                    anchorEl={anchorEl ? anchorEl.el : null}
                    open={!!anchorEl}
                    value={sprintMenuItems[4]}
                    onClose={e => {
                      setAnchorEl(null);
                    }}
                    PaperProps={{
                      style: {
                        width: '26ch',
                      },
                    }}
                    className={classes.menu}
                  >
                    {sprintMenuItems.map((option, index) => {
                      return (
                        <MenuItem
                          key={`sprint-${option}`}
                          className={classes.menuItem}
                          style={{ color: sprintInPast[option] ? '#888' : '#333' }}
                          value={option}
                          selected={itemSprintId === option.split(':')[0]}
                          onClick={e => {
                            setAnchorEl(null);
                            const wpd = option.split(':')[0];
                            handleUpdate(
                              utils.sprintId2Date(wpd, viewState.numWeeksInSprint).format('YYYY-MM-DD'),
                              'expectedEndDate',
                            );
                          }}
                        >
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
                {itemSprintId !== constants.NO_SPRINT && (
                  <Typography variant={'h6'} className={classes.dateMessage3}>
                    {`${sprintStartDate.split(',')[0]} - ${sprintEndDate}`}
                  </Typography>
                )}
              </Fragment>
              {/* )} */}
            </Fragment>
          </div>
        </div>
      )}
    </form>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
    };
  },
  {
    saveCurrentProject,
    rollUpDates,
    // updateCommunity,
  },
)(ItemDetails);
