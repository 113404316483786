import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import DraggableWPCard from './DraggableWPCard';
import DraggableTaskCard from './DraggableTaskCard';
import * as STATUS from '../../constants/status';
import * as TASK from '../../constants/tasks';

// Droppable container for Dragable WP cards
const DroppableWPCardList = ({
  listId = 'LIST',
  wpCards, // actual cards now
  thin = false,
  viewStack,
  isLibrary,
  viewState,
  parentContext,
  onboarding,
}) => {
  const height = wpCards.length < 3 ? '100%' : 'auto';
  const bmargin = '40px';
  const inWorkflow = viewState.currentProjectType === 'Workflow';

  const allHidden = wpCards.reduce((acc, wpCard) => {
    if (
      wpCard.status === STATUS.IN_PROGRESS ||
      (!thin && viewState.showUnpinned) ||
      (thin && viewState.showDetailUnpinned) ||
      inWorkflow ||
      wpCard.pinned
    ) {
      return false;
    } else {
      return acc;
    }
  }, true);
  const noWPCards = wpCards.length === 0;

  return (
    <Droppable droppableId={listId} type={undefined}>
      {(dropProvided, dropSnapshot) => (
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          style={{ height: height, marginBottom: bmargin, paddingBottom: '60px' }} // Necessary to override the droppable styling
        >
          {wpCards.map((wpCard, index) => {
            if (wpCard.cardType === TASK.WORKPACKAGE_CARD) {
              if (
                wpCard.status === STATUS.IN_PROGRESS ||
                (!thin && viewState.showUnpinned) ||
                (thin && viewState.showDetailUnpinned) ||
                inWorkflow ||
                wpCard.pinned
                // || wpCard.childPinned
              ) {
                return (
                  <DraggableWPCard
                    key={`dcl-${wpCard.uuid}-${index}`}
                    wpCard={wpCard}
                    thin={thin}
                    viewStack={viewStack}
                    draggableIndex={index}
                    parentContext={parentContext}
                    isLibrary={isLibrary}
                  />
                );
              } else {
                return (
                  <div
                    style={{
                      display: 'flex',
                      height: 2,
                      backgroundColor: '#4442',
                      marginBottom: 1,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                );
              }
            } else {
              // to support Library Board hack that uses WPCardLists
              return (
                <DraggableTaskCard
                  key={`wcl-${wpCard.uuid}-${index}`}
                  taskCard={wpCard}
                  thin={thin}
                  viewStack={viewStack}
                  isLibrary={true}
                />
              );
            }
          })}
          {thin && noWPCards && (
            <p style={{ textAlign: 'center', marginTop: '40px' }}>
              Your have no workpackages defined. Press + to add a workpackage.
            </p>
          )}
          {thin && !noWPCards && allHidden && (
            <Fragment>
              <p style={{ textAlign: 'center', marginTop: '40px' }}>All your workpackages are hidden.</p>
              <p style={{ textAlign: 'center' }}>
                Toggle the visibility switch in the upper right corner to see them.
              </p>
            </Fragment>
          )}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default connect(state => {
  return {
    viewState: state.viewState,
  };
}, {})(DroppableWPCardList);
