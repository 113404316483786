/* eslint-disable */

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Card, CardContent, CardActions, Chip } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ActionMenu from '../styled_components/ActionMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';
import PublishIcon from '@material-ui/icons/Publish';
import Person from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { InfoButton } from '../styled_components/InfoButton';
import { PinButton } from '../styled_components/PinButton';
import {
  setUserColleguePref,
  updateViewState,
  saveCurrentProject,
  handleMenu,
  dispatchUpdateViewState,
  setInfoDialog,
} from '../../data/actions/userInterface';
import { useTheme } from '@material-ui/styles';
import { onboardingUpdate, stickyNoteTilt } from '../../data/actions/app';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as utils from '../../utils/generalUtilities';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';
import * as constants from '../../constants/app';
import { isEmptyChildren } from 'formik';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: ({ thin }) => (thin ? '2px' : '10px'),
    marginRight: ({ thin }) => (thin ? '2px' : '10px'),
    boxShadow: '3px 6px 6px -1px rgba(138, 148, 159, 0.7)',
    border: `solid .1px`,
    borderBottom: '0px',
    borderRadius: 0,
    background: theme.palette.workCard.notStarted,
    borderColor: theme.palette.workCard.notStartedBorder,
    // paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
    // add minor tilt to card for organic look/feel
    transform: ({ wpCardUUID }) => `rotate(${stickyNoteTilt(wpCardUUID)}deg)`,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: ({ thin }) => (thin ? 6 : undefined),
    marginLeft: ({ thin }) => (thin ? 15 : undefined),
    marginRight: ({ thin }) => (thin ? 10 : undefined),
    marginBottom: ({ thin }) => (thin ? -16 : undefined),
    padding: ({ thin }) => (thin ? 0 : undefined),
  },
  nameRow: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rightSideStuff: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: ({ isSelected }) => (isSelected ? 5 : 0),
    marginBottom: -5,
  },
  thinInfoButton: {
    marginLeft: 10,
    alignSelf: 'flex-start',
    marginTop: -4,
  },
  selected: {
    transform: ({ wpCardUUID, thin }) =>
      thin ? undefined : `rotate(${stickyNoteTilt(wpCardUUID)}deg) scale(1.04, 1.04)`,
    boxShadow: ({ thin }) => '2px 8px 8px 0px rgba(138, 148, 159, 1)',
    marginTop: ({ thin }) => (thin ? '16px' : '10px'),
    marginBottom: ({ thin }) => (thin ? '16px' : '25px'),
    border: ({ thin }) =>
      thin
        ? `solid 1.2px ${theme.palette.workCard.selectedBorder}`
        : `solid 1.2px ${theme.palette.workCard.selectedBorder}`,
    borderColor: ({ thin }) =>
      thin ? theme.palette.workCard.selectedBorder : theme.palette.workCard.selectedBorder,
  },
  started: {
    background: ({ thin }) => (thin ? theme.palette.workCard.started : theme.palette.workCard.startedWBS),
    borderColor: ({ thin }) =>
      thin ? theme.palette.workCard.startedBorder : theme.palette.workCard.startedBorderWBS,
  },
  completed: {
    background: ({ thin }) => (thin ? theme.palette.workCard.completed : theme.palette.workCard.completed),
    borderColor: ({ thin }) =>
      thin ? theme.palette.workCard.completedBorder : theme.palette.workCard.completedBorder,
  },
  headerText: {
    fontSize: '1.1rem',
    fontWeight: '700',
  },
  thinName: {
    flex: 1,
    justifyContent: 'center',
    marginTop: '5px',
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  nameText: {
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  thinChipContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    marginRight: 15,
    // backgroundColor: 'yellow',
  },
  header: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  chipContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // backgroundColor: 'green'
  },
  dateRow: {
    maxHeight: '.8em',
  },
  chip: {
    border: 'solid 1px #E2C250C0',
    background: '#E2C25030',
    // background: 'none',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginLeft: ({ thin }) => (thin ? 10 : 0),
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToTooltip: {
    fontSize: 20,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  thinActionMenu: {
    marginLeft: 10,
    alignSelf: 'center',
  },
  taskDate: {
    maxHeight: ({ thin }) => (thin ? undefined : '.7em'),
    marginLeft: ({ thin }) => (thin ? 10 : 0),
    // backgroundColor: 'yellow'
  },
  percentSize: {
    fontSize: ({ thin }) => (thin ? 14 : 14),
    marginLeft: ({ thin }) => (thin ? 5 : 2),
  },
  dateString: {
    fontSize: ({ thin }) => (thin ? 15 : '.7em'),
    marginTop: ({ thin }) => (thin ? 0 : -1),
  },
  subTitleRow: {
    marginTop: -2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  numTasks: {
    fontSize: '.68rem',
    marginRight: 10,
    marginTop: -5,
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  pinButton: {
    marginLeft: 5,
  },
  tasksContainer: {
    display: 'flex',
    marginTop: '4px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  taskBoxNotStarted: {
    width: '6px',
    height: '12px',
    margin: 1,
    border: '1px solid grey',
    backgroundColor: 'white',
  },
  taskBoxCompleted: {
    width: '6px',
    height: '12px',
    margin: 1,
    border: '1px solid grey',
    backgroundColor: 'green',
  },
  taskBoxInProgress: {
    width: '6px',
    height: '12px',
    margin: 1,
    border: '1px solid grey',
    backgroundColor: 'yellow',
  },
  taskBoxOnHold: {
    width: '6px',
    height: '12px',
    margin: 1,
    border: '1px solid grey',
    backgroundColor: 'red',
  },
  taskBoxInReview: {
    width: '6px',
    height: '12px',
    margin: 1,
    border: '1px solid grey',
    backgroundColor: '#efde7a',
  },
  flexCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const DraggableWPCard = ({
  wpCard,
  viewStack,
  isLibrary = false,
  draggableIndex,
  viewState,
  parentContext,
  handleMenu,
  userPrefs,
  setUserColleguePref,
  dispatchUpdateViewState,
  saveCurrentProject,
  onboardingUpdate,
  thin = false,
  onboarding,
  workflows,
  setInfoDialog,
}) => {
  const {
    percentageComplete,
    isPriority,
    expectedStartDate,
    expectedEndDate,
    actualStartDate,
    actualEndDate,
    assignedTo,
    refId,
    name,
    tasks,
    numNonSkippedTasks,
    numDoneTasks,
    numInProgressItems,
  } = wpCard.workpackage;
  const status = wpCard.status;
  const theme = useTheme();
  const [adviceComponent, setAdviceComponent] = React.useState(() => {
    return utils.getAdviceComponent(workflows, wpCard.workpackage.templateRefUUID, wpCard.workpackage);
  });
  const inWorkflow = viewState.currentProjectType === 'Workflow';

  const handleDoubleClick = e => {
    const newStack = viewStack.slice();
    newStack.push({
      screen: NAV.BOARD_DETAIL,
      item: {
        d: wpCard.parentCard.index,
        w: wpCard.index,
        t: -1,
      },
      level: 'w',
    });
    Object.assign(viewState, {
      curWindow: isLibrary ? NAV.LIBRARY_BOARD : NAV.BOARD,
      lastWindow: {
        ...viewState.lastWindow,
        [viewState.curProjectUUID]: isLibrary ? NAV.LIBRARY_BOARD : NAV.BOARD,
      },
      prevWindow: isLibrary ? NAV.LIBRARY_BOARD : parentContext,
      currentDeliverable: wpCard.parentCard.index,
      currentWorkPackage: wpCard.index,
      currentTask: 0,
      [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
    });
    dispatchUpdateViewState(viewState);
    onboarding = Object.assign(
      { ...onboarding },
      {
        seenWorkPackageDoubleClick: true,
      },
    );
    onboardingUpdate(onboarding);
  };
  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });

  const stackTop = viewStack[viewStack.length - 1];
  const isSelected =
    ('pd'.includes(stackTop.level) &&
      stackTop.item.d === wpCard.parentCard.index &&
      stackTop.item.w === wpCard.index) ||
    (viewState.mergeWPs &&
      viewState.mergeWPs.d === wpCard.parentCard.index &&
      viewState.mergeWPs.w === wpCard.index);
  const isStarted = status === STATUS.IN_PROGRESS || status === STATUS.IN_REVIEW || status === STATUS.BLOCKED;
  const isCompleted = status === STATUS.DONE;
  const classes = useStyles({ thin, isSelected, wpCardUUID: wpCard.uuid, isStarted, isCompleted });

  const dateStats = utils.getDateStats(expectedEndDate);

  const { daysLeft, isLate, dueSoon } = dateStats;
  let { lateIconColor, tooltipTitle } = utils.getDateStatsInfo(isLate, dueSoon, daysLeft);

  const curStatus = isCompleted ? STATUS.DONE : isStarted ? STATUS.IN_PROGRESS : STATUS.NOT_STARTED;
  let itemSprintId = utils.date2SprintId(expectedEndDate, viewState.numWeeksInSprint);
  let sprintOffset = utils.getSprintOffset(itemSprintId, null, viewState.numWeeksInSprint);
  const [sprintStartDate, sprintEndDate] = utils.getSprintHumanDates(
    itemSprintId,
    viewState.numWeeksInSprint,
  );
  tooltipTitle = !sprintStartDate
    ? ''
    : isCompleted
    ? `finished in work period ${itemSprintId} : ${sprintStartDate} - ${sprintEndDate}`
    : `scheduled for work period ${itemSprintId} : ${sprintStartDate} - ${sprintEndDate}`;
  // let itemSprintId = null;
  switch (curStatus) {
    case STATUS.NOT_STARTED:
      lateIconColor = sprintOffset >= 0 ? grey[500] : lateIconColor;
      break;
    case STATUS.DONE:
      lateIconColor = sprintOffset >= 0 ? grey[500] : '#444';
      break;
    case STATUS.IN_PROGRESS:
    default:
      lateIconColor = sprintOffset >= 0 ? grey[500] : lateIconColor;
  }

  const avatarId = assignedTo ? assignedTo.substring(0, 1).toUpperCase() : null;
  let collegueColor;
  if (assignedTo) {
    if (userPrefs.collegues[assignedTo]) {
      collegueColor = userPrefs.collegues[assignedTo].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: assignedTo,
        color: collegueColor,
      };
    }
  }
  let MENU_ACTIONS = [
    {
      name: 'Open',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Open`,
      icon: <OpenInNewIcon />,
    },
    {
      name: 'Rename',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Edit`,
      icon: <EditIcon />,
    },
    {
      name: 'Split',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Split`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Delete',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Delete`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Cut',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Cut`,
      icon: <ReplyIcon />,
    },
    {
      name: 'Copy',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Copy`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Paste',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Paste`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'to Del',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.ToDel`,
      icon: <PublishIcon />,
      tooltip: 'convert this task group to a deliverable',
    },
  ];
  if (parentContext === NAV.SPRINTVIEW || viewState.curWindow === NAV.LIBRARY_BOARD) {
    MENU_ACTIONS.splice(2, 1); // remove to Del option if called from Library view
  }
  if (!viewState.copyContent || viewState.copyType !== 'workPackage') MENU_ACTIONS.splice(6, 1); // remove paste option if no copy content

  if (viewState.mergeWPs && viewState.mergeWPs.wpUUID === wpCard.workpackage.uuid) {
    MENU_ACTIONS.unshift({
      name: 'Merge',
      action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Merge`,
      icon: <ReplyIcon />,
    });
  }
  // if (!inWorkflow && wpCard.status !== STATUS.IN_PROGRESS) {
  //   MENU_ACTIONS.push({
  //     name: wpCard.workpackage.pinned ? 'Unpin' : 'Pin',
  //     action: `work package.${wpCard.parentCard.index}.${wpCard.index}.Pin`,
  //     icon: wpCard.workpackage.pinned ? <VisibilityOffIcon /> : <VisibilityOffIcon />,
  //   });
  // }
  const showPercentage = percentageComplete > 0 && actualEndDate === '';

  const handleSelection = (wpCard, e) => {
    const isShiftKey = e.shiftKey;

    if (e) e.stopPropagation(); // Necessary to avoid event bubbling up to parent column

    const stackTop = viewStack[viewStack.length - 1];
    const curSelectedDelInd = stackTop.item?.d;
    const curSelectedWPInd = stackTop.item?.w;
    const isWPSelected = curSelectedDelInd >= 0 && curSelectedWPInd >= 0;
    if (!isShiftKey || !isWPSelected || curSelectedDelInd !== wpCard.parentCard.index) {
      const newStack = viewStack.slice();
      newStack[newStack.length - 1].item = {
        // for now this is a no-op as the only place this is called is from a NAV.BOARD
        d: wpCard.parentCard.index,
        w: wpCard.index,
        t: -1,
      };
      Object.assign(viewState, {
        currentDeliverable: wpCard.parentCard.index,
        currentWorkPackage: wpCard.index,
        mergeWPs: null,
        [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
      });
    } else {
      // shift key depressed and previous WP selected and same deliverable
      Object.assign(viewState, {
        mergeWPs: { d: wpCard.parentCard.index, w: wpCard.index, wpUUID: wpCard.workpackage.uuid },
      });
    }

    dispatchUpdateViewState(viewState);
  };
  let numOpenTasks = numNonSkippedTasks - numDoneTasks;
  if (isNaN(numOpenTasks)) numOpenTasks = 0;
  const taskName = numOpenTasks === 1 ? 'task' : 'tasks';
  const isLibraryChange = viewState.curWindow === NAV.LIBRARY_BOARD;

  // console.log('card', wpCard.uuid);
  const emptyAdvice = adviceComponent ? utils.isInfoEmpty(adviceComponent.advice) : false;

  return (
    <Draggable
      key={`wc-${wpCard.uuid}`} // This is not ideal and it's not a long term solution but creating this since we don't have unique IDs available on cards.
      draggableId={wpCard.uuid}
      index={draggableIndex}
      shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <div key={`div-${wpCard.uuid}`} onDoubleClick={handleDoubleClick}>
          <Tooltip title={onboarding.seenWorkPackageDoubleClick ? '' : 'double click to open'}>
            <Card
              key={`card-${wpCard.uuid}`}
              id={wpCard.uuid}
              ref={dragProvided.innerRef}
              onClick={e => handleSelection(wpCard, e)}
              {...dragProvided.draggableProps}
              {...dragProvided.dragHandleProps}
              className={
                '' +
                `${classes.card} ` +
                `${isStarted ? classes.started : ''} ` +
                `${isCompleted ? classes.completed : ''} ` +
                `${isSelected ? classes.selected : ''} `
              }
              variant={isSelected ? 'outlined' : 'elevation'}
            >
              {thin ? (
                <Fragment>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.nameRow}>
                      {refId && (
                        <Tooltip title={'unique ID'}>
                          <div className={classes.thinChipContainer}>
                            <Chip label={refId} className={classes.chip} />
                          </div>
                        </Tooltip>
                      )}
                      <div className={classes.flexCol}>
                        <Typography
                          variant="body1"
                          component="h2"
                          className={`${classes.thinName}`}
                          align="left"
                        >
                          {name}
                        </Typography>
                        <div className={classes.tasksContainer}>
                          {tasks.map(task => {
                            if (task.status === STATUS.BLOCKED) {
                              return <div className={classes.taskBoxOnHold} />;
                            }
                          })}
                          {tasks.map(task => {
                            if (task.status === STATUS.IN_PROGRESS) {
                              return <div className={classes.taskBoxInProgress} />;
                            }
                          })}
                          {tasks.map(task => {
                            if (task.status === STATUS.IN_REVIEW) {
                              return <div className={classes.taskBoxInReview} />;
                            }
                          })}
                          {tasks.map(task => {
                            if (task.status === STATUS.NOT_STARTED) {
                              return <div className={classes.taskBoxNotStarted} />;
                            }
                          })}
                          {tasks.map(task => {
                            if (task.status === STATUS.DONE) {
                              return <div className={classes.taskBoxCompleted} />;
                            }
                          })}
                        </div>
                      </div>

                      <InfoButton
                        handleClick={() => {
                          setInfoDialog({
                            // sets InfoDialog reducer values
                            title: `Task Group: ${wpCard.workpackage.name}`,
                            content: {
                              description: wpCard.workpackage.description,
                              tooltip: wpCard.workpackage.tooltip ?? constants.DEFAULT_TOOLTIP,
                            },
                            onSave: result => {
                              wpCard.workpackage.description = result.description;
                              wpCard.workpackage.tooltip = result.tooltip;
                              saveCurrentProject();
                            },
                          });
                        }}
                        size="small"
                        color={theme.palette.primary.infoButton}
                        bgdColor="#FFF0"
                        hoverColor={theme.palette.primary.infoButton + '10'}
                        borderColor={theme.palette.primary.infoButton}
                        tooltip={'view summary'}
                        containerClassName={classes.thinInfoButton}
                        type={'a'}
                      />
                    </div>
                    <div className={classes.rightSideStuff}>
                      {isPriority && <PriorityIcon />}
                      {showPercentage && (
                        <Typography variant="overline" className={classes.percentSize}>
                          {`${percentageComplete.toFixed(0)}%`}
                        </Typography>
                      )}
                      {parentContext !== NAV.SPRINTVIEW && itemSprintId !== constants.NO_SPRINT && (
                        <div className={classes.taskDate}>
                          <Tooltip title={tooltipTitle}>
                            <Typography
                              variant="overline"
                              className={classes.dateString}
                              style={{ color: lateIconColor }}
                            >
                              {itemSprintId}
                            </Typography>
                          </Tooltip>
                        </div>
                      )}
                      {!inWorkflow && wpCard.status !== STATUS.IN_PROGRESS && (
                        <PinButton
                          handleClick={() => {
                            handleMenu(`work package.${wpCard.parentCard.index}.${wpCard.index}.Pin`);
                          }}
                          size="medium"
                          color={wpCard.pinned ? '#AAA' : '#AAA8'}
                          bgdColor={
                            wpCard.status === STATUS.DONE
                              ? theme.palette.workCard.completed
                              : theme.palette.workCard.notStarted
                          }
                          hoverColor={
                            wpCard.status === STATUS.DONE
                              ? theme.palette.workCard.completed + 'E0'
                              : theme.palette.workCard.notStarted + 'E0'
                          }
                          borderColor={
                            wpCard.status === STATUS.DONE
                              ? theme.palette.workCard.completed
                              : theme.palette.workCard.notStarted
                          }
                          tooltip={wpCard.pinned ? 'remove pin' : 'pin this task group'}
                          containerClassName={classes.pinButton}
                          solid={wpCard.pinned}
                        />
                      )}
                      {avatarId ? (
                        <Tooltip title={assignedTo} className={classes.assignedToTooltip}>
                          <div
                            className={classes.assignedToAvatar}
                            style={{ backgroundColor: collegueColor }}
                          >
                            <Typography className={classes.assignedToText}>{avatarId}</Typography>
                          </div>
                        </Tooltip>
                      ) : (
                        <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                          <Person className={classes.notAssignedTo} />
                        </div>
                      )}
                      {isSelected && (
                        <div className={classes.thinActionMenu}>
                          <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />
                        </div>
                      )}
                    </div>
                  </CardContent>
                  <CardContent></CardContent>
                </Fragment>
              ) : (
                <Fragment>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.titleContents}>
                      <Typography variant="body2" className={classes.nameText} component="h3">
                        {name}
                      </Typography>
                      {!isLibraryChange && (
                        <Fragment>
                          <div className={classes.subTitleRow}>
                            {/* <div>
                              <Typography variant="overline" className={classes.numTasks}>
                                {numOpenTasks === 0 ? 'completed' : `${numOpenTasks} ${taskName}`}
                              </Typography>
                            </div> */}
                            <div className={classes.tasksContainer}>
                              {tasks.map(task => {
                                if (task.status === STATUS.BLOCKED) {
                                  return <div className={classes.taskBoxOnHold} />;
                                }
                              })}
                              {tasks.map(task => {
                                if (task.status === STATUS.IN_PROGRESS) {
                                  return <div className={classes.taskBoxInProgress} />;
                                }
                              })}
                              {tasks.map(task => {
                                if (task.status === STATUS.IN_REVIEW) {
                                  return <div className={classes.taskBoxInReview} />;
                                }
                              })}
                              {tasks.map(task => {
                                if (task.status === STATUS.NOT_STARTED) {
                                  return <div className={classes.taskBoxNotStarted} />;
                                }
                              })}
                              {tasks.map(task => {
                                if (task.status === STATUS.DONE) {
                                  return <div className={classes.taskBoxCompleted} />;
                                }
                              })}
                            </div>
                            {parentContext !== NAV.SPRINTVIEW && itemSprintId !== constants.NO_SPRINT && (
                              <Tooltip title={tooltipTitle}>
                                <Typography
                                  variant="overline"
                                  className={classes.dateString}
                                  style={{ color: lateIconColor, minWidth: '50px', marginLeft: '10px' }}
                                >
                                  {itemSprintId}
                                </Typography>
                              </Tooltip>
                            )}
                          </div>
                        </Fragment>
                      )}
                    </div>
                    <InfoButton
                      handleClick={() => {
                        setInfoDialog({
                          // sets InfoDialog reducer values
                          title: `Task Group: ${wpCard.workpackage.name}`,
                          content: {
                            description: wpCard.workpackage.description,
                            tooltip: wpCard.workpackage.tooltip ?? constants.DEFAULT_TOOLTIP,
                          },
                          onSave: result => {
                            wpCard.workpackage.description = result.description;
                            wpCard.workpackage.tooltip = result.tooltip;
                            saveCurrentProject();
                          },
                        });
                      }}
                      size="small"
                      color={theme.palette.primary.infoButton}
                      bgdColor="#FFF0"
                      hoverColor={theme.palette.primary.infoButton + '10'}
                      borderColor={theme.palette.primary.infoButton}
                      tooltip={wpCard.workpackage.tooltip ?? constants.DEFAULT_TOOLTIP}
                      containerClassName={classes.infoButton}
                      type={'i'}
                    />
                    {isSelected && <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />}
                  </CardContent>
                  <CardActions className={classes.cardActions} style={{ marginTop: itemSprintId ? -12 : -2 }}>
                    {refId && (
                      <Tooltip title={'unique ID'}>
                        <div className={classes.chipContainer}>
                          <Chip label={refId} className={classes.chip} />
                        </div>
                      </Tooltip>
                    )}
                    {isPriority && <PriorityIcon />}
                    {showPercentage && (
                      <Typography variant="overline">{`${percentageComplete.toFixed(0)}%`}</Typography>
                    )}
                    {!inWorkflow && wpCard.status !== STATUS.IN_PROGRESS && (
                      <PinButton
                        handleClick={() => {
                          handleMenu(`work package.${wpCard.parentCard.index}.${wpCard.index}.Pin`);
                        }}
                        size="medium"
                        color={wpCard.pinned ? '#AAA' : '#AAA8'}
                        bgdColor={
                          wpCard.status === STATUS.DONE
                            ? theme.palette.workCard.completed
                            : theme.palette.workCard.notStarted
                        }
                        hoverColor={
                          wpCard.status === STATUS.DONE
                            ? theme.palette.workCard.completed + 'E0'
                            : theme.palette.workCard.notStarted + 'E0'
                        }
                        borderColor={
                          wpCard.status === STATUS.DONE
                            ? theme.palette.workCard.completed
                            : theme.palette.workCard.notStarted
                        }
                        tooltip={wpCard.pinned ? 'remove pin' : 'pin this task group'}
                        containerClassName={classes.pinButton}
                        solid={wpCard.pinned}
                      />
                    )}
                    {avatarId ? (
                      <Tooltip title={assignedTo} className={classes.assignedToTooltip}>
                        <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                          <Typography className={classes.assignedToText}>{avatarId}</Typography>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                        <Person className={classes.notAssignedTo} />
                      </div>
                    )}
                  </CardActions>
                </Fragment>
              )}
            </Card>
          </Tooltip>
        </div>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
      onboarding: state.onboarding,
    };
  },
  {
    updateViewState,
    setInfoDialog,
    saveCurrentProject,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
    onboardingUpdate,
  },
)(DraggableWPCard);
