import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { dispatchUpdateViewState } from '../../data/actions/userInterface';
import * as NAV from '../../constants/navigation';


const useMenuStyles = makeStyles({
  noPadding: {
    padding: 0,
  },
  button: {
    borderRadius: 0,
    alignSelf: 'start',
  },
  listIcon: {
    minWidth: '30px',
  },
});

/**
 * ActionMenu is a styled menu component.
 * @param {Function} handleMenu calls the action creator for the menu
 * @param {Object} actions An array of actions that have the action name, label, and icon for the menu item
 */
const ActionMenu = ({ handleMenu, actions, onOpenCallback = null, viewStack, viewState, dispatchUpdateViewState }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useMenuStyles();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    event.stopPropagation()
    if(onOpenCallback) onOpenCallback();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = action => {
    if(action.open) {
      const newStack = viewStack.slice();
      const stackTop = newStack[newStack.length - 1];
      newStack.push({
        screen: NAV.BOARD_DETAIL,
        item: {
          d: viewState.currentDeliverable,
          w: action.open.type === 'w' ? viewState.currentWorkPackage : -1,
          t: -1,
        },
        level: action.open.type,
        zoomDelUID: stackTop.zoomDelUID
      });
      Object.assign(viewState, {
        [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
      });
      dispatchUpdateViewState(viewState);
    } else {
      handleMenu(action);
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        aria-controls="card-action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={`${classes.noPadding} ${classes.button}`}
      >
        <MoreVertIcon className={classes.noPadding} />
      </IconButton>
      <Menu
        id="card-action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {actions.map(({ action, name, icon }) => (
          <MenuItem
            key={name}
            onClick={(e) => {
              e.stopPropagation()
              handleSelect(action);
            }}
          >
            <ListItemIcon className={classes.listIcon}>
              {icon}
            </ListItemIcon>
            <Typography variant="inherit">{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
export default connect(
  state => {
    return {
      viewState: state.viewState,
    };
  },
  {
     dispatchUpdateViewState,
  },
)(ActionMenu);