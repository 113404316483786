import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  icon: {
    width: '1em',
    height: '1em',
    fill: theme.palette.secondary.main,
    fontSize: '20',
    fontWeight: '700',
    letterSpacing: '0.034em',
  },
}));

const DeliverableIcon = ({ wrapperClass = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.iconWrapper} ${wrapperClass}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 33 33"
        className={classes.icon}
      >
        <g
          id="Deliverable_Icon"
          data-name="Deliverable Icon"
          transform="translate(0.459)"
        >
          <rect
            id="Rectangle_639"
            data-name="Rectangle 639"
            width="33"
            height="33"
            rx="2"
            transform="translate(-0.459)"
            fill="rgba(80,226,194,0.12)"
          />
          <text id="D" transform="translate(9.541 23)">
            <tspan x="0" y="0">
              D
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

const WorkpackageIcon = ({ wrapperClass = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.iconWrapper} ${wrapperClass}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="33"
        viewBox="0 0 33 33"
        className={classes.icon}
      >
        <g
          id="Workpackage_Icon"
          data-name="Workpackage Icon"
          transform="translate(0.459)"
        >
          <rect
            id="Rectangle_640"
            data-name="Rectangle 640"
            width="33"
            height="33"
            rx="2"
            transform="translate(-0.459)"
            fill="#6eeacb"
          />
          <text id="W" transform="translate(7.541 24)" fill="#fff">
            <tspan x="0" y="0">
              W
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

const ProjectIcon = ({ wrapperClass = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.iconWrapper} ${wrapperClass}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        className={classes.icon}
      >
        <g
          id="Project_Icon"
          data-name="Project Icon"
          transform="translate(0.459)"
        >
          <rect
            id="Rectangle_638"
            data-name="Rectangle 638"
            width="32"
            height="32"
            rx="2"
            transform="translate(-0.459)"
            fill="#c4c4c4"
          />
          <text id="P" transform="translate(9.541 23)" fill="#fff">
            <tspan x="0" y="0">
              P
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export { DeliverableIcon, WorkpackageIcon, ProjectIcon };
