import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import SplitWPTaskCard from './SplitWPTaskCard';
import * as STATUS from '../../constants/status';
import { InputField } from '../styled_components/InputField';

// Droppable container for Dragable WP cards
const SplitWPTaskList = ({
  listId = 'LIST', //
  taskList,
  thin = true,
  newWPName,
  setSplitWorkPackageName,
}) => {
  // const height = 'calc(100vh - 60px)';
  // const bmargin = '60px';
  let bm = Math.round(150 / window.devicePixelRatio);
  const pixelRatio = window.devicePixelRatio / 2.0;

  const bmargin = `${bm}px`;
  const height = `calc(${Math.round(80 / pixelRatio)}vh - ${bmargin})`;
  console.log(height);
  return (
    <Droppable droppableId={listId} type={undefined}>
      {(dropProvided, dropSnapshot) => (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginRight: listId === '0' ? '8px' : '0px',
          }}
        >
          {listId === '0' ? (
            <Typography
              style={{
                fontSize: '0.8rem',
                fontWeight: 550,
                color: '#888',
                marginLeft: 8,
              }}
            >
              {'Keep these tasks in the task group'}
            </Typography>
          ) : (
            <InputField
              name="name"
              value={newWPName}
              doAutoSave={false}
              label={'New Task Group Name: '}
              // placeholder={labelSet.defaultInput}
              handleBlur={newName => {
                setSplitWorkPackageName(newName);
              }}
              // inputProps={emailInputProps}
            />
          )}

          <div
            ref={dropProvided.innerRef}
            style={{
              height: '90%',
              paddingBottom: bmargin,
              padding: '3px',
              border: 'solid 3px #BBB',
              borderRadius: 5,
              overflowY: 'scroll',
            }} // Necessary to override the droppable styling
            {...dropProvided.droppableProps}
          >
            {taskList.map((task, index) => {
              if (task && task.status !== STATUS.NOT_NEEDED) {
                return (
                  <SplitWPTaskCard
                    key={`wcl-${task.uuid}-${task.status}`}
                    task={task}
                    thin={thin}
                    index={index}
                  />
                );
              } else {
                return null;
              }
            })}
            <div style={{ height: '200px' }} />
            {dropProvided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};
export default connect(state => {
  return {};
}, {})(SplitWPTaskList);
