import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Email } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import GoogleButton from 'react-google-button';
import { Logo } from '../Logo';

import { newAccountRequested } from '../data/actions/app';

const classes = {
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: '12px',
    marginRight: '12px',
  },
  button: {
    margin: '0 10px',
    height: '50px',
  },
  paper: {
    marginTop: '4px',
    display: 'flex',
    marginLeft: '200px',
    marginRight: '200px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `80px 100px 100px`,
  },
  link: {
    fontWeight: 800,
  },
  avatar: {
    margin: '4px',
    color: 'white',
    backgroundColor: 'blue',
  },
  welcomeWrapper: {
    margin: '2em',
    textAlign: 'center',
  },
  signInWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '2em',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '300px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  submit: {
    marginTop: '12px',
  },
  forgotPWButton: {
    marginTop: '12px',
    alignSelf: 'flex-end',
  },
  trialMessages: {
    marginTop: '12px',
    fontSize: '1em',
    fontWeight: 500,
    color: '#444',
  },
  welcome: {
    marginBottom: 8,
  },
  pleaseSignin: {
    marginTop: 10,
  },
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      signUp: false,
      error: '',
      showSignInForm: false,
      showForgotPassword: false,
      successMessage: '',
    };
  }

  handleSubmitError = error => {
    if (this.state.signUp) {
      console.log(`SignIn createUserWithEmailAndPassword ${error.code}, ${error.message}`);
    } else {
      console.log(`SignIn signInWithEmailAndPassword failed ${error.code}, ${error.message}`);
    }
    this.setState({ error: error.message });
  };

  handleSubmit = event => {
    const { firebase } = this.props;
    const { showForgotPassword, email, password, signUp } = this.state;
    event.preventDefault();
    console.log(`handleSubmit${JSON.stringify(this.state, null, 2)}`);

    if (email.includes('gmail' || 'google')) {
      this.setState({
        error: `It looks like you're signing up with a Gmail account. Please use the Sign in with Google link above when using a Google account.`,
      });
      return;
    }

    if (showForgotPassword) {
      firebase.auth
        .sendPasswordResetEmail(email)
        .catch(error => this.handleSubmitError(error))
        .then(() => {
          if (!this.state.error) {
            this.setState({
              successMessage: `We sent a reset link to the email address we have on file. Please check your spam folder if you are unable to find it.`,
            });
          }
        });
    } else if (signUp) {
      // verify email, password confirmation
      firebase.auth
        .createUserWithEmailAndPassword(email, password)
        .then(res => {
          this.props.newAccountRequested();
        })
        .catch(error => this.handleSubmitError(error));
    } else {
      //TODO validate fields.
      firebase.auth
        .signInWithEmailAndPassword(email, password)
        // note - positive response is handled by onAuthStateChanged in App.js
        .catch(error => this.handleSubmitError(error));
    }
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      error: '',
      successMessage: '',
    });
  };

  googleSignin = () => {
    const { core, auth } = this.props.firebase;
    const googleAuthProvider = new core.auth.GoogleAuthProvider();
    this.setState({ showSignInForm: false });
    auth.signInWithPopup(googleAuthProvider);
  };

  toggleSignInForm = () => {
    this.setState({ showSignInForm: !this.state.showSignInForm });
  };

  toggleForgotPW = () => {
    this.setState({
      showForgotPassword: !this.state.showForgotPassword,
    });
  };

  render() {
    const { showSignInForm, showForgotPassword } = this.state;
    const { classes } = this.props;
    const buttonText = showSignInForm ? 'Sign In' : 'Sign Up';
    return (
      <Fragment>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Logo />
            <div className={classes.welcomeWrapper}>
              <Typography component="h1" variant="h5" className={classes.welcome}>
                Welcome to Project-Assistant
              </Typography>
              <Typography component="h2" variant="h6" className={classes.pleaseSignin}>
                Please sign in or sign up.
              </Typography>
              <Typography
                component="h3"
                // variant="h7"
                className={classes.trialMessages}
              >
                Desktop only - not optimized for mobile devices.
              </Typography>
            </div>
            <div className={classes.signInWrapper}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.toggleSignInForm}
                startIcon={<Email />}
                color="primary"
              >
                Sign in with Email
              </Button>
              <GoogleButton onClick={this.googleSignin} />
            </div>
            {showSignInForm && (
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    onChange={this.handleChange('email')}
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </FormControl>
                {!this.state.showForgotPassword && (
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                      onChange={this.handleChange('password')}
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                  </FormControl>
                )}
                {this.state.signUp && (
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password Confirmation </InputLabel>
                    <Input
                      onChange={this.handleChange('passwordConfirmation')}
                      name="passwordConfirmation"
                      type="password"
                      id="passwordConfirmation "
                      autoComplete="current-password"
                    />
                  </FormControl>
                )}
                {!this.state.showForgotPassword && (
                  <FormControlLabel
                    control={
                      <Checkbox onChange={this.handleChange('signUp')} value="signUp" color="secondary" />
                    }
                    label="New User"
                  />
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  disabled={
                    (this.state.signUp && this.state.password !== this.state.passwordConfirmation) ||
                    this.state.password === '' ||
                    this.state.email === ''
                  }
                >
                  {showForgotPassword ? 'Reset Password' : buttonText}
                </Button>
                {this.state.error !== '' && (
                  <TextField
                    error
                    fullWidth
                    id="outlined-error"
                    label="Error"
                    defaultValue={this.state.error}
                    className={classes.textField}
                    multiline
                    margin="normal"
                    variant="outlined"
                  />
                )}
                {this.state.successMessage !== '' && (
                  <TextField
                    fullWidth
                    color="secondary"
                    id="outlined-error"
                    label="Success"
                    defaultValue={this.state.successMessage}
                    className={classes.textField}
                    multiline
                    margin="normal"
                    variant="outlined"
                  />
                )}
              </form>
            )}
            {showSignInForm && (
              <Button className={classes.forgotPWButton} onClick={this.toggleForgotPW}>
                {showForgotPassword ? 'Go Back to Sign In' : 'Forgot Password?'}
              </Button>
            )}
          </Paper>
        </main>
      </Fragment>
    );
  }
}

const connectedComponent = connect(
  state => {
    return {
      user: state.user,
      firebase: state.firebase,
    };
  },
  {
    newAccountRequested,
  },
)(SignIn);

export default withStyles(classes)(connectedComponent);
