export const CUST_ID = "262877";   //miniOrange Customer ID

export const ENV_PRODUCTION = "Production"
export const ENV_BETA = "Beta"
export const ENV_LOCALHOST = "Localhost"

const projectId = process.env.REACT_APP_PROJECT_ID;
export const ENVIRONMENT = projectId === 'project-534d9' 
  ? ENV_PRODUCTION 
  : (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") 
    ? ENV_LOCALHOST 
    : ENV_BETA

let CLIENT_ID;
let TARGET_ENDPOINT

switch(ENVIRONMENT) {
  case ENV_PRODUCTION:
    CLIENT_ID = "FZ6f1dC2wjrnN9Gs";   //miniOrange Client ID for "production React login" app
    TARGET_ENDPOINT = "https://app.project-assistant.com/";   // redirect endpoint after login
    break;
  case ENV_BETA:
    CLIENT_ID = "yuswknAQdp0iZ49F";   //miniOrange Client ID for "beta React login" app
    TARGET_ENDPOINT = "https://project-assistant-beta.firebaseapp.com/";   // redirect endpoint after login
    break;
  default:
  case ENV_LOCALHOST:
    CLIENT_ID = "7PpsCKI6EnWi2sXt";   //miniOrange Client ID for "localhost React login" app
    TARGET_ENDPOINT = "https://localhost:3000/";   // redirect endpoint after login
    break;
}
// IMPORTANT: these values MUST match the settings int the "Get Firebase JWT API" app for this to all work

// then next endpoint should be the same as what's listed in the corresponding app Single Sign-On URL, eg for production
export const LOGIN_ENDPOINT = `https://projectassistant.xecurify.com/moas/broker/login/jwt/${CUST_ID}?client_id=${CLIENT_ID}&redirect_uri=${TARGET_ENDPOINT}`;   // mo login endpoint
export const LOGOUT_ENDPOINT = `https://projectassistant.xecurify.com/moas/broker/login/jwt/logout/${CUST_ID}?redirect_uri=${TARGET_ENDPOINT}`;   // mo login endpoint

export const PA_APP_ID = 'PA_APP_ID_xNjI3MDEyMDI2IiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJNTyIsImF1ZC';  // app secret used in JWT creation FB function mOValidate()
