import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Avatar, Menu, IconButton, MenuItem } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';
import * as constants from '../constants/app';

import { Logo } from '../Logo';
import Tooltip from '@material-ui/core/Tooltip';

import {
  dispatchSignOutUser,
  dispatchUpdateUsibilityReport,
  getOnboardingDefaults,
} from '../data/actions/app';
import { updateViewState } from '../data/actions/userInterface';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import userPrefs from '../data/reducers/userPrefs';
import { updateUserPref } from '../data/actions/firebase/userPreferences';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: '10px',
  },
  root: {
    flexGrow: 1,
    position: 'sticky',
    zIndex: 1300,
    top: 0,
  },
  appbar: {
    position: 'sticky',
    background: theme.palette.primary.appbar,
    // background: theme.palette.primary.appbar,
  },
  rightButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonContainer: {
    marginLeft: -20,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    position: 'absolute',
    color: '#444',
    right: 0,
    left: 0,
    fontWeight: 500,
  },
  helpButton: {
    padding: 0,
    marginRight: 20,
  },
  userButton: {
    padding: 0,
  },
  homeButton: {
    zIndex: 1301,
    marginLeft: 7,
    marginRight: 3,
    marginTop: 2,
    '& svg': {
      fontSize: 32, // sets size of IconButton and scales the font inside
    },
  },
  emailStyle: {
    // margin: '7px'
    marginLeft: '4px',
    marginBottom: '6px',
  },
  emailTextStyle: {
    fontSize: '0.9em',
    fontWeight: 600,
    color: theme.palette.secondary.verydark,
    // color: 'red',
  },
  roleTextStyle: {
    fontSize: '0.8em',
    fontWeight: 500,
    color: theme.palette.secondary.verydark,
    // color: 'red',
  },
  menu: {
    padding: '10px',
    marginTop: '35px',
    marginLeft: '-40px',
  },
}));

const PAAppBar = ({
  project,
  percentageComplete,
  user,
  userPrefs,
  dispatchSignOutUser,
  docListenerParent,
  showingAnalytics,
  viewState,
  updateViewState,
  dispatchUpdateUsibilityReport,
  updateUserPref,
  group,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openHelpMenu = event => {
    setAnchorEl({ el: event.currentTarget, type: 'help' });
  };

  const openAccountMenu = event => {
    setAnchorEl({ el: event.currentTarget, type: 'account' });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    if (docListenerParent) {
      if (docListenerParent.curAnalyticsListener && docListenerParent.curAnalyticsListener.stopListener) {
        // turn off doc listeners before logging out
        console.log('stopping Analytics doc listener');
        docListenerParent.curAnalyticsListener.stopListener();
        docListenerParent.curAnalyticsListener = null;
      }
      if (docListenerParent.curWorkflowSetListener && docListenerParent.curWorkflowSetListener.stopListener) {
        console.log('stopping WorkflowSet doc listener');
        docListenerParent.curWorkflowSetListener.stopListener();
        docListenerParent.curWorkflowSetListener = null;
      }
      if (docListenerParent.curProjectListener && docListenerParent.curProjectListener.stopListener) {
        console.log('stopping CurProject doc listener');
        docListenerParent.curProjectListener.stopListener();
        docListenerParent.curProjectListener = null;
      }
    }
    handleClose();
    try {
      dispatchSignOutUser();
    } catch (e) {
      console.log(`caught signout error: ${e}`);
    }
  };

  const handleGroup = () => {
    handleClose();
    if (!group) {
      viewState = Object.assign(viewState, {
        curWindow: NAV.DASHBOARD,
        showGroupDialog: true,
      });
    } else {
      viewState = Object.assign(viewState, {
        prevWindow: null,
        curWindow: NAV.SMARTTEMPLATES,
      });
    }
    updateViewState(viewState);
  };

  const handleAdmin = () => {
    handleClose();
    viewState = Object.assign(viewState, {
      prevWindow: null,
      curWindow: NAV.ADMINPAGE,
    });
    updateViewState(viewState);
  };

  const handleAccount = () => {
    handleClose();
    viewState = Object.assign(viewState, {
      prevWindow: viewState.curWindow !== NAV.ACCOUNT ? viewState.curWindow : null,
      curWindow: NAV.ACCOUNT,
    });
    updateViewState(viewState);
  };
  // <div className={classes.root} zIndex={100}>
  // <div className={classes.root}>

  const projectTitle = project.library
    ? `Workflow - ${project.name}`
    : percentageComplete.toFixed
    ? `${project.name}${
        project.expectedEndDate ? ' (' + moment(project.expectedEndDate).format('MMM D, YYYY') + ')' : ''
      }`
    : // ? `${project.name} (${percentageComplete?.toFixed(0)}%)`
      `${project.name}`;

  const editingSmartTemplate =
    !(
      viewState.currentWorkflowSetId === constants.PUBLIC_GROUP_ID ||
      viewState.currentWorkflowSetId === constants.CORE_SMART_TEMPLATES_ID
    ) &&
    viewState.curWindow !== NAV.SMARTTEMPLATES &&
    viewState.curWindow !== NAV.GROUPPAGE;

  const videoTours = [];
  switch (viewState.curWindow) {
    case NAV.BOARD: // project scope
      const wbsView = viewState.wbsStack[viewState.wbsStack.length - 1];
      if (wbsView.screen === NAV.BOARD) {
        videoTours.push({
          title: 'Video Tour of this Page',
          link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI',
        });
        videoTours.push({
          title: 'Video Tour of a Column',
          link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4',
        });
      } else {
        if (wbsView.level === 'd') {
          videoTours.push({
            title: 'Video Tour of this Page',
            link: 'https://www.youtube.com/watch?v=mHivSHo-_0c',
          });
        }
        if (wbsView.level === 'w') {
          videoTours.push({
            title: 'Video Tour of this Page',
            link: 'https://www.youtube.com/watch?v=-1DNfLjN-QM',
          });
        }
        if (wbsView.level === 't') {
          videoTours.push({
            title: 'Video Tour of this Page',
            link: 'https://www.youtube.com/watch?v=xNX41EgIG5g',
          });
        }
      }
      videoTours.push({
        title: 'Task Group Library',
        link: 'https://www.project-assistant.com/work-package-library',
      });
      break;
    case NAV.DASHBOARD:
      videoTours.push({
        title: 'Video Tour of your Dashboard',
        link: 'https://www.youtube.com/watch?v=iri7sPdQF08',
      });
      // videoTours.push({title: "Needs Attention Report Tour", link: "https://www.youtube.com/watch?v=rSyQXZg6uI0"})
      break;
    default:
  }
  videoTours.push({
    title: 'Additional Help',
    link: 'https://www.project-assistant.com/project-assistant-help ',
  });

  // console.log('')
  // console.log(`+++++++++++++++++++++++++++++++ rendering PABAR : ${curProjectListener ? curProjectListener.id : 'not set'}`)
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <div className={classes.buttonContainer}>
            <Tooltip title={editingSmartTemplate ? `Private Workflows` : `Dashboard`}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                size="small"
                onClick={() => {
                  // set viewState window to dashboard
                  dispatchUpdateUsibilityReport();
                  viewState = Object.assign(viewState, {
                    curWindow: editingSmartTemplate ? NAV.SMARTTEMPLATES : NAV.DASHBOARD,
                    currentWorkflowSetId: editingSmartTemplate ? viewState.currentWorkflowSetId : 0,
                  });
                  updateViewState(viewState);
                }}
                className={classes.homeButton}
              >
                {editingSmartTemplate ? <ArrowBackRoundedIcon size="large" /> : <HomeIcon size="large" />}
              </IconButton>
            </Tooltip>
            <Logo />
          </div>
          <div className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title}>
              {showingAnalytics ? `Analytics` : projectTitle}
            </Typography>
          </div>
          {user && (
            <div className={classes.rightButtonContainer}>
              <Tooltip title="Help">
                <IconButton
                  aria-label="more"
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={openHelpMenu}
                  className={classes.helpButton}
                >
                  <HelpOutlineOutlinedIcon fontSize="large" style={{ flex: 1, color: '#888' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Account'}>
                <IconButton
                  aria-label="more"
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  onClick={openAccountMenu}
                  className={classes.userButton}
                >
                  <Avatar alt={user.displayName} src={user.photoURL} className={classes.avatar} />
                </IconButton>
              </Tooltip>
              <Menu
                id="user-menu"
                anchorEl={anchorEl ? anchorEl.el : null}
                keepMounted
                open={!!(anchorEl && anchorEl.type === 'help')}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '26ch',
                  },
                }}
                className={classes.menu}
              >
                {videoTours.map((item, index) => {
                  return (
                    <MenuItem
                      key={`thispage-helpvid-${index}`}
                      onClick={() => {
                        setAnchorEl(null);
                        const url = item.link;
                        window.open(url, '_blank');
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Menu
                id="user-menu"
                anchorEl={anchorEl ? anchorEl.el : null}
                keepMounted
                open={!!(anchorEl && anchorEl.type === 'account')}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
                className={classes.menu}
              >
                <div className={classes.emailStyle}>
                  <Typography className={classes.emailTextStyle}>{user.email}</Typography>
                  {user.role === USER.ROLE_SYS_ADMIN && (
                    <Typography className={classes.roleTextStyle}>Administrator</Typography>
                  )}
                  <hr />
                </div>
                <MenuItem key="Account" onClick={handleAccount}>
                  Account
                </MenuItem>
                {user.role === USER.ROLE_SYS_ADMIN && (
                  <div>
                    <MenuItem key="Admin Page" onClick={handleAdmin}>
                      Admin
                    </MenuItem>
                    <MenuItem
                      key="Analytics Page"
                      onClick={e => {
                        viewState = Object.assign(viewState, {
                          prevWindow: viewState.curWindow,
                          curWindow: NAV.ANALYTICS,
                        });
                        updateViewState(viewState);
                      }}
                    >
                      Analytics
                    </MenuItem>
                  </div>
                )}
                {(user.role === USER.ROLE_SYS_ADMIN || user.role === USER.ROLE_CONSULTANT) && (
                  <MenuItem key="Workflows" onClick={handleGroup}>
                    Workflows
                  </MenuItem>
                )}
                {user.role === USER.ROLE_SYS_ADMIN && (
                  <MenuItem
                    key="Reset Onboarding"
                    onClick={() => {
                      const newPrefs = { ...userPrefs, onboarding: getOnboardingDefaults() };
                      updateUserPref(newPrefs);
                      handleClose();
                    }}
                  >
                    Reset Onboarding
                  </MenuItem>
                )}
                <MenuItem key="Logout" onClick={handleSignout}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default connect(
  state => {
    return {
      showingAnalytics: state.viewState.showingAnalytics,
      viewState: state.viewState,
      group: state.group,
      userPrefs: state.userPrefs,
    };
  },
  {
    dispatchSignOutUser,
    updateViewState,
    dispatchUpdateUsibilityReport,
    updateUserPref,
  },
)(PAAppBar);
