import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '1em',
    height: '1em',
    fill: theme.palette.secondary.main,
  },
}));

export const PriorityIcon = ({ wrapperClass = '' }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.iconWrapper} ${wrapperClass}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80.68 289.14"
        className={classes.icon}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect width="80.68" height="193.85" rx="20.88" />
            <circle cx="40.34" cy="254.56" r="34.58" />
          </g>
        </g>
      </svg>
    </div>
  );
};
