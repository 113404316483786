import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: ({ size }) => size ?? '1.1rem',
    marginLeft: ({ indent }) => indent ?? '0px',
    marginTop: '5px',
    marginBottom: ({ marginBottom }) => marginBottom ?? '5px',
    marginTop: ({ marginTop }) => marginTop ?? '0px',
    fontWeight: 600,
    color: '#303030',
  },
}));

export const Heading = ({ heading, size = null, indent = '0px', marginTop = null, marginBottom = null }) => {
  const classes = useStyles({ size, indent, marginTop, marginBottom });
  return (
    <Typography variant={'h6'} className={classes.heading}>
      {heading}
    </Typography>
  );
};
