export const ANSWER_NO = 'NO';
export const ANSWER_YES = 'YES';
export const ANSWER_LATER = 'LATER';

export const LIB_TASK_CONTAINER_CARD = 'LIB_TASK_CONTAINER_CARD';
export const LIB_WP_CONTAINER_CARD = 'LIB_WP_CONTAINER_CARD';
export const DELIVERABLE_CARD = 'DELIVERABLE_CARD';
export const WORKPACKAGE_CARD = 'WORKPACKAGE_CARD';
export const TASK_CARD = 'TASK_CARD';

export const TIME_FILTER_OFF = {name: 'No time filter', time: null};
export const TIME_FILTER_1WEEK = {name: '7 days', time: 7};
export const TIME_FILTER_2WEEKS = {name: '14 days', time: 14};
export const TIME_FILTER_1MONTH = {name: '30 days', time: 30};
export const TIME_FILTER_2MONTHS = {name: '45 days', time: 45};
export const TIME_FILTER_3MONTHS = {name: '90 days', time: 90};
