import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import { InfoButton } from './styled_components/InfoButton';
import { InputField } from './styled_components/InputField';

import * as NAV from '../constants/navigation';

const useStyles = makeStyles(theme => ({
  templateDropdown: {
    width: 300,
    minWidth: 300,
  },
  nameField: {
    width: '500px',
    marginBottom: '15px',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#BBB',
      // color: "green"
    },
  },
  input: {
    minWidth: 400,
    marginRight: '10px',
  },
  inputExists: {
    minWidth: 400,
    backgroundColor: '#FF000010',
    marginRight: '10px',
  },
  infoButtonContainer: {
    flex: 0,
    // alignSelf: 'flex-start',
    marginLeft: 15,
    marginRight: 20,
    marginTop: 10,
    height: 26,
    width: 26,
  },
  dialogTitle: {
    maxWidth: '450px',
  },
  projectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
}));

const labelSet1 = {
  type: 'name',
  title: `In order to set up your Workflows you first need to create a Group.  Please enter a name for your Group.`,
  // title: `Please choose a name for your new Group:`,
  label: `Group Name`,
  defaultInput: `type a name for your group here`,
  b1: 'Connect to Existing Group',
  b2: 'Create New Group',
};

const labelSet2 = {
  type: 'id',
  title: `Alternatively you can joing an existing Group.  Enter Existing Group Id:`,
  // title: `Enter Existing Group Id:`,
  label: `Group Id`,
  defaultInput: `put existing Group Id here`,
  b1: 'Create New Group',
  b2: 'Connect to Exiting Group',
};

export default function GroupNameDialog({
  viewState,
  limitType,
  showLimitReachedDialog,
  dispatchUpdateViewState,
  dispatchSetGroup,
  user,
  firebase,
}) {
  const [groupInput, setGroupInput] = React.useState('');
  const [labelSet, setLabelSet] = React.useState(labelSet1);
  const classes = useStyles();
  const theme = useTheme();

  // const handleUpgrade = () => {
  //   viewState = Object.assign(viewState, {
  //     prevWindow: viewState.curWindow,
  //     curWindow: NAV.ACCOUNT,
  //   });
  //   dispatchUpdateViewState(viewState);
  // };
  const handleCancel = () => {
    // showLimitReachedDialog(null);
  };

  // let title = `Create a New Group:`;
  const emailInputProps = false ? { className: classes.inputExists } : { className: classes.input };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.projectRow}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            {labelSet.title}
          </DialogTitle>
          <InfoButton
            handleClick={() => {
              window.open(`https://www.project-guidance.com/post/coming-soon-workflow-management`);
            }}
            size="medium"
            color="#FFF"
            bgdColor={theme.palette.primary.addButton}
            hoverColor={theme.palette.secondary.addButton + 'E0'}
            borderColor="#FFF"
            tooltip="learn more about Workflows"
            containerClassName={classes.infoButtonContainer}
            type={'i'}
          />
        </div>
        <DialogContent>
          <InputField
            name="email"
            value={groupInput}
            label={labelSet.label}
            placeholder={labelSet.defaultInput}
            handleBlur={newEmail => {
              setGroupInput(newEmail);
            }}
            inputProps={emailInputProps}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (labelSet.title === labelSet1.title) {
                setLabelSet(labelSet2);
              } else {
                setLabelSet(labelSet1);
              }
              setGroupInput('');
            }}
            variant="contained"
            color="primary"
          >
            {labelSet.b1}
          </Button>
          <Button
            onClick={() => {
              if (groupInput.trim() !== '') {
                const newGroup = {
                  id: labelSet.type === 'id' ? groupInput.trim() : '',
                  name: labelSet.type === 'name' ? groupInput.trim() : '',
                  members: [user.email.trim()],
                  info: {
                    [user.email.trim()]: { isAdmin: true, tags: [] },
                  },
                };
                viewState = Object.assign(viewState, {
                  prevWindow: null,
                  curWindow: NAV.GROUPPAGE,
                  showGroupDialog: false,
                });
                dispatchSetGroup(newGroup, labelSet.type, firebase, viewState);
              }
            }}
            disabled={groupInput === ''}
            variant="contained"
            color="secondary"
          >
            {labelSet.b2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
