import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import {
  saveCurrentProject,
  handleCardDrop,
  toggleNotNeededTaskVisibility,
  toggleSortTasks,
} from '../../data/actions/userInterface';

import { ContentWrapper } from '../styled_components/ContentWrapper';

// import Button from '@material-ui/core/Button';
import SplitWPTaskList from './SplitWPTaskList';
import { updateViewState } from '../../data/actions/userInterface';

import {
  dispatchUpdateViewState,
  handleMenu,
  updateSplitWorkPackage,
  setSplitWorkPackageName,
  clearSplitWorkPackage,
  finishSplitWorkPackage,
} from '../../data/actions/userInterface';
import { toggleDetailPinVisibility } from '../../data/actions/app';
import { makeStyles, useTheme } from '@material-ui/core';

const detailStyles = makeStyles(theme => ({
  splitSectionWrapper: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridColumnGap: '2vw',
    marginTop: '20px',
  },
  editorWrapper: {
    overflowY: 'hidden',
    marginRight: '20px',
    // marginBottom: '200px',
  },
  tasksWrapper: {
    overflowY: 'scroll',
    height: 'calc(100vh - 250px)',
    // marginBottom: '100px',
    // maxHeight: '65vh',
    // maxHeight: 'calc(100vh - 320px)',
  },
  threeColumn: {
    width: '100%',
    height: 'calc(100vh - 220px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // overflowY: 'hidden',
    // backgroundColor: 'yellow'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  column1: {
    // flex: 1,
    display: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '250px',
    // paddingTop: '15px',
  },

  column2: {
    flex: 2,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100% - 60px)',
    marginLeft: 5,
    marginBottom: 20,
    overflowY: 'hidden',
  },
  column2Collapsed: {
    display: 'block',
    // marginRight: 2,
    // paddingRight: 15,
    height: 'calc(100% - 60px)',
    marginLeft: 0,
    overflowY: 'hidden',
  },
  listColumn: {
    flex: 1,
    // display: 'block',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginRight: ({ notesExpanded }) => (notesExpanded ? 20 : 0),
    marginLeft: 0,
    marginBottom: 20,
    overflowY: 'hidden',
    // backgroundColor: 'red'
  },
  headerBar: {
    marginLeft: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    borderBottom: `solid 3px #888`,
    height: 35,
    backgroundColor: theme.palette.secondary.main,
    alignItems: 'center',
    paddingBottom: -4,
  },
  addIcon: {
    color: 'white',
    borderWidth: 2,
    borderColor: 'white',
    // fontSize: 16,
  },
  lateIcon: {
    alignSelf: 'center',
    marginLeft: 10,
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 550,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  notesTitle: {
    color: '#FFF',
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none',
    marginLeft: '10px',
  },
  splitButton: {
    marginLeft: '20px',
  },
  expandButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: 5,
    marginRight: ({ notesExpanded }) => (notesExpanded ? 0 : 2),
    marginLeft: 3,
  },
  switch: {
    inputProps: { 'aria-label': 'show/hide hidden items' },
  },
}));
const buttonStyles = makeStyles(theme => ({
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    // '&:hover': {
    //   backgroundColor: ({buttonColor}) => `${buttonColor}`,
    // },
    width: '120px',
    marginRight: '10px',
  },
}));
let scrollTimer;

const SplitWPDialog = ({
  viewState,
  finishSplitWorkPackage,
  updateSplitWorkPackage,
  setSplitWorkPackageName,
  clearSplitWorkPackage,
}) => {
  const theme = useTheme();
  const classes = detailStyles({ notesExpanded: viewState.notesExpanded });

  const onDragEnd = result => {
    console.log(`drag ended - do something`);
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;
    // console.log(`source: ${source.droppableId} - ${source.index}  destination: ${destination.droppableId} - ${destination.index}`)
    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    let dropOffset = 0;
    if (source.droppableId === destination.droppableId && source.index < destination.index) dropOffset = 1;
    const cutItem = viewState.splitWP.taskLists[parseInt(source.droppableId)].splice(source.index, 1)[0];
    viewState.splitWP.taskLists[parseInt(destination.droppableId)].splice(
      destination.index + dropOffset,
      0,
      cutItem,
    );
    updateSplitWorkPackage(viewState.splitWP.taskLists[0], viewState.splitWP.taskLists[1]);
  };

  const containerHeight = window.innerHeight - 220;
  const editorHeight = containerHeight - 90;
  console.log(`heights: ${editorHeight} ${window.innerHeight}`);
  const list1 = viewState.splitWP ? viewState.splitWP.taskLists[0] : [];
  const list2 = viewState.splitWP ? viewState.splitWP.taskLists[1] : [];
  return (
    <div
      style={{
        position: 'absolute',
        top: '66px',
        bottom: 0,
        right: 0,
        left: 50,
        maxHeight: 'calc(100vh -  66px)',
        overflow: 'hidden',
        backgroundColor: '#BBB6',
        zIndex: 20,
      }}
    >
      <div
        style={{
          margin: '30px',
          padding: '10px',
          paddingBottom: '30px',
          marginLeft: '60px',
          marginRight: '50px',
          border: '8px solid #BBB',
          height: 'calc(100vh - 160px)',
          overflow: 'hidden',
          borderRadius: '15px',
          backgroundColor: 'white',
          flex: 1,
        }}
      >
        <ContentWrapper
          splitWPTitle={true}
          refId={viewState.splitWP.workpackage.refId}
          title={viewState.splitWP.workpackage.name}
          headerWidth={window.innerWidth - 150}
          headerMarginTop={'10px'}
          viewState={viewState}
          titleChildren={
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Tooltip title={'split the task group'}>
                <Button
                  size={'small'}
                  variant="contained"
                  color="secondary"
                  onClick={e => {
                    finishSplitWorkPackage();
                    e.stopPropagation();
                  }}
                  disabled={list1.length === 0 || list2.length === 0}
                  className={classes.splitButton}
                >
                  Split
                </Button>
              </Tooltip>
              <Tooltip title={'cancel split'}>
                <Button
                  size={'small'}
                  variant="contained"
                  onClick={e => {
                    clearSplitWorkPackage();
                    e.stopPropagation();
                  }}
                  className={classes.splitButton}
                >
                  Cancel
                </Button>
              </Tooltip>
            </div>
          }
        ></ContentWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={classes.threeColumn}>
            <SplitWPTaskList
              listId={'0'} //
              taskList={list1}
            />
            <SplitWPTaskList
              listId={'1'}
              taskList={list2}
              newWPName={viewState.splitWP.newWPName}
              setSplitWorkPackageName={setSplitWorkPackageName}
            />
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    handleCardDrop,
    toggleNotNeededTaskVisibility,
    toggleSortTasks,
    updateViewState,
    toggleDetailPinVisibility,
    updateSplitWorkPackage,
    setSplitWorkPackageName,
    clearSplitWorkPackage,
    finishSplitWorkPackage,
  },
)(SplitWPDialog);
