import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
      default:
      return state;
  }
}
