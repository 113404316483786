import * as constants from '../../../constants/app.js';
import * as ACTION from '../actionTypes';

export function getUserPreferences(firebase, userId) {
  return new Promise((resolve, reject) => {
    return firebase.db
      .collection('preferences')
      .doc(userId)
      .get()
      .then(querySnapshot => {
        resolve(querySnapshot.data());
      })
      .catch(function(err) {
        reject(`Error getting user ID ${userId} preferences: ${err}`);
      });
  });
}

export function addNewUserPreferencesDoc(firebase, userId, preferences) {
  return new Promise((resolve, reject) => {
    // set the users preferences
    return firebase.db
      .collection('preferences')
      .doc(userId)
      .set(preferences)
      .then(querySnapshot => {
        resolve('success');
      })
      .catch(function(err) {
        reject(`Error addNewUserPreferencesDoc: ${err}`);
      });
  });
}

export function updateUserPreferencesDoc(firebase, userId, newPreferences) {
  return new Promise((resolve, reject) => {
    // set the users preferences
    return firebase.db
      .collection('preferences')
      .doc(userId)
      .set(newPreferences)
      .then(querySnapshot => {
        resolve('success');
      })
      .catch(function(err) {
        reject(`Error updateUserPreferencesDoc: ${err}`);
      });
  });
}

export function updatePreferenceTemplates(userPrefs, workflows) {
  const templateList = [];
  workflows[constants.PUBLIC_GROUP_ID].forEach(template => {
    if (templateList.length < 2 && template.library.level === 1) {
      // by default, take first 2 level 1 templates (if they exist)
      templateList.push(template.uuid);
    }
  });
  if (templateList.length === 0) throw new Error('Cannot build default preferences for user');
  return {
    collegues: userPrefs.collegues, // from old device persisted values
    templateList: templateList,
    onboarding: userPrefs.onboarding, // from old device persisted values
  };
}

export function updateUserPref(preferences) {
  return (dispatch, getState) => {
    const { firebase, user } = getState();
    updateUserPreferencesDoc(firebase, user.uid, preferences);
    dispatch({
      type: ACTION.SET_PREFERENCES,
      userPrefs: preferences,
    });
  };
}
