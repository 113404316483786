/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import {
  SupervisorAccount,
  Lock,
  Event,
  AccountTree,
  Assignment,
  DoubleArrow,
  Add,
  ArrowBack,
  ArrowUpward,
  ArrowDownward,
} from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton, Button, Typography, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import DraftEditor from '../styled_components/DraftEditor';
import { ContentWrapper } from '../styled_components/ContentWrapper';
import { InfoButton } from '../styled_components/InfoButton';
import { Heading } from '../styled_components/Heading';
import { Row } from '../styled_components/Row';
import { TeamModal } from './TeamModal';
import { InputField } from '../styled_components/InputField';

import {
  saveCurrentProject,
  updateViewState,
  setInfoDialog,
  dispatchUpdateViewState,
  handleMenu,
  dispatchUpdateProjects,
  updateWorkflows,
} from '../../data/actions/userInterface';
import { dispatchAnalyticsEvent } from '../../data/actions/analytics';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app';
import { updateUserPref } from '../../data/actions/firebase/userPreferences';
import { orderProjects } from '../../data/actions/firebase/userProjects';

import NamePicker from '../NamePicker';

import * as utils from '../../utils/generalUtilities.js';
import * as constants from '../../constants/app';

import * as NAV from '../../constants/navigation';

const useStyles = makeStyles(theme => ({
  backgroundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'hidden',
    height: 'calc(100% - 60px)',
    backgroundColor: theme.palette.secondary.faint,
  },
  mainDialog: {
    // flex: 1,
    width: '1000px',
    height: '550px',
    display: 'flex',
    overflowY: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10vh',
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.dark,
    borderRadius: 15,
    paddingTop: '5px',
    // opacity: 1.0,
    backgroundColor: '#fff',
    // backgroundColor: 'yellow'
  },
  namePickerContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: '0px 0',
    display: 'flex',
    minWidth: '400px',
    // backgroundColor: 'red',
  },
  button: {
    marginRight: '10px',
  },
  twocolumn: {
    flex: 1,
    // maxWidth: 600,
    // maxWidth: 'calc(100% - 530px)',
    width: '100%',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
    // backgroundColor: 'yellow'
  },
  column1: {
    // flex: 1,
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '440px',
    marginLeft: 50,
  },
  column2: {
    // flexShrink: 1,
    flex: 1,
    // display: 'flex',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: 40,
    maxWidth: '460px',
    // backgroundColor: 'green'
  },
  workflowMenu: {
    width: '300px',
    // height: '10px',
    // backgroundColor: 'yellow'
  },
  templateContainer: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    minWidth: '275px',
    height: '230px',
    overflowY: 'auto',
    padding: 0,
    marginRight: 0,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: '#fff',
    // backgroundColor: 'yellow'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '400px',
    height: '280px',
    overflowY: 'auto',
    padding: 0,
    marginTop: 25,
    marginRight: 40,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: '#fff',
    // backgroundColor: 'yellow'
  },
  selectedButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: '#2f22',
    paddingLeft: 10,
    // alignSelf: 'stretch',
    height: '50px',
    minHeight: '50px',
    // flex: 1,
    '&:hover': {
      background: '#2f24',
    },
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: '#red',
    // backgroundColor: '#fff',
    // alignSelf: 'stretch',
    paddingLeft: 10,
    height: '50px',
    minHeight: '50px',
    // flex: 1,
    '&:hover': {
      background: '#CCC4',
    },
  },
  addButton: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#666',
    backgroundColor: theme.palette.secondary.main + 'C0',
    alignSelf: 'stretch',
    maxHeight: '50px',
    flex: 1,
    marginTop: '5px',
    marginRight: 40,
    '&:hover': {
      background: theme.palette.secondary.main + '80',
    },
  },
  col2spacer: {
    width: '440px',
    height: '10px',
    // backgroundColor: 'green',
  },
  editorWrapper: {
    // flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    // alignItems: 'stretch',
    alignItems: 'flex-start',
    // maxHeight: 500,
  },
  gaoTitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  gaoButton: {
    marginLeft: 30,
  },
  teamTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  teamButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.primary.light,
    margin: '0 10px',
    '&:hover': {
      background: theme.palette.primary.contrastText,
      color: theme.palette.primary.light,
    },
  },
  buttonContainer: {
    width: 80,
    height: 46,
    marginRight: 30,
  },
  newProjectButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px',
  },
  goals: {
    marginTop: -35,
    maxHeight: '240px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // backgroundColor: 'yellow',
  },
  newButton: {
    marginLeft: 30,
  },
  noPadding: {
    padding: 0,
  },
  listIcon: {
    minWidth: '30px',
  },
  workflowInst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 25,
  },
  nameInst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 35,
  },
  peopleInst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 185,
  },
  leftArrow: {
    marginRight: 10,
    fontSize: 20,
    color: 'red',
  },
  instructions: {
    fontSize: 20,
    color: 'red',
    marginRight: 10,
  },
  templateTypo: {
    padding: '5px',
    textAlign: 'left',
    marginRight: '10px',
    alignItems: 'flex-start',
    fontSize: 20,
    // color: 'red',
    // marginRight: 10,
  },
  templateTitle: {
    padding: '5px',
    paddingTop: '7px',
    paddingBottom: '8px',
    marginTop: '5px',
    marginBottom: '2px',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 700,
    // color: 'red',
    backgroundColor: theme.palette.secondary.light,
    // marginRight: 10,
  },
  checkbox: {
    // marginBottom:'5px',
  },
  infoButton: {
    alignSelf: 'flex-start',
    marginTop: 2,
    marginRight: 0,
    marginLeft: 8,
  },
  infoButton2: {
    alignSelf: 'flex-start',
    marginTop: 6,
    marginRight: 0,
    marginLeft: 8,
  },
}));

const DISPLAY_MODE_ALL_EXISTING = 10;
const DISPLAY_MODE_CUSTOMIZE_LIST = -1;
const DISPLAY_MODE_NEW_WORKFLOW = 1;
const DISPLAY_MODE_NEW_NAME = 2;
const DISPLAY_MODE_NEW_PEOPLE = 3;
const DISPLAY_MODE_NEW_REST = 4;

const NEW_SPONSOR = 'Add new email';
const NO_SPONSOR = 'Clear sponser';
const SPONSOR = 'sponsor';
const NEW_PM = 'Add new email';
const NO_PM = 'Clear project manager';
const PROJ_MANAGER = 'projectManager';

let keyListener;
// let favouriteTemplates.selectedIndex = null;

const ProjectInfo = ({
  label,
  project,
  validationSchema,
  saveCurrentProject,
  setInfoDialog,
  viewState,
  firebase,
  user,
  group,
  projects,
  workflows,
  userPrefs,
  dispatchUpdateViewState,
  dispatchUpdateProjects,
  updateWorkflows,
  dispatchAnalyticsEvent,
  updateUserPref,
  updateViewState,
}) => {
  const classes = useStyles({ displayMode });
  const theme = useTheme();
  const creatingNew = viewState.curWindow !== NAV.PROJECT_OVERVIEW;
  const creatingNewWorkflow = viewState.curWindow === NAV.CREATE_NEW_TEMPLATE;

  const visWorkflows = utils.getVisibleWorkflows(workflows);
  const [donePressed, setDonePressed] = useState(false);

  const [favouriteTemplates, setFavouriteTemplates] = useState(() => {
    const ftemplates = [];
    userPrefs.templateList.forEach((templateId, index) => {
      ftemplates.push({
        name: visWorkflows[templateId].template.name,
        icon: null,
        index: index,
        template: visWorkflows[templateId].template,
      });
    });
    return { selectedIndex: null, templateInfo: ftemplates };
  });
  const [allTemplates, setAllTemplates] = useState(() => {
    const aTemplates = [];
    let curGroupId;
    const favTemplatesIndex = userPrefs.templateList.reduce((acc, tId) => ((acc[tId] = true), acc), {});

    Object.keys(visWorkflows).forEach((templateUUID, index) => {
      const templateInfo = visWorkflows[templateUUID];
      if (templateInfo.setId !== constants.CORE_SMART_TEMPLATES_ID.toString()) {
        if (curGroupId !== templateInfo.setId) {
          curGroupId = templateInfo.setId;
          const curGroup = user.groups.find(ugroup => ugroup.id === curGroupId);
          aTemplates.push({
            title: curGroup ? `from  ${curGroup.name}` : 'Public Workflows',
            index: aTemplates.length,
          });
        }
        const haveTemplateId = favTemplatesIndex[templateInfo.template.uuid];
        aTemplates.push({
          name: templateInfo.template.name,
          icon: null,
          index: aTemplates.length,
          template: templateInfo.template,
          checked: haveTemplateId ? true : false,
          level: templateInfo.template.library.level,
        });
      }
    });
    return aTemplates;
  });
  const [displayMode, setDisplayMode] = useState(() => {
    return label === NAV.PROJECT_OVERVIEW ? DISPLAY_MODE_ALL_EXISTING : DISPLAY_MODE_NEW_WORKFLOW;
  });
  const [curProject, setCurProject] = useState(project);
  const [showModal, toggleModal] = useState(false);

  const handleKeyDown = e => {
    if (favouriteTemplates.selectedIndex !== null) {
      // only not null in edit mode and user has selectedIndex an item to move
      const newTemplateInfo = favouriteTemplates.templateInfo.slice();
      if (favouriteTemplates.selectedIndex >= newTemplateInfo.length) {
        favouriteTemplates.selectedIndex = newTemplateInfo.length - 1;
      }
      newTemplateInfo.splice(favouriteTemplates.selectedIndex, 1);

      let moveTo = null;
      if (e.code === 'ArrowUp') {
        if (favouriteTemplates.selectedIndex > 0) {
          newTemplateInfo.splice(
            favouriteTemplates.selectedIndex - 1,
            0,
            favouriteTemplates.templateInfo[favouriteTemplates.selectedIndex],
          );
          moveTo = favouriteTemplates.selectedIndex - 1;
        }
      } else if (e.code === 'ArrowDown') {
        if (favouriteTemplates.selectedIndex < favouriteTemplates.templateInfo.length - 1) {
          newTemplateInfo.splice(
            favouriteTemplates.selectedIndex + 1,
            0,
            favouriteTemplates.templateInfo[favouriteTemplates.selectedIndex],
          );
          moveTo = favouriteTemplates.selectedIndex + 1;
        }
      }
      if (moveTo !== null) {
        newTemplateInfo.forEach((temp, index) => {
          if (temp) temp.index = index;
        });
        setFavouriteTemplates({ selectedIndex: moveTo, templateInfo: newTemplateInfo });
      }
    }
  };
  useEffect(() => {
    keyListener = document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', keyListener);
    };
  }, [favouriteTemplates]);
  useEffect(() => {
    setCurProject(project);
  }, [project]);
  useEffect(() => {
    if (!creatingNew && project.sponsor) {
      setSponsorData({
        // update local state vars on value update
        email: project.sponsor,
        useMenu: true,
        isValidEmail: project.sponsor === '' ? false : true,
      });
    } else {
      setSponsorData({ email: '', useMenu: true, isValidEmail: false });
    }
  }, [project.sponsor]);
  useEffect(() => {
    if (!creatingNew && project.projectManager) {
      setPMData({
        email: project.projectManager,
        useMenu: true,
        isValidEmail: project.projectManager === '' ? false : true,
      });
    } else {
      setPMData({ email: '', useMenu: true, isValidEmail: false });
    }
  }, [project.projectManager]);

  let {
    name,
    creator,
    baselineStartDate,
    baselineEndDate,
    expectedStartDate,
    expectedEndDate,
    actualStartDate,
    actualEndDate,
    sponsor,
    projectManager,
    sharedWith,
    refPrefix,
    goalsAndObjectives,
    library,
    workflowUUID, // for templates, points to parent
    templateRefUUID, // for projects points to genesis template
  } = curProject;

  curProject.description = '';
  curProject.tooltip = 'enter an overview of the project';
  const [sponsorData, setSponsorData] = useState(
    sponsor !== '' && utils.isValidEmail(sponsor)
      ? { email: sponsor, useMenu: true, isValidEmail: true }
      : { email: '', useMenu: true, isValidEmail: false },
  );
  const [pmData, setPMData] = useState(
    projectManager !== '' && utils.isValidEmail(projectManager)
      ? { email: projectManager, useMenu: true, isValidEmail: true }
      : { email: '', useMenu: true, isValidEmail: false },
  );
  const handleUpdate = (value, name) => {
    let trimmedValue = typeof value === 'string' ? value.trim() : value;

    // Issue - auto blur can tigger this without validation resulting in invalid data saved
    if (name === 'refPrefix') {
      if (!utils.validProjectIDTest(trimmedValue) || trimmedValue === project[name]) return;
      const oldPrefix = project[name];
      trimmedValue = trimmedValue ? trimmedValue.toUpperCase() : '';
      curProject[name] = trimmedValue;
      utils.handleChangeProjectId(curProject, oldPrefix); // update all wp refIds with new value
    } else {
      if (name === SPONSOR || name === PROJ_MANAGER) {
        // check if name is in team, if so remove it
        const swIndex = curProject.sharedWith.indexOf(trimmedValue);
        if (swIndex < 0 && trimmedValue !== '') {
          curProject.sharedWith.push(trimmedValue);
        }
      }
      curProject[name] = trimmedValue;
    }
    // setCurProject(curProject); // capture any changes to project in local var so screen updates correctly
    if (creatingNew) {
      setCurProject(Object.assign({}, curProject)); // force rerender
    } else {
      saveCurrentProject();
    }
  };

  const handleSelect = item => {
    let a = 1;
    project = !creatingNewWorkflow
      ? utils.createNewProject('', user.email, favouriteTemplates.templateInfo[item.index].template)
      : utils.createNewTemplateFromTemplate(
          '',
          user.email,
          favouriteTemplates.templateInfo[item.index].template,
          group.id,
        );
    project.baselineStartDate = moment().format('YYYY-MM-DD');
    project.baselineEndDate = moment()
      .add(1, 'day')
      .format('YYYY-MM-DD');
    baselineStartDate = project.baselineStartDate;
    baselineEndDate = project.baselineEndDate;
    setCurProject(project);
    setDisplayMode(displayMode + 1);
  };

  const projIsNotAWorkflow = library === null || library === undefined;

  let relWorkflow;
  let relWorkflowName;
  if (projIsNotAWorkflow) {
    relWorkflow = utils.findWorkflowByUUID(workflows, templateRefUUID);
    relWorkflowName = relWorkflow ? relWorkflow.name : 'unknown';
  } else {
    // template
    let curTemplateLookupInfo = window.templateUUIDLookup && window.templateUUIDLookup[curProject.uuid];
    if (curTemplateLookupInfo && (!curProject.library || curProject.library.level > 0)) {
      const relWorkflowLookup = window.templateUUIDLookup[curTemplateLookupInfo.parentUUID];
      relWorkflow = relWorkflowLookup.component;
      relWorkflowName = relWorkflow ? relWorkflow.name : 'unknown';
    } else {
      relWorkflow = null;
      relWorkflowName = 'none';
    }
  }
  // console.log('expected', expectedStartDate);
  const editingSmartTemplate =
    !(
      viewState.currentWorkflowSetId === constants.PUBLIC_GROUP_ID ||
      viewState.currentWorkflowSetId === constants.CORE_SMART_TEMPLATES_ID
    ) &&
    viewState.curWindow !== NAV.SMARTTEMPLATES &&
    viewState.curWindow !== NAV.GROUPPAGE;

  const aa = curProject;
  console.log(`displayMode = ${displayMode}`);
  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.mainDialog}>
        <ContentWrapper
          title={
            displayMode === DISPLAY_MODE_CUSTOMIZE_LIST
              ? NAV.SETUP_FAVOURITE_TEMPLATES
              : label === NAV.CREATE_NEW_PROJECT || label === NAV.CREATE_NEW_TEMPLATE
              ? label
              : library
              ? 'Workflow Overview'
              : 'Project Overview'
          }
          titleChildren={
            label === NAV.CREATE_NEW_PROJECT || label === NAV.CREATE_NEW_TEMPLATE ? null : (
              <div style={{ marginTop: 15, alignSelf: 'flex-start' }}>
                {relWorkflow && projIsNotAWorkflow && (
                  <InfoButton
                    handleClick={() => {
                      setInfoDialog({
                        // sets InfoDialog reducer values
                        title: `Project: ${curProject.name}`,
                        content: {
                          description: curProject.description,
                          tooltip: curProject.tooltip ?? constants.DEFAULT_TOOLTIP,
                        },
                        onSave: result => {
                          curProject.description = result.description;
                          curProject.tooltip = result.tooltip;
                          saveCurrentProject();
                        },
                      });
                    }}
                    size="small"
                    color={theme.palette.primary.infoButton}
                    bgdColor="#FFF0"
                    hoverColor={theme.palette.primary.infoButton + '10'}
                    borderColor={theme.palette.primary.infoButton}
                    tooltip={curProject.tooltip ?? constants.DEFAULT_TOOLTIP}
                    containerClassName={classes.infoButton}
                    type={'a'}
                  />
                )}
                {relWorkflow && !projIsNotAWorkflow && !creatingNewWorkflow && (
                  <InfoButton
                    handleClick={() => {
                      viewState = Object.assign(viewState, {
                        infoDialogData: {
                          name: `Template: ${curProject.name}`,
                          text: curProject.advice,
                          label: `Advice for the Template: ${curProject.name}`,
                          onSave: newText => {
                            project.advice = newText;
                            setCurProject(project);
                            saveCurrentProject();
                          },
                        },
                      });
                      updateViewState(viewState);
                    }}
                    size="small"
                    color={theme.palette.primary.infoButton}
                    bgdColor="#FFF0"
                    hoverColor={theme.palette.primary.infoButton + '10'}
                    borderColor={theme.palette.primary.infoButton}
                    tooltip={utils.isInfoEmpty(curProject.advice) ? 'add help' : 'edit help'}
                    containerClassName={classes.infoButton}
                    type={'a'}
                  />
                )}
              </div>
            )
          }
          backToWindow={
            editingSmartTemplate
              ? label === NAV.CREATE_NEW_PROJECT || label === NAV.CREATE_NEW_TEMPLATE
                ? undefined
                : NAV.SMARTTEMPLATES
              : displayMode === DISPLAY_MODE_ALL_EXISTING
              ? NAV.DASHBOARD
              : undefined
          }
          viewState={viewState}
          dispatchUpdateViewState={dispatchUpdateViewState}
          indentTitle={displayMode === DISPLAY_MODE_ALL_EXISTING ? undefined : 10}
          setInfoDialog={setInfoDialog}
          saveCurrentProject={saveCurrentProject}
        >
          {Object.keys(project).length !== 0 && (
            <TeamModal
              // key={new Date().getTime()} // remount each time
              open={showModal}
              toggle={toggleModal}
              team={sharedWith}
              projects={projects}
              user={user}
              viewState={viewState}
              saveCurrentProject={() => {
                saveCurrentProject();
              }}
              firebase={firebase}
            />
          )}
          <div className={classes.twocolumn}>
            <div className={classes.column1}>
              {(displayMode === DISPLAY_MODE_NEW_WORKFLOW || displayMode === DISPLAY_MODE_CUSTOMIZE_LIST) && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={classes.menuContainer}>
                    {favouriteTemplates.templateInfo.map(item => (
                      <Tooltip
                        title="use up and down arrows to reorder"
                        disableHoverListener={
                          !favouriteTemplates.selectedIndex ||
                          !favouriteTemplates.templateInfo[favouriteTemplates.selectedIndex] ||
                          favouriteTemplates.templateInfo[favouriteTemplates.selectedIndex].template.uuid !==
                            item.template.uuid
                        }
                      >
                        <div
                          onClick={() => {
                            if (displayMode === DISPLAY_MODE_CUSTOMIZE_LIST) {
                              setFavouriteTemplates({ ...favouriteTemplates, selectedIndex: item.index });
                            } else {
                              handleSelect(item);
                            }
                          }}
                          variant="contained"
                          className={
                            item === favouriteTemplates.templateInfo[favouriteTemplates.selectedIndex]
                              ? classes.selectedButton
                              : classes.menuButton
                          }
                        >
                          <Typography variant="inherit" className={classes.templateTypo}>
                            {item.name.length > 35 ? `${item.name.substring(0, 32)}...` : item.name}
                          </Typography>
                          {item.template.library.level > 0 && !utils.isInfoEmpty(item.template.description) && (
                            <InfoButton
                              handleClick={e => {
                                e.stopPropagation();
                                setInfoDialog({
                                  // sets InfoDialog reducer values
                                  title: `Template: ${item.name}`,
                                  content: {
                                    description: item.template.description,
                                    tooltip: 'view description',
                                  },
                                });
                              }}
                              size="small"
                              color={theme.palette.primary.infoButton}
                              bgdColor="#FFF0"
                              hoverColor={theme.palette.primary.infoButton + '10'}
                              borderColor={theme.palette.primary.infoButton}
                              tooltip="view description"
                              containerClassName={classes.infoButton2}
                              type={'i'}
                            />
                          )}
                        </div>
                        {/* <Typography variant="inherit" className={classes.templateTypo}>
                            {item.name}
                          </Typography>
                        </div> */}
                      </Tooltip>
                    ))}
                  </div>
                  <Button
                    onClick={() => {
                      const nextDisplayMode =
                        displayMode === DISPLAY_MODE_NEW_WORKFLOW
                          ? DISPLAY_MODE_CUSTOMIZE_LIST
                          : DISPLAY_MODE_NEW_WORKFLOW;
                      setDisplayMode(nextDisplayMode);
                      if (nextDisplayMode === DISPLAY_MODE_NEW_WORKFLOW) {
                        const newTemplateList = [];
                        favouriteTemplates.templateInfo.forEach(item => {
                          newTemplateList.push(item.template.uuid);
                        });
                        setFavouriteTemplates({ ...favouriteTemplates, selectedIndex: null });
                        updateUserPref({ ...userPrefs, templateList: newTemplateList });
                      }
                    }}
                    variant="contained"
                    className={classes.addButton}
                  >
                    {displayMode === DISPLAY_MODE_NEW_WORKFLOW ? `More workflows...` : `Done, go back`}
                  </Button>
                </div>
              )}
              {displayMode >= DISPLAY_MODE_NEW_NAME && (
                <Fragment>
                  <Row colWidth={'400'}>
                    <InputField
                      validationSchema={validationSchema}
                      name="name"
                      value={name}
                      label={!projIsNotAWorkflow || creatingNewWorkflow ? 'Workflow Name' : 'Project Name'}
                      handleBlur={handleUpdate}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Assignment />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Row>
                  <Row colWidth={'400'}>
                    <InputField
                      validationSchema={validationSchema}
                      name="workflowName"
                      value={relWorkflowName}
                      label={projIsNotAWorkflow ? 'Workflow' : 'Parent Workflow'}
                      handleBlur={handleUpdate}
                      disabled={true}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountTree />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Row>
                </Fragment>
              )}
              {projIsNotAWorkflow && displayMode >= DISPLAY_MODE_NEW_PEOPLE && !creatingNewWorkflow && (
                <Fragment>
                  <Row colWidth={'400'}>
                    <div className={classes.namePickerContainer}>
                      <NamePicker
                        field={sponsorData}
                        fieldLabel={'Sponsor'}
                        newEmailLabel={`Email for sponsor`}
                        project={curProject}
                        community={user.community}
                        newPersonText={NEW_SPONSOR}
                        removeText={NO_SPONSOR}
                        onBlur={inputValue => {
                          setSponsorData({
                            email: inputValue,
                            useMenu: false,
                            isValidEmail: utils.isValidEmail(inputValue),
                          });
                        }}
                        cancelButtonClick={() => {
                          setSponsorData({ email: '', useMenu: true, isValidEmail: false });
                        }}
                        addButtonClick={() => {
                          if (sponsorData.isValidEmail) {
                            setSponsorData({
                              email: sponsorData.email,
                              useMenu: true,
                              isValidEmail: utils.isValidEmail(sponsorData.email),
                            });
                            handleUpdate(sponsorData.email, SPONSOR);
                          }
                        }}
                        onMenuChange={e => {
                          if (e.target.value === NO_SPONSOR) {
                            setSponsorData({ email: '', useMenu: true, isValidEmail: false });
                            handleUpdate('', SPONSOR);
                          } else {
                            if (e.target.value === NEW_SPONSOR) {
                              setSponsorData({ email: '', useMenu: false, isValidEmail: false });
                            } else {
                              if (e.target.value !== sponsorData.email) {
                                setSponsorData({
                                  email: e.target.value,
                                  useMenu: e.target.value !== NEW_SPONSOR,
                                  isValidEmail: utils.isValidEmail(e.target.value),
                                });
                                handleUpdate(e.target.value, SPONSOR);
                              }
                            }
                          }
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SupervisorAccount />
                            </InputAdornment>
                          ),
                          autocomplete: 'off',
                        }}
                      />
                    </div>
                  </Row>
                  <Row colWidth={'400'}>
                    <div className={classes.namePickerContainer}>
                      <NamePicker
                        field={pmData}
                        fieldLabel={'Project Manager'}
                        newEmailLabel={`Email for Project Manager`}
                        project={curProject}
                        community={user.community}
                        handleSave={handleUpdate}
                        handleUpdate={handleUpdate}
                        newPersonText={NEW_PM}
                        removeText={NO_PM}
                        onBlur={inputValue => {
                          setPMData({
                            email: inputValue,
                            useMenu: false,
                            isValidEmail: utils.isValidEmail(inputValue),
                          });
                        }}
                        cancelButtonClick={() => {
                          setPMData({ email: '', useMenu: true, isValidEmail: false });
                        }}
                        addButtonClick={() => {
                          if (pmData.isValidEmail) {
                            setPMData({
                              email: pmData.email,
                              useMenu: true,
                              isValidEmail: utils.isValidEmail(pmData.email),
                            });
                            handleUpdate(pmData.email, PROJ_MANAGER);
                          }
                        }}
                        onMenuChange={e => {
                          if (e.target.value === NO_PM) {
                            setPMData({ email: '', useMenu: true, isValidEmail: false });
                            handleUpdate('', PROJ_MANAGER);
                          } else {
                            if (e.target.value === NEW_PM) {
                              setPMData({ email: '', useMenu: false, isValidEmail: false });
                              handleUpdate('', PROJ_MANAGER);
                            } else {
                              if (e.target.value !== pmData.email) {
                                setPMData({
                                  email: e.target.value,
                                  useMenu: e.target.value !== NEW_PM,
                                  isValidEmail: utils.isValidEmail(e.target.value),
                                });
                                handleUpdate(e.target.value, PROJ_MANAGER);
                              }
                            }
                          }
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SupervisorAccount />
                            </InputAdornment>
                          ),
                          autocomplete: 'off',
                        }}
                      />
                    </div>
                  </Row>
                </Fragment>
              )}

              {projIsNotAWorkflow && displayMode === DISPLAY_MODE_ALL_EXISTING && (
                <Fragment>
                  <Tooltip title={'set your team members for this project'}>
                    <Button
                      onClick={() => toggleModal(true)}
                      color="primary"
                      variant="contained"
                      disabled={false}
                      size="small"
                      className={classes.buttonContainer}
                    >
                      Team
                    </Button>
                    {/* <div className={classes.teamTitleContainer}>
                      <Heading heading="Team" />
                      <IconButton
                        aria-label="add-team"
                        aria-controls="add-team"
                        aria-haspopup="true"
                        onClick={() => toggleModal(true)}
                        className={classes.teamButton}
                        size="small"
                      >
                        <Add fontSize="small" />
                      </IconButton>
                    </div> */}
                  </Tooltip>
                </Fragment>
              )}
            </div>
            <div className={classes.column2}>
              {displayMode === DISPLAY_MODE_CUSTOMIZE_LIST && (
                <Fragment>
                  <div className={classes.workflowInst}>
                    <ArrowDownward className={classes.leftArrow} />
                    <Typography variant="inherit" className={classes.instructions}>
                      {'Check off the workflows you want to use'}
                    </Typography>
                    <Tooltip title="get information about selecting workflows">
                      <IconButton
                        aria-label="workflow-info"
                        aria-controls="workflow-info"
                        aria-haspopup="true"
                        onClick={() => {
                          alert('Under construction: need to add a link here');
                        }}
                        // className={classes.teamButton}
                        // size="small"
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className={classes.templateContainer}>
                    {allTemplates.map(item => (
                      <Fragment>
                        {item.title ? (
                          <Typography variant="inherit" className={classes.templateTypo}>
                            {item.title}
                          </Typography>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              checked={item.checked}
                              className={classes.checkbox}
                              onChange={e => {
                                const newTemp = allTemplates.slice();
                                newTemp[item.index].checked = allTemplates[item.index].checked ? false : true;
                                const newTempInfo = favouriteTemplates.templateInfo.slice();
                                if (!item.checked) {
                                  const correspondingIndex = newTempInfo.findIndex(favItem => {
                                    const test = favItem.template.uuid === item.template.uuid;
                                    return test;
                                  });
                                  if (correspondingIndex >= 0) newTempInfo.splice(correspondingIndex, 1);
                                } else {
                                  newTempInfo.push({
                                    name: newTemp[item.index].template.name,
                                    icon: null,
                                    index: newTempInfo.length,
                                    template: newTemp[item.index].template,
                                  });
                                }
                                setAllTemplates(newTemp);
                                setFavouriteTemplates({ ...favouriteTemplates, templateInfo: newTempInfo });
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="inherit" className={classes.templateTitle}>
                                {item.name}
                              </Typography>
                              {item.level > 0 && !utils.isInfoEmpty(item.template.description) && (
                                <InfoButton
                                  handleClick={e => {
                                    e.stopPropagation();
                                    setInfoDialog({
                                      // sets InfoDialog reducer values
                                      title: `Template: ${item.name}`,
                                      content: {
                                        description: item.template.description,
                                        tooltip: 'view description',
                                      },
                                    });
                                  }}
                                  size="small"
                                  color={theme.palette.primary.infoButton}
                                  bgdColor="#FFF0"
                                  hoverColor={theme.palette.primary.infoButton + '10'}
                                  borderColor={theme.palette.primary.infoButton}
                                  tooltip="view description"
                                  containerClassName={classes.infoButton}
                                  type={'i'}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Fragment>
              )}
              {displayMode === DISPLAY_MODE_NEW_WORKFLOW && (
                <div className={classes.workflowInst}>
                  <ArrowBack className={classes.leftArrow} />
                  <Typography variant="inherit" className={classes.instructions}>
                    {`First select a ${label === NAV.CREATE_NEW_TEMPLATE ? 'base' : 'workflow'} for your ${
                      label === NAV.CREATE_NEW_TEMPLATE ? 'new workflow' : 'project'
                    }`}
                  </Typography>
                  <Tooltip title="get information about workflows">
                    <IconButton
                      aria-label="workflow-info"
                      aria-controls="workflow-info"
                      aria-haspopup="true"
                      onClick={() => {
                        alert('need to add a link here');
                      }}
                      // className={classes.teamButton}
                      // size="small"
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {displayMode === DISPLAY_MODE_NEW_NAME && (
                <div className={classes.nameInst}>
                  <ArrowBack className={classes.leftArrow} />
                  <Typography variant="inherit" className={classes.instructions}>
                    {'now enter a name for your project'}
                  </Typography>
                </div>
              )}
              {displayMode === DISPLAY_MODE_NEW_PEOPLE && (
                <div className={classes.peopleInst}>
                  <ArrowBack className={classes.leftArrow} />
                  <Typography variant="inherit" className={classes.instructions}>
                    {"record who's sponsoring and leading"}
                  </Typography>
                </div>
              )}

              {displayMode === DISPLAY_MODE_ALL_EXISTING ? (
                <Fragment>
                  <Row colWidth={'400'}>
                    <InputField
                      validationSchema={validationSchema}
                      name="refPrefix"
                      value={refPrefix}
                      handleBlur={handleUpdate}
                      forceUpperCase={true}
                      label={projIsNotAWorkflow ? 'Project ID' : 'Workflow ID'}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DoubleArrow />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Row>
                  <Row colWidth={'400'}>
                    <InputField
                      validationSchema={validationSchema}
                      name="creator"
                      disabled
                      label="Administered by"
                      value={creator}
                      handleBlur={handleUpdate}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Row>
                </Fragment>
              ) : (
                displayMode === DISPLAY_MODE_NEW_REST &&
                !creatingNewWorkflow && (
                  <Fragment>
                    <Heading heading="Goals & Objectives" marginTop={'2px'} marginBottom={'-15px'} />
                    {/* <div className={classes.goals}> */}
                    <Row colWidth={'420'}>
                      <DraftEditor
                        label={null}
                        value={goalsAndObjectives}
                        onSave={result => {
                          curProject.goalsAndObjectives = result.goalsAndObjectives;
                        }}
                        field="goalsAndObjectives"
                        small
                        hideToolbar={true}
                        // maxHeight={'170px'}
                        fixedHeight={'150px'}
                        // maxHeightOuter={'230px'}
                        minHeight={'10px'}
                      />
                    </Row>
                    {/* </div> */}
                  </Fragment>
                )
              )}
              {projIsNotAWorkflow && displayMode >= DISPLAY_MODE_NEW_REST && !creatingNewWorkflow ? (
                <Fragment>
                  <Heading heading="Estimated Timeline" />
                  <Row>
                    <InputField
                      validationSchema={validationSchema}
                      name="baselineStartDate"
                      type="date"
                      value={baselineStartDate}
                      disabled={!utils.subcomponetsHaveNoDateSet(curProject, 'start', 'P')}
                      label="Baseline Start Date"
                      handleBlur={handleUpdate}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Event />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InputField
                      validationSchema={validationSchema}
                      name="baselineEndDate"
                      type="date"
                      value={baselineEndDate}
                      disabled={!utils.subcomponetsHaveNoDateSet(curProject, 'end', 'P')}
                      label="Baseline End Date"
                      handleBlur={handleUpdate}
                      customValidation={
                        baselineEndDate !== '' && baselineEndDate < baselineStartDate
                          ? 'Baseline end date should be later than the baseline start date.'
                          : null
                      }
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Event />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Row>
                </Fragment>
              ) : (
                <div className={classes.col2spacer} />
              )}
              {displayMode === DISPLAY_MODE_NEW_REST && !creatingNewWorkflow && (
                <div className={classes.nameInst}>
                  <ArrowUpward className={classes.leftArrow} />
                  <Typography variant="inherit" className={classes.instructions}>
                    {'finally, note your goals and time estimates'}
                  </Typography>
                </div>
              )}

              {displayMode === DISPLAY_MODE_ALL_EXISTING && (
                <Fragment>
                  <Row>
                    {actualStartDate !== '' && (
                      <InputField
                        validationSchema={validationSchema}
                        name="actualStartDate"
                        type="date"
                        disabled
                        label="Actual Start Date"
                        value={actualStartDate}
                        handleBlur={handleUpdate}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Event />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {actualEndDate !== '' && (
                      <InputField
                        validationSchema={validationSchema}
                        name="actualEndDate"
                        type="date"
                        disabled
                        label="Actual End Date"
                        value={actualEndDate}
                        handleBlur={handleUpdate}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Event />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Row>
                </Fragment>
              )}
            </div>
          </div>
          {displayMode < DISPLAY_MODE_NEW_REST &&
            !(displayMode === DISPLAY_MODE_NEW_NAME && creatingNewWorkflow) && (
              <div className={classes.newProjectButtonRow}>
                <Button
                  size={'small'}
                  onClick={() => {
                    dispatchUpdateUsibilityReport();
                    viewState = Object.assign(viewState, {
                      curWindow: creatingNewWorkflow ? NAV.SMARTTEMPLATES : NAV.DASHBOARD,
                    });
                    dispatchUpdateViewState(viewState);
                  }}
                  variant="contained"
                  className={classes.newButton}
                >
                  Cancel
                </Button>
                <Button
                  size={'small'}
                  disabled={
                    displayMode === DISPLAY_MODE_NEW_WORKFLOW ||
                    displayMode === DISPLAY_MODE_CUSTOMIZE_LIST ||
                    curProject.name === ''
                  }
                  onClick={() => {
                    if (creatingNewWorkflow) {
                      setDisplayMode(DISPLAY_MODE_NEW_REST);
                    } else {
                      setDisplayMode(displayMode + 1);
                    }
                  }}
                  variant="contained"
                  className={classes.newButton}
                >
                  Next
                </Button>
              </div>
            )}
          {(displayMode === DISPLAY_MODE_NEW_REST ||
            (displayMode === DISPLAY_MODE_NEW_NAME && creatingNewWorkflow)) && (
            <div className={classes.newProjectButtonRow}>
              <Button
                size={'small'}
                onClick={() => {
                  dispatchUpdateUsibilityReport();
                  viewState = Object.assign(viewState, {
                    curWindow: creatingNewWorkflow ? NAV.SMARTTEMPLATES : NAV.DASHBOARD,
                  });
                  dispatchUpdateViewState(viewState);
                }}
                variant="contained"
                className={classes.newButton}
              >
                Cancel
              </Button>
              <Button
                size={'small'}
                onClick={() => {
                  // clean up all refs in curProject
                  setDonePressed(true);
                  if (creatingNewWorkflow) {
                    utils.resetProjectState(
                      curProject.name,
                      curProject.creator,
                      curProject,
                      true,
                      false,
                      false,
                      false,
                    );
                    firebase.db
                      .collection('workflows')
                      .add(curProject)
                      .then(docRef => {
                        curProject.id = docRef.id;
                        workflows[group.id].push(curProject);
                        // The sort order is projects
                        // project name alphabetic
                        orderProjects(workflows[group.id]);
                        // projects.sort((p1, p2) => (p1.name < p2.name ? -1 : p1.name === p2.name ? 0 : 1));
                        viewState.currentWorkflowIndex = workflows[group.id].findIndex(
                          curProj => curProj.id === curProject.id,
                        );
                        if (
                          viewState.currentWorkflowIndex < 0 ||
                          viewState.currentWorkflowIndex >= workflows[group.id].length
                        ) {
                          viewState.currentWorkflowIndex = 0;
                        }
                        viewState.currentDeliverable = 0;
                        viewState.currentWorkPackage = 0;
                        viewState.currentTask = 0;
                        viewState.form = null;
                        viewState.alert = null;
                        viewState.currentProjectType = NAV.TYPE_WORKFLOW;
                        viewState.curWindow = NAV.SMARTTEMPLATES;
                        viewState.wbsStack = [
                          {
                            screen: NAV.BOARD,
                            item: { d: -1, w: -1, t: -1 },
                            level: 'p',
                          },
                        ]; // reset wbs nav stack
                        viewState.libStack = [
                          {
                            screen: NAV.LIBRARY_BOARD,
                            item: { d: -1, w: -1, t: -1 },
                            level: 'p',
                          },
                        ]; // reset lib nav stack
                        dispatchAnalyticsEvent('Workflow.add', {
                          project: curProject.name,
                          template: curProject.templateName,
                        });

                        updateWorkflows(workflows, viewState, true);
                      });
                  } else {
                    utils.resetProjectState(
                      curProject.name,
                      curProject.creator,
                      curProject,
                      false,
                      false,
                      false,
                    );
                    firebase.db
                      .collection('projects')
                      .add(curProject)
                      .then(docRef => {
                        curProject.id = docRef.id;
                        projects.push(curProject);
                        // The sort order is projects
                        // project name alphabetic
                        orderProjects(projects);
                        // projects.sort((p1, p2) => (p1.name < p2.name ? -1 : p1.name === p2.name ? 0 : 1));
                        viewState.currentProjectIndex = projects.findIndex(
                          curProj => curProj.id === curProject.id,
                        );
                        if (
                          viewState.currentProjectIndex < 0 ||
                          viewState.currentProjectIndex >= projects.length
                        ) {
                          viewState.currentProjectIndex = 0;
                        }
                        viewState.currentDeliverable = 0;
                        viewState.currentWorkPackage = 0;
                        viewState.currentTask = 0;
                        viewState.form = null;
                        viewState.alert = null;
                        viewState.currentProjectType = NAV.TYPE_PROJECT;
                        viewState.curWindow = NAV.BOARD;
                        viewState.wbsStack = [
                          {
                            screen: NAV.BOARD,
                            item: { d: -1, w: -1, t: -1 },
                            level: 'p',
                          },
                        ]; // reset wbs nav stack
                        viewState.libStack = [
                          {
                            screen: NAV.LIBRARY_BOARD,
                            item: { d: -1, w: -1, t: -1 },
                            level: 'p',
                          },
                        ]; // reset lib nav stack
                        dispatchAnalyticsEvent('Project.add', {
                          project: curProject.name,
                          template: curProject.templateName,
                        });

                        dispatchUpdateProjects(projects, viewState, true);
                      });
                  }
                }}
                disabled={curProject.name === '' || donePressed}
                variant="contained"
                className={classes.newButton}
              >
                Done
              </Button>
            </div>
          )}
        </ContentWrapper>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      firebase: state.firebase,
      user: state.user,
      group: state.group,
      userPrefs: state.userPrefs,
    };
  },
  {
    saveCurrentProject,
    setInfoDialog,
    dispatchUpdateViewState,
    dispatchUpdateProjects,
    updateWorkflows,
    dispatchAnalyticsEvent,
    handleMenu,
    updateUserPref,
    updateViewState,
  },
)(ProjectInfo);
