import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.SET_USER_INFO:
    case ACTION.LOAD_USER_DOCS:
    case ACTION.SET_USIBILITY_REPORT_DATA:
      return action.projData;

    case ACTION.CLEAR_USER_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
}
