import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION.ANALYTICS_UPDATE:
      return action.analyticsDoc;

    default:
      return state;
  }
}
