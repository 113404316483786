import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { saveCurrentProject, rollUpDates } from '../data/actions/userInterface';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import NameIcon from '@material-ui/icons/SupervisorAccount';
import Close from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { InputField } from '../components/styled_components/InputField';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Menu,
  Button,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { PriorityIcon } from './Icons/PriorityIcon';
import * as TASK from '../constants/tasks';
import Typography from '@material-ui/core/Typography';
import * as STATUS from '../constants/status';
import * as utils from '../utils/generalUtilities.js';

const useStyles = makeStyles(theme => ({
  selector: {
    // width: '240px',
    // marginRight: '10px',
    marginTop: '5px',
  },
  input: {
    // width: '240px',
    // marginRight: '10px',
    marginTop: '5px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItemTitle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.assign} !important`,
  },
  specialMenuItem: {
    display: 'flex',
    alignItems: 'center',
    fontColor: 'yellow',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '5px',
  },
  dateMessage: {
    alignSelf: 'flex-start',
    fontSize: '.8rem',
    color: '#888',
  },
  newTeamMemberLabel: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    color: '#666',
  },
  newTeamMemberText: {
    color: theme.palette.secondary.dark,
  },
  addButton: {
    width: '115px',
    backgroundColor: theme.palette.secondary.faint,
    marginLeft: '10px',
  },
  cancelButton: {
    width: '115px',
    backgroundColor: '#EEE',
  },
}));

const assignStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBase: {
    color: ({ buttonColor }) => theme.palette.getContrastText(buttonColor),
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
  },
}));

const NamePicker = props => {
  const {
    field,
    fieldLabel,
    newEmailLabel,
    inputProps,
    project,
    community,
    showName = false,
    showProjectTeam = true,
    newPersonText,
    removeText,
    onBlur,
    addButtonClick,
    cancelButtonClick,
    onMenuChange,
    // updateCommunity,
  } = props;
  const classes = useStyles();
  const THIS_PROJECT = 'This Project';
  const OTHER_PROJECTS = 'Other Projects';

  const memberExistsInTeam = { [project.creator]: true };
  if (project.sponsor && project.sponsor !== '') memberExistsInTeam[project.sponsor] = true;
  if (project.projectManager && project.projectManager !== '')
    memberExistsInTeam[project.projectManager] = true;
  project.sharedWith.forEach(member => {
    memberExistsInTeam[member] = true;
  });
  const ascendSort = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
  const nonProjectCommunity = [];
  community.forEach(member => {
    if (!memberExistsInTeam[member]) {
      nonProjectCommunity.push(member);
    }
  });
  nonProjectCommunity.sort(ascendSort);

  const team = [];
  Object.keys(memberExistsInTeam).forEach(member => {
    team.push(member);
  });
  team.sort(ascendSort);

  return (
    <Fragment>
      {!field.useMenu ? (
        <Fragment>
          <Typography variant={'h6'} className={classes.newTeamMemberLabel}>
            <span className={classes.newTeamMemberText}>{newEmailLabel}</span>
          </Typography>
          <InputField
            name={''}
            value={field.email}
            label={''}
            handleBlur={inputValue => {
              onBlur(inputValue);
            }}
            onEnterFunction={inputValue => {
              onBlur(inputValue);
            }}
            doAutoSave={true}
            className={classes.input}
            autofocus={true}
            InputProps={inputProps}
          />
          <div
            style={{
              diaplay: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginTop: '0px',
              marginBottom: '20px',
            }}
          >
            <Button
              size={'small'}
              onClick={cancelButtonClick}
              variant="contained"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              size={'small'}
              onClick={addButtonClick}
              variant="contained"
              className={classes.addButton}
              disabled={!field.isValidEmail}
            >
              Add
            </Button>
          </div>
        </Fragment>
      ) : (
        <FormControl variant="outlined" className={classes.selector}>
          <InputLabel id="workpackage-assignedto-label">{fieldLabel}</InputLabel>
          <Select
            id="pickEmailMenu"
            name={showProjectTeam ? fieldLabel : ''}
            labelId={fieldLabel}
            classes={{ root: classes.menuItem }}
            disabled={false}
            value={field.email}
            onChange={e => onMenuChange(e)}
            variant="outlined"
            label={fieldLabel}
          >
            <MenuItem
              key={`key-${newPersonText}`}
              className={classes.specialMenuItem}
              divider={true}
              size={'small'}
              value={newPersonText}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={newPersonText} />
            </MenuItem>
            <MenuItem
              key={`key-${removeText}`}
              className={classes.specialMenuItem}
              divider={true}
              size={'small'}
              value={removeText}
            >
              <ListItemIcon>{removeText === 'Close' ? <Close /> : <DeleteIcon />}</ListItemIcon>
              <ListItemText primary={removeText} />
            </MenuItem>
            {showProjectTeam && (
              <MenuItem
                key={`key-${THIS_PROJECT}`}
                className={classes.menuItemTitle}
                divider={true}
                size={'small'}
                value={THIS_PROJECT}
                disabled={true}
              >
                <ListItemText primary={THIS_PROJECT} style={{ color: 'black', fontWeight: 'bold' }} />
              </MenuItem>
            )}
            {showProjectTeam &&
              team.map((email, index) => {
                return (
                  <MenuItem
                    key={`key-${email}`}
                    // className={classes.menuItem}
                    divider={false}
                    size={'small'}
                    value={email}
                  >
                    <ListItemText primary={email} />
                  </MenuItem>
                );
              })}
            {nonProjectCommunity.length > 0 && (
              <MenuItem
                key={`key-${OTHER_PROJECTS}`}
                className={classes.menuItemTitle}
                divider={true}
                size={'small'}
                value={OTHER_PROJECTS}
                disabled={true}
              >
                <ListItemText primary={OTHER_PROJECTS} style={{ color: 'black', fontWeight: 'bold' }} />
              </MenuItem>
            )}
            {nonProjectCommunity.map((email, index) => {
              return (
                <MenuItem
                  key={`key-${email}`}
                  className={classes.menuItem}
                  divider={false}
                  size={'small'}
                  value={email}
                >
                  <ListItemText primary={email} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Fragment>
  );
};

export default connect(
  state => {
    return {};
  },
  {
    saveCurrentProject,
  },
)(NamePicker);
