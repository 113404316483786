/* eslint-disable */

import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Card, CardContent, CardActions, Chip } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ActionMenu from '../styled_components/ActionMenu';
import StatusButton from '../styled_components/StatusButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';
import PublishIcon from '@material-ui/icons/Publish';
import Person from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { PinButton } from '../styled_components/PinButton';

import {
  setUserColleguePref,
  updateViewState,
  handleMenu,
  dispatchUpdateViewState,
  saveCurrentProject,
  rollUpDates,
} from '../../data/actions/userInterface';
import { useTheme } from '@material-ui/styles';
import { stickyNoteTilt } from '../../data/actions/app';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as utils from '../../utils/generalUtilities';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: ({ thin }) => (thin ? '2px' : '8px'),
    marginRight: ({ thin }) => (thin ? '2px' : '8px'),
    boxShadow: '3px 6px 6px -1px rgba(138, 148, 159, 0.7)',
    // boxShadow: '1px 1px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1.2px ${theme.palette.primary.main}`,
    // paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
    background: theme.palette.workCard.notStarted,
    borderColor: theme.palette.workCard.notStartedBorder,
    // backgroundColor: 'red',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: ({ thin }) => (thin ? 6 : 0),
    marginLeft: ({ thin }) => (thin ? 15 : 0),
    marginRight: ({ thin }) => (thin ? 10 : 0),
    marginBottom: ({ thin }) => (thin ? -16 : 0),
    padding: ({ thin }) => (thin ? 0 : undefined),
  },
  nameRow: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rightSideStuff: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: ({ isSelected }) => (isSelected ? 5 : 0),
    marginBottom: -5,
  },
  thinInfoButton: {
    marginLeft: 10,
    alignSelf: 'flex-start',
    marginTop: -4,
  },
  started: {
    // background: theme.palette.primary.main,
    background: theme.palette.workCard.started,
    borderColor: theme.palette.workCard.startedBorder,
  },
  completed: {
    // background: theme.palette.secondary.light,
    background: theme.palette.workCard.completed,
    borderColor: theme.palette.workCard.startedcompletedBorderBorder,
  },
  header: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  selected: {
    border: `solid 1.2px ${theme.palette.secondary.main}`,
    transform: ({ cardUUID, thin }) =>
      thin ? undefined : `rotate(${stickyNoteTilt(cardUUID)}deg) scale(1.04, 1.04)`,
    // boxShadow: ({ thin }) => (thin ? 0 : '2px 8px 8px 0px rgba(138, 148, 159, 1)'),
    // marginTop: ({ thin }) => (thin ? undefined : '10px'),
    // marginBottom: ({ thin }) => (thin ? undefined : '25px'),
    boxShadow: ({ thin }) => '2px 8px 8px 0px rgba(138, 148, 159, 1)',
    marginTop: ({ thin }) => (thin ? '16px' : '10px'),
    marginBottom: ({ thin }) => (thin ? '16px' : '25px'),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  thinName: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 15,
    alignSelf: 'center',
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  nameText: {
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  thinChipContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    // backgroundColor: 'yellow',
  },
  chipContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // backgroundColor: 'green'
  },
  chip: {
    // border: 'solid 1px #e0e0e0',
    border: 'solid 1px #C250E2A0',
    background: '#C250E225',
    // background: 'none',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginTop: ({ thin }) => (thin ? 1 : 0),
    marginRight: ({ thin }) => (thin ? 10 : 0),
    marginLeft: ({ thin }) => (thin ? 10 : 0),
    // backgroundColor: 'yellow',
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToTooltip: {
    fontSize: 20,
    marginRight: ({ thin }) => (thin ? 10 : 0),
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },

  thinActionMenu: {
    marginLeft: 10,
    alignSelf: 'center',
    // backgroundColor: 'yellow',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statusButton: {
    width: '120px',
  },
  // taskDate: {
  //   marginRight: 12,
  //   marginLeft: 5,
  //   textAlign: 'right',
  //   // backgroundColor: 'blue'
  // },
  // dateString: {
  //   fontSize: ({ thin }) => (thin ? 15 : 14),
  // },
  taskDate: {
    maxHeight: ({ thin }) => (thin ? undefined : '.7em'),
    marginLeft: ({ thin }) => (thin ? 10 : 0),
    // backgroundColor: 'yellow',
  },
  percentSize: {
    fontSize: ({ thin }) => (thin ? 14 : 14),
    marginLeft: ({ thin }) => (thin ? 5 : 2),
    // backgroundColor: 'yellow',
  },
  dateString: {
    fontSize: ({ thin }) => (thin ? 15 : '.7em'),
    marginTop: ({ thin }) => (thin ? 0 : -5),
    // backgroundColor: 'yellow',
  },
  pinButton: {
    marginLeft: 5,
  },
}));

const DraggableTaskCard = ({
  taskCard,
  thin = false,
  viewStack,
  isLibrary = false,
  viewState,
  handleMenu,
  userPrefs,
  setUserColleguePref,
  dispatchUpdateViewState,
  saveCurrentProject,
  rollUpDates,
  workflows,
}) => {
  const {
    percentageComplete,
    actualStartDate,
    isPriority,
    expectedStartDate,
    expectedEndDate,
    actualEndDate,
    assignedTo,
    refId,
    name,
    status,
  } = taskCard.task;
  const theme = useTheme();
  const [adviceComponent, setAdviceComponent] = React.useState(() => {
    return utils.getAdviceComponent(workflows, taskCard.task.templateRefUUID, taskCard.task);
  });
  const inWorkflow = viewState.currentProjectType === 'Workflow';

  const handleDoubleClick = e => {
    const newStack = viewStack.slice();
    const stackTop = newStack[newStack.length - 1];
    newStack.push({
      screen: NAV.BOARD_DETAIL,
      item: {
        d: taskCard.parentCard.parentCard.index,
        w: taskCard.parentCard.index,
        t: taskCard.index,
      },
      level: 't',
      zoomDelUID: stackTop.zoomDelUID,
    });
    Object.assign(viewState, {
      prevWindow: isLibrary ? NAV.LIBRARY_BOARD : NAV.BOARD,
      detailsTabIndex: 0,
      currentDeliverable: taskCard.parentCard.parentCard.index,
      currentWorkPackage: taskCard.parentCard.index,
      currentTask: taskCard.index,
    });
    if (viewStack === viewState.wbsStack) {
      viewState.wbsStack = newStack;
    } else {
      viewState.libStack = newStack;
    }

    dispatchUpdateViewState(viewState);
  };

  // const thisCard = useRef(null);

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
    // if (thisCard.current) {
    //   thisCard.addEventListener('dblclick', function (e) {
    //     console.log(`double clicked: ${}`);
    //   });
    // }
  });

  const stackTop = viewStack[viewStack.length - 1];
  const isSelected =
    'pdw'.includes(stackTop.level) &&
    stackTop.item.d === taskCard.parentCard.parentCard.index &&
    stackTop.item.w === taskCard.parentCard.index &&
    stackTop.item.t === taskCard.index;
  const isStarted = actualStartDate !== '';
  const isCompleted = actualEndDate !== '';
  const classes = useStyles({ thin, cardUUID: taskCard.uuid, isSelected, isStarted, isCompleted });

  const dateStats = utils.getDateStats(expectedEndDate);
  const { daysLeft, isLate, dueSoon } = dateStats;
  let { lateIconColor, tooltipTitle } = utils.getDateStatsInfo(isLate, dueSoon, daysLeft);

  // const needsDueDate = actualStartDate !== '' && !expectedEndDate;
  // const showCalIcon =
  //   status !== STATUS.NOT_NEEDED &&
  //   status !== STATUS.DONE &&
  //   (isLate || dueSoon || needsDueDate);

  // if not started - add exp start date with tooltip
  // if started - show exp end date (these get flagged if not set)
  // if done - show act end date
  let itemDate = null;
  switch (status) {
    case STATUS.NOT_NEEDED:
      break;
    case STATUS.NOT_STARTED:
      const startDateStats = utils.getDateStats(expectedStartDate);
      const startDateStatsInfo = utils.getDateStatsInfo(
        startDateStats.isLate,
        startDateStats.dueSoon,
        startDateStats.daysLeft,
        false,
      );
      itemDate = expectedStartDate !== '' ? `${moment(expectedStartDate).format('MM/DD/YYYY')}` : 'xx/xx/xx';
      tooltipTitle = expectedStartDate !== '' ? startDateStatsInfo.tooltipTitle : 'start date not set';
      lateIconColor = itemDate === 'xx/xx/xx' ? grey[500] : startDateStatsInfo.lateIconColor;
      break;
    case STATUS.DONE:
      itemDate = actualEndDate !== '' ? `${moment(actualEndDate).format('MM/DD/YYYY')}` : 'xx/xx/xx';
      tooltipTitle = 'actual end date';
      lateIconColor = itemDate === 'xx/xx/xx' ? grey[500] : '#444';
      break;
    case STATUS.IN_PROGRESS:
    case STATUS.IN_REVIEW:
    case STATUS.BLOCKED:
    default:
      itemDate = expectedEndDate !== '' ? `${moment(expectedEndDate).format('MM/DD/YYYY')}` : 'xx/xx/xx';
      // const weekInFuture = moment(expectedEndDate).isAfter(moment().add(6, 'days'))
      tooltipTitle =
        expectedEndDate !== ''
          ? // ? weekInFuture
            tooltipTitle
          : // : tooltipTitle
            'estimated end date not set';
      lateIconColor = itemDate === 'xx/xx/xx' ? grey[500] : lateIconColor;
  }

  // let lateIconColor = grey[500];
  // let tooltipTitle = 'Needs expected end date';
  // if (!needsDueDate) {
  //   lateIconColor = dueSoon ? yellow[800] : red[500];
  //   tooltipTitle =
  //     daysLeft > 0
  //       ? `${Math.abs(daysLeft)} day${daysLeft > 1 ? 's' : ''} to go`
  //       : daysLeft === 0
  //       ? `due today`
  //       : `${Math.abs(daysLeft)} day${
  //           daysLeft < -1 ? 's' : ''
  //         } overdue`;
  // }

  const avatarId = assignedTo ? assignedTo.substring(0, 1).toUpperCase() : null;
  let collegueColor;
  if (assignedTo) {
    if (userPrefs.collegues[assignedTo]) {
      collegueColor = userPrefs.collegues[assignedTo].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: assignedTo,
        color: collegueColor,
      };
    }
  }
  let MENU_ACTIONS = [
    {
      name: 'Open',
      action: `task.${taskCard.index}.Open`,
      icon: <OpenInNewIcon />,
    },
    {
      name: 'Rename',
      action: `task.${taskCard.index}.Edit`,
      icon: <EditIcon />,
    },
    {
      name: 'Delete',
      action: `task.${taskCard.index}.Delete`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Cut',
      action: `task.${taskCard.index}.Cut`,
      icon: <ReplyIcon />,
    },
    {
      name: 'Copy',
      action: `task.${taskCard.index}.Copy`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Paste',
      action: `task.${taskCard.index}.Paste`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'to WP',
      action: `task.${taskCard.index}.ToWP`,
      icon: <PublishIcon />,
      tooltip: 'convert this task to a task group',
    },
  ];
  if (viewState.curWindow === NAV.LIBRARY_BOARD) {
    MENU_ACTIONS.splice(6, 1); // remove to WP option if called from Library view
  }

  if (!viewState.copyContent || viewState.copyType !== 'task') {
    MENU_ACTIONS.splice(5, 1); // remove paste option if no copy content
  }
  // if (!inWorkflow && taskCard.status !== STATUS.IN_PROGRESS) {
  //   MENU_ACTIONS.push({
  //     name: taskCard.task.pinned ? 'Unpin' : 'Pin',
  //     action: `task.${taskCard.index}.Pin`,
  //     icon: taskCard.task.pinned ? <VisibilityOffIcon /> : <VisibilityOffIcon />,
  //   });
  // }
  const showPercentage = percentageComplete > 0 && actualEndDate === '';

  const handleSelection = (taskCard, e) => {
    if (e) e.stopPropagation(); // Necessary to avoid event bubbling up to parent column
    const newStack = viewStack.slice();
    newStack[newStack.length - 1].item = {
      // for now this is a no-op as the only place this is called is from a NAV.BOARD
      d: taskCard.parentCard.parentCard.index,
      w: taskCard.parentCard.index,
      t: taskCard.index,
    };
    Object.assign(viewState, {
      currentDeliverable: taskCard.parentCard.parentCard.index,
      currentWorkPackage: taskCard.parentCard.index,
      currentTask: taskCard.index,
      [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
    });
    dispatchUpdateViewState(viewState);
    // setSelectedItem(card);
  };

  const emptyAdvice = adviceComponent ? utils.isInfoEmpty(adviceComponent.advice) : false;
  // console.log('card', taskCard.uuid);
  return (
    <Draggable
      key={`wc-${taskCard.uuid}`} // This is not ideal and it's not a long term solution but creating this since we don't have unique IDs available on cards.
      draggableId={taskCard.uuid}
      index={taskCard.index}
      shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <div onDoubleClick={handleDoubleClick}>
          <Card
            key={`card-${taskCard.uuid}`}
            id={taskCard.uuid}
            ref={dragProvided.innerRef}
            onClick={e => handleSelection(taskCard, e)}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            className={`${isSelected ? classes.selected : ''}
            ${classes.card} ${actualEndDate !== '' ? classes.completed : ''} ${
              actualStartDate !== '' ? classes.started : ''
            }`}
            variant={isSelected ? 'outlined' : 'elevation'}
          >
            {thin ? (
              <CardContent className={classes.cardContent}>
                <div className={classes.nameRow}>
                  {refId && (
                    <Tooltip title={'unique ID'}>
                      <div className={classes.thinChipContainer}>
                        <Chip label={refId} className={classes.chip} />
                      </div>
                    </Tooltip>
                  )}
                  <Typography variant="body1" component="h2" className={classes.thinName} align="left">
                    {name}
                  </Typography>
                </div>
                <div className={classes.rightSideStuff}>
                  {isPriority && <PriorityIcon />}
                  {showPercentage && (
                    <Typography variant="overline">{`${percentageComplete.toFixed(0)}%`}</Typography>
                  )}
                  {!inWorkflow && taskCard.status !== STATUS.IN_PROGRESS && (
                    <PinButton
                      handleClick={() => {
                        handleMenu(`task.${taskCard.index}.Pin`);
                      }}
                      size="medium"
                      color={taskCard.pinned ? '#AAA' : '#AAA8'}
                      bgdColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed
                          : theme.palette.workCard.notStarted
                      }
                      hoverColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed + 'E0'
                          : theme.palette.workCard.notStarted + 'E0'
                      }
                      borderColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed
                          : theme.palette.workCard.notStarted
                      }
                      tooltip={taskCard.pinned ? 'remove pin' : 'pin this task'}
                      containerClassName={classes.pinButton}
                      solid={taskCard.pinned}
                    />
                  )}
                  {avatarId ? (
                    <Tooltip title={assignedTo} className={classes.assignedToTooltip}>
                      <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                        <Typography className={classes.assignedToText}>{avatarId}</Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                      <Person className={classes.notAssignedTo} />
                    </div>
                  )}
                  <StatusButton taskCard={taskCard} />
                  {isSelected && (
                    <div className={classes.thinActionMenu}>
                      <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />
                    </div>
                  )}
                </div>
              </CardContent>
            ) : (
              <Fragment>
                <CardContent className={classes.cardContent}>
                  <div className={classes.titleContents}>
                    <Typography variant="body2" className={classes.nameText} component="h3">
                      {name}
                    </Typography>
                  </div>
                  {isSelected && <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} />}
                </CardContent>
                <CardActions className={classes.cardActions}>
                  {refId && (
                    <div className={classes.chipContainer}>
                      <Chip label={refId} className={classes.chip} />
                    </div>
                  )}
                  {showPercentage && (
                    <Typography variant="overline">{`${percentageComplete.toFixed(0)}%`}</Typography>
                  )}
                  {/* {showCalIcon && (
                    <Tooltip
                      title={tooltipTitle}
                      className={classes.assignedToTooltip}
                    >
                      <DateRange style={{ color: lateIconColor }} />
                    </Tooltip>
                  )} */}
                  {isPriority && <PriorityIcon />}
                  {!inWorkflow && taskCard.status !== STATUS.IN_PROGRESS && (
                    <PinButton
                      handleClick={() => {
                        handleMenu(`task.${taskCard.index}.Pin`);
                      }}
                      size="medium"
                      color={taskCard.pinned ? '#AAA' : '#AAA8'}
                      bgdColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed
                          : theme.palette.workCard.notStarted
                      }
                      hoverColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed + 'E0'
                          : theme.palette.workCard.notStarted + 'E0'
                      }
                      borderColor={
                        taskCard.status === STATUS.DONE
                          ? theme.palette.workCard.completed
                          : theme.palette.workCard.notStarted
                      }
                      tooltip={taskCard.pinned ? 'remove pin' : 'pin this task'}
                      containerClassName={classes.pinButton}
                      solid={taskCard.pinned}
                    />
                  )}
                  {avatarId ? (
                    <Tooltip title={assignedTo} className={classes.assignedToTooltip}>
                      <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                        <Typography className={classes.assignedToText}>{avatarId}</Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                      <Person className={classes.notAssignedTo} />
                    </div>
                  )}
                </CardActions>
              </Fragment>
            )}
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    updateViewState,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
    saveCurrentProject,
    rollUpDates,
  },
)(DraggableTaskCard);
