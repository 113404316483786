import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputField } from './styled_components/InputField';
import Backdrop from '@material-ui/core/Backdrop';
import { DeleteOutlined } from '@material-ui/icons';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
import { dispatchSetGroup, dispatchUpdateViewState } from '../data/actions/userInterface';
import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';

const userTableHeadCells = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    className: 'stringCell',
  },
  {
    id: 'admin',
    numeric: false,
    disablePadding: false,
    label: 'Admin',
    className: 'stringCell',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
    className: 'stringCell',
  },
];

const groupStyles = makeStyles(theme => ({
  container: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '0px',
    // marginTop: '0px'
    // marginRight: '50px',
    paddingRight: '50px',
  },
  waitingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: '100%',
    // width: '100%',
    // zIndex: 5,
    backgroundColor: '#40404010',
  },
  waiting: {
    margin: 'auto',
  },
  header: {
    paddingBottom: '10px',
  },
  formSectionContainer: {
    flexDirection: 'column',
    margin: '20px 0',
    display: 'flex',
  },
  formSection: {
    flexDirection: 'row',
    margin: '0px 0',
    display: 'flex',
    backgroundColor: 'white',
  },
  stringCell: {},
  input: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '5px',
  },
  dateMessage: {
    alignSelf: 'flex-start',
    fontSize: '.8rem',
    color: '#888',
  },
  loadingText: {
    fontSize: '1em',
    fontWeight: 500,
    color: '#60606080',
  },
  loadingBackdrop: {
    flexDirection: 'column',
    margin: '0px 0',
    display: 'flex',
    alignItems: 'center',
  },
  tableDiv: {
    height: 'calc(100vh - 430px)', // required for scroll bars to work
    marginTop: 40,
    overflowY: 'scroll',
    backgroundColor: 'white',
  },
  buttonContainer: {
    width: 120,
    height: 56,
  },
  input: {
    minWidth: 400,
    marginRight: '10px',
  },
  inputExists: {
    minWidth: 400,
    backgroundColor: '#FF000010',
    marginRight: '10px',
  },
  titlecontainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titletext: {
    fontSize: '2.1em',
    fontWeight: 600,
    color: '#333B',
    marginBottom: '5px',
  },
  grouptext2: {
    fontSize: '1.2em',
    fontWeight: 500,
    color: '#60606080',
    // marginBottom: '5px',
  },
  grouptext3: {
    fontSize: '1.2em',
    fontWeight: 500,
    color: '#60606080',
    marginBottom: '40px',
  },
}));
const showWaitingIndicator = styleName => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}
      invisible={true}
      onClick={() => {}}
    >
      <div className={groupStyles.loadingBackdrop}>
        <Typography component="h2" variant="h5" className={groupStyles.loadingText}>
          One moment, loading your group...
        </Typography>
        <div>
          <CircularProgress
            style={{
              color: '#52d1b570',
              marginTop: '25px',
              alignText: 'center',
              // margin: 'auto',
            }}
            size={60}
          />
        </div>
      </div>
    </Backdrop>
  );
};

const DATE_FORMAT = 'YYYY/MM/DD';

// Droppable container for Dragable WP cards
const GroupPage = ({
  firebase,
  user,
  productPlans,
  group,
  viewState,
  dispatchSetGroup,
  dispatchUpdateViewState,
}) => {
  const classes = groupStyles();
  const [showWaiting, setShowWaiting] = React.useState(false);
  const [memberEmail, setMemberEmail] = React.useState('');
  const [memberTags, setMemberTags] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState('desc'); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = React.useState('email');
  const [memberSelectedForDelete, setRowSelectedForDelete] = React.useState({});

  const handleSave = (value, name) => {};

  const handleUpdate = (e, name) => {};
  const handleSubmit = () => {};

  if (!group) {
    // if on this page but group not set, jump to dashboard
    viewState = Object.assign(viewState, {
      curWindow: NAV.DASHBOARD,
    });
    dispatchUpdateViewState(viewState);
    return null;
  }
  const createNewGroupMember = (userEmail, tags) => {
    group.members.push(userEmail);
    group.info[userEmail] = { tags: tags };
    dispatchSetGroup(group, 'update', firebase);
  };

  const removeGroupMember = userEmail => {
    const removedMemberInd = group.members.findIndex(m => m === userEmail);
    if (removedMemberInd >= 0) {
      group.members.splice(removedMemberInd, 1);
      delete group.members[userEmail];
    }
    dispatchSetGroup(group, 'update', firebase);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, property, order) => {
    const stabilizedThis = array.map((el, index) => [el, index]); // ensure complete data for sort algorithm
    const comparator = getComparator(order, property);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const sortTable = (property, flipOrder = true) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    const newOrder = flipOrder ? (isAsc ? 'desc' : 'asc') : sortOrder;
    const newArray = stableSort(memberList, property, newOrder);

    setOrderBy(property);
    setSortOrder(newOrder);
    // setSubscriptions(newArray);
    setSelectedRow(0);
  };

  const renderTableHeadCell = props => {
    const { classes, order, orderBy, headCells } = props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell, index) => {
            const tableCellClassName = classes.stringCell;
            return (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className={tableCellClassName}
              >
                {headCell.sortable === undefined || headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={event => {
                      sortTable(headCell.id); // headCell.id holds the property name
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  // build subscription list array for list
  const memberList = [];
  let numAdmins = 0;
  if (group.members) {
    group.members.forEach(member => {
      if (group.info[member].isAdmin) numAdmins++;
      memberList.push({
        email: member,
        role: group.info[member].isAdmin ? 'X' : '',
        tags: group.info[member].isAdmin ? '' : group.info[member].tags,
      });
    });
  }

  let emailExists = false;
  if (memberList) {
    if (memberEmail) {
      if (memberList.findIndex(m => m.email.trim() === memberEmail.trim()) >= 0) emailExists = true;
    }
  }
  const buttonDisabled = emailExists || !memberEmail || !memberEmail || memberEmail === '';
  const emailInputProps = emailExists ? { className: classes.inputExists } : { className: classes.input };

  return (
    <div className={classes.container}>
      {showWaiting ? (
        showWaitingIndicator(classes.waitingContainer)
      ) : (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.formSectionContainer}>
              <div className={classes.titlecontainer}>
                <Typography component="h2" variant="h2" className={classes.titletext}>
                  {`Your Group Members`}
                </Typography>
                {/* <Typography component="h6" variant="h6" className={classes.grouptext2}>
                  {``}
                </Typography> */}
                <Typography component="h6" variant="h6" className={classes.grouptext3}>
                  {`${group.name} - ID: ${group.id}`}
                </Typography>
              </div>
              <div className={classes.formSection}>
                <InputField
                  name="email"
                  value={memberEmail}
                  label="New Account Email:"
                  handleBlur={newEmail => {
                    setMemberEmail(newEmail.trim());
                  }}
                  // inputProps={{className: classes.inputExists}}
                  inputProps={emailInputProps}
                />
                <InputField
                  name="tags"
                  value={memberTags}
                  label="Tags:"
                  handleBlur={tags => {
                    setMemberTags(tags.trim());
                  }}
                  inputProps={{ className: classes.input }}
                />
                <Button
                  onClick={() => {
                    if (buttonDisabled) return;

                    const m = memberList.find(m => m.email === memberEmail);
                    if (m) {
                      alert(`member ${memberEmail} already exists`);
                    } else {
                      createNewGroupMember(memberEmail.trim(), memberTags.trim());
                      // setMembersList(memberList)    // forces rerender
                      setMemberEmail('');
                      setMemberTags('');
                      // setShowWaiting(true);
                    }
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={buttonDisabled}
                  size="small"
                  className={classes.buttonContainer}
                  // style={{
                  //   backgroundColor: '#00afa9',
                  //   color: 'white',
                  //   fontWeight: 600,
                  // }}
                >
                  Add New Member
                </Button>
              </div>
            </div>
          </form>
          {memberList && (
            <Fragment>
              <div className={classes.tableDiv}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                  >
                    {renderTableHeadCell({
                      headCells: userTableHeadCells,
                      classes: classes,
                      order: sortOrder,
                      orderBy: orderBy,
                      isUsersTable: true,
                    })}
                    <TableBody>
                      {memberList.map((member, index) => {
                        return (
                          <TableRow
                            className={index === selectedRow ? classes.selectedRow : classes.tableRow}
                            // onClick={event => handleClickOnUser(event, index)}
                            tabIndex={-1}
                            key={member.email}
                          >
                            <TableCell component="th" className={classes.userEmail} id={'email'} scope="row">
                              {member.email}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {member.role}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {member.tags}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {(numAdmins > 1 || member.role.trim() !== 'X') && (
                                <DeleteOutlined
                                  onClick={() => {
                                    setRowSelectedForDelete(member);
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Dialog
                open={!!memberSelectedForDelete.email}
                onClose={() => {
                  setRowSelectedForDelete(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`Delete member`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Press Delete to confirm removal of member ${memberSelectedForDelete.email} from the group`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setRowSelectedForDelete({});
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      removeGroupMember(memberSelectedForDelete.email);
                      setRowSelectedForDelete({});
                    }}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
export default connect(
  state => {
    return {
      firebase: state.firebase,
      user: state.user,
      viewState: state.viewState,
      productPlans: state.productPlans,
      group: state.group,
    };
  },
  {
    dispatchSetGroup,
    dispatchUpdateViewState,
  },
)(GroupPage);
