import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {
  seenProjectDoubleClick: false,
  seenWorkPackageDoubleClick: false,
};

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.onboarding) {
        const newState = Object.assign({ ...INITIAL_STATE }, action.payload.onboarding);
        return newState;
      }
      return state;

    case ACTION.SET_ONBOARDING:
      return action.onboarding;

    default:
      return state;
  }
}
