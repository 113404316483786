import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputField } from '../components/styled_components/InputField';
import Backdrop from '@material-ui/core/Backdrop';
import { DeleteOutlined } from '@material-ui/icons';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';

import * as USER from '../constants/user';

const userTableHeadCells = [
  {
    id: 'userId',
    numeric: false,
    disablePadding: false,
    label: 'User ID',
    className: 'stringCell',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    className: 'stringCell',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Plan',
    className: 'stringCell',
  },
  {
    id: 'expiry',
    numeric: false,
    disablePadding: false,
    label: 'Expires',
    className: 'stringCell',
  },
  {
    id: 'expired',
    numeric: false,
    disablePadding: false,
    label: 'Expired',
    className: 'stringCell',
  },
];

const adminStyles = makeStyles(theme => ({
  container: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    // marginTop: '0px'
  },
  waitingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: '100%',
    // width: '100%',
    // zIndex: 5,
    backgroundColor: '#40404010',
  },
  waiting: {
    margin: 'auto',
  },
  header: {
    paddingBottom: '10px',
  },
  formSectionContainer: {
    flexDirection: 'column',
    margin: '20px 0',
    display: 'flex',
  },
  formSection: {
    flexDirection: 'row',
    margin: '0px 0',
    display: 'flex',
  },
  input: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '5px',
  },
  dateMessage: {
    alignSelf: 'flex-start',
    fontSize: '.8rem',
    color: '#888',
  },
  loadingText: {
    fontSize: '1em',
    fontWeight: 500,
    color: '#60606080',
  },
  loadingBackdrop: {
    flexDirection: 'column',
    margin: '0px 0',
    display: 'flex',
    alignItems: 'center',
  },
  tableDiv: {
    height: 'calc(100vh - 300px)', // required for scroll bars to work
    marginTop: 40,
    overflowY: 'scroll',
  },
  buttonContainer: {
    width: 120,
    height: 56,
  },
  input: {},
  inputExists: {
    backgroundColor: '#FF000010',
  },
}));
const showWaitingIndicator = styleName => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}
      invisible={true}
      onClick={() => {}}
    >
      <div className={adminStyles.loadingBackdrop}>
        <Typography component="h2" variant="h5" className={adminStyles.loadingText}>
          One moment, loading existing subscriptions...
        </Typography>
        <div>
          <CircularProgress
            style={{
              color: '#52d1b570',
              marginTop: '25px',
              // marginLeft: '25px',
              alignText: 'center',
              // margin: 'auto',
            }}
            size={60}
          />
        </div>
      </div>
    </Backdrop>
  );
};

const DATE_FORMAT = 'YYYY/MM/DD';
const TYPE = {
  PARTNER_PLAN: 'Partner Plan',
  SPECIAL_PLAN: 'Special Plan',
  DEMO_PLAN: 'Demo Plan',
  BASIC_PLUS_PLAN: 'Basic+ Plan',
  PARTNER_PLUS_PLAN: 'Partner+ Plan',
  SPECIAL_PLUS_PLAN: 'Special+ Plan',
  DEMO_PLUS_PLAN: 'Demo+ Plan',
};

const typeArray = [];
Object.keys(TYPE).forEach(key => {
  typeArray.push(TYPE[key]);
});
// Droppable container for Dragable WP cards
const AdminPage = ({ firebase, user, productPlans }) => {
  const classes = adminStyles();
  const [showWaiting, setShowWaiting] = React.useState(false);
  const [accountType, setAccountType] = React.useState(TYPE.PARTNER_PLAN);
  const [accountEmail, setAccountEmail] = React.useState('');
  const [accountUserId, setAccountUserId] = React.useState('');
  // const [accountEmail, setAccountEmail] = React.useState("smason.van@gmail.com");
  // const [accountUserId, setAccountUserId] = React.useState("h4AG1hP261Z7rKho3H5KR1vLo4d2"); // smason.test50&yahoo.com
  const [subscriptions, setSubscriptions] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState('desc'); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = React.useState('email');
  const [rowSelectedForDelete, setRowSelectedForDelete] = React.useState({});

  const handleSave = (value, name) => {
    //   setFieldValue(name, value);
    //   // if(name === "assignedTo" && item[name] === '' && item.tasks) {
    //   //   item.tasks.forEach(task => {
    //   //     if(!task.assignedTo || task.assignedTo === '') {
    //   //       task.assignedTo = value;
    //   //     }
    //   //   })
    //   // }
    //   item[name] = value;
    //   saveCurrentProject();
  };

  const handleUpdate = (e, name) => {
    //   const value = e.target.value;
    //   setFieldValue(name, value);
    //   item[name] = value;
    //   setFieldTouched(name, true, false);
    //   if (
    //     name === 'actualStartDate' ||
    //     name === 'actualEndDate' ||
    //     name === 'expectedStartDate' ||
    //     name === 'expectedEndDate'
    //   ) {
    //     rollUpDates();
    //   }
    //   saveCurrentProject();
  };
  const handleSubmit = () => {};

  const createNewUserSubscription = (userId, userEmail, accountType) => {
    const basicPlan = productPlans.find(element => element.name === USER.BASIC_PLAN_NAME);

    const customerDoc = {
      email: userEmail,
    };
    // const expiryDateStr = moment().add(1, 'year').format(DATE_FORMAT)
    const expiryDateStr = '2100/01/01';
    const subscriptionDoc = {
      status: 'active',
      manual: 'true',
      planId: basicPlan.id,
      planName: accountType,
      expiry: expiryDateStr,
    };
    // set up new "customer" with manual subscription for accountType
    firebase.db
      .collection('customers')
      .doc(userId)
      .set(customerDoc)
      .then(docRef => {
        firebase.db
          .collection('customers')
          .doc(userId)
          .collection('subscriptions')
          .doc('manual')
          .set(subscriptionDoc)
          .then(sdocRef => {
            subscriptions.push({
              userId: userId,
              email: userEmail,
              type: accountType,
              expiry: expiryDateStr,
              expired: '',
            });
            setSubscriptions(subscriptions);
            setShowWaiting(false);
            sortTable(orderBy, false);
            setAccountUserId('');
            setAccountEmail('');
          });
      })
      .catch(error => {
        alert(`Error creating new manual subscription: ${error}`);
      });
  };

  const removeUserSubscription = userId => {
    // remove "customer" with manual subscription for accountType
    firebase.db
      .collection('customers')
      .doc(userId)
      .collection('subscriptions')
      .doc('manual')
      .delete()
      .then(result => {
        firebase.db
          .collection('customers')
          .doc(userId)
          .delete()
          .then(docRef => {
            const curInd = subscriptions.findIndex(element => element.userId === userId);
            subscriptions.splice(curInd, 1);
            setSubscriptions(subscriptions);
            setShowWaiting(false);
            sortTable(orderBy, false);
            setAccountUserId('');
            setAccountEmail('');
          });
      })
      .catch(error => {
        alert(`Error removing manual subscription: ${error}`);
      });
  };

  const getFirebaseSubscriptions = () => {
    return new Promise((resolve, reject) => {
      // read all current subscriptions in Firebase
      const customerIDs = [];
      firebase.db
        .collection('customers')
        .get()
        .then(custSnapshot => {
          custSnapshot.forEach(doc => {
            const customerData = doc.data();
            customerIDs.push({ userId: doc.id, email: customerData.email });
          });
          if (customerIDs.length === 0) {
            resolve([]);
            return;
          }
          return customerIDs;
        })
        .then(customerIDList => {
          const getSubPromises = [];

          customerIDList.forEach(customerData => {
            getSubPromises.push(
              firebase.db
                .collection('customers')
                .doc(customerData.userId)
                .collection('subscriptions')
                .where('status', 'in', ['trialing', 'active'])
                .get()
                .then(subSnapshot => {
                  const results = [];
                  subSnapshot.forEach(doc => {
                    const subID = doc.id;
                    const userSubscriptionData = doc.data();
                    if (subID === 'manual') {
                      const expiryDate = userSubscriptionData.expiry
                        ? moment(userSubscriptionData.expiry)
                        : moment('2100/01/01');
                      const subExpired = expiryDate.isBefore(moment());
                      const expiryStr = expiryDate.format(DATE_FORMAT);
                      results.push({
                        userId: customerData.userId,
                        email: customerData.email,
                        type: userSubscriptionData.planName,
                        expiry: expiryStr,
                        expired: subExpired ? 'true' : '',
                      });
                    } else {
                      const subscribedProductId = userSubscriptionData.items[0].plan.product;
                      const subPlanIndex = productPlans.findIndex(
                        element => element.id === subscribedProductId,
                      );
                      const subPlan = subPlanIndex >= 0 ? productPlans[subPlanIndex].name : 'unknown';
                      const expiryDate = moment.unix(userSubscriptionData.current_period_end.seconds);
                      let subExpired = expiryDate.isBefore(moment());
                      // subExpired = true
                      const expiryStr = expiryDate.format(DATE_FORMAT);
                      results.push({
                        userId: customerData.userId,
                        email: customerData.email,
                        type: subPlan,
                        expiry: expiryStr,
                        expired: subExpired ? 'true' : '',
                      });
                    }
                  });
                  return results[0] ? results[0] : null;
                }),
            );
          });
          Promise.all(getSubPromises).then(results => {
            const nonNullResults = [];
            results.forEach(result => {
              if (result) nonNullResults.push(result);
            });
            resolve(nonNullResults);
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, property, order) => {
    const stabilizedThis = array.map((el, index) => [el, index]); // ensure complete data for sort algorithm
    const comparator = getComparator(order, property);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const sortTable = (property, flipOrder = true) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    const newOrder = flipOrder ? (isAsc ? 'desc' : 'asc') : sortOrder;
    const newArray = stableSort(subscriptions, property, newOrder);

    setOrderBy(property);
    setSortOrder(newOrder);
    setSubscriptions(newArray);
    setSelectedRow(0);
  };

  const renderTableHeadCell = props => {
    const { classes, order, orderBy, headCells, isUsersTable } = props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell, index) => {
            const tableCellClassName = classes.stringCell;
            // switch (headCell.className) {
            //   default:
            //     tableCellClassName = classes.stringCell;
            // }
            return (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className={tableCellClassName}
              >
                {headCell.sortable === undefined || headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={event => {
                      sortTable(headCell.id); // headCell.id holds the property name
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  // build subscription list array for list
  const subscriptionList = [];
  const numPlans = Object.keys(productPlans).length;
  if (subscriptions === null) {
    if (!showWaiting) setShowWaiting(true);
    if (numPlans > 0) {
      // retrieve manual subscriptions from Firebase
      getFirebaseSubscriptions()
        .then(result => {
          setSubscriptions(result);
          setShowWaiting(false);
        })
        .catch(error => {
          alert(`error retrieving firebase subscriptions: ${error}`);
          setSubscriptions([]);
        });
    }
  } else {
    if (subscriptions) {
      Object.keys(subscriptions).forEach(key => {
        subscriptionList.push(subscriptions[key]);
      });
    }
  }
  let emailExists = false;
  let userIDExists = false;
  if (subscriptions) {
    if (accountEmail) {
      if (subscriptions.findIndex(element => element.email.trim() === accountEmail.trim()) >= 0)
        emailExists = true;
    }
    if (accountUserId) {
      if (subscriptions.findIndex(element => element.userId.trim() === accountUserId.trim()) >= 0)
        userIDExists = true;
    }
  }
  const buttonDisabled =
    emailExists ||
    userIDExists ||
    !subscriptions ||
    !accountUserId ||
    !accountEmail ||
    !accountType ||
    accountUserId.length !== 28 ||
    accountEmail === '';
  const emailInputProps = emailExists ? { className: classes.inputExists } : { className: classes.input };
  const userIdInputProps = userIDExists ? { className: classes.inputExists } : { className: classes.input };
  return (
    <div className={classes.container}>
      {showWaiting ? (
        showWaitingIndicator(classes.waitingContainer)
      ) : (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.formSectionContainer}>
              <div className={classes.formSection}>
                <InputField
                  name="email"
                  value={accountUserId}
                  label="Firebase User ID:"
                  handleBlur={newUserId => {
                    setAccountUserId(newUserId.trim());
                  }}
                  // inputProps={userIDExists ? {className: classes.inputExists} : {className: classes.input}}
                  inputProps={userIdInputProps}
                />
                <InputField
                  name="email"
                  value={accountEmail}
                  label="New Account Email:"
                  handleBlur={newEmail => {
                    setAccountEmail(newEmail.trim());
                  }}
                  // inputProps={{className: classes.inputExists}}
                  inputProps={emailInputProps}
                />

                <FormControl variant="outlined" className={classes.input}>
                  <InputLabel id="plan-type-label">Plan Type</InputLabel>
                  <Select
                    labelId="plan-type-label"
                    id="priority-select"
                    name="isPriority"
                    classes={{ root: classes.menuItem }}
                    disabled={false}
                    value={accountType}
                    onChange={e => {
                      setAccountType(e.currentTarget.innerText);
                    }}
                    variant="outlined"
                    label="Plan Type"
                  >
                    <MenuItem className={classes.menuItem} value={TYPE.PARTNER_PLAN}>
                      {TYPE.PARTNER_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.SPECIAL_PLAN}>
                      {TYPE.SPECIAL_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.DEMO_PLAN}>
                      {TYPE.DEMO_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.BASIC_PLUS_PLAN}>
                      {TYPE.BASIC_PLUS_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.PARTNER_PLUS_PLAN}>
                      {TYPE.PARTNER_PLUS_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.SPECIAL_PLUS_PLAN}>
                      {TYPE.SPECIAL_PLUS_PLAN}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TYPE.DEMO_PLUS_PLAN}>
                      {TYPE.DEMO_PLUS_PLAN}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    if (buttonDisabled) return;

                    if (subscriptions && subscriptions[accountUserId]) {
                      alert(`subscription for ${accountUserId} already exists`);
                    } else {
                      createNewUserSubscription(
                        accountUserId.trim(),
                        accountEmail.trim(),
                        accountType.trim(),
                      );
                      setShowWaiting(true);
                    }
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={buttonDisabled}
                  size="small"
                  className={classes.buttonContainer}
                  // style={{
                  //   backgroundColor: '#00afa9',
                  //   color: 'white',
                  //   fontWeight: 600,
                  // }}
                >
                  Add Plan
                </Button>
              </div>
            </div>
          </form>
          {subscriptions && (
            <Fragment>
              <div className={classes.tableDiv}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                  >
                    {renderTableHeadCell({
                      headCells: userTableHeadCells,
                      classes: classes,
                      order: sortOrder,
                      orderBy: orderBy,
                      isUsersTable: true,
                    })}
                    <TableBody>
                      {subscriptions.map((row, index) => {
                        return (
                          <TableRow
                            className={index === selectedRow ? classes.selectedRow : classes.tableRow}
                            // onClick={event => handleClickOnUser(event, index)}
                            tabIndex={-1}
                            key={row.email}
                          >
                            <TableCell component="th" className={classes.userEmail} id={'userId'} scope="row">
                              {row.userId}
                            </TableCell>
                            <TableCell component="th" className={classes.userEmail} id={'email'} scope="row">
                              {row.email}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {row.type}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {row.expiry}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {row.expired}
                            </TableCell>
                            <TableCell
                              className={classes.stringCell}
                              // align="right"
                            >
                              {row.type !== USER.BASIC_PLAN_NAME && (
                                <DeleteOutlined
                                  onClick={() => {
                                    setRowSelectedForDelete(row);
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Dialog
                open={!!rowSelectedForDelete.email}
                onClose={() => {
                  setRowSelectedForDelete(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`Delete subscription`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Press Delete to confirm removal of ${rowSelectedForDelete.type} for ${rowSelectedForDelete.email}`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setRowSelectedForDelete({});
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setRowSelectedForDelete({});
                      removeUserSubscription(rowSelectedForDelete.userId);
                    }}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
export default connect(state => {
  return {
    firebase: state.firebase,
    user: state.user,
    productPlans: state.productPlans,
  };
}, {})(AdminPage);
