import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Alert({ open = true, title, text, alertYesButton, answerYes, answerNo }) {
  // const [open, setOpen] = React.useState(false);

  const handleYes = () => {
    console.log(`Alert.handleYes`);
    let values = { text: 'Yes' };
    answerYes(values);
  };
  const handleNo = () => {
    console.log(`Alert.handleNo`);
    answerNo();
  };

  const handleClose = () => {
    console.log(`Alert.handleClose`);
    answerNo();
  };
  if (!alertYesButton) alertYesButton = 'none'; // map from old value scheme to new
  if (alertYesButton && alertYesButton === true) alertYesButton = 'yes';

  let buttons;
  switch (alertYesButton) {
    case 'yes':
      buttons = (
        <Fragment>
          <Button onClick={handleYes} color="secondary" variant="contained" autoFocus>
            Yes
          </Button>
          <Button variant="contained" onClick={handleNo} color="primary">
            Cancel
          </Button>
        </Fragment>
      );
      break;
    case 'ok':
      buttons = (
        <Button onClick={handleYes} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      );
      break;
    default:
    case 'none':
      buttons = (
        <Button onClick={handleNo} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      );
      break;
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>{buttons}</DialogActions>
      </Dialog>
    </div>
  );
}
