import * as ACTION from './actionTypes';

import { Client} from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication, EventType } from '@azure/msal-browser';
import { config } from '../../components/styled_components/MSAzureConfig';

// action creators

export const initMSAzureClientApp = () => {
  return (dispatch, getState) => {
    const { msAzureAPI } = getState();

    /**
    * Configuration object to be passed to MSAL instance on creation.
    * For a full list of MSAL.js configuration parameters, visit:
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
    */
    const msalClientApp = new PublicClientApplication({
      auth: {
        clientId: config.clientId,        // must match Azure project app/client id
        redirectUri: config.redirectUri,  // must match Azure project settings
        authority: 'https://login.microsoftonline.com/common', // both personal and organzational - for details hover over PublicClientApplication - must match Azure project settings
        // postLogoutRedirectUri: config.redirectUri,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      // system: {
      //   loggerOptions: {
      //       loggerCallback: (level, message, containsPii) => {
      //           if (containsPii) {
      //               return;
      //           }
      //           console.log(`MSAL app log msg: ${message}`);
      //       },
      //   }
      // },
    });

    // Check if there are already accounts in the browser session
    // If so, set the first account as the active account
    const accounts = msalClientApp.getAllAccounts();
    if (accounts && accounts.length > 0) {
      msalClientApp.setActiveAccount(accounts[0]);
    }
    msalClientApp.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // Set the active account - this simplifies token acquisition
        const authResult = event.payload;
        msalClientApp.setActiveAccount(authResult.account);
      }
    });;

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msalClientApp,
      {
        account: msalClientApp.getActiveAccount(),
        scopes: config.scopes,
        interactionType: InteractionType.Popup
      }
    );
    dispatch({
      type: ACTION.UPDATE_ONEDRIVE,
      data: {
        ...msAzureAPI, 
        msalClientApp: msalClientApp,
        authProvider: authProvider,  // supplied to MS Graph API Client when created (after signin, destroyed on signout??)
        initialized: true,
      },
    });
  };
}

export const getOneDriveFileList = (folderPath) => {
  return (dispatch, getState) => {
    const { msAzureAPI } = getState();
    console.log(`updating OneDrive file list for path "${folderPath}"`)
    const apiPath = !folderPath || folderPath === '' ? `/me/drive/root/children` : `/me/drive/root:/${folderPath}:/children`;
    msAzureAPI.graphAPIClient
      .api(apiPath)   // requires 'Files.Read.All' or 'Files... permissions in order to work
      .get()
      .then(fileList => {
        console.log(`file list contains ${fileList.value.length} items`);
        dispatch({
          type: ACTION.UPDATE_ONEDRIVE,
          data: {...msAzureAPI, oneDriveFileList: fileList.value, currentOneDriveFolder: folderPath},
        });  
      })
      .catch(err => {
        console.log(`Error encountered getting OneDrive directory contents for folderPath: ${folderPath}.  Error: ${err}`);
      });


  };
}

export const msAzureSignIn = () => {
  return (dispatch, getState) => {
    const { msAzureAPI } = getState();
    const loginProps = {
      scopes: config.scopes, 
      prompt: 'select_account' 
    };
    msAzureAPI.msalClientApp.loginPopup(loginProps)
    .then(response => {
      console.log(`MS signed in`);

      dispatch({
        type: ACTION.UPDATE_ONEDRIVE,
        data: {
          ...msAzureAPI, 
          signedIn: true,
          graphAPIClient: Client.initWithMiddleware({
            authProvider: msAzureAPI.authProvider
          }),
        },
      });
    })
    .catch(err => {
      console.log(`MS signin error: ${err}`);
    })
  }
};

export const msAzureSignOut = () => {
  return (dispatch, getState) => {
    const { msAzureAPI } = getState();
    msAzureAPI.msalClientApp.logoutPopup()
    .then(response => {
      console.log(`MS signed out`);
      dispatch({
        type: ACTION.UPDATE_ONEDRIVE,
        data: {
          ...msAzureAPI, 
          signedIn: false,
          graphClient: null,
          oneDriveFileList: null,
        },
      });  
    })
    .catch(err => {
      console.log(`MS signout error: ${err}`);
    })
  }
};


