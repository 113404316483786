import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography } from '@material-ui/core';

import DraftEditor from './styled_components/DraftEditor';

import { setInfoDialog } from '../data/actions/userInterface';

import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';
import * as constants from '../constants/app';

const useStyles = makeStyles(theme => ({
  dialog: {},
  dialogContent: {
    marginLeft: '10px',
    marginRight: '10px',
    overflowY: 'hidden',
    // backgroundColor: 'lightBlue',
  },
}));

const labelSet1 = {
  type: 'name',
  title: `Please choose a name for your new Group:`,
  label: `Group Name`,
  defaultInput: `type a name for your group here`,
  b1: 'Connect to Existing Group',
  b2: 'Create New Group',
};

const labelSet2 = {
  type: 'id',
  title: `Enter Existing Group Id:`,
  label: `Group Id`,
  defaultInput: `put existing Group Id here`,
  b1: 'Create New Group',
  b2: 'Connect to Exiting Group',
};

function InfoDialog({
  infoDialog, // parms from reducer
  setInfoDialog, // update reducer
}) {
  const inputs = infoDialog;
  const [editorState, setEditorState] = React.useState(inputs.content.description);
  const [tooltip, setToolTip] = React.useState(inputs.content.tooltip);
  const classes = useStyles();

  const onEditorChange = editorState => {
    setEditorState(editorState);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => {
          setInfoDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false} // turn off auto width logic and apply paperWidthFalse styling
        classes={{ paperWidthFalse: { width: '80hw', maxWidth: '1000px' } }}
        // style={{ { width: '800hw' }}
      >
        <div style={{ flex: 0, width: '70vw' }}>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <DialogTitle style={{ fontWeight: 700 }} id="alert-dialog-title">
              {inputs.title}
            </DialogTitle>
            <div
              style={{
                width: '180px',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                // backgroundColor: 'yellow',
                marginRight: '20px',
              }}
            >
              <Button
                onClick={() => {
                  setInfoDialog(null);
                }}
                variant="contained"
                color="primary"
              >
                {'Close'}
              </Button>
              {inputs.onSave && (
                <Button
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    inputs.onSave({ description: editorState, tooltip: tooltip });
                    setInfoDialog(null);
                  }}
                  disabled={tooltip === ''}
                  variant="contained"
                  color="secondary"
                >
                  {'save'}
                </Button>
              )}
            </div>
          </DialogActions>
          {inputs.onSave && (
            <div
              style={{
                marginLeft: '30px',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div>
                <Typography component="h7" variant="h7">
                  {'One-Line Summary (max 60 chars):'}
                </Typography>
              </div>
              <TextField
                autoFocus
                variant="outlined"
                // label={'short summary'}
                name={'short summary'}
                margin="dense" // tight box
                id="tooltip"
                type="text"
                multiline={false}
                value={tooltip}
                onClick={() => {}}
                inputProps={{ readOnly: !inputs.onSave }}
                onChange={e => {
                  setToolTip(e.target.value.substring(0, 50));
                }}
                style={{ marginLeft: '5px', marginRight: '35px', marginBottom: '30px', width: '400px' }}
                className={classes.nameField}
              />
            </div>
          )}
          <div style={{ flexDirection: 'column' }}>
            <Typography component="h7" variant="h7" style={{ marginLeft: '30px', marginTop: '30px' }}>
              {inputs.onSave ? 'Describe the Work to be Done:' : 'Description:'}
            </Typography>
            <DialogContent className={classes.dialogContent}>
              <DraftEditor
                value={inputs.content.description}
                saveOnChange={true} // triggers on change
                onChange={onEditorChange}
                field={'description'}
                fixedHeight={inputs.onSave ? '50vh' : '65vh'}
                readOnly={!inputs.onSave}
              />
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default connect(
  state => {
    return {
      infoDialog: state.infoDialog,
    };
  },
  {
    setInfoDialog,
  },
)(InfoDialog);
