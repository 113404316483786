import * as ACTION from '../actions/actionTypes';
import * as schema from '../projectSchema.js';

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.projects) {
        let updatedProjects = [];
        action.payload.projects.forEach(project => {
          if (!project.library) {
            schema.migrateProjectSchema(project);
            delete project.corruptData;
            updatedProjects.push(project);
          }
        });
        return updatedProjects;
      }
      return state;

    case ACTION.SET_USER_INFO:
    case ACTION.UPDATE_PROJECTS:
    case ACTION.LOAD_USER_DOCS:
      return action.projects;

    case ACTION.CLEAR_USER_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
}
