import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// import Tooltip from '@material-ui/core/Tooltip';

import * as USER from '../constants/user';
import { LiveTvRounded } from '@material-ui/icons';

const LEFT_COLUMN_WIDTH_PERCENT = '50%';
const FONT_COLOR = '#777';
const DARK_FONT_COLOR = '#666';
const accountStyles = makeStyles(theme => ({
  scrollContainer: {
    height: 'calc(100vh - 140px)', // required for scroll bars to work
    overflowY: 'auto',
    display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItem: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '20px'
    // backgroundColor: 'yellow'
  },
  waitingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: '100%',
    // width: '100%',
    // zIndex: 5,
    backgroundColor: '#40404010',
  },
  waiting: {
    margin: 'auto',
  },
  leftColumn: {
    width: LEFT_COLUMN_WIDTH_PERCENT,
    minWidth: '420px',
    // overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  rightColumn: {
    width: `calc(100% - ${LEFT_COLUMN_WIDTH_PERCENT})`,
    // overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: FONT_COLOR,
    marginTop: '40px',
  },
  expiredTitle: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: 'red',
    marginTop: '20px',
  },
  expiryDate: {
    fontSize: '1.4em',
    fontWeight: 500,
  },
  plan: {
    fontSize: '3.7em',
    fontWeight: 600,
    color: DARK_FONT_COLOR,
    marginTop: '20px',
    marginBottom: '30px',
  },
  rightSideContainer: {
    // backgroundColor: theme.palette.secondary.main,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.secondary.main,
    borderRadius: 13,
  },
  upgradeTitle: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: FONT_COLOR,
  },
  upgradePlanTitle: {
    fontSize: '3em',
    fontWeight: 600,
    color: FONT_COLOR,
    marginTop: '15px',
    marginBottom: '25px',
  },
  basicPlanPrice: {
    fontSize: '2.5em',
    fontWeight: 600,
    color: DARK_FONT_COLOR,
    marginTop: '20px',
  },
  basicPlanInterval: {
    fontSize: '.9em',
    fontWeight: 500,
    color: DARK_FONT_COLOR,
    marginTop: '4px',
    marginBottom: '10px',
    marginLeft: '60px',
  },
  planFeatureTitle: {
    fontSize: '1.3em',
    fontWeight: 500,
    color: FONT_COLOR,
    alignSelf: 'flex-start',
    marginLeft: '20px',
    marginBottom: '5px',
  },
  planFeature: {
    fontSize: '1.3em',
    fontWeight: 500,
    color: FONT_COLOR,
    alignSelf: 'flex-start',
    marginLeft: '40px',
    marginBottom: '5px',
  },
  buttonContainer: {
    marginTop: '30px',
  },
  altButtonContainer: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  detailsButtonContainer: {
    marginTop: '30px',
    marginBottom: '40px',
  },
  orButton: {
    position: 'relative',
    right: '30px',
    top: '100px',
  },
}));
const showWaitingIndicator = styleName => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}
      invisible={true}
      onClick={() => {}}
    >
      <CircularProgress
        style={{
          color: '#52d1b570',
          marginTop: '25px',
          margin: 'auto',
        }}
        size={60}
      />
    </Backdrop>
  );
};

// Droppable container for Dragable WP cards
export const UserAccount = ({ firebase, user, productPlans }) => {
  const classes = accountStyles();
  const [planIndex, setPlanIndex] = React.useState(0);
  const [showWaiting, setShowWaiting] = React.useState(false);

  if (!user || !user.plan) {
    // if page loaded before user set - ie, app restarted from account page
    return showWaitingIndicator(classes.waitingContainer);
  }

  let curPlan = user.plan.name; // todo at start of session read curPlan into store from firebase
  const curPlanFeatures = user.plan.features;

  const usingFreePlan = user.plan.name === USER.FREE_PLAN_NAME;

  let title = usingFreePlan ? 'currently using the' : `currently subscribed to`;

  const leftSideFeatureComponents = [];
  leftSideFeatureComponents.push(
    <div key={'fc-title'} className={classes.planFeatureTitle}>
      {`includes:`}
    </div>,
  );
  curPlanFeatures.forEach((feature, index) => {
    leftSideFeatureComponents.push(
      <div key={`fc-${index}`} className={classes.planFeature}>
        {`${feature.name}: ${feature.value}`}
      </div>,
    );
  });
  const rightSideFeatureComponents = [];
  rightSideFeatureComponents.push(
    <div key={'fc-title'} className={classes.planFeatureTitle}>
      {`includes:`}
    </div>,
  );
  // currently only supporting one upgrade path TODO make this more general
  productPlans[1].features.forEach(feature => {
    rightSideFeatureComponents.push(
      <div className={classes.planFeature}>{`${feature.name}: ${feature.value}`}</div>,
    );
  });
  const selectedPlanPrice = productPlans[1].prices[planIndex];
  const altText = selectedPlanPrice.interval === 'year' ? `pay monthly instead` : `pay annually instead`;

  let expires = null;
  let expiryColor = FONT_COLOR;
  let expiredTitle;
  if (!usingFreePlan || user.plan.expired) {
    const dateStr = user.plan.expiry.format('MMM D, YYYY');
    if (user.plan.expired) {
      expires = `expired on: ${dateStr}`;
      expiredTitle = `previous ${user.plan.expiredPlan}`;
      expiryColor = 'red';
    } else {
      expires = `expires on: ${dateStr}`;
    }
  }
  const redirectURL = `${window.location.origin}/?acc=t`;
  // console.log(`======================= redirect: ${redirectURL}`)
  // currently this code only supports one upgradable plan.... need to expand it to accomodate more
  return (
    <div className={classes.scrollContainer}>
      <div className={classes.container}>
        <div
          className={classes.leftColumn}
          style={usingFreePlan ? { marginRight: '30px' } : { marginRight: '0px' }}
        >
          <div className={classes.title}>{title}</div>
          <div className={classes.plan}>{curPlan}</div>
          <div>{leftSideFeatureComponents}</div>
          {expires && !user.plan.expired && (
            <div className={classes.expiryDate} style={{ marginTop: '20px', color: expiryColor }}>
              {expires}
            </div>
          )}
          {expires && user.plan.expired && (
            <div>
              <div className={classes.expiredTitle}>{expiredTitle}</div>
              <div className={classes.expiryDate} style={{ marginTop: '5px', color: expiryColor }}>
                {expires}
              </div>
            </div>
          )}
          {(!usingFreePlan || user.plan.expired) && user.plan.planId !== 'manual' && (
            <Button
              onClick={() => {
                const getStripePortalLink = firebase.functions.httpsCallable(
                  'ext-firestore-stripe-payments-createPortalLink',
                );
                getStripePortalLink({
                  returnUrl: redirectURL,
                  locale: 'auto', // Optional, defaults to "auto"
                })
                  .then(data => {
                    window.location.assign(data.data.url);
                  })
                  .catch(error => {
                    console.error(`Error redirecting user to Stripe Customer Portal: ${error}`);
                  });

                setShowWaiting(true);
              }}
              color="secondary"
              variant="contained"
              size="small"
              className={classes.detailsButtonContainer}
              style={{
                backgroundColor: '#00afa9',
                color: 'white',
                fontWeight: 600,
              }}
            >
              {user.plan.expired ? `View Details` : `View Subscription Details`}
            </Button>
          )}
        </div>
        <div
          className={classes.rightColumn}
          style={usingFreePlan ? { minWidth: '460px', marginLeft: '30px' } : { marginLeft: '0px' }}
        >
          {usingFreePlan && (
            <div className={classes.rightSideContainer}>
              <div className={classes.upgradeTitle}>{'upgrade to the'}</div>
              <div className={classes.upgradePlanTitle}>{productPlans[1].name}</div>
              <div>{rightSideFeatureComponents}</div>
              <div className={classes.basicPlanPrice}>{`$${selectedPlanPrice.monthlyEq}/month`}</div>
              <div className={classes.basicPlanInterval}>
                {selectedPlanPrice.interval === 'year' ? `paid annually` : `paid every month`}
              </div>
              <Button
                onClick={() => {
                  setPlanIndex(planIndex === 0 ? 1 : 0);
                }}
                color="secondary"
                variant="outlined"
                size="small"
                className={classes.altButtonContainer}
              >
                {altText}
              </Button>
              <Button
                onClick={() => {
                  // launch subscription sequence
                  // from https://console.firebase.google.com/u/0/project/project-assistant-beta/extensions/instances/firestore-stripe-payments?tab=details
                  // To subscribe the user to a specific pricing plan, create a new doc in the
                  // checkout_sessions collection for the user. The extension will update the
                  // doc with a Stripe Checkout session ID which you then use to redirect the
                  // user to the checkout page.

                  // create checkout session in stripe but posting a doc to the user's checkout_sessions

                  firebase.db
                    .collection('customers')
                    .doc(user.uid)
                    .collection('checkout_sessions')
                    .add({
                      price: selectedPlanPrice.id, // identifies product/plan and pricing option
                      success_url: redirectURL, // redirect to the current url
                      cancel_url: redirectURL,
                    })
                    .then(docRef => {
                      // Wait for the CheckoutSession to create a session in Stripe and get a response, via the doc
                      docRef.onSnapshot(snap => {
                        const { error, url } = snap.data();
                        if (error) {
                          // Show an error to your customer and
                          // inspect your Cloud Function logs in the Firebase console.
                          alert(`An error occured: ${error.message}`);
                        }
                        if (url) {
                          // We have a Stripe Checkout URL, let's redirect to Stripe
                          window.location.assign(url);
                          // afterwards it redirects back to here.... but how do we know if it succeeded... do we have to poll the dbase again?
                        }
                      });
                    })
                    .catch(error => {});

                  setShowWaiting(true);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.buttonContainer}
                style={{
                  backgroundColor: '#00afa9',
                  color: 'white',
                  fontWeight: 600,
                }}
              >
                {`Subscribe Now - $${selectedPlanPrice.amount} per ${selectedPlanPrice.interval}`}
              </Button>
            </div>
          )}
        </div>
        {showWaiting && showWaitingIndicator(classes.waitingContainer)}
      </div>
    </div>
  );
};
export default connect(state => {
  return {
    firebase: state.firebase,
    user: state.user,
    productPlans: state.productPlans,
  };
}, {})(UserAccount);
