export const ROLE_GENERAL_USER = 'ROLE_GENERAL_USER';
export const ROLE_CONSULTANT = 'ROLE_CONSULTANT';
export const ROLE_SYS_ADMIN = 'ROLE_SYS_ADMIN';
export const ROLE_GROUP_MEMBER = 'ROLE_GROUP_MEMBER';
export const ROLE_GROUP_ADMIN = 'ROLE_GROUP_ADMIN';

export const FREE_PLAN_NAME = 'Free Plan';
export const BASIC_PLAN_NAME = 'Basic Plan';
export const FREE_PLAN_OWNED_PROJ_LIMIT = 2;
export const FREE_PLAN_SHARED_PROJ_LIMIT = 3;

export const ONBOARDING_WELCOME_DIALOG = 'welcome_dialog';
export const ONBOARDING_DASHBOARD_DIALOG = 'dashboard_dialog';
export const ONBOARDING_PROJ_PLAN_DIALOG = 'project_plan_dialog';
export const ONBOARDING_WBS_DIALOG = 'wbs_dialog';
export const ONBOARDING_WORK_SCHEDULE_DIALOG = 'work_schedule_dialog';
export const ONBOARDING_WBS_ZOOMED_DIALOG = 'wbs_zoomed_dialog';
export const ONBOARDING_SPRINTVIEW_DIALOG = 'sprintview_dialog';
export const ONBOARDING_DELIVERABLE_DIALOG = 'deliverable_dialog';
export const ONBOARDING_WORKPACKAGE_DIALOG = 'workpackage_dialog';
export const ONBOARDING_TASK_DIALOG = 'task_dialog';
export const ONBOARDING_EXAMPLE_PROJECT_DIALOG = 'example project dialog';

// export const STARTUP_VIDEO_URL = 'https://youtu.be/0caVufl0hwA';
export const STARTUP_VIDEO_URL = 'https://youtu.be/gwAbhRzZ5SA';
