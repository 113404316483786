import React from 'react';
import { connect } from 'react-redux';
import FlatList from 'flatlist-react';

import { updateViewState, dispatchUpdateViewState } from '../../data/actions/userInterface';
import { getDoneTasksBySprint, randColor } from '../../utils/generalUtilities';

import {
  Typography,
  Chip,
  Card,
  CardContent,
  Tabs,
  Tab,
  Box,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Person from '@material-ui/icons/Person';
import { ListAlt, CompareArrows, EmojiEvents, School, CheckCircle } from '@material-ui/icons';
import { ContentWrapper } from './ContentWrapper';
import DraftEditor from './DraftEditor';
import OnboardingDialog from '../OnboardingDialog';

import * as USER from '../../constants/user';
import * as NAV from '../../constants/navigation';
const assignedToAvatarSize = 30;

const StyledTab = withStyles(theme => ({
  root: {
    width: 240,
    fontSize: '0.9rem',
    fontColor: theme.palette.primary.main,
    fontWeight: 500,
  },
}))(props => <Tab {...props} />);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  tabButton: {
    minWidth: '100px',
  },
  tabPanel: {
    padding: '0px 0',
  },
  card: {
    margin: '10px',
    boxShadow: '4px 4px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1px #444`,
    borderRadius: '11px',
    paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'space-between',
  },
  thinChipContainer: {
    marginTop: 8,
    marginBottom: 8,
    marginRight: 20,
    justifyContent: 'flex-start',
  },
  chip: {
    border: 'solid 1px #C250E2A0',
    background: '#C250E225',
    // background: 'none',
  },
  taskPathContext: {
    flex: 1,
    fontSize: '0.9rem',
    marginLeft: '20px',
    marginTop: '-2px',
    marginBottom: '5px',
    // fontWeight: 600,
    color: '#777',
  },
  sprintTitle: {
    marginTop: '35px',
  },
  showContext: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.9rem',
    fontStyle: 'italic',
    fontWeight: 700,
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '12px',
    marginBottom: '5px',
    color: '#777',
    borderStyle: 'solid',
    borderColor: '#666',
    borderWidth: 1,
    borderRadius: 10,
    width: 20,
    height: 20,
  },
  flatListContainer: {
    flex: 1,
    height: 'calc(100vh - 200px)',
    overflow: 'scroll',
    // backgroundColor: 'yellow',
  },
  taskLinkText: {
    flex: 1,
    fontSize: '1.2rem',
    marginLeft: '0px',
    marginRight: '20px',
    marginTop: '0px',
    marginBottom: '10px',
    color: '#555',
    cursor: 'pointer',
    lineHeight: 1.3,
    '&:hover': {
      color: '#3c8cba',
      textDecoration: 'underline',
    },
  },
  actualDate: {
    fontSize: '1.1rem',
    marginRight: '15px',
    marginLeft: '15px',
    marginTop: '8px',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginTop: 6,
    marginRight: 10,
    marginLeft: 10,
    // backgroundColor: 'yellow',
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToTooltip: {
    fontSize: 20,
    marginRight: 10,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
}));

const TabBar = ({ value, handleChange }) => {
  const classes = useStyles();
  return (
    <Tabs
      style={{ alignSelf: 'flex-end', marginLeft: 30 }}
      value={value}
      orientation="horizontal"
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="secondary"
      // variant="fullWidth"
      aria-label="notes tabs"
      scrollButtons="auto"
      className={classes.root}
    >
      <StyledTab label="Plan" {...a11yProps(0)} icon={<EmojiEvents />} />
      <StyledTab label="Accomplishments" {...a11yProps(1)} icon={<ListAlt />} />
      {/* <StyledTab
        label="Problem / Opportunity"
        {...a11yProps(2)}
        icon={<CompareArrows />}
      /> */}
      <StyledTab label="Learnings" {...a11yProps(2)} icon={<School />} />
      <StyledTab label="Close Project" {...a11yProps(3)} icon={<CheckCircle />} />
    </Tabs>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const ProjectPlanPage = ({
  project, //
  handleProjectInfoChange,
  viewState,
  updateViewState,
  dispatchUpdateViewState,
  userPrefs,
}) => {
  const classes = useStyles();
  const handleChange = (_event, newValue) => {
    if (newValue !== viewState.overviewTabIndex) {
      Object.assign(viewState, { overviewTabIndex: newValue });
      updateViewState(viewState); // store tab index in redux store allows global detection of user action (tap on tab)
    }
  };
  const [onboardingDialog, setOnboardingDialog] = React.useState(
    Boolean(userPrefs.onboarding && userPrefs.onboarding[USER.ONBOARDING_PROJ_PLAN_DIALOG]),
  );
  const [hoverTaskId, setHoverTaskId] = React.useState(null);

  const value = viewState.overviewTabIndex;
  const {
    note,
    richTextNote,
    problemOpportunity,
    goalsAndObjectives,
    learnings,
    projectPriorities,
  } = project;

  const accomplishments = getDoneTasksBySprint(project);

  const renderSprintItems = (item, index) => {
    return (
      <div key={`${item.sprintId}-${index}`}>
        <Typography component="h2" variant="h5" className={classes.sprintTitle}>
          {`Work Period ${item.sprintId} - ${item.humanDateStr}`}
        </Typography>
        {item.taskArray.map((taskItem, index) => {
          const avatarId = taskItem.task.assignedTo
            ? taskItem.task.assignedTo.substring(0, 1).toUpperCase()
            : null;
          let collegueColor;
          if (taskItem.task.assignedTo) {
            if (userPrefs.collegues[taskItem.task.assignedTo]) {
              collegueColor = userPrefs.collegues[taskItem.task.assignedTo].color;
            } else {
              collegueColor = randColor();
            }
          }
          return (
            <Card
              key={`card-${taskItem.uuid}-${index}`}
              id={taskItem.uuid}
              onClick={e => {}}
              className={classes.card}
              variant={'outlined'}
            >
              <CardContent className={classes.cardContent}>
                <div className={classes.thinChipContainer}>
                  <Chip label={taskItem.task.refId} className={classes.chip} />
                </div>
                <div
                  key={index}
                  className={classes.urProjectRow}
                  style={{
                    marginTop: 10,
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <Typography
                    variant={'h6'}
                    className={classes.taskLinkText}
                    onClick={e => {
                      Object.assign(viewState, {
                        curWindow: NAV.BOARD_DETAIL,
                        wbsStack: [
                          {
                            screen: NAV.BOARD,
                            item: {
                              d: taskItem.delIndex,
                              w: 0,
                              t: 0,
                            },
                            level: 'p',
                          },
                          {
                            screen: NAV.BOARD_DETAIL,
                            item: {
                              d: taskItem.delIndex,
                              w: taskItem.workpackageIndex,
                              t: 0,
                            },
                            level: 'w',
                          },
                          {
                            screen: NAV.BOARD_DETAIL,
                            item: {
                              d: taskItem.delIndex,
                              w: taskItem.workpackageIndex,
                              t: taskItem.taskIndex,
                            },
                            level: 't',
                          },
                        ],
                        detailsTabIndex: 0,
                        currentDeliverable: taskItem.delIndex, // need to map to index here
                        currentWorkPackage: taskItem.workpackageIndex,
                        currentTask: taskItem.uuid,
                      });
                      dispatchUpdateViewState(viewState);
                    }}
                  >
                    {taskItem.task.name}
                  </Typography>

                  {hoverTaskId === taskItem.uuid && (
                    <Typography variant={'h8'} className={classes.taskPathContext}>
                      {taskItem.path}
                    </Typography>
                  )}
                </div>
                {avatarId ? (
                  <Tooltip title={taskItem.task.assignedTo} className={classes.assignedToTooltip}>
                    <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                      <Typography className={classes.assignedToText}>{avatarId}</Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                    <Person className={classes.notAssignedTo} />
                  </div>
                )}
                <Typography variant={'h6'} className={classes.actualDate}>
                  {taskItem.task.actualEndDate}
                </Typography>
                <div
                  className={classes.showContext}
                  onMouseOver={e => {
                    setHoverTaskId(taskItem.uuid);
                  }}
                  onMouseOut={e => {
                    setHoverTaskId(null);
                  }}
                >
                  {`i`}
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <ContentWrapper title="Plan" titleChildren={<TabBar value={value} handleChange={handleChange} />}>
      {/** Project Plan */}
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <DraftEditor
          value={goalsAndObjectives}
          onSave={handleProjectInfoChange}
          field="goalsAndObjectives"
          fixedHeight={'68vh'}
          small
        />
      </TabPanel>
      {/** Accomplishments */}

      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <div className={classes.flatListContainer}>
          <FlatList className={classes.flatList} list={accomplishments} renderItem={renderSprintItems} />
          <div style={{ height: '400px' }} />
        </div>
      </TabPanel>
      {/* * Problem Opportunity
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <DraftEditor
          // label={'Problem/Opportunity'}
          onSave={handleProjectInfoChange}
          value={problemOpportunity}
          field="problemOpportunity"
          fixedHeight={'68vh'}
        />
      </TabPanel> */}
      {/** Learnings */}
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <DraftEditor
          onSave={handleProjectInfoChange}
          value={learnings}
          field="learnings"
          fixedHeight={'68vh'}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={3}>
        <DraftEditor
          onSave={handleProjectInfoChange}
          value={projectPriorities}
          field="projectPriorities"
          fixedHeight={'68vh'}
        />
      </TabPanel>
      {onboardingDialog && (
        <OnboardingDialog
          title={'The Project Planning Screen'}
          text={[
            'Use this screen to plan and track your project activities.',
            'Brainstorm your Plan, Accomplishments and Learnings.',
            'Describe how you will close the project.',
          ]}
          links={null}
          // links={[
          //   { text: 'here', link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI' },
          //   { text: 'here', link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4' },
          // ]}
          // text={"When you log in the Dashboard displays...\nPlease select the  page video now\nGo back to '?' anytime"}
          onboardingKey={USER.ONBOARDING_PROJ_PLAN_DIALOG}
          closeDialog={setOnboardingDialog}
          textOffset={'20px'}
        />
      )}
    </ContentWrapper>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    updateViewState,
    dispatchUpdateViewState,
  },
)(ProjectPlanPage);
