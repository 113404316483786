import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const buttonStyles = makeStyles(theme => ({
  button: {
    color: ({ color }) => `${color}`,
    // theme.palette.getContrastText(bgdColor),
    backgroundColor: ({ bgdColor }) => `${bgdColor}`,
    '&:hover': {
      backgroundColor: ({ hoverColor }) => `${hoverColor}`,
    },
    border: ({ borderColor }) => `solid 1.5px ${borderColor}`,
    maxWidth: ({ width }) => `${width}px`,
    minWidth: ({ width }) => `${width}px`,
    maxHeight: ({ width }) => `${width}px`,
    minHeight: ({ width }) => `${width}px`,
    borderRadius: '3px',
    // borderRadius: ({width}) => `${Math.floor(width/2)}px`,
    boxShadow: '0px 1px 2px -2px rgba(138, 148, 159, 0.7)',
    // textTransform: 'none', // prevents text from being drawn as uppercase (default)
    // fontStyle: 'italic',
    fontWeight: 700,
  },
}));

export const ExpandButton = ({
  handleClick,
  color,
  bgdColor,
  hoverColor,
  borderColor,
  size, // 'small', 'medium' , 'big'
  tooltip,
  containerClassName,
  notesExpanded,
}) => {
  const width = size === 'small' ? 21 : size === 'medium' ? 31 : 41;
  const buttonClasses = buttonStyles({ color, bgdColor, hoverColor, borderColor, size, width });

  return (
    <div className={containerClassName}>
      <Tooltip title={tooltip}>
        <Button size={size} onClick={handleClick} variant="contained" className={buttonClasses.button}>
          {notesExpanded ? (
            <ExpandMore fontSize={size} style={{ transform: 'rotate(90deg)' }} />
          ) : (
            <ExpandLess fontSize={size} style={{ transform: 'rotate(90deg)' }} />
          )}
        </Button>
      </Tooltip>
    </div>
  );
};
