// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { handleMenu, reorderColumns, handleCardDrop } from '../../data/actions/userInterface';
import DraggableDelColumn from './DraggableDelColumn';
import DraggableWPColumn from './DraggableWPColumn';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { ContentWrapper } from '../styled_components/ContentWrapper';
import { AddButton } from '../styled_components/AddButton';
import {
  withStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Add } from '@material-ui/icons';

const styles = {
  root: {
    paddingBottom: '30px', // To give some breathing room with the scroll bar
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    overflowY: 'hidden',
    maxHeight: 'calc(100% - 120px)',
  },
  collapsed: {
    height: '0',
  },
};

const dbuttonStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
  arrow: {
    transition: 'transform 0.3s ease-in-out',
    transform: ({ isExpanded }) => (isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  fab: {
    color: 'white',
  },
  listIcon: {
    minWidth: '30px',
  },
  addButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.light,
    margin: '0 5px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
    boxShadow: 1,
  },
}));

const DeliverableActions = ({ isExpanded, toggleExpanded, viewState, handleMenu }) => {
  const classes = dbuttonStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.buttonWrapper}>
      <IconButton
        aria-label="add-attachment"
        aria-controls="add-attachment"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        className={classes.addButton}
      >
        <Add fontSize="small" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            const stackTop = viewState.libStack[viewState.libStack.length - 1];
            let curDelIndex = stackTop.item.d;
            let location = '';
            if (curDelIndex === undefined || curDelIndex < 0) {
              curDelIndex = 0;
              location = '.ABOVE';
            }
            handleMenu(`deliverable.${curDelIndex}.Add${location}`);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Add Deliverable</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            const stackTop = viewState.libStack[viewState.libStack.length - 1];

            if (viewState.currentDeliverable === 0) {
              // in tasks library column
              let curWPIndex = stackTop.item.w;
              let location = '';
              if (curWPIndex === undefined || curWPIndex < 0) {
                curWPIndex = 0;
                location = '.ABOVE';
              }
              handleMenu(`task.${curWPIndex}.Add${location}`);
            } else {
              let curDelIndex = stackTop.item.d;
              if (curDelIndex === undefined || curDelIndex < 0) {
                curDelIndex = 0;
              }
              let curWPIndex = stackTop.item.w;
              let location = '';
              if (curWPIndex === undefined || curWPIndex < 0) {
                curWPIndex = 0;
                location = '.ABOVE';
              }
              handleMenu(`work package.${curDelIndex}.${curWPIndex}.Add${location}`);
            }
            // handleMenu(
            //   `work package.${viewState.currentDeliverable}.${viewState.currentWorkPackage}.Add`,
            // );
            setAnchorEl(null);
          }}
        >
          <ListItemIcon className={classes.listIcon}>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {viewState.currentDeliverable === 0 ? 'Add Task' : 'Add Task Group'}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

class Library extends React.Component {
  onDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      this.props.reorderColumns(source.index, destination.index, true);
    } else {
      this.props.handleCardDrop(source, destination, 'p', this.props.viewState, true);
    }
  };

  render() {
    const {
      label,
      key,
      cards,
      handleMenu,
      viewState,
      classes,
      withScrollableColumns,
      isCombineEnabled,
    } = this.props;

    return (
      <ContentWrapper
        title="Library Components"
        titleChildren={
          <AddButton
            handleClick={() => {
              const stackTop = viewState.libStack[viewState.libStack.length - 1];
              let curDelIndex = stackTop.item.d;
              let location = '';
              if (curDelIndex === undefined || curDelIndex < 0) {
                curDelIndex = 0;
                location = '.ABOVE';
              }
              handleMenu(`deliverable.${curDelIndex}.Add${location}`);
            }}
            size="medium"
            // color={theme.palette.secondary.addButton}
            color={'#FFF'}
            bgdColor={'#99ccc4'}
            hoverColor={'#52d1b5'}
            borderColor={'#99ccc4'}
            // borderColor={theme.palette.secondary.addButton}
            tooltip="add a new deliverable"
            containerClassName={classes.buttonWrapper}
          />
        }
      >
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="WBS" type="COLUMN" direction="horizontal" ignoreContainerClipping>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`${classes.root}`}
                aria-expanded={true}
              >
                {cards.map((delCard, index) => {
                  return (
                    <DraggableDelColumn
                      key={`ldc-${delCard.uuid}`}
                      index={index}
                      delCard={delCard}
                      isScrollable={withScrollableColumns}
                      isCombineEnabled={isCombineEnabled}
                      isExpanded={true}
                      viewStack={viewState.libStack}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ContentWrapper>
    );
  }
}

export default connect(
  state => {
    return {
      viewState: state.viewState,
    };
  },
  {
    handleMenu,
    reorderColumns,
    handleCardDrop,
  },
)(withStyles(styles)(Library));
