import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    margin: '20px 0',
    display: 'grid',
    gridTemplateColumns: ({ colWidth }) =>
      `repeat(6, minmax(${colWidth}px, 1fr))`,
    gridGap: '10px',
  },
}));

export const Row = ({ children, colWidth = 200 }) => {
  const classes = useStyles({ colWidth });
  return <div className={classes.row}> {children} </div>;
};
