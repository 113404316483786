import moment from 'moment';
import { detect } from 'detect-browser';
import axios from 'axios';

import * as ACTION from './actionTypes';
import * as ANALYTICS from '../../constants/analytics';
import * as USER from '../../constants/user';

// Note all thunks do not create copy of analytics store as these updates do not trigger any rerendering

export function dispatchAnalyticsEvent(eventType, data, once = false) {
  // once only allows the value to be set once per session. fiorst time
  return (dispatch, getState) => {
    dispatch(analyticsEvent(getState, eventType, data, once));
  };
}

export function analyticsEvent(getState, eventType, data, once = false) {
  // once only allows the value to be set once per session. fiorst time
  const { session } = getState();
  let allow = true;
  if (once)
    allow =
      session.data.events.findIndex((value, index, obj) => {
        return value === eventType;
      }) < 0;
  if (allow) {
    session.data.events.push({
      type: eventType,
      ts: moment()
        .local()
        .format(),
      data,
    });

    return {
      type: ACTION.SESSION_UPDATE,
      data: session.data,
    };
  }
  return { type: 'noop' };
}

export function dispatchAnalyticsCounterEvent(eventType) {
  return (dispatch, getState) => {
    dispatch(analyticsCounterEvent(getState, eventType));
  };
}
export function analyticsCounterEvent(getState, eventType) {
  const { session } = getState();

  const index = session.data.events.findIndex((value, index, obj) => {
    return value === eventType;
  });
  if (index >= 0) {
    session.data.events[index]++;
  } else {
    session.data.events[index] = 0;
  }

  return {
    type: ACTION.SESSION_UPDATE,
    data: session.data,
  };
}

export function analyticsSystemEvent(store, recordId, recordType, data) {
  // return; // temp disable analytics

  // used for events outside of session context, eg system level events like signup, welcome email
  const { analytics, user, firebase } = store;

  if (!analytics.records) return;
  if (analytics.records[recordId]) {
    throw new Error(`Duplicate analytics event recorded for ${recordType} with id ${recordId}`); // should not be generating the same id as prev events, something's wrong
  }

  const now = moment()
    .local()
    .format(); // in local time
  if (!recordId) {
    // autogen id if id is not defined
    recordId = `${recordType}::${user.email}::${now}`;
  }

  analytics.records[recordId] = {
    // add new record
    timestamp: now,
    userEmail: user.email,
    recType: recordType,
    data: JSON.stringify(data),
  };

  console.log(`firebase.db.add - analyticsSystemsEvent`);
  firebase.db
    .collection('analytics')
    .doc(analytics.docId)
    .set(analytics)
    .then(res => {
      // rest of data processing handled by curAnalyticsListener in App.js:componentDidUpdate() which will pass this data to the redux store
    })
    .catch(function(error) {
      console.log(
        `Analytics System Event data did not get uploaded to server - no worries, it will be persisted by app and updated next time.`,
      );
    });
}

// pass analytics doc records to store
export function updateAnalytics(analyticsDoc) {
  return {
    type: ACTION.ANALYTICS_UPDATE,
    analyticsDoc: analyticsDoc,
  };
}

function getLocationInformation() {
  return new Promise((resolve, reject) => {
    const isBeta = process.env.REACT_APP_PROJECT_ID === 'project-assistant-beta';
    const origin = process.env.REACT_APP_ORIGIN;
    const isLocalDev = window.location.hostname === 'localhost';
    // if(isLocalDev) {
    //   resolve({
    //     city: 'devCity',
    //     region: 'devRegion',
    //     country: 'devCountry',
    //     countryCode: 'dev',
    //   });
    //   return;
    // }
    const parms = { params: {} };
    try {
      axios
        .get(`https://api.ipify.org`, parms)
        .then(ipaddressObj => {
          const ipAddr = ipaddressObj?.data;
          if (!ipAddr) reject(`invalid ip '${ipAddr}' returned from ipify.org`);
          const trimmedAddress = `https://pro.ip-api.com/json/` + ipAddr.trim() + '?key=DigKMVaDR8I8iIe';
          // alt https://ipgeolocation.io/
          console.log(`resolving location`);
          // console.log(`resolving location from ${trimmedAddress}`);
          axios
            .get(trimmedAddress)
            .then(ipinfo => {
              console.log(`location resolved as ${ipinfo.data.city} ${ipinfo.data.country}`);
              resolve({
                city: ipinfo.data.city,
                region: ipinfo.data.regionName,
                country: ipinfo.data.country,
                countryCode: ipinfo.data.countryCode,
              });
            })
            .catch(err => {
              console.log(`could not resolve location !!  error: ${err}`);
              reject(`could not resolve location !!  error: ${err}`);
              // reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
}

export function checkSessionState(onlyExpire = false) {
  return (dispatch, getState) => {
    const { session, user, app } = getState();
    const now = new Date().getTime();

    if (!user.email) {
      // if user logged out, skip generating a new session
      if (
        session.startTS || // if old session running and expired, force expiry of session store
        now - session.lastSessionUpdateTS > ANALYTICS.SESSION_EXPIRY_TIME_MS
      ) {
        console.log(`++++++++++++++++++++++ EXPIRING OLD NEW SESSION - user logged out +++++++++++++++++`);
        dispatch({ type: ACTION.SESSION_EXPIRED });
      } else {
        console.log(
          `++++++++++++++++++++++ CHECKING SESSION STATE - user logged out +++++++++++++++++ ${
            session.startTS
          }  ${ANALYTICS.SESSION_EXPIRY_TIME_MS}  ${now - session.lastSessionUpdateTS}`,
        );
      }
      return; // is user logged out, ie browser focused on login screen, don't start up a new session, wait until user logs in
    }
    if (!onlyExpire) {
      // only set up new session info if not onlyExpire flag
      console.log(
        `++++++++++++++++++++++ CHECKING SESSION STATE +++++++++++++++++ ${session.startTS}  ${
          ANALYTICS.SESSION_EXPIRY_TIME_MS
        }  ${now - session.lastSessionUpdateTS}`,
      );

      const newSession =
        !session.startTS || // no session data (just checking startTS as an example)
        user.uid !== app.prevUserUID || // new user logged in, force new session
        now - session.lastSessionUpdateTS > ANALYTICS.SESSION_EXPIRY_TIME_MS;

      if (newSession) {
        const browser = detect(); // get browser info
        console.log(`++++++++++++++++++++++ SETTING NEW SESSION INFO +++++++++++++++++`);
        // if last action earlier than threshold time or forced save (eg, during browser exit),

        const action = {
          type: ACTION.SETUP_SESSION_INFO,
          sessionData: {
            uid: `S::${user.email}::${new Date().getTime()}`,
            user: { uid: user.uid, email: user.email, role: user.role },
            browser: {
              name: browser.name,
              version: parseFloat(browser.version),
              os: browser.os,
            },
            geo: {
              city: 'unknown',
              region: 'unknown',
              country: 'unknown',
              countryCode: 'unknown',
            }, // updated later below using getLocationInformation
            duration: 0, // in minutes - updated in saveSession()
            content: {
              numCreatedProjects: 0,
              numOtherProjects: 0,
              numCreatedTemplates: 0,
              uniqueShares: 0,
            }, // in line items - updated in saveSession()
            events: [], // will hold eventtype/data pairs for user actions in the app during the session
          },
        };

        getLocationInformation()
          .then(geoData => {
            dispatch({
              type: ACTION.SET_SESSION_GEO,
              geoData: geoData,
            });
          })
          .catch(error => {
            console.log(`getLocationInformation failed during new session logic: ${error}`);
          });

        dispatch(action);
      }
    }
  };
}

const SAVE_SESSION_RESPONSE_NO_ACTION = 0;
const SAVE_SESSION_RESPONSE_SAVED = 1;
const SAVE_SESSION_RESPONSE_SESSION_EXPIRED = 2;

// on app focus and blur (blur forces save)
export function dispatchSaveSession(forceSave = false) {
  return (dispatch, getState) => {
    saveSession(getState(), forceSave).then(response => {
      switch (response) {
        case SAVE_SESSION_RESPONSE_SAVED:
          dispatch({ type: ACTION.SESSION_SAVED });
          break;
        case SAVE_SESSION_RESPONSE_SESSION_EXPIRED:
          dispatch({ type: ACTION.SESSION_EXPIRED });
          break;
        default:
        // do nothing
      }
    });
  };
}

// on user log out (with force on)
export function saveSession(store, forceSave = false) {
  return new Promise((resolve, reject) => {
    const { session, analytics, user, projects, libraryComponents, firebase } = store;

    if (
      // true || // temp disable analytics for now
      !session.startTS || // there is no active session (startTS will be set if there is an active session)
      !analytics.weekId || // no analytics doc downloaded from cloud
      !user || // user is not logged in / active
      !user.email ||
      !projects // user's projects have not been loaded
    ) {
      resolve(SAVE_SESSION_RESPONSE_NO_ACTION);
      return;
    }
    console.log(`++++ call to save session (forced: ${forceSave}, saveToBackend: ${session.saveToBackend})`);
    const now = new Date().getTime();

    if (!session.saveToBackend) {
      // session has not been updated
      if (now - session.lastSessionUpdateTS > ANALYTICS.SESSION_EXPIRY_TIME_MS && session.startTS) {
        resolve(SAVE_SESSION_RESPONSE_SESSION_EXPIRED); // session expired - reset data - can only happen when app has been left in foreground
      } else {
        resolve(SAVE_SESSION_RESPONSE_NO_ACTION);
      }
      return;
    } else {
      // at this point session is marked to be saved to backend, so do so if save conditions met
      if (now - session.lastSessionUpdateTS > ANALYTICS.SAVE_SESSION_AFTER_MS_IDLE || forceSave) {
        // if last action earlier than threshold time or forced save (eg, during browser exit),
        console.log(`++++++++++++++++++++++ SAVING SESSION RECORDS +++++++++++++++++`);

        // before saving session, update session info, like duration and project counts
        session.data['duration'] = Math.ceil(
          Math.ceil((moment().unix() - moment(session.startTS).unix()) / 60), // duration in minutes (rounded up)
        );

        session.data['content'] = {
          numCreatedProjects: 0,
          numOtherProjects: 0,
          numCreatedTemplates: 0,
          uniqueShares: 0,
        };
        let uniqueShares = {};
        Object.keys(projects).forEach(key => {
          const curProj = projects[key];
          if (curProj.creator === user.email) {
            session.data['content'].numCreatedProjects++;
          } else {
            session.data['content'].numOtherProjects++;
          }
          curProj.sharedWith.forEach(shareEmail => {
            if (shareEmail !== user.email) {
              uniqueShares[shareEmail] = true;
            }
          });
        });
        session.data['content'].uniqueShares = Object.keys(uniqueShares).length;

        const isAdmin = user.role === USER.ROLE_SYS_ADMIN;
        const isConsultant = user.role === USER.ROLE_CONSULTANT;
        if ((isAdmin || isConsultant) && libraryComponents) {
          Object.keys(libraryComponents.workflows).forEach(key => {
            const curTemp = libraryComponents.workflows[key];
            if (curTemp.creator === user.email) {
              session.data['content'].numCreatedTemplates++;
            }
          });
        }

        // add or replace session analytics record with latest session data
        analytics.records[session.data.uid] = {
          recType: ANALYTICS.SESSION_RECORD,
          timestamp: session.startTS,
          userEmail: user.email,
          data: {
            ...session.data,
            startTS: session.startTS, // for backwards compatibility with downstream processing - in future remove from prociessing and here
          },
        };

        console.log(
          `calling firebase.db.set - saveSession - ${Object.keys(analytics.records).length} records...`,
        );

        firebase.db
          .collection('analytics')
          .doc(analytics.docId)
          .set(analytics)
          .then(res => {
            console.log(`Session data uploaded to server.`);
            resolve(SAVE_SESSION_RESPONSE_SAVED);
            // rest of data processing handled by curAnalyticsListener in App.js:componentDidUpdate() which will pass this data to the redux store
          })
          .catch(function(error) {
            console.log(
              `Session data did not get uploaded to server - no worries, session data persisted by app and updated next time.`,
              // however duration will be screwed up. How to handle this - possibly mark session with error code in data
            );
            resolve(SAVE_SESSION_RESPONSE_NO_ACTION);
          });
      } else {
        resolve(SAVE_SESSION_RESPONSE_NO_ACTION);
      }
    }
  });
}
