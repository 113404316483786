/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg'; // see https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Heading } from '../styled_components/Heading';
import { updateViewState } from '../../data/actions/userInterface';

import { makeStyles } from '@material-ui/core';

const editorStyles = makeStyles(theme => ({
  editorContainer: {
    height: ({ fixedHeight }) => (fixedHeight ? fixedHeight : '60vh'),
    maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : undefined),
    // flex: 1,
    border: '2px solid lightGrey',
    padding: '0px',
    display: 'flex',

    // flex: ({ maxHeight, fixedHeight, flex }) => (maxHeight && !fixedHeight ? 0 : flex ? flex : 1),
    // // display: 'flex',
    // display: 'block',
    // flexDirection: 'column',
    // height: ({ fixedHeight, maxHeightOuter }) =>
    //   maxHeightOuter ? maxHeightOuter : fixedHeight ? `${fixedHeight + 40}px` : undefined,
    // height: '300px',
    // width: ({ fixedWidth }) => (fixedWidth ? `${fixedWidth}px` : undefined),
  },
  wrapper: {
    flex: 1,
    // border: '4px solid red',

    display: 'flex',
    flexDirection: 'column',

    // container for toolbar and editor
    // flex: ({ maxHeight, fixedHeight, flex }) => (maxHeight || fixedHeight ? 0 : flex ? flex : 1),
    // alignSelf: 'stretch',
    // display: ({ maxHeight }) => (maxHeight ? 'block' : 'flex'),
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'stretch',
    // overflow: 'hidden',
    // border: '1px solid #BBB',
    // flex: 0,
    // height: '200px',

    // maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : '75vh'),
    // minHeight: ({ minHeight }) => (minHeight ? minHeight : '65vh'),
    // maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : undefined),
    // minWidth: ({ minWidth }) => (minWidth ? minWidth : undefined),
    // height: ({ fixedHeight }) => (fixedHeight ? `${fixedHeight}px` : undefined),
    // width: ({ fixedWidth }) => (fixedWidth ? `${fixedWidth}px` : undefined),
    // backgroundColor: 'lightYellow',
  },

  editor: {
    flex: 1,
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    // marginBottom: '50px',
    cursor: 'text',
    fontSize: '16px',
    // padding: '0 10px',
    // marginBottom: 60,
    overflowY: ({ noScroll }) => (noScroll ? 'hidden' : undefined),
    // maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : undefined),
    // minHeight: ({ minHeight }) => (minHeight ? minHeight : undefined),
    // maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : undefined),
    // minWidth: ({ minWidth }) => (minWidth ? minWidth : undefined),
    // height: ({ fixedHeight }) => (fixedHeight ? `${fixedHeight - 5}px` : undefined),
    width: ({ fixedWidth }) => (fixedWidth ? `${fixedWidth - 5}px` : undefined),
    backgroundColor: 'lightRed',
  },
  small: {
    maxHeight: '40vh',
  },
  hidden: {
    display: 'none',
  },
  toolbar: {
    // padding: '10px 0',
    margin: 0,
    position: 'sticky',
    top: 0,
    // background: 'white',
    // background: 'lightGreen',
    zIndex: 2,
  },
  hideToolbar: {
    top: 0,
    display: 'None',
  },
}));

let scrollTimer;
let scrollTimer2;
/**
 * Editor for rich text notes.
 * @param {string} type This is the key of the object that is being updated
 * @param {Object | string} value The value of the editor, string is currently deprecated and is saved as an object.
 * @param {Function} onSave The action that is used to update the project fields.
 */

const DraftEditor = ({
  label,
  value = '',
  placeholder = '',
  onSave,
  onChange,
  field,
  fieldSuffix = '',
  viewState,
  noScroll,
  maxHeight,
  maxHeightOuter,
  flex,
  minHeight,
  maxWidth,
  minWidth,
  fixedHeight,
  fixedWidth,
  hideToolbar = false,
  updateViewState,
  readOnly = false,
  restrictedToolbar = 0,
  saveOnChange = false, // save on blur - auto save
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [scrollElem, setScrollElem] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [lines, setLines] = useState(0);
  const editorContainerRef = useRef(null);

  const handleEditorChange = function(e) {
    const prevLines = lines;

    // const currentContent = editorState.getCurrentContent();
    // const selection = editorState.getSelection();
    // const entityKey = Entity.create('MENTION', 'IMMUTABLE', { text: 'apple', value: 'apple', url: 'apple' });
    // const textWithEntity = Modifier.insertText(currentContent, selection, '@APPLE', null, entityKey);
    // this.setState({ editorState: EditorState.push(editorState, textWithEntity, 'insert-characters') });

    // let a = editorState.selection.
    // if (prevLines !== editorState.blocks.length) {
    //   setLines(e.blocks.length);
    // }
    setEditorState(editorState);
  };

  // useEffect(() => {
  //   const scrollableDiv = editorContainerRef.current.querySelector('.rdw-editor-main');
  //   console.log(
  //     `scroll: clientHeight ${scrollableDiv.clientHeight} scrollHeight ${scrollableDiv.scrollHeight}`,
  //   );
  //   const newScrollTop = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
  //   // scrollableDiv.scrollTop = newScrollTop >= 0 ? newScrollTop : 0;
  // }, [lines]);

  // fixedHeight = 350;
  // maxHeightOuter = undefined;
  // hideToolbar = true;

  const classes = editorStyles({
    hideToolbar,
    maxHeight,
    maxHeightOuter,
    minHeight,
    flex,
    maxWidth,
    minWidth,
    fixedHeight,
    fixedWidth,
    noScroll,
  });
  useEffect(() => {
    console.log(`--------------- DraftEditor useEffect value  +++++`);
    // console.log(`--------------- DraftEditor useEffect value: ${JSON.stringify(value)}`);
    const textValue =
      typeof value === 'string'
        ? ContentState.createFromText(value) // Handle old conversion for deprecated string notes
        : convertFromRaw(value);
    let newState = EditorState.createWithContent(textValue);
    // if (curSelection) newState = EditorState.acceptSelection(newState, curSelection);
    setEditorState(newState);
  }, [value]); // add 'value' to the dependency list to recalculate state when value changes.

  const onScroll = e => {
    // console.log(`--------------- DraftEditor onScroll 1`);
    if (e.currentTarget.scrollTop !== scrollTop) {
      if (scrollTimer) {
        clearTimeout(scrollTimer);
      }
      let thisScrollTop = e.currentTarget.scrollTop;
      // console.log(`--------------- DraftEditor onScroll 2 ${thisScrollTop}`);

      scrollTimer = setTimeout(e => {
        // debounce scoll values
        // viewState.scrollTop[scrollName] = thisScrollTop;
        // console.log(`scroll ${viewState.scrollTop[scrollName]}`);
        // console.log(`--------------- DraftEditor onScroll updating scrollTop: ${thisScrollTop}`);
        setScrollTop(thisScrollTop);
        // updateViewState(viewState);
        scrollTimer = undefined;
      }, 50);
    }
  };
  useEffect(() => {
    // console.log(`--------------- DraftEditor useEffect scrollElem`);

    if (scrollElem) {
      scrollElem.addEventListener('scroll', onScroll);
    }
    return function cleanup() {
      if (scrollElem) {
        scrollElem.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollElem]);

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleSave = () => {
    console.log(`--------------- DraftEditor handleSave ---- `);

    const curContent = editorState.getCurrentContent();
    const content = convertToRaw(editorState.getCurrentContent());
    const isEmpty = content?.blocks.length === 1 && content?.blocks[0].text === '';
    if (saveOnChange) {
      // this is a real hack... problem is how to trigger autosave instead of on blur vs save with Save button
      onSave({ content, isEmpty });
    } else {
      onSave({ [field]: content });
    }
  };
  /**
   * Draftjs was adding extra newlines and stripping styles from the clipboard's pasted content.
   * It requires a function that overrides this behaviour and now just directly updates the state from the pasted text.
   * It's unclear as to why it's necessary to provide a function to the text editor for handling pasted text.
   * I tried using just a boolean value but pasted text is not handled. Passing this function normalizes the pasting behaviour completely.
   * @param {string} text
   * @param {html} html
   */
  const handlePastedText = (text, html) => {};

  const scrollName = `editor-${field}${fieldSuffix}`;
  // console.log(`-------------- DraftEditor  rendering.....`);

  // {label ? <Heading heading={label} /> : null}
  // <div
  //   ref={el => {
  //     if (el) {
  //       const scrollDiv = el.querySelector('.rdw-editor-main');
  //       if (scrollTimer2) {
  //         clearTimeout(scrollTimer2);
  //       }
  //       scrollTimer2 = setTimeout(e => {
  //         // scrollDiv.scrollTop = viewState.scrollTop[scrollName] ? viewState.scrollTop[scrollName] : 0;
  //         scrollDiv.scrollTop = scrollTop ?? 0;
  //       }, 0);
  //       setScrollElem(scrollDiv);
  //     }
  //   }}
  // >
  // toolbarClassName={hideToolbar ? classes.hideToolbar : classes.toolbar}

  return (
    <div className={classes.editorContainer} ref={editorContainerRef}>
      <Editor
        spellCheck
        wrapperClassName={`${classes.wrapper}`}
        // wrapperClassName={`${classes.wrapper} ${maxHeight}`}
        toolbarClassName={hideToolbar ? classes.hideToolbar : classes.toolbar}
        // toolbarClassName={classes.hideToolbar}
        editorClassName={classes.editor}
        handlePastedText={handlePastedText}
        onBlur={() => {
          if (!readOnly && !saveOnChange) {
            // console.log(`--------------- DraftEditor save onBlur`);
            handleSave();
          }
        }} // automated saving on blur
        onChange={editorState => {
          if (onChange) {
            onChange(editorState);
            return;
          }
          if (!readOnly && saveOnChange) {
            // console.log(`--------------- DraftEditor save onChange`);
            handleEditorChange(editorState);
            handleSave();
          }
        }} // save on each change - pass result to parent which saves with button
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        placeholder={placeholder}
        readOnly={readOnly}
        toolbar={{
          //values taken from example on https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
          // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
          options: restrictedToolbar
            ? ['inline', 'colorPicker', 'fontSize', 'list', 'emoji', 'link', 'history']
            : [
                'inline',
                'colorPicker',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'emoji',
                'link',
                'image',
                'history',
              ],
          inline: {
            inDropdown: restrictedToolbar === 1 ? true : false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: restrictedToolbar
              ? ['bold', 'italic', 'strikethrough']
              : ['bold', 'italic', 'strikethrough'],
            // options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
            // bold: { icon: bold, className: undefined },
            // italic: { icon: italic, className: undefined },
            // strikethrough: { icon: strikethrough, className: undefined },
            // underline: { icon: underline, className: undefined },
            // monospace: { icon: monospace, className: undefined },
            // superscript: { icon: superscript, className: undefined },
            // subscript: { icon: subscript, className: undefined },
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontSize: {
            // icon: fontSize,
            options: [12, 14, 16, 18, 24, 30, 36, 48, 60, 72],
            // options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          // fontFamily: {
          //   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
          //   className: undefined,
          //   component: undefined,
          //   dropdownClassName: undefined,
          // },
          list: {
            inDropdown: true,
            // inDropdown: restrictedToolbar === 1 ? true : false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
            // options: ['unordered', 'ordered'],
            // unordered: { icon: unordered, className: undefined },
            // ordered: { icon: ordered, className: undefined },
            // indent: { icon: indent, className: undefined },
            // outdent: { icon: outdent, className: undefined },
          },
          textAlign: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
            // left: { icon: left, className: undefined },
            // center: { icon: center, className: undefined },
            // right: { icon: right, className: undefined },
            // justify: { icon: justify, className: undefined },
          },
          colorPicker: {
            // icon: color,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              'rgb(97,189,109)',
              'rgb(26,188,156)',
              'rgb(84,172,210)',
              'rgb(44,130,201)',
              'rgb(147,101,184)',
              'rgb(71,85,119)',
              'rgb(204,204,204)',
              'rgb(65,168,95)',
              'rgb(0,168,133)',
              'rgb(61,142,185)',
              'rgb(41,105,176)',
              'rgb(85,57,130)',
              'rgb(40,50,78)',
              'rgb(0,0,0)',
              'rgb(247,218,100)',
              'rgb(251,160,38)',
              'rgb(235,107,86)',
              'rgb(226,80,65)',
              'rgb(163,143,132)',
              'rgb(239,239,239)',
              'rgb(255,255,255)',
              'rgb(250,197,28)',
              'rgb(243,121,52)',
              'rgb(209,72,65)',
              'rgb(184,49,47)',
              'rgb(124,112,107)',
              'rgb(209,213,216)',
            ],
          },
          link: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            // link: { icon: link, className: undefined },
            // unlink: { icon: unlink, className: undefined },
            linkCallback: undefined,
          },
          emoji: {
            // icon: emoji,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: [
              '😀',
              '😁',
              '😂',
              '😃',
              '😉',
              '😋',
              '😎',
              '😍',
              '😗',
              '🤗',
              '🤔',
              '😣',
              '😫',
              '😴',
              '😌',
              '🤓',
              '😛',
              '😜',
              '😠',
              '😇',
              '😷',
              '😈',
              '👻',
              '😺',
              '😸',
              '😹',
              '😻',
              '😼',
              '😽',
              '🙀',
              '🙈',
              '🙉',
              '🙊',
              '👼',
              '👮',
              '🕵',
              '💂',
              '👳',
              '🎅',
              '👸',
              '👰',
              '👲',
              '🙍',
              '🙇',
              '🚶',
              '🏃',
              '💃',
              '⛷',
              '🏂',
              '🏌',
              '🏄',
              '🚣',
              '🏊',
              '⛹',
              '🏋',
              '🚴',
              '👫',
              '💪',
              '👈',
              '👉',
              '👉',
              '👆',
              '🖕',
              '👇',
              '🖖',
              '🤘',
              '🖐',
              '👌',
              '👍',
              '👎',
              '✊',
              '👊',
              '👏',
              '🙌',
              '🙏',
              '🐵',
              '🐶',
              '🐇',
              '🐥',
              '🐸',
              '🐌',
              '🐛',
              '🐜',
              '🐝',
              '🍉',
              '🍄',
              '🍔',
              '🍤',
              '🍨',
              '🍪',
              '🎂',
              '🍰',
              '🍾',
              '🍷',
              '🍸',
              '🍺',
              '🌍',
              '🚑',
              '⏰',
              '🌙',
              '🌝',
              '🌞',
              '⭐',
              '🌟',
              '🌠',
              '🌨',
              '🌩',
              '⛄',
              '🔥',
              '🎄',
              '🎈',
              '🎉',
              '🎊',
              '🎁',
              '🎗',
              '🏀',
              '🏈',
              '🎲',
              '🔇',
              '🔈',
              '📣',
              '🔔',
              '🎵',
              '🎷',
              '💰',
              '🖊',
              '📅',
              '✅',
              '❎',
              '💯',
            ],
          },
          embedded: {
            // icon: embedded,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            embedCallback: undefined,
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
          image: {
            // icon: image,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: false,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
          // remove: {
          //   icon: eraser,
          //   className: undefined,
          //   component: undefined
          // },
          history: {
            inDropdown: true,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
            // undo: { icon: undo, className: undefined },
            // redo: { icon: redo, className: undefined },
          },
        }}
      />
    </div>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects, // included so this component updates on projects change
      viewState: state.viewState,
    };
  },
  {
    updateViewState,
  },
)(DraftEditor);
