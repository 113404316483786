import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = '';

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ACTION.PROCESS_COMMAND:
      return action.commandString;

    case ACTION.UPDATE_PROJECTS:
    case ACTION.CLEAR_USER_INFO:
    case ACTION.RESET_UI_PARMS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
