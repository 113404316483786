import * as constants from '../../../constants/app.js';
import * as USER from '../../../constants/user';

export function getUserGroupIDs(userGroups, role) {
  const userGroupIds = [constants.PUBLIC_GROUP_ID]; // always include public group id (==0) in this list
  if (role !== USER.ROLE_GENERAL_USER) {
    userGroupIds.push(constants.CORE_SMART_TEMPLATES_ID);
  }
  userGroups.forEach(group => {
    if (group.id.length === 20) {
      // valid id
      userGroupIds.push(group.id);
    }
  });
  return userGroupIds;
}
export function getUsersGroups(firebase, userEmail) {
  return new Promise((resolve, reject) => {
    return firebase.db
      .collection('groups')
      .where('members', 'array-contains', userEmail)
      .get()
      .then(plansSnapshot => {
        const userGroups = [];
        let adminGroup = null;
        plansSnapshot.forEach(groupDoc => {
          const groupData = groupDoc.data();
          if (!adminGroup && groupData.info[userEmail].isAdmin) {
            adminGroup = Object.assign({}, groupData);
          }
          userGroups.push({
            id: groupData.id,
            name: groupData.name,
            tags: groupData.info[userEmail].isAdmin
              ? ''
              : groupData.info[userEmail].tags
              ? groupData.info[userEmail].tags
              : '',
            isAdmin: groupData.info[userEmail].isAdmin,
          });
        });
        resolve({ adminGroup, userGroups });
      })
      .catch(function(err) {
        reject(`Error getting user's ${userEmail} groups: ${err}`);
      });
  });
}
