import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import DraggableTaskCard from './DraggableTaskCard';
import * as STATUS from '../../constants/status';
import * as utils from '../../utils/generalUtilities.js';

// Droppable container for Dragable WP cards
const DroppableTaskCardList = ({
  listId = 'LIST',
  taskCards, // actual cards now
  viewStack,
  isLibrary,
  thin = false,
  viewState,
}) => {
  const pr = window.devicePixelRatio;
  // console.log(`pixel ratio ${window.devicePixelRatio}`)
  let bm = Math.round(240 / window.devicePixelRatio);
  const bmargin = `${bm}px`;
  const height = `calc(100vh - ${bmargin})`;
  // console.log(height);

  // console.log(`rendering Task List`);
  // if (viewState.sortTasksByStatus) {
  //   sortTaskCards(taskCards);
  // }
  const inWorkflow = viewState.currentProjectType === 'Workflow';

  const allHidden = taskCards.reduce((acc, taskCard) => {
    if (
      taskCard.status === STATUS.IN_PROGRESS ||
      (!thin && viewState.showUnpinned) ||
      (thin && viewState.showDetailUnpinned) ||
      inWorkflow ||
      taskCard.pinned
    ) {
      return false;
    } else {
      return acc;
    }
  }, true);
  const noTaskCards = taskCards.length === 0;

  return (
    <Droppable droppableId={listId} type={undefined}>
      {(dropProvided, dropSnapshot) => (
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          style={{
            // height: height,
            marginBottom: bmargin,
            // paddingBottom: bmargin,
            // backgroundColor: 'yellow'
          }} // Necessary to override the droppable styling
        >
          {taskCards.map((taskCard, index) => {
            if (viewState.showNotNeededTasks || taskCard.task.status !== STATUS.NOT_NEEDED) {
              if (
                taskCard.status === STATUS.IN_PROGRESS ||
                viewState.showDetailUnpinned ||
                inWorkflow ||
                taskCard.pinned
              ) {
                return (
                  <DraggableTaskCard
                    key={`wcl-${taskCard.uuid}`}
                    taskCard={taskCard}
                    thin={thin}
                    viewStack={viewStack}
                    isLibrary={isLibrary}
                  />
                );
              } else {
                return (
                  <div
                    style={{
                      display: 'flex',
                      height: 2,
                      backgroundColor: '#4442',
                      marginBottom: 2,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                );
              }
            } else {
              return null;
            }
          })}
          {thin && noTaskCards && (
            <p style={{ textAlign: 'center', marginTop: '40px' }}>
              Your have no tasks defined. Press + to add a task.
            </p>
          )}
          {thin && !noTaskCards && allHidden && (
            <Fragment>
              <p style={{ textAlign: 'center', marginTop: '40px' }}>All your tasks are hidden.</p>
              <p style={{ textAlign: 'center' }}>
                Toggle the visibility switch in the upper right corner to see them.
              </p>
            </Fragment>
          )}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
export default connect(state => {
  return {
    viewState: state.viewState,
  };
}, {})(DroppableTaskCardList);
