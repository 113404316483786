import * as ACTION from '../actions/actionTypes';

const INITIAL_STATE = {
  isBeta: process.env.REACT_APP_PROJECT_ID === 'project-assistant-beta',
  forceLogout: false,
  prevBackendAppId: null,
  newAccountRequested: false, // used to capture new user signup in firebase processing logic
  prevUserUID: null,
};

export default function appReducer(state = INITIAL_STATE, action = {}) {
  console.log(`ACTION: ${action.type}`); // uncomment this line to see Actions listed in log
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.app) {
        const backendChanged = action.payload.app.prevBackendAppId !== process.env.REACT_APP_APP_ID;
        if (backendChanged) {
          console.log(
            `Backend is changed from appID ${action.payload.app.prevBackendAppId} to ${process.env.REACT_APP_APP_ID}`,
          );
        }
        return {
          ...state,
          // backend changed so force logout to ensure persisted data from prev backend is cleared
          // in future, there may be other conditions where we want to force a logout
          forceLogout: backendChanged,
          // forceLogout: true,
          prevBackendAppId: process.env.REACT_APP_APP_ID,
        };
      }
      return state;

    case ACTION.SET_USER_INFO:
      return {
        ...state,
        forceLogout: false,
        newAccountRequested: false,
        prevUserUID: state.prevUserUID ? state.prevUserUID : action.userToken.uid,
      };

    case ACTION.CLEAR_USER_INFO:
      return {
        ...state,
        forceLogout: false,
        newAccountRequested: false,
        prevUserUID: action.prevUserUID,
      };

    case ACTION.NEW_ACCOUNT_REQUESTED:
      return {
        ...state,
        newAccountRequested: true,
      };

    default:
      return {
        ...state,
      };
  }
}
