// user-related actions
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const TOUCH_USER_EMAIL = 'TOUCH_USER_EMAIL';
export const SET_ASSIGNMENT_ORDER = 'SET_ASSIGNMENT_ORDER';
export const SET_PROJECT_ASSIGNMENTS = 'SET_PROJECT_ASSIGNMENTS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const RESET_ONBOARDING = 'RESET_ONBOARDING';
export const SET_SPRINT_PERIOD = 'SET_SPRINT_PERIOD';
export const SET_GROUP = 'SET_GROUP';
export const SHOW_GROUP_DIALOG = 'SHOW_GROUP_DIALOG';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const NEW_ACCOUNT_REQUESTED = 'NEW_ACCOUNT_REQUESTED';
export const LOAD_USER_DOCS = 'LOAD_USER_DOCS';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_ONBOARDING = 'SET_ONBOARDING';

// document-related actions - for future use see PA-367
export const SET_INFO_DIALOG = 'SET_INFO_DIALOG';
export const SET_VIEW_STATE = 'SET_VIEW_STATE';
export const RESET_UI_PARMS = 'RESET_UI_PARMS';
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS';
export const UPDATE_WORKFLOWS = 'UPDATE_WORKFLOWS';
export const SET_TEMPLATES = 'SET_TEMPLATES';

export const ANALYTICS_UPDATE = 'ANALYTICS_UPDATE';
export const SESSION_UPDATE = 'SESSION_UPDATE';
export const SETUP_SESSION_INFO = 'SETUP_SESSION_INFO';
export const SESSION_SAVED = 'SESSION_SAVED';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const SET_SESSION_GEO = 'SET_SESSION_GEO';

export const SET_PLANS = 'SET_PLANS';
export const ADD_DELIVERABLE = 'ADD_DELIVERABLE';
export const PROCESS_COMMAND = 'PROCESS_COMMAND';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const SET_PS_SCROLL_TOP = 'SET_PS_SCROLL_TOP';
export const EDIT_ATTACHMENT = 'EDIT_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELECT_ATTACHMENT';
export const UPDATE_ONEDRIVE = 'UPDATE_ONEDRIVE';
export const UPDATE_PROJECT_CARDS = 'UPDATE_PROJECT_CARDS';
export const TOGGLE_NOT_NEEDED_TASK_VISIBILITY = 'TOGGLE_NOT_NEEDED_TASK_VISIBILITY';
export const TOGGLE_PIN_VISIBILITY = 'TOGGLE_PIN_VISIBILITY';
export const TOGGLE_DETAIL_PIN_VISIBILITY = 'TOGGLE_DETAIL_PIN_VISIBILITY';
export const TOGGLE_SORT_TASKS = 'TOGGLE_SORT_TASKS';
export const RESET_PROJECT_SCHEDULE_BUTTONS = 'RESET_PROJECT_SCHEDULE_BUTTONS';
export const SET_USIBILITY_REPORT_DATA = 'SET_USIBILITY_REPORT_DATA';
export const SPLIT_WP = 'SPLIT_WP';
export const MERGE_WPS = 'MERGE_WPS';
