/* eslint-disable */

import React, { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Typography, Tabs, Tab, Button, makeStyles, withStyles, Tooltip } from '@material-ui/core';
import * as TASK from '../../constants/tasks';
import { AddButton } from '../styled_components/AddButton';
import { useTheme } from '@material-ui/styles';

const tabBarStyles = makeStyles(theme => ({
  tabButton: {
    minWidth: '200px',
  },
  containerPanel: {
    display: 'block',
    // flex: 0,
    // height: '60%',
    overflowY: 'scroll',
    // padding: '0px 0',
    // flexDirection: 'column',
    // height: `${window.innerHeight - 600}px`,
    // backgroundColor: 'yellow'
  },
  headerBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `solid 3px #888`,
    height: 35,
    paddingBottom: -4,
  },
  topBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 35,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 35,
  },
  rightSideBarButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '140px',
    height: 35,
    marginRight: '10px',
    marginLeft: '0px',
    marginBottom: '80px'
  },
  addButton: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    width: '60px',
    marginRight: '10px',
    marginLeft: '10px',
    backgroundColor: 'white',
  },
  addButtonContainer: {
    marginRight: '60px',
    marginLeft: '20px',
    marginBottom: '5px',
  },
  sortButton: {
    color: ({ sortOn }) => (sortOn ? theme.palette.secondary.addButton : 'grey'),
    border: ({ sortOn }) => `solid 1 ${sortOn ? theme.palette.secondary.addButton : 'grey'}`,
    width: '60px',
    backgroundColor: ({ sortOn }) => (sortOn ? 'white' : '#ddd'),
  },
  hideButton: {
    color: ({ sortOn }) => (sortOn ? theme.palette.secondary.addButton : 'grey'),
    border: ({ sortOn }) => `solid 1 ${sortOn ? theme.palette.secondary.addButton : 'grey'}`,
    width: '60px',
    backgroundColor: ({ hideNN }) => (hideNN ? 'grey' : 'white'),
  },
  itemListTitle: {
    color: '#FFF',
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none',
    marginLeft: 40,
  },
}));

const StyledTab = withStyles(theme => ({
  root: {
    width: 280,
    fontSize: '0.9rem',
    fontWeight: 600,
  },
}))(props => {
  return (
    <Tooltip title={props.tooltip}>
      <Tab {...props} />
    </Tooltip>
  );
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-ComponentListWrapper-${index}`,
  };
}

export const ComponentListWrapper = ({
  children,
  value,
  viewStack,
  index,
  viewState,
  showButtons = false,
  card = null,
  handleMenu,
  notNeededTaskCount = 0,
  toggleNotNeededTaskVisibility = null,
  toggleSortTasks = null,
  thinBar = true,
  ...other
}) => {
  // const  = props;
  const sortOn = viewState.sortTasksByStatus;
  const hideNN = viewState.hideNotNeededTasks;
  const classes = tabBarStyles({ index, hideNN, sortOn, thinBar });
  const theme = useTheme();

  return (
    <Typography
      component="div"
      role="ComponentListWrapper"
      id={`simple-ComponentListWrapper-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div className={classes.containerPanel}>
        <div className={classes.headerBar}>
          {showButtons && card && handleMenu && (
            <div className={classes.topBarContainer}>
              <div className={classes.leftSide}>
                <Typography component="p" variant="overline" className={classes.itemListTitle}>
                  {card.cardType === TASK.WORKPACKAGE_CARD ? 'Tasks' : 'Task Groups'}
                </Typography>
                <AddButton
                  handleClick={() => {
                    const stackTop = viewStack[viewStack.length - 1];
                    let location = '';
                    if (card.cardType === TASK.WORKPACKAGE_CARD) {
                      let curTaskIndex = stackTop.item.t;
                      if (curTaskIndex === undefined || curTaskIndex < 0) {
                        curTaskIndex = 0;
                        location = '.ABOVE';
                      }
                      handleMenu(`task.${curTaskIndex}.Add${location}`);
                    } else {
                      // TASK.DELIVERABLE_CARD
                      let curDelIndex = stackTop.item.d;
                      let curWPIndex = stackTop.item.w;
                      if (curWPIndex === undefined || curWPIndex < 0) {
                        curWPIndex = 0;
                        location = '.ABOVE';
                      }
                      handleMenu(`work package.${curDelIndex}.${curWPIndex}.Add${location}`);
                    }
                  }}
                  size="small"
                  color={theme.palette.secondary.addButton}
                  bgdColor="#FFF"
                  hoverColor="#EEE"
                  // borderColor={theme.palette.secondary.addButton}
                  tooltip={`add a ${card.cardType === TASK.WORKPACKAGE_CARD ? 'task' : 'task group'}`}
                  containerClassName={classes.addButtonContainer}
                />
              </div>
              {card.cardType === TASK.WORKPACKAGE_CARD && (
                <Fragment>
                  <div className={classes.rightSideBarButtons}>
                    {/* <Tooltip title={'toggle task sorting'}> 
                      // commented out for now, may re-instate as a manual sort in future 
                      <Button
                        size={'small'}
                        variant="contained"
                        className={classes.sortButton}
                        onClick={toggleSortTasks}
                      >
                        {viewState.sortTasksByStatus ? (
                          <ArrowDownwardIcon fontSize="small" fontWeight={800} color="secondary" />
                        ) : (
                          <MinimizeIcon fontSize="small" fontWeight={700} color="secondary" />
                        )}
                      </Button>
                    </Tooltip> */}
                    {notNeededTaskCount > 0 && (
                      <Tooltip title={'toggle visibility of not needed tasks'}>
                        <Button
                          size={'small'}
                          variant="contained"
                          className={classes.hideButton}
                          onClick={toggleNotNeededTaskVisibility}
                        >
                          {viewState.showNotNeededTasks ? (
                            <Visibility fontSize="small" fontWeight={500} color="secondary" />
                          ) : (
                            <VisibilityOff fontSize="small" fontWeight={500} color="secondary" />
                          )}
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    </Typography>
  );
};
