import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputField } from './styled_components/InputField';
import Backdrop from '@material-ui/core/Backdrop';
import { DeleteOutlined } from '@material-ui/icons';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
import { dispatchSetGroup, dispatchUpdateViewState, saveCurrentProject } from '../data/actions/userInterface';
import * as NAV from '../constants/navigation';

const userTableHeadCells = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    className: 'stringCell',
  },
  {
    id: 'admin',
    numeric: false,
    disablePadding: false,
    label: 'Admin',
    className: 'stringCell',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
    className: 'stringCell',
  },
];

const groupStyles = makeStyles(theme => ({
  container: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '0px',
    paddingRight: '50px',
    // margin: 50,
    // border: 'solid 1',
    // borderRadius: 7,
  },
  contentContainer: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    marginTop: '20px',
    padding: 20,
    border: 'solid 1 888',
    backgroundColor: 'white',
  },
  leftSide: {
    // height: '100%',
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    // overflowY: 'scroll',
    height: 'calc(100vh - 300px)', // required for scroll bars to work
  },
  rightSide: {
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '40px',
    // marginTop: '0px'
  },
  stringCell: {},
  tagCell: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '150px',
  },
  ruleCell: {
    height: '20px',
    paddingTop: '0px',
    paddingBottom: '0px',
    // marginTop: '0px'
  },
  ruleRow: {
    height: '20px',
    paddingTop: '2px',
    paddingBottom: '20px',
    width: '400px',
    // marginTop: '0px'
  },
  waitingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: '100%',
    // width: '100%',
    // zIndex: 5,
    backgroundColor: '#40404010',
  },
  waiting: {
    margin: 'auto',
  },
  header: {
    paddingBottom: '10px',
  },
  formSectionContainer: {
    flexDirection: 'column',
    margin: '20px 0',
    display: 'flex',
  },
  formSection: {
    flexDirection: 'row',
    margin: '0px 0',
    display: 'flex',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '5px',
  },
  dateMessage: {
    alignSelf: 'flex-start',
    fontSize: '.8rem',
    color: '#888',
  },
  loadingText: {
    fontSize: '1em',
    fontWeight: 500,
    color: '#60606080',
  },
  loadingBackdrop: {
    flexDirection: 'column',
    margin: '0px 0',
    display: 'flex',
    alignItems: 'center',
  },
  tableDiv: {
    height: 'calc(100vh - 300px)', // required for scroll bars to work
    marginTop: 10,
    overflowY: 'scroll',
    paddingRight: '30px',
  },
  buttonContainer: {
    width: 120,
    height: 56,
  },
  inputExists: {
    minWidth: 400,
    backgroundColor: '#FF000010',
    marginRight: '10px',
  },
  titlecontainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rulesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  rulesColumn: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: '20px',
  },

  rulesTitle1: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#606060B0',
    // marginBottom: '15px',
  },
  rulesTitle2: {
    fontSize: '1.2em',
    fontWeight: 500,
    color: '#444B',
    marginTop: '20px',
    marginBottom: '5px',
    marginLeft: '10px',
    textDecoration: 'underline',
  },
  rulesTitle3: {
    fontSize: '1.0em',
    fontWeight: 500,
    color: '#808080B0',
    marginBottom: '5px',
    marginLeft: '20px',
  },
  titletext2: {
    fontSize: '1.6em',
    fontWeight: 500,
    color: '#3338',
    marginTop: '10px',
    marginBottom: '5px',
  },
  titletext: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: '#333A',
    marginBottom: '5px',
  },
  grouptext2: {
    fontSize: '1.2em',
    fontWeight: 500,
    color: '#60606080',
    marginBottom: '40px',
  },
  linkText: {
    fontSize: '1.2em',
    color: '#5bbcfc',
    textDecoration: 'underline',
  },
  notLinkText: {
    fontSize: '1.2em',
    color: '#444',
  },
  tagLinkText: {
    fontSize: '1.2em',
    color: '#5bbcfc',
    textDecoration: 'underline',
    marginRight: '10px',
  },
  tagNotLinkText: {
    fontSize: '1.2em',
    color: '#444',
    marginRight: '10px',
  },
}));
const showWaitingIndicator = styleName => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}
      invisible={true}
      onClick={() => {}}
    >
      <div className={groupStyles.loadingBackdrop}>
        <Typography component="h2" variant="h5" className={groupStyles.loadingText}>
          One moment...
        </Typography>
        <div>
          <CircularProgress
            style={{
              color: '#52d1b570',
              marginTop: '25px',
              // marginLeft: '25px',
              alignText: 'center',
              // margin: 'auto',
            }}
            size={60}
          />
        </div>
      </div>
    </Backdrop>
  );
};

const DATE_FORMAT = 'YYYY/MM/DD';

// Droppable container for Dragable WP cards
const TemplateAccessPage = ({
  firebase,
  user,
  productPlans,
  group,
  workflows,
  viewState,
  dispatchSetGroup,
  dispatchUpdateViewState,
  saveCurrentProject,
}) => {
  const classes = groupStyles();
  // const [rules, setRules] = React.useState(
  //   {emailEquals: [], tagEquals:[]}
  // );
  const [showWaiting, setShowWaiting] = React.useState(false);
  const [memberEmail, setMemberEmail] = React.useState('');
  const [memberTags, setMemberTags] = React.useState('');
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState('desc'); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = React.useState('email');
  const [memberSelectedForDelete, setRowSelectedForDelete] = React.useState({});

  const handleSave = (value, name) => {};

  const handleUpdate = (e, name) => {};
  const handleSubmit = () => {};

  if (!group) {
    // if on this page but group not set, jump to dashboard
    viewState = Object.assign(viewState, {
      curWindow: NAV.DASHBOARD,
    });
    dispatchUpdateViewState(viewState);
    return null;
  }
  const createNewGroupMember = (userEmail, tags) => {
    group.members.push(userEmail);
    group.info[userEmail] = { tags: tags };
    dispatchSetGroup(group, 'update', firebase);
  };

  const removeGroupMember = userEmail => {
    const removedMemberInd = group.members.findIndex(m => m === userEmail);
    if (removedMemberInd >= 0) {
      group.members.splice(removedMemberInd, 1);
      delete group.members[userEmail];
    }
    dispatchSetGroup(group, 'update', firebase);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, property, order) => {
    if (!array || array.length === 0) return [];
    const stabilizedThis = array.map((el, index) => [el, index]); // ensure complete data for sort algorithm
    const comparator = getComparator(order, property);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const sortTable = (property, flipOrder = true) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    const newOrder = flipOrder ? (isAsc ? 'desc' : 'asc') : sortOrder;
    const newArray = stableSort(memberList, property, newOrder);

    setOrderBy(property);
    setSortOrder(newOrder);
    // setSubscriptions(newArray);
    setSelectedRow(0);
  };

  const renderTableHeadCell = props => {
    const { classes, order, orderBy, headCells } = props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell, index) => {
            const tableCellClassName = classes.stringCell;
            return (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className={tableCellClassName}
              >
                {headCell.sortable === undefined || headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={event => {
                      sortTable(headCell.id); // headCell.id holds the property name
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const emptyTags = '---';
  const splitTags = tagsString => {
    if (tagsString === '') return [emptyTags];
    const tags = tagsString.split(/[\s,;]+/);
    const result = [];
    tags.forEach(tag => {
      tag = tag.trim();
      if (tag.length > 0) {
        result.push(tag);
      }
    });
    return result;
  };
  const curTemplate = workflows[viewState.currentWorkflowSetId][viewState.currentWorkflowIndex];
  if (!curTemplate.accessRules) curTemplate.accessRules = { emailEquals: [], tagEquals: [] };

  // build subscription list array for list
  const memberList = [];
  if (group.members) {
    group.members.forEach(member => {
      const mInd = curTemplate.accessRules.emailEquals.findIndex(email => email === member);

      const sTags = group.info[member].isAdmin ? [emptyTags] : splitTags(group.info[member].tags);
      memberList.push({
        email: member,
        role: group.info[member].isAdmin ? 'X' : '',
        tags: sTags,
        isAdmin: group.info[member].isAdmin,
        hasAccess: mInd >= 0,
      });
    });
  }

  const setRules = newRules => {
    const newWorkflows = Object.assign({}, workflows);
    newWorkflows[viewState.currentWorkflowSetId][viewState.currentWorkflowIndex].accessRules = newRules;
    saveCurrentProject(); // save template to cloud, will call updateWorkflows() after updated in cloud
  };

  let emailExists = false;
  if (memberList) {
    if (memberEmail) {
      if (memberList.findIndex(m => m.email.trim() === memberEmail.trim()) >= 0) emailExists = true;
    }
  }

  return (
    <div className={classes.container}>
      {showWaiting ? (
        showWaitingIndicator(classes.waitingContainer)
      ) : (
        <Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.formSectionContainer}>
              <div className={classes.titlecontainer}>
                <Typography component="h2" variant="h2" className={classes.titletext2}>
                  {`access rules for`}
                </Typography>
                <Typography component="h4" variant="h4" className={classes.titletext}>
                  {`${curTemplate.name}`}
                </Typography>
              </div>
            </div>
          </form>
          <div className={classes.contentContainer}>
            <div className={classes.leftSide}>
              <Typography component="h2" variant="h2" className={classes.rulesTitle1}>
                Click links to add rules:
              </Typography>
              {memberList && (
                <Fragment>
                  <div className={classes.tableDiv}>
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                      >
                        {renderTableHeadCell({
                          headCells: userTableHeadCells,
                          classes: classes,
                          order: sortOrder,
                          orderBy: orderBy,
                          isUsersTable: true,
                        })}
                        <TableBody>
                          {memberList.map((member, index) => {
                            return (
                              <TableRow
                                className={index === selectedRow ? classes.selectedRow : classes.tableRow}
                                // onClick={event => handleClickOnUser(event, index)}
                                tabIndex={-1}
                                key={member.email}
                              >
                                <TableCell
                                  component="th"
                                  className={classes.stringCell}
                                  id={'email'}
                                  scope="row"
                                >
                                  <Typography
                                    component="h3"
                                    variant="h3"
                                    className={
                                      member.isAdmin || member.hasAccess
                                        ? classes.notLinkText
                                        : classes.linkText
                                    }
                                    onClick={event => {
                                      if (!member.isAdmin && !member.hasAccess) {
                                        const newEmailRules = curTemplate.accessRules.emailEquals.slice();
                                        newEmailRules.push(member.email);
                                        const newRules = {
                                          ...curTemplate.accessRules,
                                          emailEquals: newEmailRules,
                                        };
                                        setRules(newRules);
                                      }
                                    }}
                                  >
                                    {member.email}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.stringCell}>{member.role}</TableCell>
                                <TableCell className={classes.tagCell}>
                                  {member.tags.map(tag => {
                                    const tagInd = curTemplate.accessRules.tagEquals.indexOf(tag);
                                    const tagNotUsed = tag !== emptyTags && tagInd < 0;
                                    return (
                                      <Typography
                                        component="h3"
                                        variant="h3"
                                        className={tagNotUsed ? classes.tagLinkText : classes.tagNotLinkText}
                                        onClick={e => {
                                          if (tagNotUsed) {
                                            const newTagRules = curTemplate.accessRules.tagEquals.slice();
                                            newTagRules.push(tag);
                                            const newRules = {
                                              ...curTemplate.accessRules,
                                              tagEquals: newTagRules,
                                            };
                                            setRules(newRules);
                                          }
                                        }}
                                      >
                                        {tag}
                                      </Typography>
                                    );
                                  })}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Fragment>
              )}
            </div>
            <div className={classes.rightSide}>
              <Typography component="h2" variant="h2" className={classes.rulesTitle1}>
                Access Rules
              </Typography>
              <div className={classes.rulesContainer}>
                <div className={classes.rulesColumn}>
                  <Typography component="h3" variant="h3" className={classes.rulesTitle2}>
                    Allow these emails:
                  </Typography>
                  <TableBody>
                    {curTemplate.accessRules.emailEquals.map((r, index) => {
                      return (
                        <TableRow className={classes.ruleRow} tabIndex={-1} key={`emailIs_${r}`}>
                          <TableCell component="th" className={classes.ruleCell} id={'email'} scope="row">
                            <Typography component="h3" variant="h3" className={classes.notLinkText}>
                              {r}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.ruleCell}>
                            <DeleteOutlined
                              onClick={() => {
                                const newEmailRules = curTemplate.accessRules.emailEquals.slice();
                                const ruleIndToDelete = newEmailRules.indexOf(r);
                                newEmailRules.splice(ruleIndToDelete, 1);
                                const newRules = { ...curTemplate.accessRules, emailEquals: newEmailRules };
                                setRules(newRules);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </div>
                <div className={classes.rulesColumn}>
                  <Typography component="h3" variant="h3" className={classes.rulesTitle2}>
                    Allow these tags:
                  </Typography>

                  <TableBody>
                    {curTemplate.accessRules.tagEquals.map((r, index) => {
                      return (
                        <TableRow className={classes.ruleRow} tabIndex={-1} key={`emailIs_${r}`}>
                          <TableCell
                            component="th"
                            className={classes.ruleCell}
                            id={'emailEquals'}
                            scope="row"
                          >
                            <Typography component="h3" variant="h3" className={classes.notLinkText}>
                              {r}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.ruleCell}>
                            <DeleteOutlined
                              onClick={e => {
                                const newTagRules = curTemplate.accessRules.tagEquals.slice();
                                const ruleIndToDelete = newTagRules.indexOf(r);
                                newTagRules.splice(ruleIndToDelete, 1);
                                const newRules = { ...curTemplate.accessRules, tagEquals: newTagRules };
                                setRules(newRules);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default connect(
  state => {
    return {
      firebase: state.firebase,
      user: state.user,
      viewState: state.viewState,
      productPlans: state.productPlans,
      group: state.group,
      workflows: state.workflows,
    };
  },
  {
    dispatchSetGroup,
    dispatchUpdateViewState,
    saveCurrentProject,
  },
)(TemplateAccessPage);
