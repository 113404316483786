import * as ACTION from '../actions/actionTypes';
import { Client} from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, UserAgentApplication } from '@azure/msal-browser';


function authCallback(error, response) {
  // Handle redirect response
  console.log('+++++in here ++');
}

const INITIAL_STATE = {
  initialized: false,
  signedIn: false,  // boolean if token exists
  msalAppClient: null,
  authProvider: null,
  graphAPIClient: null,
  oneDriveFileList: null, // last file list
};

export default function msAzure(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    // case 'persist/REHYDRATE':
    //   return state;

    case ACTION.UPDATE_ONEDRIVE:
      return action.data;

    default:
      return state;
  }
}
