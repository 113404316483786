/* eslint-disable */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import GroupPage from './GroupPage';
import TemplateAccessPage from './TemplateAccessPage';
import { Heading } from './styled_components/Heading';
import { InfoButton } from './styled_components/InfoButton';
import { AddButton } from './styled_components/AddButton';
import { DroppableWorkflowCardList } from './project_overview/DroppableWorkflowCardList';

import { ContentWrapper } from './styled_components/ContentWrapper';

import { dispatchUpdateUsibilityReport } from '../data/actions/app';
import { Typography } from '@material-ui/core';
import { dispatchUpdateViewState, saveWorkflows } from '../data/actions/userInterface';
import * as USER from '../constants/user';
import * as NAV from '../constants/navigation';
import * as constants from '../constants/app';
import { LIB_TASK_CONTAINER_CARD } from '../constants/tasks';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  contentWindow: {
    flex: 1, // rel flex in parent
    display: 'flex', // children flex
    height: '100vh',
    backgroundColor: theme.palette.secondary.faint,
  },
  docContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    overflowY: 'auto',
  },
  dualDocRow: {
    flex: 1, // rel flex in parent
    display: 'flex', // children flex
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100vh',
    // backgroundColor: 'blue',
  },
  section: {
    gridColumnStart: 'main',
    minHeight: '30vh',
  },
  sidenav: {
    backgroundColor: theme.palette.secondary.faint,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },

  drawer: {
    display: 'block',
    marginTop: 90,
    width: drawerWidth,
    height: `600px`,
    whiteSpace: 'nowrap',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: theme.spacing(7) + 1,
  },
  content: {
    flex: 1, // rel flex in parent, since drawer is fixed height/width, this takes all the rest of the space
    display: 'flex', // children flex
    overflow: 'hidden',
    padding: theme.spacing(3),
    paddingRight: 10,
    paddingLeft: 25,
    // backgroundColor: 'yellow',
  },
  selectedDrawerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    backgroundColor: theme.palette.secondary.menu,
  },
  drawerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  projectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
  infoButtonContainer: {
    flex: 0,
    alignSelf: 'flex-start',
    // marginLeft: 5,
    marginTop: -2,
    height: 22,
    width: 22,
  },
  addWPButton: {
    height: 26,
    marginLeft: 15,
  },
  projectListContainer: {
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
    maxWidth: 800,
    minWidth: 800,
    marginTop: 10,
    marginBottom: 30,
    paddingLeft: 60,
    paddingRight: '50px',
    // backgroundColor: 'yellow'
  },
  titletext: {
    fontSize: '2.1em',
    fontWeight: 600,
    color: '#333B',
    marginBottom: '5px',
  },
}));

const ActiveWorkflows = ({
  user,
  handleProjectInfoChange,
  project,
  viewState,
  workflows,
  group,
  saveWorkflows,
  dispatchUpdateViewState,
  wbsCardsUpdateTS,
  libCardsUpdateTS,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isWorkflow = viewState.currentProjectType === NAV.TYPE_WORKFLOW;
  const [open] = React.useState(false);
  const isWorkflowProject = !!project.library;

  const menuIconColor = '666';
  const menuButtons = [
    {
      name: NAV.DASHBOARD,
      icon: <ArrowBackRoundedIcon style={{ flex: 1, color: menuIconColor }} />,
      disabled: false,
    },
    {
      name: NAV.SMARTTEMPLATES,
      icon: <AccountTreeRoundedIcon style={{ flex: 1, color: menuIconColor }} />,
      disabled: workflows[group.id] ? false : true,
    },
    {
      name: NAV.GROUPPAGE,
      icon: <PeopleAltRoundedIcon style={{ flex: 1, color: menuIconColor }} />,
      disabled: false,
    },
  ];

  const { help, projectPriorities } = project;
  let curCard;
  let content;
  const selectedWorkflow = workflows[viewState.currentWorkflowSetId][viewState.currentWorkflowIndex];
  const accessRulesVisible =
    viewState.currentProjectType === NAV.TYPE_WORKFLOW &&
    !(
      viewState.currentWorkflowSetId === constants.PUBLIC_GROUP_ID ||
      viewState.currentWorkflowSetId === constants.CORE_SMART_TEMPLATES_ID
    ) &&
    selectedWorkflow.library.level !== 0;

  switch (viewState.curWindow) {
    default:
    case NAV.GROUPPAGE:
      content = (
        <ContentWrapper
          title="Workflow Managment"
          titleChildren={
            <InfoButton
              handleClick={() => {
                window.open(`https://www.project-guidance.com/template-management-help`);
              }}
              size="small"
              color="#FFF"
              bgdColor={theme.palette.primary.addButton}
              hoverColor={theme.palette.secondary.addButton + 'E0'}
              borderColor="#FFF"
              tooltip="learn about Workflow Managment"
              containerClassName={classes.infoButtonContainer}
              type={'i'}
            />
          }
          scrollY
        >
          <GroupPage />
        </ContentWrapper>
      );
      break;
    case NAV.SMARTTEMPLATES:
      // case NAV.TEMPLATE_ACCESS:
      content = (
        <ContentWrapper
          title="Workflow Managment"
          titleChildren={
            <InfoButton
              handleClick={() => {
                window.open(`https://www.project-guidance.com/template-management-help`);
              }}
              size="small"
              color="#FFF"
              bgdColor={theme.palette.primary.addButton}
              hoverColor={theme.palette.secondary.addButton + 'E0'}
              borderColor="#FFF"
              tooltip="learn about Workflow Managment"
              containerClassName={classes.infoButtonContainer}
              type={'i'}
            />
          }
          scrollY
        >
          <div className={classes.projectRow}>
            <Typography component="h2" variant="h2" className={classes.titletext}>
              {'Your Smart Workflows'}
            </Typography>
            <AddButton
              handleClick={() => {
                viewState = Object.assign(viewState, {
                  curWindow: NAV.CREATE_NEW_TEMPLATE,
                });
                dispatchUpdateViewState(viewState);
              }}
              size="medium"
              color="#FFF"
              bgdColor={theme.palette.primary.addButton}
              hoverColor={theme.palette.secondary.addButton + 'E0'}
              borderColor="#FFF"
              tooltip="add a new workflow"
              containerClassName={classes.addWPButton}
            />
          </div>
          <div className={classes.projectListContainer}>
            <DroppableWorkflowCardList
              listId={'Templates'}
              workflows={workflows}
              saveWorkflows={saveWorkflows}
              templateSetId={group.id}
              isPrivateList={true}
            />
          </div>
          {accessRulesVisible && <TemplateAccessPage />}
        </ContentWrapper>
      );
      break;
    // case NAV.TEMPLATE_ACCESS:
    //   content = (
    //     <ContentWrapper
    //       title={'Edit access rules for template:'}
    //       backToWindow={NAV.SMARTTEMPLATES}
    //       viewState={viewState}
    //       dispatchUpdateViewState={dispatchUpdateViewState}
    //       scrollY={true}
    //       headerMarginBottom={0}
    //     >
    //       <TemplateAccessPage />
    //     </ContentWrapper>
    //   );
    //   break;
  }

  return (
    <div className={classes.contentWindow}>
      <Drawer
        // zIndex={90}
        // style={{marginTop: 160}}
        variant="permanent"
        className={clsx(classes.drawer, {
          // [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            // [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        containerStyle={{
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <List className={classes.sidenav}>
          <ListItem button key={'space1'} disabled style={{ height: 73 }} />
          {menuButtons.map((item, index) => {
            let curStackItem;
            let jumpToTop = false;
            return (
              <Tooltip title={item.name}>
                <ListItem
                  button
                  key={item.name}
                  disabled={item.disabled}
                  onClick={() => {
                    Object.assign(viewState, {
                      curWindow: item.name,
                      currentWorkflowSetId: item.name === NAV.DASHBOARD ? 0 : viewState.currentWorkflowSetId,
                    });
                    dispatchUpdateViewState(viewState);
                  }}
                  // style={{overflow: 'hidden'}}
                  className={
                    item.name === viewState.curWindow ? classes.selectedDrawerButton : classes.drawerButton
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {/* <ListItemText primary={item.name} /> */}
                </ListItem>
              </Tooltip>
            );
          })}
          <ListItem button key={'space2'} disabled style={{ height: 1000 }} />
        </List>
        <Divider />
      </Drawer>
      {/* <main className={classes.content}>{content}</main> */}
      <div className={classes.content}>{content}</div>
    </div>
  );
};

export default connect(
  state => {
    return {
      viewState: state.viewState,
      wbsCardsUpdateTS: state.viewState.wbsCardsUpdateTS,
      libCardsUpdateTS: state.viewState.libCardsUpdateTS,
      workflows: state.workflows,
      group: state.group,
    };
  },
  {
    dispatchUpdateUsibilityReport,
    dispatchUpdateViewState,
    saveWorkflows,
  },
)(ActiveWorkflows);
