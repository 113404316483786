/* eslint-disable */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

export const InputField = ({
  type = 'input',
  name,
  value,
  label,
  handleBlur,
  inputProps,
  selectProps = {},
  placeholder,
  disabled = false,
  validationSchema,
  customValidation = null,
  className = '',
  forceUpperCase = false,
  multiline = false,
  changeTimeout = 1000,
  blurFunction = null,
  onEnterFunction = null,
  autofocus = false,
  doAutoSave = true,
}) => {
  const [inputValue, setValue] = useState(value);
  const [errorMessages, setErrors] = useState({});
  const [timer, setTimer] = useState(null);
  const [inputRef, setInputRef] = useState(null);

  const handleValidation = (schema, value) => {
    let newErrors = {};
    schema.validate({ [name]: value }).catch(({ errors, params }) => {
      if (params.path === name) {
        console.error(`InputField error found for ${name}: ${value}`);
        newErrors[name] = errors[0];
      }
    });
    setErrors(newErrors); // will cause rerender
  };
  /**
   * Once the user stops typing we save their input after 1 second of inactivity
   */
  const autoSave = () => {
    setTimer(clearTimeout(timer));
    setTimer(
      setTimeout(() => {
        handleBlur(inputValue, name);
      }, changeTimeout),
    );
  };

  const updateInput = value => {
    if (forceUpperCase) value = value ? value.toUpperCase() : '';
    if (!validationSchema) {
      setValue(value); // If no validation required just update the value;
    } else if (customValidation) {
      setErrors({ [name]: customValidation });
    } else {
      handleValidation(validationSchema, value);
    }
    setValue(value);
  };

  useEffect(() => {
    // if (value) updateInput(value);
    updateInput(value);
  }, [value]);

  return (
    <TextField
      id={`${name}-${type}`}
      name={name}
      ref={r => {
        setInputRef(r);
      }}
      type={type}
      variant="outlined"
      value={inputValue}
      multiline={multiline}
      label={label}
      autoFocus={autofocus}
      onKeyDown={e => {
        if (e.key === 'Enter' && onEnterFunction) {
          console.log(`blurring input`);
          inputRef.blur();
          onEnterFunction(inputValue);
        }
      }}
      onKeyUp={doAutoSave && autoSave}
      placeholder={placeholder}
      error={Boolean(errorMessages[name])}
      onChange={e => updateInput(e.target.value)}
      helperText={errorMessages[name] || ''}
      onBlur={() => handleBlur(inputValue, name)}
      InputProps={inputProps}
      SelectProps={selectProps}
      disabled={disabled}
      className={className}
    />
  );
};
