/* eslint-disable */

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { Typography, Card, CardContent, CardActions, Chip } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import ActionMenu from '../styled_components/ActionMenu';
import StatusButton from '../styled_components/StatusButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';
import PublishIcon from '@material-ui/icons/Publish';
import Person from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { PinButton } from '../styled_components/PinButton';

import {
  setUserColleguePref,
  updateViewState,
  handleMenu,
  dispatchUpdateViewState,
  saveCurrentProject,
  rollUpDates,
} from '../../data/actions/userInterface';
import { useTheme } from '@material-ui/styles';
import { stickyNoteTilt } from '../../data/actions/app';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { red, yellow, grey } from '@material-ui/core/colors';
import * as utils from '../../utils/generalUtilities';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: ({ thin }) => (thin ? '2px' : '8px'),
    marginRight: ({ thin }) => (thin ? '2px' : '8px'),
    boxShadow: '3px 6px 6px -1px rgba(138, 148, 159, 0.7)',
    // boxShadow: '1px 1px 4px 0 rgba(138, 148, 159, 0.7)',
    border: `solid 1.2px ${theme.palette.primary.main}`,
    // paddingBottom: '0',
    cursor: 'pointer',
    zIndex: 5,
    background: theme.palette.workCard.notStarted,
    borderColor: theme.palette.workCard.notStartedBorder,
    // backgroundColor: 'red',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: ({ thin }) => (thin ? 6 : 0),
    marginLeft: ({ thin }) => (thin ? 15 : 0),
    marginRight: ({ thin }) => (thin ? 10 : 0),
    marginBottom: ({ thin }) => (thin ? -16 : 0),
    padding: ({ thin }) => (thin ? 0 : undefined),
  },
  nameRow: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rightSideStuff: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: ({ isSelected }) => (isSelected ? 5 : 0),
    marginBottom: -5,
  },
  thinInfoButton: {
    marginLeft: 10,
    alignSelf: 'flex-start',
    marginTop: -4,
  },
  started: {
    // background: theme.palette.primary.main,
    background: theme.palette.workCard.started,
    borderColor: theme.palette.workCard.startedBorder,
  },
  completed: {
    // background: theme.palette.secondary.light,
    background: theme.palette.workCard.completed,
    borderColor: theme.palette.workCard.startedcompletedBorderBorder,
  },
  header: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  selected: {
    border: `solid 1.2px ${theme.palette.secondary.main}`,
    transform: ({ cardUUID, thin }) =>
      thin ? undefined : `rotate(${stickyNoteTilt(cardUUID)}deg) scale(1.04, 1.04)`,
    // boxShadow: ({ thin }) => (thin ? 0 : '2px 8px 8px 0px rgba(138, 148, 159, 1)'),
    // marginTop: ({ thin }) => (thin ? undefined : '10px'),
    // marginBottom: ({ thin }) => (thin ? undefined : '25px'),
    boxShadow: ({ thin }) => '2px 8px 8px 0px rgba(138, 148, 159, 1)',
    marginTop: ({ thin }) => (thin ? '16px' : '10px'),
    marginBottom: ({ thin }) => (thin ? '16px' : '25px'),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  thinName: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 15,
    alignSelf: 'center',
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  nameText: {
    color: ({ isStarted }) => (isStarted ? theme.palette.text.secondary : theme.palette.text.notStarted),
  },
  thinChipContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    // backgroundColor: 'yellow',
  },
  chipContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // backgroundColor: 'green'
  },
  chip: {
    // border: 'solid 1px #e0e0e0',
    border: 'solid 1px #C250E2A0',
    background: '#C250E225',
    // background: 'none',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginTop: ({ thin }) => (thin ? 1 : 0),
    marginRight: ({ thin }) => (thin ? 10 : 0),
    marginLeft: ({ thin }) => (thin ? 10 : 0),
    // backgroundColor: 'yellow',
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  assignedToTooltip: {
    fontSize: 20,
    marginRight: ({ thin }) => (thin ? 10 : 0),
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },

  thinActionMenu: {
    marginLeft: 10,
    alignSelf: 'center',
    // backgroundColor: 'yellow',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statusButton: {
    width: '120px',
  },
  taskDate: {
    maxHeight: ({ thin }) => (thin ? undefined : '.7em'),
    marginLeft: ({ thin }) => (thin ? 10 : 0),
    // backgroundColor: 'yellow',
  },
  percentSize: {
    fontSize: ({ thin }) => (thin ? 14 : 14),
    marginLeft: ({ thin }) => (thin ? 5 : 2),
    // backgroundColor: 'yellow',
  },
  dateString: {
    fontSize: ({ thin }) => (thin ? 15 : '.7em'),
    marginTop: ({ thin }) => (thin ? 0 : -5),
    // backgroundColor: 'yellow',
  },
  pinButton: {
    marginLeft: 5,
  },
}));

const SplitWPTaskCard = ({ task, index, userPrefs, setUserColleguePref }) => {
  if (!task) return null;

  const { actualStartDate, isPriority, actualEndDate, assignedTo, refId, name, status } = task;

  // const thisCard = useRef(null);
  const [rerender, setRerender] = useState(new Date().getTime());

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });

  const isSelected = false;
  const isStarted = status === STATUS.IN_PROGRESS || status === STATUS.IN_REVIEW || status === STATUS.BLOCKED;
  const isCompleted = status === STATUS.DONE;
  // const isStarted = actualStartDate !== '';
  // const isCompleted = actualEndDate !== '';
  const classes = useStyles({ thin: true, cardUUID: task.uuid, isSelected, isStarted, isCompleted });
  const avatarId = assignedTo ? assignedTo.substring(0, 1).toUpperCase() : null;
  let collegueColor;
  if (assignedTo) {
    if (userPrefs.collegues[assignedTo]) {
      collegueColor = userPrefs.collegues[assignedTo].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: assignedTo,
        color: collegueColor,
      };
    }
  }

  return (
    <Draggable
      key={`wc-${task.uuid}`} // This is not ideal and it's not a long term solution but creating this since we don't have unique IDs available on cards.
      draggableId={task.uuid}
      index={index}
      shouldRespectForceTouch={false}
    >
      {(dragProvided, dragSnapshot) => (
        <div>
          <Card
            key={`card-${task.uuid}`}
            id={task.uuid}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            className={`${isSelected ? classes.selected : ''}
              ${classes.card} ${isCompleted ? classes.completed : ''} ${isStarted ? classes.started : ''}`}
            // ${classes.card} ${actualEndDate !== '' ? classes.completed : ''} ${
            //   actualStartDate !== '' ? classes.started : ''
            // }`}
            variant={isSelected ? 'outlined' : 'elevation'}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.nameRow}>
                {refId && (
                  <Tooltip title={'unique ID'}>
                    <div className={classes.thinChipContainer}>
                      <Chip label={refId} className={classes.chip} />
                    </div>
                  </Tooltip>
                )}
                <Typography variant="body1" component="h2" className={classes.thinName} align="left">
                  {name}
                </Typography>
              </div>
              <div className={classes.rightSideStuff}>
                {isPriority && <PriorityIcon />}
                {avatarId ? (
                  <Tooltip title={assignedTo} className={classes.assignedToTooltip}>
                    <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                      <Typography className={classes.assignedToText}>{avatarId}</Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                    <Person className={classes.notAssignedTo} />
                  </div>
                )}
                <StatusButton task={task} onChangeFn={setRerender} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      userPrefs: state.userPrefs,
    };
  },
  {
    updateViewState,
    handleMenu,
    setUserColleguePref,
    dispatchUpdateViewState,
    saveCurrentProject,
    rollUpDates,
  },
)(SplitWPTaskCard);
