import * as ACTION from '../actions/actionTypes';
import * as schema from '../projectSchema.js';

const INITIAL_STATE = null;

export default function(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      console.log(`rehydrating workflows...`);
      if (action.payload && action.payload.workflows) {
        let updatedWorkFlows = { 0: [] };
        if (Array.isArray(action.payload.workflows)) {
          action.payload.workflows.forEach(wf => {
            if (!wf.deletedDate) {
              delete wf.corruptData;
              if (wf.version != schema.SCHEMA_VERSION) {
                const updatedTemplate = schema.migrateTemplateSchema(wf);
                updatedWorkFlows[0].push(updatedTemplate);
              } else {
                updatedWorkFlows[0].push(wf);
              }
            }
          });
        } else {
          Object.keys(action.payload.workflows).forEach(setId => {
            action.payload.workflows[setId].forEach(swf => {
              if (!swf.deletedDate) {
                delete swf.corruptData;
                if (!updatedWorkFlows[setId]) updatedWorkFlows[setId] = [];
                if (swf.version != schema.SCHEMA_VERSION) {
                  const updatedTemplate = schema.migrateTemplateSchema(swf);
                  updatedWorkFlows[setId].push(updatedTemplate);
                } else {
                  updatedWorkFlows[setId].push(swf);
                }
              }
            });
          });
        }
        console.log(`rehydrating workflows... done`);

        return updatedWorkFlows;
      }
      return state;

    case ACTION.SET_USER_INFO:
    case ACTION.UPDATE_WORKFLOWS:
      return action.workflows;

    case ACTION.SET_GROUP:
      return action.workflows ? action.workflows : state;

    case ACTION.CLEAR_USER_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
}
